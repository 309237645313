import React from "react";
import {BrowserRouter, Redirect, Switch} from "react-router-dom";
import {Routes, RouteType} from './router-constants';
import LotsPage from '../pages/lots-page/lots-page';
import DashboardPage from '../pages/dashboard-page/dashboard-page';
import LoginPage from "../pages/login-page/login-page";
import ChangePasswordPage from "../pages/change-password-page/change-password-page";
import AuthRoute from './components/auth-route'
import AnalysesPage from "../pages/analyses-page/analyses-page";
import LaboratoriesPage from "../pages/laboratories-page/laboratories-page";
import ProductsPage from "../pages/products-page/products-page";
import UsersPage from "../pages/users-page/users-page";
import ProfilePage from "../pages/profile-page/profile-page";
import CompaniesPage from "../pages/companies-page/companies-page";
import ChangePasswordRequestPage from "../pages/change-password-request-page/change-password-request-page";
import {UserRole} from "../utils/enums/user-role";
import LaboratoryDetailsPage from "../pages/laboratory-details-page/laboratory-details-page";
import CategoriesAnalysesPage from "../pages/analyses-categories-page/analyses-categories-page";
import ProductDetailsPage from "../pages/product-details-page/product-details-page";
import DocumentsPage from "../pages/documents-page/documents-page";
import LotDetailsPage from "../pages/lot-details-page/lot-details-page";
import StatsPage from "../pages/stats-page/stats-page";
import AllDocumentsPage from "../pages/all-documents-page/all-documents-page";
import ImportsPage from "../pages/imports-page/imports-page";
import {DocumentType} from "../utils/enums/document-type";

let Router = () => (
    <BrowserRouter>
        <Switch>
            {/* Public pages */}
            <AuthRoute path={Routes.LOGIN_PAGE} type={RouteType.PUBLIC} exact component={LoginPage}/>
            <AuthRoute path={Routes.CHANGE_PASSWORD_REQUEST_PAGE} type={RouteType.PUBLIC} exact component={ChangePasswordRequestPage}/>
            <AuthRoute path={Routes.CHANGE_PASSWORD_PAGE} type={RouteType.PUBLIC} exact component={ChangePasswordPage}/>
            <AuthRoute path={Routes.CREATE_PASSWORD_PAGE} type={RouteType.PUBLIC} exact
                       component={(props) => <ChangePasswordPage isCreation={true} {...props}/>}/>

            {/* Private pages */}
            <AuthRoute path={Routes.DASHBOARD_PAGE} type={RouteType.PRIVATE} exact component={DashboardPage}
                       rights={[UserRole.SUPER_ADMINISTRATOR, UserRole.COMPANY_ADMINISTRATOR, UserRole.SUPERVISOR, UserRole.CONTRIBUTOR, UserRole.VISITOR]}/>
            <AuthRoute path={`${Routes.LOTS_PAGE}/:id/documents`} type={RouteType.PRIVATE} component={props => <DocumentsPage documentType={DocumentType.LOT} {...props}/>}
                       rights={[UserRole.SUPER_ADMINISTRATOR, UserRole.COMPANY_ADMINISTRATOR, UserRole.SUPERVISOR, UserRole.CONTRIBUTOR]}/>
            <AuthRoute path={`${Routes.LOTS_PAGE}/:id`} type={RouteType.PRIVATE} component={LotDetailsPage}
                       rights={[UserRole.SUPER_ADMINISTRATOR, UserRole.COMPANY_ADMINISTRATOR, UserRole.SUPERVISOR, UserRole.CONTRIBUTOR, UserRole.VISITOR]}/>
            <AuthRoute path={Routes.LOTS_PAGE} type={RouteType.PRIVATE} exact component={LotsPage}
                       rights={[UserRole.SUPER_ADMINISTRATOR, UserRole.COMPANY_ADMINISTRATOR, UserRole.SUPERVISOR, UserRole.CONTRIBUTOR, UserRole.VISITOR]}/>
            <AuthRoute path={Routes.PRODUCTS_PAGE} type={RouteType.PRIVATE} exact component={ProductsPage}
                       rights={[UserRole.SUPER_ADMINISTRATOR, UserRole.COMPANY_ADMINISTRATOR, UserRole.SUPERVISOR, UserRole.CONTRIBUTOR, UserRole.VISITOR]}/>
            <AuthRoute path={`${Routes.PRODUCTS_PAGE}/:id/documents`} type={RouteType.PRIVATE} component={props => <DocumentsPage documentType={DocumentType.PRODUCT} {...props}/>}
                       rights={[UserRole.SUPER_ADMINISTRATOR, UserRole.COMPANY_ADMINISTRATOR, UserRole.SUPERVISOR, UserRole.CONTRIBUTOR]}/>
            <AuthRoute path={`${Routes.PRODUCTS_PAGE}/:id`} type={RouteType.PRIVATE} component={ProductDetailsPage}
                       rights={[UserRole.SUPER_ADMINISTRATOR, UserRole.COMPANY_ADMINISTRATOR, UserRole.SUPERVISOR, UserRole.CONTRIBUTOR, UserRole.VISITOR]}/>
            <AuthRoute path={Routes.ANALYSES_PAGE} type={RouteType.PRIVATE} exact component={AnalysesPage}
                       rights={[UserRole.SUPER_ADMINISTRATOR, UserRole.COMPANY_ADMINISTRATOR, UserRole.SUPERVISOR, UserRole.CONTRIBUTOR, UserRole.VISITOR]}/>
            <AuthRoute path={Routes.CATEGORIES_ANALYSES_PAGE} type={RouteType.PRIVATE} exact component={CategoriesAnalysesPage}
                       rights={[UserRole.SUPER_ADMINISTRATOR, UserRole.COMPANY_ADMINISTRATOR, UserRole.SUPERVISOR, UserRole.CONTRIBUTOR]}/>
            <AuthRoute path={Routes.LABORATORIES_PAGE} type={RouteType.PRIVATE} exact component={LaboratoriesPage}
                       rights={[UserRole.SUPER_ADMINISTRATOR, UserRole.COMPANY_ADMINISTRATOR, UserRole.SUPERVISOR, UserRole.CONTRIBUTOR, UserRole.VISITOR]}/>
            <AuthRoute path={`${Routes.LABORATORIES_PAGE}/:id/documents`} type={RouteType.PRIVATE} component={props => <DocumentsPage documentType={DocumentType.LABORATORY} {...props}/>}
                       rights={[UserRole.SUPER_ADMINISTRATOR, UserRole.COMPANY_ADMINISTRATOR, UserRole.SUPERVISOR, UserRole.CONTRIBUTOR]}/>
            <AuthRoute path={`${Routes.LABORATORIES_PAGE}/:id`} type={RouteType.PRIVATE} component={LaboratoryDetailsPage}
                       rights={[UserRole.SUPER_ADMINISTRATOR, UserRole.COMPANY_ADMINISTRATOR, UserRole.SUPERVISOR, UserRole.CONTRIBUTOR, UserRole.VISITOR]}/>
            <AuthRoute path={Routes.PROFILE_PAGE} type={RouteType.PRIVATE} exact component={ProfilePage}/>
            <AuthRoute path={Routes.USERS_PAGE} type={RouteType.PRIVATE} exact component={UsersPage}
                       rights={[UserRole.SUPER_ADMINISTRATOR, UserRole.COMPANY_ADMINISTRATOR]}/>
            <AuthRoute path={Routes.COMPANIES_PAGE} type={RouteType.PRIVATE} exact component={CompaniesPage}
                       rights={[UserRole.SUPER_ADMINISTRATOR]}/>
            <AuthRoute path={Routes.STATS_PAGE} type={RouteType.PRIVATE} exact component={StatsPage}
                       rights={[UserRole.SUPER_ADMINISTRATOR, UserRole.COMPANY_ADMINISTRATOR, UserRole.SUPERVISOR]}/>
            <AuthRoute path={Routes.ALL_DOCUMENTS_PAGE} type={RouteType.PRIVATE} exact component={AllDocumentsPage}/>
            <AuthRoute path={Routes.IMPORTS_PAGE} type={RouteType.PRIVATE} exact component={ImportsPage}
                       rights={[UserRole.COMPANY_ADMINISTRATOR]}/>

            {/* Page not found */}
            <Redirect to={Routes.LOGIN_PAGE}/>
        </Switch>
    </BrowserRouter>
);

export default Router;
