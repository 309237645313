import {useDrag} from "react-dnd";
import T from "i18n-react";
import React from "react";

const PaneAnalyseElement = ({analyse, dndItemType}) => {

    const [{opacity}, dragRef] = useDrag(
        () => ({
            type: dndItemType,
            item: {analyse},
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.5 : 1
            })
        }), [analyse]
    )

    return (
        <tr className="pane-analyse-element" ref={dragRef} style={{opacity}}>
            <td className="text-start p-3 pe-2">
                <div className="title p-0">{T.translate('table.columns.name')}</div>
                <div className="value p-0">{analyse.analysisInfo.name}</div>
            </td>
            <td className="text-start p-3 px-2">
                <div className="title p-0">{T.translate('table.columns.method')}</div>
                <div className="value p-0">{analyse.analysisInfo.method}</div>
            </td>
            <td className="text-start p-3 ps-2">
                <div className="title p-0">{T.translate('table.columns.technique')}</div>
                <div className="value p-0">{analyse.analysisInfo.technique ?? "-"}</div>
            </td>
        </tr>
    );
}

export default PaneAnalyseElement;