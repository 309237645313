import React from 'react'
import {Form} from "react-bootstrap-v5";
import './page-form-template.scss';
import logo from "../../assets/images/logo-ciklab.png";
import Page from "./page";

export default function PageFormTemplate({pageTitle, onSubmit, children, className}) {
    return (
        <Page pageTitle={pageTitle}>
            <main id="pageForm" className={`d-flex h-100 w-100 bg-light ${className}`}>
                <Form className="m-auto p-4 text-center" onSubmit={onSubmit}>
                    <div className="mb-5">
                        <img src={logo} alt="Logo Ciklab" className="w-100"/>
                    </div>
                    {children}
                </Form>
            </main>
        </Page>
    )
}
