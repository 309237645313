import React from 'react';
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Routes} from "../../../../router/router-constants";
import {connect} from "react-redux";
import {companyActions} from "../../../../redux/company/actions-company";
import {companyService} from "../../../../services/company-service";
import CompanyEntity from "../../../../models/entities/company-entity";
import {alertActions} from "../../../../redux/alert/actions-alert";
import T from 'i18n-react'
import {ToastClass} from "../../../toast-alert/toast-alert-class";

const NavUserItem = ({title, subtitle, icon, route, onNavigateToRoute, currentUser, showEditCompanyModal, showToast}) => {

    const history = useHistory();
    const navigateTo = path => history.push(path);

    const handleClick = async () => {
        if (route === Routes.MY_COMPANY_PAGE) {
            try {
                const companyData = await companyService.getCompanyById(currentUser.companyId);
                const companyEntity = new CompanyEntity(companyData);
                showEditCompanyModal(companyEntity);
                onNavigateToRoute();
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        } else {
            onNavigateToRoute();
            navigateTo(route);
        }
    }

    return (
        <div className="nav-link d-flex w-100 align-items-center" onClick={handleClick}>
            <div className="icon me-2"><FontAwesomeIcon icon={icon}/></div>
            <div>
                <div className="title">{title}</div>
                <div className="description">{subtitle}</div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showEditCompanyModal: company => dispatch(companyActions.companyEdit(company)),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavUserItem);