import React, {useState} from 'react';
import {FormControl, InputGroup} from "react-bootstrap-v5";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import T from "i18n-react";
import {InputMaxLength} from "../../utils/enums/input-max-length";

const SearchInput = ({className, onChange, initialValue}) => {

    const [inputValue, setInputValue] = useState(initialValue);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <InputGroup className={`search-input ${className}`}>
            <InputGroup.Text id="search-input">
                <FontAwesomeIcon icon="search"/>
            </InputGroup.Text>
            <FormControl
                value={inputValue}
                className="ps-0"
                placeholder={T.translate('table.search')}
                aria-label={T.translate('table.search')}
                aria-describedby="search-input"
                onChange={handleInputChange}
                maxLength={InputMaxLength.STANDARD_TEXT}
            />
        </InputGroup>
    );
}

export default SearchInput;