import iconAccepted from "../../assets/images/icons/accepted.svg";
import iconToDestroy from "../../assets/images/icons/to-destroy.svg";
import iconToRebuild from "../../assets/images/icons/to-rebuild.svg";
import iconToResend from "../../assets/images/icons/to-resend.svg";

export const NotCompliantReason = {
    TO_DESTROY: "TO_DESTROY",
    TO_RESEND: "TO_RESEND",
    TO_REBUILD: "TO_REBUILD",
    ACCEPTED_WITH_DISPENSATION: "ACCEPTED_WITH_DISPENSATION"
}

export const NotCompliantReasonMessageKey = {
    TO_DESTROY: "notCompliantReason.toDestroy",
    TO_RESEND: "notCompliantReason.toResend",
    TO_REBUILD: "notCompliantReason.toRebuild",
    ACCEPTED_WITH_DISPENSATION: "notCompliantReason.acceptedWithDispensation"
}

export const NotCompliantReasonIcon = {
    TO_DESTROY: iconToDestroy,
    TO_RESEND: iconToResend,
    TO_REBUILD: iconToRebuild,
    ACCEPTED_WITH_DISPENSATION: iconAccepted,
}