import React from 'react';
import {Card, Tab, Tabs} from "react-bootstrap-v5";
import './card-tabs.scss'

const CardTabs = ({className, tabs}) => (
    <Card className={`card-tabs ${className}`}>
        <Card.Body>
            {!!tabs && tabs.length > 0 && (
                <Tabs defaultActiveKey={tabs[0].key} className="m-0 border-0">
                    {tabs.map(tab => (
                        <Tab eventKey={tab.key} title={<Card.Title className="m-0 py-2">{tab.title}</Card.Title>} tabClassName="m-0 border-0 w-50 text-start">
                            {tab.content}
                        </Tab>
                    ))}
                </Tabs>
            )}
        </Card.Body>
    </Card>
);

export default CardTabs;