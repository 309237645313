import React, {useEffect, useState} from 'react';
import {VictoryAxis, VictoryChart, VictoryLabel, VictoryScatter} from "victory";
import variables from '../../assets/styles/variables.scss';

const maxSize = 50;
const minSize = 25;
const maxFontSize = 30;
const minFontSize = 15;

const BubbleChart = ({dataset}) => {
    const currentYear = new Date().getFullYear();
    const colors = [variables.primary, variables.purpleGrey];

    const [data, setData] = useState([]);

    useEffect(() => {
        const maxY = Math.max(...dataset.map(d => d.y));
        const newDataset = dataset.map(d => {
            return {
                x: d.x,
                y: Math.max(minSize / 4, Math.min(maxSize / 4, (d.y / (maxY === 0 ? 1 : maxY)) * (maxSize - minSize) / 2)),
                size: Math.max(minSize, Math.min(maxSize, d.y * maxSize / (maxY === 0 ? 1 : maxY))),
                value: d.y
            };
        });
        setData(newDataset);
    }, [dataset]);

    const labelComponent = (
        <VictoryLabel
            verticalAnchor="middle"
            textAnchor="middle"
            dx={0}
            dy={0}
            style={[{
                fill: "white",
                fontSize: ({datum}) => (datum.size - minSize / 1.5) / (maxSize - minSize) * (maxFontSize - minFontSize) + minFontSize,
            }]}
        />
    );

    return (
        <VictoryChart height={200} width={200}>
            <VictoryScatter
                height={200} width={200}
                style={{data: {fill: ({datum}) => colors[(currentYear - datum.x) % colors.length]}}}
                domain={{x: [currentYear - 1, currentYear], y: [0, maxSize / 2]}}
                bubbleProperty="size"
                maxBubbleSize={maxSize}
                minBubbleSize={minSize}
                data={data}
                labels={({datum}) => Math.round(datum.value)}
                labelComponent={labelComponent}
            />
            <VictoryAxis
                tickFormat={dataset.map(item => item.x)}
                tickLabelComponent={<VictoryLabel dy={10}/>}
                style={{
                    axis: {stroke: "transparent"},
                    tickLabels: {fill: variables.dark, fontFamily: variables.fontFamily},
                }}
            />
        </VictoryChart>
    );
};

export default BubbleChart;
