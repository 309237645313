import React, {useEffect, useState} from 'react';
import {FormSelect} from "react-bootstrap-v5";
import T from "i18n-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TablePagination from "./table-pagination";
import {TableSizeOptions} from "../../utils/enums/table-size-options";
import './table.scss'
import {SortDirection} from "../../utils/enums/sort-direction";
import CustomTable from "./custom-table";
import {cancelDebounce, debounceFunc} from "../../utils/functions/debounce-function";
import OverlayLoadingOrError from "../overlay-component/overlay-loading-or-error";

const RemoteTable = ({
                         id,
                         className,
                         children,
                         columns,
                         onFetchData,
                         responseData,
                         reload,
                         groupByName,
                         groupById,
                         useSizeSelect = true,
                         usePagination = true,
                         tableMinHeight,
                         useDragRow = false,
                         checkAll = false,
                         handleGroupChange,
                         handleGroupReorder,
                         indexName,
                         completeElement,
                         leftGroupNameColumnId,
                         searchText = '',
                         defaultSortedField = '',
                         defaultSortedOrder = SortDirection.ASC,
                         isSavingCell = false,
                         isOverflowYHidden = true,
                         tableSize = "50"
                     }) => {

    const sortableColumns = columns.map(col => {
        return {
            ...col,
            id: col.dataField,
            accessor: col.dataField,
            Header: ({column}) => {
                return (
                    <div className={`column-title d-flex justify-content-between align-items-center ${col.sort ? "sortable-column" : ""}`}
                        onClick={event => !col.sort ? event.stopPropagation() : handleSort(event, col.dataField)}>
                        <div>{column.text}</div>
                        {
                            col.sort &&
                            <div className={`order-icon order-${col.dataField !== state.sortedField || !state.sortedOrder ? "none" : state.sortedOrder}`}>
                                <FontAwesomeIcon
                                    className={`order-icon-desc ${col.dataField === state.sortedField && state.sortedOrder === SortDirection.DESC ? "d-none" : "d-block"}`}
                                    icon="chevron-up"/>
                                <FontAwesomeIcon
                                    className={`order-icon-asc ${col.dataField === state.sortedField && state.sortedOrder === SortDirection.ASC ? "d-none" : "d-block"}`}
                                    icon="chevron-down"/>
                            </div>
                        }
                    </div>
                )
            },
        };
    });

    const [state, setState] = useState({
        isLoading: true,
        isSavingCell: false,
        error: '',
        elements: [],
        page: 1,
        totalPages: 1,
        size: TableSizeOptions[tableSize],
        numberOfElements: 0,
        totalElements: 0,
        sortedField: defaultSortedField,
        sortedOrder: defaultSortedOrder,
        searchText: '',
    });

    useEffect(() => {
        if (responseData != null) {
            setState(prevState => {
                let updatedElements = prevState.elements.map((element, elementIndex) => {
                    if (element[indexName] === responseData[indexName]) {
                        return completeElement(responseData, elementIndex)
                    } else {
                        return element
                    }
                })
                return {
                    ...prevState,
                    elements: updatedElements,
                };
            })
        }
    }, [responseData, completeElement, indexName])

    useEffect(() => {
        const callApi = async () => {
            try {
                const dataFetched = await onFetchData(state.page, state.size, state.sortedOrder.toUpperCase(), state.sortedField, state.searchText);
                if (!dataFetched) return;
                setState(prevState => {
                    return {
                        ...prevState,
                        isLoading: false,
                        error: '',
                        elements: dataFetched.elements,
                        totalPages: dataFetched.totalPages,
                        numberOfElements: dataFetched.numberOfElements,
                        totalElements: dataFetched.totalElements
                    };
                })
            } catch (error) {
                if (error.response.status === "cancel") {
                    setState(prevState => {
                        return {...prevState, isLoading: true, error: ''};
                    })
                    return
                }
                setState(prevState => {
                    return {...prevState, isLoading: false, error: error.message};
                })
            }
        }

        setState(prevState => {
            return {...prevState, isLoading: true}
        })

        const idFunc = `remote-table-${id}`;
        if (!!state.searchText) {
            const optimizedCallApi = debounceFunc(callApi, idFunc);
            optimizedCallApi();
        } else {
            cancelDebounce(idFunc);
            callApi();
        }
    }, [onFetchData, state.page, state.size, state.sortedOrder, state.sortedField, state.searchText, reload, id]);

    useEffect(() => {
        if (searchText.length === 0 || searchText.length >= 3) {
            setState(prevState => ({...prevState, page: 1, searchText}))
        }
    }, [searchText]);


    useEffect(() => {
        setState(prevState => {
            return {
                ...prevState,
                isSavingCell,
            }
        });
    }, [isSavingCell])

    const handlePageChange = page => {
        setState({...state, page})
    }

    const handleSizeChange = e => {
        const size = e.target.value;
        setState({...state, page: 1, size})
    }

    const handleSort = (e, field) => {
        e.stopPropagation();
        let order;

        if (state.sortedField === "") {
            order = SortDirection.ASC;
        } else {
            order = state.sortedOrder === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
        }
        setState({...state, page: 1, sortedField: field, sortedOrder: order})
    }

    const sizeSelect = () => {
        let options = [];
        for (const size in TableSizeOptions) {
            options = [...options, <option key={size} value={size}>{size}</option>]
        }
        return (
            <FormSelect className="mx-2" onChange={handleSizeChange} defaultValue={state.size}>
                {options}
            </FormSelect>
        );
    }

    return (
        <div className={`remote-table ${className}`}>
            {useSizeSelect && (
                <div className="d-block d-sm-flex p-2 p-sm-4 justify-content-between align-item-center">
                    <div className="size-selector d-inline-flex align-items-center mb-2 mb-sm-0">
                        {T.translate('table.displaySize', {size: sizeSelect()})}
                    </div>
                </div>
            )}
            <div
                className={`${isOverflowYHidden ? "overflow-scroll-x" : "overflow-scroll-x-visible-y"} text-center position-relative ${!!state.error ? "error" : ""}`}
                style={{minHeight: !!tableMinHeight ? tableMinHeight : undefined}}>
                <CustomTable columns={sortableColumns} data={state.elements} groupByName={groupByName} groupById={groupById} onSort={handleSort}
                             handleGroupReorder={handleGroupReorder}
                             sortedField={state.sortedField} sortedOrder={state.sortedOrder} useDragRow={useDragRow} checkAll={checkAll} handleGroupChange={handleGroupChange}
                             leftGroupNameColumnId={leftGroupNameColumnId}
                             />
                <OverlayLoadingOrError error={state.error} isLoading={state.isLoading || state.isSavingCell}/>
            </div>
            {children}
            {
                usePagination &&
                <div className="d-block d-sm-flex justify-content-between align-item-center p-2 p-sm-4">
                    <div></div>
                    <TablePagination page={state.page} totalPages={state.totalPages} onPageChange={handlePageChange}/>
                </div>
            }
        </div>
    );
}

export default RemoteTable;
