import React, {useCallback, useEffect, useRef, useState} from 'react';
import T from "i18n-react";
import CustomModal from "../../../components/custom-modal/custom-modal";
import {alertActions} from "../../../redux/alert/actions-alert";
import {connect} from "react-redux";
import {Alert, Button, Col, Form, FormControl, Row, Spinner} from "react-bootstrap-v5";
import {analyseActions} from "../../../redux/analyse/actions-analyse";
import InputLabel from "../../../components/input-label/input-label";
import Validator from "../../../utils/validators/validator";
import {analyseService} from "../../../services/analyse-service";
import {SortDirection} from "../../../utils/enums/sort-direction";
import {UserRole} from "../../../utils/enums/user-role";
import LaboratoryTable from "./create-or-edit-analysis-laboratory-table";
import '../analysis-page.scss'
import AnalyseEntity from "../../../models/entities/analyse-entity";
import SelectLabel from "../../../components/input-label/select-label";
import {analyseCategoryService} from "../../../services/analyse-category-service";
import CompanyAutocompleteLabel from "../../../components/input-label/company-autocomplete-label";
import TagsInputCustom from "../../../components/tag/tag-inside-input";
import AnalyseDto from "../../../models/dto/analyse-dto";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";

const CreateOrEditAnalyseModal = ({analyseReducer, currentUser, showToast, hideCreateOrEditModal, showEditReferencesModal}) => {

    const [isLoading, setLoading] = useState({value: false, next: false});
    const [isNoParameterMode, setNoParameterMode] = useState(true);

    const [analyseEntity, setAnalyseEntity] = useState({});

    const [company, setCompany] = useState({
        id: currentUser?.companyId ?? '',
        name: '',
    });
    const [name, setName] = useState('');
    const [method, setMethod] = useState('');
    const [technique, setTechnique] = useState('');
    const [unit, setUnit] = useState('');
    const [category, setCategory] = useState({id: '', name: ''});
    const [mainLaboratoryId, setMainLaboratoryId] = useState('');
    const [laboratoryList, setLaboratoryList] = useState([]);

    const [categoryList, setCategoryList] = useState([]);

    const [parameterList, setParameterList] = useState([]);


    function checkIfAnAnalysisIsStoredInsideTheReducer() {
        return Object.keys(analyseReducer.analysisBeingCreatingOrModified).length !== 0;
    }

    function setParameterListWithUniqueValues(laboratoryParameters) {
        setParameterList(Array.from(new Set(
            laboratoryParameters?.map(({parameterName}) => parameterName) ?? [])
        ));
    }

    useEffect(() => {
        setNoParameterMode(parameterList.length === 0)
    }, [parameterList])

    useEffect(() => {
        setParameterListWithUniqueValues(analyseReducer.analysisBeingCreatingOrModified?.laboratoryParameters);
    }, [analyseReducer.analysisBeingCreatingOrModified?.laboratoryParameters])

    useEffect(() => {
        const getCategoryList = async () => {
            if (!company.id) {
                setCategoryList([]);
                setCategory({id: '', name: ''})
                return;
            }
            try {
                const data = await analyseCategoryService.getAll(1, 1000, SortDirection.ASC.toUpperCase(), 'name', '', company.id)
                const categories = data.content?.map(cat => {
                    return {id: cat.analysisCategoryId, name: cat.name}
                }) ?? [];
                setCategoryList(categories);
            } catch (error) {
                setApiError(error.message);
            }
        };
        if (analyseReducer.showCreateOrEditModal) {
            getCategoryList();
        }
    }, [company.id, analyseReducer.showCreateOrEditModal]);

    useEffect(() => {
        if (categoryList.length > 0 && !analyseEntity?.category?.name) {
            setCategory(categoryList[0]);
        }
    }, [categoryList])

    useEffect(() => {
        setCompany({
            id: currentUser?.companyId ?? '',
            name: '',
        });
    }, [currentUser])

    const initialStateInputErrors = {
        company: '',
        name: '',
        method: '',
        technique: '',
        category: '',
        laboratoryList: ''
    };

    const [inputErrors, setInputErrors] = useState({...initialStateInputErrors});
    const [apiError, setApiError] = useState('');

    const resetErrors = () => {
        setInputErrors({...initialStateInputErrors});
        setApiError('');
    }

    const resetFormRef = useRef(null);

    const resetForm = useCallback((analyseEntity) => {
        setCompany({
            id: analyseEntity?.company?.id ?? currentUser.companyId ?? '',
            name: analyseEntity?.company?.name ?? '',
        });
        setName(analyseEntity?.name ?? '');
        setMethod(analyseEntity?.method ?? '');
        setTechnique(analyseEntity?.technique ?? '');
        setUnit(analyseEntity?.unit ?? '');
        const defaultCategory = categoryList.length > 0 ? categoryList[0] : {id: '', name: ''};
        setCategory(analyseEntity?.category ?? defaultCategory);
        setMainLaboratoryId(analyseEntity?.mainLaboratoryId);
        setLaboratoryList(analyseEntity?.laboratoryList ?? []);
        setParameterListWithUniqueValues(analyseEntity?.laboratoryParameters);
    }, [currentUser, categoryList]);

    useEffect(() => {
        const getAnalyse = async () => {
            try {
                const analyseData = await analyseService.getAnalyse(analyseReducer.analyseId)
                const analyseFetched = new AnalyseEntity(analyseData);
                setAnalyseEntity(analyseFetched);
                if (currentUser) resetForm(analyseFetched);
            } catch (error) {
                setApiError(error.message);
            }
        }
        // On récupère l'analyse du back seulement lors de la première ouverture
        if (!analyseReducer.isCreationMode && !!analyseReducer.analyseId && !checkIfAnAnalysisIsStoredInsideTheReducer()) {
            getAnalyse();
        } else if (checkIfAnAnalysisIsStoredInsideTheReducer()) {
            setAnalyseEntity(analyseReducer.analysisBeingCreatingOrModified);
            if (currentUser) resetForm(analyseReducer.analysisBeingCreatingOrModified);
        }

    }, [analyseReducer.analyseId, analyseReducer.isCreationMode, analyseReducer.analysisBeingCreatingOrModified])

    const validateMainLaboratoryId = () => {
        if (Validator.isNullOrEmpty(mainLaboratoryId)) {
            return 'form.validation.shouldHaveMainLaboratory';
        }
        return '';
    }

    const validate = () => {
        const companyError = Validator.validateRequired(company.id);
        const nameError = Validator.validateRequired(name);
        const methodError = Validator.validateRequired(method);
        const categoryError = Validator.validateRequired(category.id);
        const mainLaboratoryIdError = validateMainLaboratoryId();
        const laboratoryListError = Validator.validateNonEmptyList(laboratoryList);
        const isValid = !companyError && !nameError && !methodError && !categoryError && !mainLaboratoryIdError && !laboratoryListError;
        setInputErrors({
            ...inputErrors,
            company: T.translate(companyError).toString(),
            name: T.translate(nameError).toString(),
            method: T.translate(methodError).toString(),
            category: T.translate(categoryError).toString(),
            laboratoryList: T.translate(!!laboratoryListError ? laboratoryListError : mainLaboratoryIdError).toString()
        })
        return isValid;
    }

    const hideModal = () => {
        setAnalyseEntity({})
        resetForm({});
        resetErrors();
        hideCreateOrEditModal();
    }

    const createOrEditAnalyse = async (analyse, continueCreating) => {
        setLoading({value: true, next: continueCreating});
        try {
            if (analyseReducer.isCreationMode) {
                await analyseService.createAnalyse(analyse);
            } else {
                await analyseService.editAnalyse(analyseReducer.analyseId, analyse);
            }
            setLoading({value: false, next: false});
            showToast(T.translate('alert.successTitle'), T.translate(`analyse.${analyseReducer.isCreationMode ? 'successCreateAnalyse' : 'successEditAnalyse'}`), ToastClass.SUCCESS);
            resetForm(null);
            if (!continueCreating) {
                hideModal();
            }
        } catch (error) {
            setLoading({value: false, next: false});
            const field = error.response?.data?.entityIdField;
            const exceptionClass = error.response?.data?.exceptionClass;
            if (exceptionClass === "ObjectAlreadyExistsException" && field === "nameAndMethod") {
                setInputErrors({
                    ...inputErrors,
                    name: T.translate('error.analyse.nameAndMethodAlreadyExists', {name: analyse.name, method: analyse.method}),
                    method: T.translate('error.analyse.nameAndMethodAlreadyExists', {name: analyse.name, method: analyse.method})
                });
            } else {
                setApiError(error.message);
            }
        }
    }

    const submitCreateAnalyseForm = (e, continueCreating) => {
        e.preventDefault();

        resetErrors();
        if (validate()) {
            const analyse = new AnalyseDto({
                company,
                name,
                method,
                technique,
                unit,
                category,
                mainLaboratoryId,
                laboratoryList,
            }, analyseReducer.isCreationMode);
            createOrEditAnalyse(analyse, continueCreating);
        }
    }

    const handleNext = (e) => {
        e.preventDefault();

        resetErrors();
        if (validate()) {

            let laboratoryParametersUpdated = [];

            laboratoryParametersUpdated = analyseEntity.laboratoryParameters ?? [];
            laboratoryParametersUpdated = laboratoryParametersUpdated.filter(({parameterName}) => parameterList.includes(parameterName));
            laboratoryParametersUpdated = laboratoryParametersUpdated.filter(({laboratoryName}) => laboratoryList.some(({name}) => name === laboratoryName));
            parameterList.forEach(nameParameter => laboratoryList.forEach(lab => {
                if (!laboratoryParametersUpdated
                    .some(laboratoryParameter => laboratoryParameter.laboratoryName === lab.name && laboratoryParameter.parameterName === nameParameter)
                ) {
                    laboratoryParametersUpdated.push({
                        laboratoryId: lab.id,
                        laboratoryName: lab.name,
                        parameterName: nameParameter,
                        reference: ''
                    });
                }
            }));
            // }

            const analyse = {
                company,
                name,
                method,
                technique,
                unit,
                category,
                mainLaboratoryId,
                laboratoryList,
                laboratoryParameters: laboratoryParametersUpdated,
            }

            resetFormRef.current = resetForm;
            setAnalyseEntity({});
            showEditReferencesModal(analyse, resetFormRef);
        }
    }

    const handleCompanyChange = value => {
        setCompany(value);
    }

    const handleAddLaboratory = laboratory => {
        setLaboratoryList([...laboratoryList, laboratory]);
        if (!mainLaboratoryId) {
            setMainLaboratoryId(laboratory.id);
        }
    }

    const handleRemoveLaboratory = index => {
        let list = [...laboratoryList];
        const removeLaboratory = list.splice(index, 1)
        setLaboratoryList(list);
        if (mainLaboratoryId === removeLaboratory[0].id) {
            setMainLaboratoryId("");
        }
    }

    const handleMainLaboratoryIdChange = (e, id) => {
        if (e.target.checked) {
            setMainLaboratoryId(id);
        } else {
            setMainLaboratoryId('');
        }
    }

    const handleCategoryChange = e => {
        const selectedIndex = e.target.options.selectedIndex;
        setCategory(categoryList[selectedIndex]);
    }

    const categoryOptions = () => {
        return categoryList.map(categoryElement => (
            <option key={categoryElement.id} value={categoryElement} selected={categoryElement.id === category.id}>{categoryElement.name}</option>
        ))
    }

    const handleTagsChange = (parameters) => {
        setParameterList(parameters);
    };

    const footer = (
        <React.Fragment>
            <Button variant="outline-secondary" onClick={hideModal} className="px-3 py-1">
                {T.translate('form.button.cancel')}
            </Button>
            {
                isNoParameterMode && analyseReducer.isCreationMode &&
                <Button variant="dark" onClick={e => submitCreateAnalyseForm(e, true)} className="px-3 py-1" disabled={isLoading.value}>
                    {isLoading.value && isLoading.next && <Spinner as="span" size="sm" animation="border"/>}
                    {T.translate('form.button.confirmAndNew')}
                </Button>
            }
            {
                isNoParameterMode &&
                <Button variant="primary" onClick={e => submitCreateAnalyseForm(e, false)} className="px-3 py-1" disabled={isLoading.value}>
                    {isLoading.value && !isLoading.next && <Spinner as="span" size="sm" animation="border"/>}
                    {T.translate('form.button.confirm')}
                </Button>
            }
            {
                !isNoParameterMode &&
                <Button variant="primary" onClick={e => handleNext(e)} className="px-3 py-1">
                    {T.translate('form.button.next')}
                </Button>
            }
        </React.Fragment>
    );

    return (
        <CustomModal show={analyseReducer.showCreateOrEditModal} onHide={hideModal}
                     title={T.translate(`analyse.title.${analyseReducer.isCreationMode ? 'createAnalyse' : 'editAnalyse'}`)} footer={footer}>
            <Form>
                <Alert variant={"danger"} hidden={!apiError}>
                    <Alert.Heading>
                        {T.translate("alert.errorTitle")}
                    </Alert.Heading>
                    {apiError}
                </Alert>
                <Row>
                    <Col sm={12} lg={8}>
                        {
                            currentUser?.role === UserRole.SUPER_ADMINISTRATOR &&
                            <CompanyAutocompleteLabel id="companyId" className="mb-4" required={true} error={inputErrors.company}
                                                      defaultValue={company} onChange={handleCompanyChange} disabled={!analyseReducer.isCreationMode}/>
                        }
                        <SelectLabel id="category" className="mb-4" type="text" label={T.translate('form.label.category')} required={true}
                                     defaultValue={category} onChange={handleCategoryChange}
                                     error={inputErrors.category}
                                     options={categoryOptions()}/>

                        <InputLabel id="name" className="mb-4" type="text" label={T.translate('form.label.analyse.name')} required={true}
                                    value={name} onChange={e => setName(e.target.value)} error={inputErrors.name}/>

                        <InputLabel id="method" className="mb-4" type="text" label={T.translate('form.label.method')} required={true}
                                    value={method} onChange={e => setMethod(e.target.value)} error={inputErrors.method}/>

                        <InputLabel id="technique" className="mb-4" type="text" label={T.translate('form.label.technique')}
                                    value={technique} onChange={e => setTechnique(e.target.value)} error={inputErrors.technique}/>

                        <InputLabel id="unit" className="mb-4" type="text" label={T.translate('form.label.unit')}
                                    value={unit} onChange={e => setUnit(e.target.value)} error={inputErrors.unit}/>

                        <TagsInputCustom id="parameters" className="mb-4" label={T.translate('form.label.analyse.analysedParameters')}
                                         placeholder={T.translate('form.label.analyse.addAParameter')}
                                         tags={parameterList} onTagsChange={handleTagsChange}
                        ></TagsInputCustom>

                    </Col>
                </Row>
                <fieldset className="px-2">
                    <legend>{T.translate('form.fieldset.laboratories')}</legend>
                    <LaboratoryTable laboratoryList={laboratoryList} onAddLaboratory={handleAddLaboratory} onRemoveLaboratory={handleRemoveLaboratory}
                                     onMainLaboratoryIdChange={handleMainLaboratoryIdChange} mainLaboratoryId={mainLaboratoryId} companyId={company.id}
                                     onLaboratoryListChange={list => setLaboratoryList(list)}/>
                    <FormControl.Feedback type="invalid" className={!!inputErrors.laboratoryList ? "d-block m-0" : "d-none"}>
                        {inputErrors.laboratoryList}
                    </FormControl.Feedback>
                </fieldset>
            </Form>
        </CustomModal>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        analyseReducer: state.analyseReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideCreateOrEditModal: () => dispatch(analyseActions.hideCreateOrEditModal()),
        showEditReferencesModal: (analisys, resetFormRef) => dispatch(analyseActions.referencesEditModal(analisys, resetFormRef)),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrEditAnalyseModal);
