import React from 'react';
import {Form} from "react-bootstrap-v5";
import './input-label.scss'
import Autocomplete from "./autocomplete";

const AutocompleteLabel = ({id, className, label, placeholder, required, value, onChange, onSuggestionsFetch, onSelect, disabled, error}) => {

    return (
        <Form.Group controlId={id} className={`autocomplete-label row align-items-center ${className} ${disabled ? "disabled" : ""}`}>
            <Form.Label className="col col-4 text-end mb-0">{label}{!!required ? <span className="required">*</span> : ""}</Form.Label>
            <div className="col col-8">
                <Autocomplete id={id} error={error} placeholder={placeholder ?? label}
                              onChange={onChange} onSelect={onSelect} value={value}
                              onSuggestionsFetch={onSuggestionsFetch} disabled={disabled}
                />
                <Form.Control.Feedback type='invalid' className={!!error ? "d-block" : ""}>
                    {error}
                </Form.Control.Feedback>
            </div>
        </Form.Group>
    );
}

export default AutocompleteLabel;

