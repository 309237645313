import React, {useState} from 'react';
import {Dropdown, Image} from "react-bootstrap-v5";
import "./app-dropdown.scss";
import iconChevronUp from "../../assets/images/icons/chevron-up.svg";

const AppDropdown = ({className, title, toggleClassName, menuClassName, children, enabled = true}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = expand => {
        if (enabled) {
            setIsExpanded(expand);
        }
    }

    return (
        <Dropdown className={`app-dropdown d-flex vertical-aligns-center ${className} ${isExpanded ? 'expanded' : ''} ${enabled ? 'enabled' : 'disabled'}`} onToggle={handleToggle}>
            <Dropdown.Toggle className={`px-2 py-1 ${toggleClassName}`}>
                {title}
                {enabled && <Image src={iconChevronUp} className="icon-arrow ms-2"/>}
            </Dropdown.Toggle>
            {enabled && (
                <Dropdown.Menu className={menuClassName}>
                    {children}
                </Dropdown.Menu>
            )}
        </Dropdown>
    );
}

export default AppDropdown;