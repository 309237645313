import React from 'react';
import PageWithMenuTemplate from "../../components/template-pages/page-with-menu-template";
import T from "i18n-react";
import Header from "../../components/header/header";
import HeaderTextLabel from "../../components/header/header-text-label";
import FormExportCsv from "./components/form-export-csv";

const StatsPage = () => {
    return (
        <PageWithMenuTemplate pageTitle={T.translate('pageTitle.stats')}>
            <Header>
                <HeaderTextLabel className="me-4" isMain={true}>
                    <h1 className="ps-1">{T.translate('menu.stats')}</h1>
                </HeaderTextLabel>
            </Header>
            <FormExportCsv className="mx-2 mx-md-3"/>
        </PageWithMenuTemplate>
    );
}

export default StatsPage;