import React from 'react';
import {Image, OverlayTrigger, Tooltip} from "react-bootstrap-v5";
import {NotCompliantReasonIcon, NotCompliantReasonMessageKey} from "../../utils/enums/not-compliant-reason";
import T from "i18n-react";
import './status-component.scss';

const SubStatusComponent = ({id, subStatus}) => {
    const tooltip = (
        <Tooltip id={`tooltip-sub-status-component-${id}`} className="tooltip-sub-status-component d-flex align-items-center">
            {T.translate(NotCompliantReasonMessageKey[subStatus]).toUpperCase()}
        </Tooltip>
    );

    return (
        <div className="sub-status-component">
            <OverlayTrigger trigger={['hover', 'focus']} key={`tooltip-trigger-sub-status-component-${id}`} placement="right"
                            overlay={tooltip}>
                <Image src={NotCompliantReasonIcon[subStatus]}/>
            </OverlayTrigger>
        </div>
    );
};

export default SubStatusComponent;
