import {COMPANY_DELETED, COMPANY_HIDE_CREATE_OR_EDIT_MODAL, COMPANY_SHOW_CREATE_MODAL, COMPANY_SHOW_EDIT_MODAL} from "./type";

const companyCreate = () => {
    return {
        type: COMPANY_SHOW_CREATE_MODAL
    }
}

const companyEdit = company => {
    return {
        type: COMPANY_SHOW_EDIT_MODAL,
        payload: company
    }
}

const companyDeleted = () => {
    return {
        type: COMPANY_DELETED
    }
}

const hideCreateOrEditModal = () => {
    return {
        type: COMPANY_HIDE_CREATE_OR_EDIT_MODAL
    }
}

export const companyActions = {
    companyCreate,
    companyEdit,
    companyDeleted,
    hideCreateOrEditModal
};