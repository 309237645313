import {
    ANALYSE_DELETED,
    ANALYSE_EDIT_REFERENCES_PREVIOUS_MODAL,
    ANALYSE_HIDE_CREATE_OR_EDIT_MODAL,
    ANALYSE_HIDE_EDIT_REFERENCES_MODAL,
    ANALYSE_HIDE_IMPORT_MODAL,
    ANALYSE_LABORATORY_DELETED,
    ANALYSE_SHOW_CREATE_MODAL,
    ANALYSE_SHOW_EDIT_MODAL,
    ANALYSE_SHOW_EDIT_REFERENCES_MODAL,
    ANALYSE_SHOW_IMPORT_MODAL,
    ANALYSE_SHOW_LINK_MODAL,
    ANALYSE_HIDE_LINK_MODAL
} from "./type";

const initialStateAnalyse = {
    showCreateOrEditModal: false,
    showEditReferencesModal: false,
    showLinkModal: false,
    showImportModal: false,
    isCreationMode: true,
    analyseId: '',
    analysisBeingCreatingOrModified: {},
    resetFormRef: null,
    file: null,
    progress: 0
}


const AnalyseReducer = (state = initialStateAnalyse, action) => {
    switch (action.type) {
        case ANALYSE_SHOW_CREATE_MODAL:
            return {
                ...state,
                showCreateOrEditModal: true,
                showEditReferencesModal: false,
                isCreationMode: true,
                analyseId: '',
                analysisBeingCreatingOrModified: {},
            }
        case ANALYSE_SHOW_EDIT_MODAL:
            return {
                ...state,
                showCreateOrEditModal: true,
                showEditReferencesModal: false,
                isCreationMode: false,
                analyseId: action.payload
            }
        case ANALYSE_SHOW_LINK_MODAL:
            return {
                ...state,
                showLinkModal: true,
                showCreateOrEditModal: false,
                showEditReferencesModal: false,
                isCreationMode: false,
                analyseId: action.payload
            }
        case ANALYSE_HIDE_LINK_MODAL:
                return {
                    ...state,
                    showLinkModal: false,
                    showCreateOrEditModal: false,
                    showEditReferencesModal: false,
                    isCreationMode: false
                }
        case ANALYSE_HIDE_CREATE_OR_EDIT_MODAL:
            return {
                ...state,
                showCreateOrEditModal: false,
                analyseId: '',
                analysisBeingCreatingOrModified: {},
                resetFormRef: null,
                progress: state.progress + 1
            }
        case ANALYSE_SHOW_EDIT_REFERENCES_MODAL:
            return {
                ...state,
                showEditReferencesModal: true,
                showCreateOrEditModal: false,
                analysisBeingCreatingOrModified: action.payload.analysisBeingCreatingOrModified,
                resetFormRef: action.payload.resetFormRef
            }
        case ANALYSE_EDIT_REFERENCES_PREVIOUS_MODAL:
            return {
                ...state,
                showEditReferencesModal: false,
                showCreateOrEditModal: true,
                analysisBeingCreatingOrModified: {
                    ...(state.analysisBeingCreatingOrModified),
                    laboratoryParameters: action.payload
                },
            }
        case ANALYSE_HIDE_EDIT_REFERENCES_MODAL:
            return {
                ...state,
                showEditReferencesModal: false,
                analyseId: '',
                analysisBeingCreatingOrModified: {},
                resetFormRef: null,
                progress: state.progress + 1
            }
        case ANALYSE_SHOW_IMPORT_MODAL:
            return {
                ...state,
                showImportModal: true
            }
        case ANALYSE_HIDE_IMPORT_MODAL:
            return {
                ...state,
                showImportModal: false,
                progress: state.progress + 1
            }
        case ANALYSE_DELETED:
            return {
                ...state,
                progress: state.progress + 1
            }
        case ANALYSE_LABORATORY_DELETED:
            return {
                ...state,
                progress: state.progress + 1
            } 
        default:
            return state;
    }
}

export default AnalyseReducer;