import React, {useCallback, useEffect, useState} from 'react';
import CustomModal from "../../../components/custom-modal/custom-modal";
import {connect} from "react-redux";
import T from 'i18n-react'
import {Alert, Button, Col, Form, Row, Spinner} from "react-bootstrap-v5";
import Validator from "../../../utils/validators/validator";
import InputFileLabel from "../../../components/input-label/input-file-label";
import {alertActions} from "../../../redux/alert/actions-alert";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {UserRole} from "../../../utils/enums/user-role";
import CompanyAutocompleteLabel from "../../../components/input-label/company-autocomplete-label";
import {FileType} from "../../../components/input-label/file-type";
import {laboratoryActions} from "../../../redux/laboratory/actions-laboratory";
import {analyseService} from "../../../services/analyse-service";
import {config} from "@fortawesome/fontawesome-svg-core";
import {ApiErrorHandler} from "../../../services/api/api-error";
import ImportTitleColumn from "../../../utils/enums/import-title-column";

const FormatModal = ({laboratoryReducer, currentUser, hideFormatModal, showToast}) => {
    const companyInitialState = {id: currentUser?.role === UserRole.SUPER_ADMINISTRATOR ? '' : currentUser?.companyId, name: ''};

    const [isLoading, setLoading] = useState(false);
    const [apiError, setApiError] = useState('');

    const [company, setCompany] = useState(companyInitialState);

    const [file, setFile] = useState(null);

    const [inputErrors, setInputErrors] = useState({compagny: '', file: ''});

    useEffect(() => {
        resetForm();
    }, [currentUser])

    const resetErrors = () => {
        setInputErrors({compagny: '', file: ''});
        setApiError('');
    }

    const resetForm = useCallback(
        () => {
            setCompany(companyInitialState);
            setFile(null);
        }, [],
    );

    const validate = () => {
        const companyError = Validator.validateRequired(company.id);
        const fileError = Validator.validateRequiredXLSXFile(file);
        const isValid = !fileError && !companyError;
        setInputErrors({
            company: T.translate(companyError).toString(),
            file: T.translate(fileError).toString()
        })
        return isValid;
    }

    const importFormat = async (formData) => {
        setLoading(true);
        try {
            await analyseService.addFormatToImportResults(laboratoryReducer.laboratoryId, formData, config);
            setLoading(false);
            hideModal();
            showToast(T.translate('alert.successTitle'), T.translate('analyse.succesImportFormat'), ToastClass.SUCCESS)
        } catch (error) {
            setLoading(false);
            setApiError(ApiErrorHandler.getErrorMessage(error).toString());
        }
    }

    const submitForm = e => {
        e.preventDefault();

        resetErrors();
        if (validate()) {
            const formData = new FormData();
            formData.append('companyId', company.id);
            formData.append('file', file);
            importFormat(formData)
        }
    }

    const hideModal = () => {
        resetErrors();
        resetForm();
        hideFormatModal();
    }

    const handleCompanyChange = value => {
        setCompany(value);
    }

    const footer = (
        <React.Fragment>
            <Button variant="outline-secondary" onClick={hideModal} className="px-3 py-1">
                {T.translate('form.button.cancel')}
            </Button>
            <Button variant="primary" onClick={submitForm} className="px-3 py-1" disabled={isLoading}>
                {isLoading ? <Spinner as="span" size="sm" animation="border"/> : ""}
                {T.translate('form.button.confirm')}
            </Button>
        </React.Fragment>
    );

    return (
        <CustomModal show={laboratoryReducer.showFormatModal} onHide={hideModal} footer={footer} title={T.translate('laboratory.title.formatToImportResults')}>
            <Form>
                <Alert variant={"danger"} hidden={!apiError}>
                    <Alert.Heading>
                        {T.translate("alert.errorTitle")}
                    </Alert.Heading>
                    {apiError}
                </Alert>
                <Row>
                    <Col sm={12} lg={8} className="offset-lg-1">
                        <div className="mb-2">
                            {T.translate('laboratory.formatModal.formatInstructions')}
                            {Object.values(ImportTitleColumn).map((value, index) => (
                                <span key={index}>
                                    {value}
                                    {index !== Object.keys(ImportTitleColumn).length - 1 && <>, </>}
                                </span>
                            ))}
                        </div>
                        <br/>
                        {
                            currentUser?.role === UserRole.SUPER_ADMINISTRATOR &&
                            <CompanyAutocompleteLabel id="companyId" className="mb-4" required={true} error={inputErrors.company}
                                                      defaultValue={company} onChange={handleCompanyChange}/>
                        }
                        <InputFileLabel id="file" className="mb-4" label={T.translate('form.label.format')} required={true}
                                        onChange={val => setFile(val)} error={inputErrors.file} accept={FileType.OOXML}/>
                    </Col>
                </Row>
            </Form>
        </CustomModal>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        laboratoryReducer: state.laboratoryReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideFormatModal: () => dispatch(laboratoryActions.hideFormatModal()),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormatModal);