import React from 'react';
import {Card} from "react-bootstrap-v5";
import './card-indicator.scss'
import OverlayLoadingOrError from "../overlay-component/overlay-loading-or-error";

const CardIndicator = ({label, value, showYear = false, className, error, isLoading}) => {
    const currentYear = new Date().getFullYear();

    return (
        <Card className={`card-indicator py-3 px-4 ${className}`}>
            <div className="d-flex justify-content-between align-items-center">
                <div className="label">{label}</div>
                {showYear && (
                    <div className="year ms-5">{currentYear}</div>
                )}
            </div>
            <div className="value pt-2">{value}</div>
            <OverlayLoadingOrError error={error} isLoading={isLoading}/>
        </Card>
    );
};

export default CardIndicator;
