import React from 'react';
import './card-indicator.scss'

const IndicatorValue = ({title, currentYear, lastYear}) => {
    const lastYearDate = new Date().getFullYear() - 1;

    return (
        <div className="indicator indicator-value p-3 h-100 d-flex flex-column justify-content-between">
            <div className="title">{title}</div>
            <div className="currentYear text-center">{currentYear}</div>
            {lastYear !== undefined && lastYear !== null
                ? <div className="lastYear">{lastYearDate}: <span>{lastYear}</span></div>
                : <div></div>
            }
        </div>
    );
};

export default IndicatorValue;
