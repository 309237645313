export default class ProductEntity {
    constructor(productData) {
        this.id = productData?.productId ?? '';
        this.companyId = productData?.company?.companyId ?? '';
        this.name = productData?.name ?? '';
        this.articleCode = productData?.articleCode ?? '';
        this.category = productData?.category ?? '';
        this.supplier = productData?.supplier ?? '';
        this.lifeDuration = productData?.lifeSpan ?? '';
        this.description = productData?.description ?? '';
        this.comment = productData?.comment ?? '';
        this.activated = productData?.activated ?? '';
        this.status = productData?.status ?? '';
        this.updateDate = productData?.updateDate ?? '';
    }
}
