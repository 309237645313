import React, {useEffect, useRef, useState} from 'react';
import T from "i18n-react";
import CustomModal from "../../../components/custom-modal/custom-modal";
import {alertActions} from "../../../redux/alert/actions-alert";
import {connect} from "react-redux";
import {lotActions} from "../../../redux/lot/actions-lot";
import {confirmDialogActions} from "../../../redux/confirm-dialog/actions-confirm-dialog";
import { documentService } from '../../../services/document-service';
import { Alert, Table, Button} from "react-bootstrap-v5";
import SearchInput from "../../../components/input-label/search-input";
import CheckLabel from "../../../components/input-label/check-label";
import './table-controls-lot-list.scss';
import {ping} from "../../../services/api/api";
import Moment from "react-moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SortDirection} from "../../../utils/enums/sort-direction";
import Validator from "../../../utils/validators/validator";
import { lotService } from '../../../services/lot-service';
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
const AttachFileAnalysisModal = ({showAttachFileAnalysisModal, showToast, lotReducer, hideAttachFileAnalysisModal}) => {

    const [apiError, setApiError] = useState('');
    const [lotDocuments, setLotDocuments] = useState([]);
    const [lotId, setLotId] = useState('');
    const [lotAnalysisId, setLotAnalysisId] = useState('');
    const [selectedDocumentId, setSelectedDocumentId] = useState('');
    const [searchText, setSearchText] = useState('');
    const downloadDocRef = useRef();

    useEffect(() => {
        const getLotDocuments = async () => {
            try {
                const lotDocuments = await documentService.getAllByEntityId(lotReducer.lot.lot.lotId, 1, 5, SortDirection.DESC.toUpperCase(), 'createdDate', searchText);
                setLotDocuments(lotDocuments.content);
            } catch (error) {
                setApiError(error.message);
            }
        }
        if(lotReducer.lot){
            setSelectedDocumentId('');
            getLotDocuments()
            setLotId(lotReducer.lot.lot.lotId);
            setLotAnalysisId(lotReducer.lot.lotAnalysisId);
            if(lotReducer.lot.document){
                setSelectedDocumentId(lotReducer.lot.document.documentId);
            }
    
        }
        
    }, [showAttachFileAnalysisModal, lotReducer, searchText]);


    const resetErrors = () => {
        setSelectedDocumentId('');
        setApiError('');
    }

    const handleSearchChange = e => {
        const text = e.target.value;
        if (text.length === 0 || text.length >= 3) {
            setSearchText(text);
        }
    }
    
    const hideModal = () => {
        setSelectedDocumentId('');
        hideAttachFileAnalysisModal();
    }

    const handleCheckBoxDocumentClick = (e, document) => {
        setSelectedDocumentId(document.documentId);
    };
    
    const checkIfSelected=(documentId)=> {
        return selectedDocumentId === documentId;
    }

    const addLotAnalysisDocument = async () => {
        try {
            await lotService.editControlDocument(lotId, lotAnalysisId, selectedDocumentId);
            showToast(T.translate('alert.successTitle'), T.translate('lot.successEditAnalysisDocument'), ToastClass.SUCCESS);
            hideModal();
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const validate = () => {
        const lotIdError = Validator.validateRequired(String(lotId));
        const lotAnalysisIdError = Validator.validateRequired(String(lotAnalysisId));
        const documentIdError = Validator.validateRequired(String(selectedDocumentId));
        return !lotIdError && !lotAnalysisIdError && !documentIdError;
    }

    const submitAddLotAnalysisDocument = (e) => {
        e.preventDefault();
        resetErrors();
        if (validate()) {
            addLotAnalysisDocument();
        }
    }

    const downloadDocument = async (fileUri) => {
        downloadDocRef.current.href = fileUri;
        // ici on ping pour éventuellement forcer axios à appeler le refresh token et éviter sinon que le download envoie un 401 car c'est un appel backend direct
        await ping();
        downloadDocRef.current.click();
    }
    const footer = (
        <React.Fragment>
            <Button variant="outline-secondary" onClick={() => hideModal()} className="px-3 py-1">
                {T.translate('form.button.cancel')}
            </Button>
            <Button variant="primary" className="px-3 py-1" disabled={lotDocuments.length === 0} onClick={submitAddLotAnalysisDocument}>
                {T.translate('form.button.confirm')}
            </Button>
        </React.Fragment>
    );

    return (
        <CustomModal show={showAttachFileAnalysisModal} footer={footer} onHide={hideModal} title={T.translate('lot.title.attachFileToLotAnalysis')} >
            <Alert variant="danger" hidden={!apiError}>
                <Alert.Heading>
                    {T.translate("alert.errorTitle")}
                </Alert.Heading>
                {apiError}
            </Alert>

            <div className="search-container-modal mt-4 ms-3 mb-4">
                <SearchInput onChange={handleSearchChange} initialValue={searchText}/>
            </div>

            <div className="documents-list-container">
                {lotDocuments.length === 0 ? <div>{T.translate('table.empty')}</div> : (
                    <Table hover>
                        <tbody>
                        {lotDocuments.map(document => (
                            <tr>
                                <td><CheckLabel id="specsSheet" onChange={e => handleCheckBoxDocumentClick(e, document)} checked={checkIfSelected(document.documentId)} /></td>
                                <td className="attachFile-originalFileName">{document.originalFileName}</td>
                                <td className="attachFile-createdDate createdDate"><Moment format={T.translate('i18n.format.date').toString()}>{document.createdDate}</Moment></td>
                                <td>
                                    <Button size="sm" onClick={() => downloadDocument(documentService.getDownloadUrl(document.fileDownloadUri))}>
                                        <FontAwesomeIcon icon="download"/>
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                )}
                <a ref={downloadDocRef} href="#0" hidden={true} download>download</a>
        </div>
        </CustomModal>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        showAttachFileAnalysisModal: state.lotReducer.showAttachFileAnalysisModal,
        lotReducer: state.lotReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideAttachFileAnalysisModal: () => dispatch(lotActions.hideAttachFileAnalysisModal()),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttachFileAnalysisModal);