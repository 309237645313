import React from "react";
import Header from '../../components/header/header'
import PageWithMenuTemplate from "../../components/template-pages/page-with-menu-template";
import T from "i18n-react";
import HeaderTextLabel from "../../components/header/header-text-label";
import {Col, Row} from "react-bootstrap-v5";
import CardLotsStatus from "./components/card-lots-status";
import {StatusLot} from "../../utils/enums/status-lot";
import CardLotsNotCompliant from "./components/card-lots-not-compliant";
import "./dashboard-page.scss";
import CardIndicatorsLotsClosed from "./components/card-indicators-lots-closed";
import CardIndicatorsLotsClosedCompliance from "./components/card-indicators-lots-closed-compliance";
import CardIndicatorsLotsClosedNbAnalysis from "./components/card-indicators-lots-closed-nb-analysis";
import CardIndicatorsLotsClosedControlPrice from "./components/card-indicators-lots-closed-control-price";

const DashboardPage = () => {
    return (
        <PageWithMenuTemplate pageTitle={T.translate('pageTitle.home')}>
            <Header>
                <HeaderTextLabel className="me-4" isMain={true}>
                    <h1 className="ps-1">{T.translate('menu.home')}</h1>
                </HeaderTextLabel>
            </Header>
            <Row className="g-4 mx-1 mb-3">
                <Col sm={12} md={6} xl={3}>
                    <CardIndicatorsLotsClosed/>
                </Col>
                <Col sm={12} md={6} xl={3}>
                    <CardIndicatorsLotsClosedCompliance/>
                </Col>
                <Col sm={12} md={6} xl={3}>
                    <CardIndicatorsLotsClosedControlPrice/>
                </Col>
                <Col sm={12} md={6} xl={3}>
                    <CardIndicatorsLotsClosedNbAnalysis/>
                </Col>
            </Row>
            <Row className="g-4 mx-1">
                <Col sm={12} xxl={6}>
                    <CardLotsStatus className="h-100" status={StatusLot.NEW} title={T.translate('title.lotsNew')}/>
                </Col>
                <Col sm={12} xxl={6}>
                    <CardLotsStatus className="h-100" status={StatusLot.IN_PROGRESS} title={T.translate('title.lotsInProgress')}/>
                </Col>
                <Col sm={12} xxl={6}>
                    <CardLotsNotCompliant className="h-100"/>
                </Col>
                <Col sm={12} xxl={6}>
                    <CardLotsStatus className="h-100" status={StatusLot.TO_CLOSE} title={T.translate('title.lotsToClose')}/>
                </Col>
            </Row>
        </PageWithMenuTemplate>
    );
}

export default DashboardPage;
