import {HIDE_CONFIRM_DIALOG, SHOW_CONFIRM_DIALOG} from './type';

const showConfirmDialog = (title, body, onCancel, onConfirm) => {
    return {
        type: SHOW_CONFIRM_DIALOG,
        title: title,
        body: body,
        onConfirm: onConfirm,
        onCancel: onCancel
    };
}

const hideConfirmDialog = () => {
    return {
        type: HIDE_CONFIRM_DIALOG
    }
}

export const confirmDialogActions = {
    showConfirmDialog,
    hideConfirmDialog
};