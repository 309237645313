export default class CurrentUserEntity {
    constructor(currentUserData) {
        this.email = currentUserData?.email ?? ''
        this.firstname = currentUserData?.firstname ?? ''
        this.lastname = currentUserData?.lastname ?? ''
        this.role = currentUserData?.role ?? ''
        this.companyId = currentUserData?.companyId ?? ''
        this.language = currentUserData?.languageCode ?? ''
        this.currency = currentUserData?.currency?.currencyCode ?? ''
        this.currencySymbol = currentUserData?.currency?.currencySymbol ?? ''
    }
}