import React, { useEffect, useState } from 'react';
import CustomModal from "../../../components/custom-modal/custom-modal";
import T from "i18n-react";
import InputLabel from "../../../components/input-label/input-label";
import { Button, Col, Image, Row, Spinner } from "react-bootstrap-v5";
import AutocompleteLabel from "../../../components/input-label/autocomplete-label";
import { ToastClass } from "../../../components/toast-alert/toast-alert-class";
import { lotService } from "../../../services/lot-service";
import { alertActions } from "../../../redux/alert/actions-alert";
import { lotActions } from "../../../redux/lot/actions-lot";
import { connect } from "react-redux";
import AlertWarning from "../../../components/alert-closeable/alert-warning";
import iconTransfer from "../../../assets/images/icons/transfer-to.svg";
import iconUndoTransfer from "../../../assets/images/icons/undo-transfer.svg";
import LocalTable from "../../../components/table/local-table";
import AppCheckbox from "../../../components/checkbox/app-checkbox";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../router/router-constants";

const TransferResultsModal = ({ lotSource, show, onHide, showToast }) => {
    const history = useHistory();

    const [isLoading, setLoading] = useState(false);
    const [searchLotNumber, setSearchLotNumber] = useState("");
    const [selectedLot, setSelectedLot] = useState(null);
    const [resultsTransferred, setResultsTransferred] = useState(null);
    const [data, setData] = useState(null);
    const [lotSourceAnalysisData, setLotSourceAnalysisData] = useState(null);
    const [lotDestAnalysisData, setLotDestAnalysisData] = useState(null);
    const [selectedIndexList, setSelectedIndexList] = useState([]);
    const [shouldConfirmTransfer, setShouldConfirmTransfer] = useState(false);
    const [lotSourceFromParent, setLotSourceFromParent] = useState(lotSource);
    const [title, setTitle] = useState(T.translate('lot.title.linkLot'));

    useEffect(() => {
        setLotSourceFromParent(lotSource);
    }, [lotSource])

    const handleCheckAllChange = () => {
        if (!data) return;

        const isAllSelected = selectedIndexList.length === data?.length;
        setSelectedIndexList(isAllSelected ? [] : [...Array(data.length).keys()]);
    }

    const sourceColumns = [
        {
            dataField: T.translate('table.fields.selected').toString(),
            text: <AppCheckbox checked={selectedIndexList.length === data?.length} multiple={true} onChange={handleCheckAllChange} disabled={resultsTransferred} />,
        },
        {
            dataField: T.translate('table.fields.lot.analysisName').toString(),
            text: T.translate('table.columns.analyseName').toString(),
        },
        {
            dataField: T.translate('table.fields.lot.analysisMethod').toString(),
            text: T.translate('table.columns.method').toString(),
        },
        {
            dataField: T.translate('table.fields.specifications').toString(),
            text: T.translate('table.columns.specifications').toString(),
        },
        {
            dataField: T.translate('table.fields.result').toString(),
            text: T.translate('table.columns.result').toString(),
        },
        {
            dataField: T.translate('table.fields.lot.unit').toString(),
            text: T.translate('table.columns.unit').toString(),
        },
    ];

    const destColumns = [
        {
            dataField: T.translate('table.fields.specifications').toString(),
            text: T.translate('table.columns.specifications').toString(),
        },
        {
            dataField: T.translate('table.fields.result').toString(),
            text: T.translate('table.columns.result').toString(),
        },
        {
            dataField: T.translate('table.fields.lot.unit').toString(),
            text: T.translate('table.columns.unit').toString(),
        },
    ];

    const handleCancel = () => {
        setSearchLotNumber("");
        setResultsTransferred(null);
        setSelectedLot(null);
        setData(null);
        setSelectedIndexList([]);
        setLoading(false);
        setShouldConfirmTransfer(false);
        onHide();
    }

    const handleGoBackToLotSelection = () => {
        setTitle(T.translate('lot.title.linkLot'));
        setResultsTransferred(null);
        setData(null);
        setLotSourceAnalysisData(null);
        setLotDestAnalysisData(null);
        setSelectedIndexList([]);
        setLoading(false);
        setShouldConfirmTransfer(false);
    }

    const handleSearchLotNumberChange = e => {
        setSearchLotNumber(e.target.value);
        if (!!selectedLot) {
            setSelectedLot(null);
        }
    }

    const handleSelectLot = lot => {
        setSelectedLot(lot);
        setSearchLotNumber(lot.lotNumber);
    }

    const searchLotByLotNumber = async searchText => {
        try {
            const response = await lotService.getAllByLotNumber(lotSourceFromParent.id, searchText);
            return response.map(lotElement => {
                return {
                    id: lotElement.lotId,
                    name: lotElement.product.supplier ? `${lotElement.lotNumber} - ${lotElement.product.name} - ${lotElement.product.articleCode} - ${lotElement.product.supplier}` : `${lotElement.lotNumber} - ${lotElement.product.name} - ${lotElement.product.articleCode}`,
                    lotNumber: lotElement.lotNumber,
                    supplier: lotElement.supplierLotNumber,
                    productSupplier: lotElement.product.supplier,
                    productName: lotElement.product.name,
                    productArticleCode: lotElement.product.articleCode,
                    destinationLots: lotElement.destinationLots
                }
            }) ?? [];
        } catch (err) {
            showToast(T.translate('alert.errorTitle'), err.message, ToastClass.ERROR);
            return [];
        }
    }

    const handleLink = async () => {
        try {
            setLoading(true);
            var request = {
                batch_number_origin: lotSourceFromParent.lotNumber,
                product_number_origin: lotSourceFromParent.articleCode,
                product_supplier_origin: lotSourceFromParent.productSupplier,
                batch_number_to: selectedLot.lotNumber,
                product_number_to: selectedLot.productArticleCode,
                product_supplier_to: selectedLot.productSupplier
            }
            await lotService.linkLots(request);
            showToast(T.translate('alert.successTitle'), T.translate('lot.successLinkLots'), ToastClass.SUCCESS); setResultsTransferred(null);
            setLoading(false);
            return true;
        } catch (err) {
            const message = err.response?.data?.message;
            const exceptionClass = err.response?.data?.exceptionClass;
            if (exceptionClass === "IllegalStateException") {
                if (message.includes('These 2 batches are already linked together in CIKLab')) {
                    showToast(T.translate('alert.successTitle'), T.translate('lot.alreadyLinkedLots'), ToastClass.SUCCESS);
                    return true;
                }
                if (message.includes('These 2 batches already have an indirect link in CIKLab')) {
                    showToast(T.translate('alert.successTitle'), T.translate('lot.alreadyLinkedLots'), ToastClass.SUCCESS);
                    return true;
                }
            }
            else {
                showToast(T.translate('alert.errorTitle'), err.message, ToastClass.ERROR);
            }
            setLoading(false);

            return false;
        }
    }
    const handleLinkSelectedLotAndTransferResult = async () => {
        var isLinked = false;
        const destinationLot = lotSourceFromParent.destinationLots.find(lot => lot.lotId === selectedLot.id);

        if (destinationLot) {
            isLinked = true;
        }

        if (!isLinked) {
            isLinked = await handleLink();
        }

        if (isLinked) {
            try {
                const responseData = await lotService.getAllTransferableAnalysis(lotSourceFromParent.id, selectedLot.id);
                setData(responseData);
                setSelectedIndexList([...Array(responseData.length).keys()]);
                setLoading(false);
                setTitle(T.translate('lot.title.transferResult'))
                setResultsTransferred(false);
            } catch (err) {
                showToast(T.translate('alert.errorTitle'), err.message, ToastClass.ERROR);
                setData([]);
                setSelectedIndexList([]);
                setResultsTransferred(null);
                setLoading(false);
            }
        }
    }

    const handleLinkSelectedLotAndClose = async () => {
        try {
            setLoading(true);
            await handleLink();
            setSearchLotNumber("");
            setResultsTransferred(null);
            setSelectedLot(null);
            setData(null);
            setLotSourceAnalysisData(null);
            setLotDestAnalysisData(null);
            setSelectedIndexList([]);
            setLoading(false);
            setShouldConfirmTransfer(false);
            onHide();
        } catch (err) {
            showToast(T.translate('alert.errorTitle'), err.message, ToastClass.ERROR);
            setLoading(false);
        }
    }

    useEffect(() => {
        const rows = data?.map(analysisRow => {
            return {
                source: {
                    id: `${analysisRow.source.lotAnalysisId}-to-${analysisRow.destination.lotAnalysisId}`,
                    lotAnalysisId: analysisRow.source.lotAnalysisId,
                    analysisName: analysisRow.source.analysisName,
                    analysisMethod: analysisRow.source.analysisMethod,
                    specs: analysisRow.source.specs,
                    result: analysisRow.source.result,
                    unit: analysisRow.source.unit,
                },
                dest: {
                    id: `${analysisRow.destination.lotAnalysisId}-from-${analysisRow.source.lotAnalysisId}`,
                    lotAnalysisId: analysisRow.destination.lotAnalysisId,
                    specs: analysisRow.destination.specs,
                    result: analysisRow.destination.result,
                    unit: analysisRow.destination.unit,
                }
            }
        }) ?? [];
        const sourceRows = rows.map(row => row.source);
        const destRows = rows.map(row => row.dest);
        setLotSourceAnalysisData(sourceRows);
        setLotDestAnalysisData(destRows);
    }, [data]);

    useEffect(() => {
        const handleCheckChange = (index) => {
            const isSelected = selectedIndexList.includes(index);
            let indexes;
            if (isSelected) {
                indexes = selectedIndexList.filter(value => value !== index);
            } else {
                indexes = [...selectedIndexList, index];
            }
            setSelectedIndexList(indexes);
        }

        setLotSourceAnalysisData(prevData => prevData?.map((analysis, index) => ({
            ...analysis,
            selected: <AppCheckbox checked={selectedIndexList.includes(index)} onChange={() => handleCheckChange(index)} disabled={resultsTransferred} />,
        })));
    }, [selectedIndexList, resultsTransferred]);

    const handleTransferResults = () => {
        const newDestData = [...lotDestAnalysisData];
        selectedIndexList.forEach(index => newDestData[index].result = lotSourceAnalysisData[index].result);
        selectedIndexList.forEach(index => newDestData[index].unit = lotSourceAnalysisData[index].unit);
        setLotDestAnalysisData(newDestData);
        setResultsTransferred(true);
    }

    const handleUndoTransfer = () => {
        const newDestData = [...lotDestAnalysisData];
        selectedIndexList.forEach(index => newDestData[index].result = '');
        selectedIndexList.forEach(index => newDestData[index].unit = '');
        setLotDestAnalysisData(newDestData);
        setResultsTransferred(false);
        setSelectedIndexList([]);
    }

    const handleSubmitTransfer = () => setShouldConfirmTransfer(true);

    const handleConfirmTransfer = async () => {
        try {
            setLoading(true);
            const analysisList = selectedIndexList.map((index) => ({
                sourceLotAnalysisId: lotSourceAnalysisData[index].lotAnalysisId,
                destinationLotAnalysisId: lotDestAnalysisData[index].lotAnalysisId,
            }));
            await lotService.transferResults(lotSourceFromParent.id, selectedLot.id, analysisList);
            showToast(T.translate('alert.successTitle'), T.translate('lot.successTransferResults'), ToastClass.SUCCESS);
            history.push(`${Routes.LOTS_PAGE}/${selectedLot.id}`);
            handleCancel();
        } catch (err) {
            showToast(T.translate('alert.errorTitle'), err.message, ToastClass.ERROR);
            setLoading(false);
        }
    }

    return (
        <CustomModal show={show} title={title} onHide={handleCancel}
            className={`modal-transfer ${resultsTransferred !== null && !shouldConfirmTransfer && "modal-xl"}`}
            bodyClassName="py-3 px-4" centered={true} dismissOnClickOutside={false}>
            {resultsTransferred === null && (
                <Row>
                    <Col xs={10} lg={10}>
                        <div className="text-center mb-2">{T.translate('lot.selectLotYouWantToLinkTo')}</div>
                        <AutocompleteLabel id="lotNumber" label={T.translate('form.label.lot.number')} className="mb-2" required={true}
                            onChange={handleSearchLotNumberChange} onSelect={handleSelectLot} value={searchLotNumber}
                            onSuggestionsFetch={searchLotByLotNumber} />
                        <InputLabel label={T.translate('form.label.product')} className={`mb-2 ${!selectedLot ? "invisible" : ""}`} disabled={true} value={selectedLot?.productName} />
                        <InputLabel label={T.translate('form.label.supplier')} className={`mb-2 ${!selectedLot ? "invisible" : ""}`} disabled={true} value={selectedLot?.supplier ?? "-"} />
                        <div className="row pt-3 pb-3">
                            <div className="col offset-4">
                                <Button variant="outline-secondary" onClick={handleCancel} className="px-3 py-1">
                                    {T.translate('lot.button.close')}
                                </Button>
                                <Button variant="success" onClick={handleLinkSelectedLotAndClose} className="ms-3 px-3 py-1" disabled={!selectedLot || isLoading}>
                                    {isLoading && <Spinner as="span" size="sm" animation="border" />}
                                    {T.translate('lot.button.linkBatches')}
                                </Button>
                                <Button variant="success" onClick={handleLinkSelectedLotAndTransferResult} className="ms-3 px-3 py-1" disabled={!selectedLot || isLoading}>
                                    {isLoading && <Spinner as="span" size="sm" animation="border" />}
                                    {T.translate('lot.button.linkAndTransfertResults')}
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
            {resultsTransferred !== null && !shouldConfirmTransfer && (
                <div className="px-4">
                    <Row>
                        <Col xs={7}>
                            <div className="pb-2"><strong>{T.translate('form.label.lot.originLotNumber')} :</strong> {lotSourceFromParent.lotNumber}</div>
                            <div className="pb-2"><strong>{T.translate('form.label.product')} :</strong> {lotSourceFromParent.productName}</div>
                            <div className="pb-2"><strong>{T.translate('form.label.product.articleCode')} :</strong> {lotSourceFromParent.articleCode}</div>
                            <div className="pb-2"><strong>{T.translate('form.label.lot.supplier')} :</strong> {lotSourceFromParent.supplier}</div>
                        </Col>
                        <Col xs={4} className="offset-1">
                            <div className="pb-2"><strong>{T.translate('form.label.lot.number')} :</strong> {selectedLot.lotNumber}</div>
                            <div className="pb-2"><strong>{T.translate('form.label.product')} :</strong> {selectedLot.productName}</div>
                            <div className="pb-2"><strong>{T.translate('form.label.product.articleCode')} :</strong> {selectedLot.articleCode}</div>
                            <div className="pb-2"><strong>{T.translate('form.label.lot.supplier')} :</strong> {selectedLot.supplier}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={7} className="d-flex">
                            <Button variant="success" onClick={handleTransferResults}
                                className={`ms-auto ${(selectedIndexList.length === 0 || !!resultsTransferred) && "invisible"}`}
                                disabled={(selectedIndexList.length === 0 || !!resultsTransferred)}>
                                <Image src={iconTransfer} className="me-2" />
                                {T.translate('lot.button.transferTheResults')}
                            </Button>
                        </Col>
                        <Col xs={4} className="offset-1">
                            <AlertWarning variant="success" title={T.translate('lot.warningPleaseCheckUnits')} />
                        </Col>
                    </Row>
                    <Row className="my-2">
                        <Col xs={7}>
                            <LocalTable columns={sourceColumns} data={lotSourceAnalysisData} />
                        </Col>
                        <Col>
                            <div className="vertical-arrow-divider" />
                        </Col>
                        <Col xs={4}>
                            <LocalTable columns={destColumns} data={lotDestAnalysisData} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={7} className="d-flex justify-content-between">
                            <Button variant="outline-secondary" onClick={handleGoBackToLotSelection}>
                                {T.translate('form.button.previous')}
                            </Button>
                            {!!resultsTransferred && (
                                <Button onClick={handleUndoTransfer}>
                                    <Image src={iconUndoTransfer} className="me-2" />
                                    {T.translate('form.button.undo')}
                                </Button>
                            )}
                        </Col>
                        <Col xs={4} className="offset-1 d-flex justify-content-end">
                            <Button variant="outline-secondary" onClick={handleCancel}>
                                {T.translate('form.button.cancel')}
                            </Button>
                            <Button variant="success" onClick={handleSubmitTransfer} className="ms-3" disabled={!resultsTransferred}>
                                {isLoading && <Spinner as="span" size="sm" animation="border" />}
                                {T.translate('form.button.confirmTransfer')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            )}
            {shouldConfirmTransfer && (
                <Row>
                    <Col xs={10} lg={8} className="offset-1 offset-lg-2">
                        <div className="text-center mb-2">{T.translate('lot.confirmTransferResults')}</div>
                        <div className="text-center mb-2">{T.translate('lot.youWillBeRedirectedToTheLot', { lotNumber: selectedLot?.lotNumber })}</div>
                        <Row className="pt-3 pb-3">
                            <Col className="offset-4">
                                <Button variant="outline-secondary" onClick={handleCancel}>
                                    {T.translate('form.button.cancel')}
                                </Button>
                                <Button variant="success" onClick={handleConfirmTransfer} className="ms-3">
                                    {isLoading && <Spinner as="span" size="sm" animation="border" />}
                                    {T.translate('form.button.confirm')}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </CustomModal>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)), addedAnalysisToLot: () => dispatch(lotActions.addAnalysisToLot())
    }
}

export default connect(null, mapDispatchToProps)(TransferResultsModal);
