import React, {useEffect, useState} from 'react';
import PageWithMenuTemplate from "../../components/template-pages/page-with-menu-template";
import Header from "../../components/header/header";
import HeaderTextLabel from "../../components/header/header-text-label";
import T from "i18n-react";
import SearchInput from "../../components/input-label/search-input";
import TableAllDocuments from "./components/table-all-documents";
import {documentService} from "../../services/document-service";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Badge} from "react-bootstrap-v5";
import {documentActions} from "../../redux/document/actions-document";
import {ToastClass} from "../../components/toast-alert/toast-alert-class";
import {alertActions} from "../../redux/alert/actions-alert";

const AllDocumentsPage = ({progress, actualiseCountExpiredDocuments, showToast}) => {
    const [searchText, setSearchText] = useState('');
    const [numberExpiredDocuments, setNumberExpiredDocuments] = useState(0);

    useEffect(() => {
        const getNumberExpiredDocuments = async () => {
            try {
                const response = await documentService.getIndicatorExpiredDocuments();
                setNumberExpiredDocuments(response.numberOfExpiredDocuments);
                actualiseCountExpiredDocuments(response.numberOfExpiredDocuments);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        getNumberExpiredDocuments();
    }, [progress]);


    const handleSearchChange = e => {
        const text = e.target.value;
        if (text.length === 0 || text.length >= 3) {
            setSearchText(text);
        }
    }

    const headerButtons = numberExpiredDocuments > 0 &&
        <div className={"me-1"}>
            <div className="icon-wrapper card-lots-not-compliant">
                <FontAwesomeIcon icon="bell"/>
                <Badge className="my-badge"><FontAwesomeIcon icon="exclamation" size="xs"/></Badge>
            </div>
            <span className={"primary fw-bold"}>Expired documents : {numberExpiredDocuments}</span>
        </div>;

    return (
        <PageWithMenuTemplate pageTitle={T.translate('pageTitle.documents')}>
            <Header>
                <HeaderTextLabel className="me-4" isMain={true}>
                    <h1 className="ps-1">{T.translate('menu.documents')}</h1>
                </HeaderTextLabel>
            </Header>
            <Header button={headerButtons}>
                <div className="search-container">
                    <SearchInput onChange={handleSearchChange}/>
                </div>
            </Header>
            <TableAllDocuments className="mx-3" searchText={searchText}/>
        </PageWithMenuTemplate>
    );
};

const mapStateToProps = state => {
    return {
        progress: state.documentReducer.progress,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        actualiseCountExpiredDocuments: (count) => dispatch(documentActions.actualiseCountExpiredDocuments(count))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllDocumentsPage);
