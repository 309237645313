import React, {useEffect, useState} from 'react';
import {Badge, Button, Card} from "react-bootstrap-v5";
import T from 'i18n-react'
import {UserRole} from "../../../utils/enums/user-role";
import {connect} from "react-redux";
import SearchInput from "../../../components/input-label/search-input";
import OverlayLoadingOrError from "../../../components/overlay-component/overlay-loading-or-error";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {lotService} from "../../../services/lot-service";
import {useHistory} from "react-router-dom";
import {SortDirection} from "../../../utils/enums/sort-direction";
import {Routes} from "../../../router/router-constants";
import Moment from "react-moment";
import TablePagination from "../../../components/table/table-pagination";
import CustomTable from "../../../components/table/custom-table";
import {StatusLot, StatusLotClassname} from "../../../utils/enums/status-lot";
import {cancelDebounce, debounceFunc} from "../../../utils/functions/debounce-function";

const CardLotsStatus = ({className, status, title, currentUser}) => {

    const history = useHistory();
    const [state, setState] = useState({isLoading: false, error: ''});
    const [lotList, setLotList] = useState([]);
    const closingReason = "";

    const [pagination, setPagination] = useState({
        page: 1,
        size: 15,
        sortedField: T.translate('table.fields.lot.lotNumber'),
        sortedOrder: SortDirection.ASC,
        searchText: '',
        totalElements: 0,
        totalPages: 1
    });

    let columns = [
        {
            dataField: T.translate('table.fields.lot.category').toString(),
            text: T.translate('table.columns.category').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.lot.articleCode').toString(),
            text: T.translate('table.columns.product.articleCode').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.lot.productName').toString(),
            text: T.translate('table.columns.lot.productName').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.lot.lotNumber').toString(),
            text: T.translate('table.columns.lot.lotNumber').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.lot.endControlDate').toString(),
            text: T.translate('table.columns.lot.endControlDate').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.lot.deadline').toString(),
            text: T.translate('table.columns.deadline').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.actions').toString(),
            text: T.translate('table.columns.actions').toString()
        }
    ];

    if (currentUser.role === UserRole.SUPER_ADMINISTRATOR) {
        columns = [
            {
                dataField: T.translate('table.fields.laboratory.companyName').toString(),
                text: T.translate('table.columns.company').toString(),
                sort: true
            },
            ...columns
        ];
    }

    if (StatusLot.NEW === status) {
        columns = [
            {
                dataField: T.translate('table.fields.lot.creationDate').toString(),
                text: T.translate('table.columns.lot.creationDateShort').toString(),
                sort: true
            },
            ...columns
        ];

        columns.map((column, index) =>
            column.dataField === "daysBeforeEndControlDate" ? columns.splice(index, 1) : ""
        );

    }

    const sortableColumns = columns.map(col => {
        return {
            ...col,
            id: col.dataField,
            accessor: col.dataField,
            Header: ({column}) => {
                return (
                    <div className={`column-title d-flex justify-content-between align-items-center ${col.sort ? "sortable-column" : ""}`}
                         onClick={event => !col.sort ? event.stopPropagation() : handleSort(event, col.dataField)}>
                        <div>{column.text}</div>
                        {
                            col.sort &&
                            <div className={`order-icon order-${col.dataField !== pagination.sortedField || !pagination.sortedOrder ? "none" : pagination.sortedOrder}`}>
                                <FontAwesomeIcon
                                    className={`order-icon-desc ${col.dataField === pagination.sortedField && pagination.sortedOrder === SortDirection.DESC ? "d-none" : "d-block"}`}
                                    icon="chevron-up"/>
                                <FontAwesomeIcon
                                    className={`order-icon-asc ${col.dataField === pagination.sortedField && pagination.sortedOrder === SortDirection.ASC ? "d-none" : "d-block"}`}
                                    icon="chevron-down"/>
                            </div>
                        }
                    </div>
                )
            },
        };
    });

    const handleSort = (e, field) => {
        e.stopPropagation();
        let order = SortDirection.DESC;
        if (field === pagination.sortedField) {
            order = !pagination.sortedOrder || pagination.sortedOrder === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
        }
        setPagination({...pagination, page: 1, sortedField: field, sortedOrder: order});
    }

    useEffect(() => {
        const handleGoToDetailsPage = (id) => {
            history.push(`${Routes.LOTS_PAGE}/${id}`)
        }

        const completeElements = allElements => {
            return allElements.map(element => {
                const dayDealine = element.daysBeforeEndControlDate < 0 ? `+${-element.daysBeforeEndControlDate}` : -element.daysBeforeEndControlDate;
                return {
                    ...element,
                    id: element.lotId,
                    creationDate: !element.creationDate ? "-" : <Moment>{element.creationDate}</Moment>,
                    endControlDate: !element.endControlDate ? "-" : <Moment>{element.endControlDate}</Moment>,
                    daysBeforeEndControlDate: !element.endControlDate ? "-" : T.translate('table.columns.dayDeadline', {dayNumber: dayDealine}),
                    actions: (
                        <div className="actions">
                            <Button size="sm" className="edit" onClick={() => handleGoToDetailsPage(element.lotId)}>
                                <FontAwesomeIcon icon="edit"/>
                            </Button>
                        </div>
                    )
                }
            })
        }

        const onFetchData = async () => {
            try {
                setState({isLoading: true, error: ''});
                const data = await lotService.getAllByStatus(status, closingReason, pagination.page, pagination.size, pagination.sortedOrder.toUpperCase(), pagination.sortedField, pagination.searchText);
                setLotList(completeElements(data.content));
                setPagination(prevState => ({
                    ...prevState,
                    totalElements: data.totalElements,
                    totalPages: data.totalPages
                }));
                setState({isLoading: false, error: ''});
            } catch (error) {
                setState({isLoading: false, error: error.message});
            }
        }

        const idFunc = `card-lots-status-${status}`;
        if (!!pagination.searchText) {
            const optimizedOnFetchData = debounceFunc(onFetchData, idFunc);
            optimizedOnFetchData();
        } else {
            cancelDebounce(idFunc);
            onFetchData();
        }
    }, [pagination.page, pagination.size, pagination.sortedOrder, pagination.sortedField, pagination.searchText, history, status]);

    const handleSearchChange = e => {
        const searchText = e.target.value;
        if (searchText.length === 0 || searchText.length >= 3) {
            setPagination({...pagination, page: 1, searchText})
        }
    }

    const handlePageChange = page => {
        setPagination({...pagination, page});
    }

    return (
        <Card className={`card-lots-status ${className}`}>
            <Card.Header className="d-flex justify-content-between align-items-end">
                <Card.Title>
                    <Badge className={`d-inline me-2 ${StatusLotClassname[status]}`} pill={true}>{pagination.totalElements}</Badge>
                    <div className="d-inline">{title}</div>
                </Card.Title>
                <div className="search-container">
                    <SearchInput onChange={handleSearchChange}/>
                </div>
            </Card.Header>
            <Card.Body>
                <div className="overflow-auto remote-table">
                    <CustomTable columns={sortableColumns} data={lotList} useDragRow={false}/>
                </div>
                <div className="d-block d-sm-flex justify-content-between align-item-center p-2 p-sm-4">
                    <div></div>
                    <TablePagination page={pagination.page} totalPages={pagination.totalPages} onPageChange={handlePageChange}/>
                </div>
                <OverlayLoadingOrError isLoading={state.isLoading} error={state.error} onCloseError={() => setState({isLoading: false, error: ''})}/>
            </Card.Body>
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser
    }
}

export default connect(mapStateToProps)(CardLotsStatus);