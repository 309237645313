import {USER_DELETED, USER_HIDE_CREATE_OR_EDIT_MODAL, USER_SHOW_CREATE_MODAL, USER_SHOW_EDIT_MODAL} from "./type";

const initialStateUser = {
    showCreateOrEditModal: false,
    isCreationMode: false,
    user: null,
    progress: 0,
}

const userReducer = (state = initialStateUser, action) => {
    switch (action.type) {
        case USER_SHOW_CREATE_MODAL:
            return {
                ...state,
                showCreateOrEditModal: true,
                isCreationMode: true,
                user: null
            }
        case USER_SHOW_EDIT_MODAL:
            return {
                ...state,
                showCreateOrEditModal: true,
                isCreationMode: false,
                user: action.payload
            }
        case USER_DELETED:
        case USER_HIDE_CREATE_OR_EDIT_MODAL:
            return {
                ...state,
                showCreateOrEditModal: false,
                isCreationMode: false,
                user: null,
                progress: state.progress + 1
            }
        default:
            return state;
    }
}

export default userReducer;