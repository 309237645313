import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card} from "react-bootstrap-v5";
import {userService} from "../../../services/user-service";
import T from 'i18n-react'
import Validator from "../../../utils/validators/validator";
import OverlayLoadingOrError from "../../../components/overlay-component/overlay-loading-or-error";
import {connect} from "react-redux";
import {alertActions} from "../../../redux/alert/actions-alert";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import InputLabel from "../../../components/input-label/input-label";
import ProfileDto from "../../../models/dto/profile-dto";
import ProfileEntity from "../../../models/entities/profile-entity";
import {authActions} from "../../../redux/auth/actions-auth";
import SelectLabel from "../../../components/input-label/select-label";
import {i18nService} from "../../../services/i18n-service";
import I18nController from "../../../utils/i18n/i18n-controller";

const ProfileInfos = ({className, showToast, authUpdateCurrentUser}) => {

    const emailField = "email";
    const phoneField = "phoneNumber";

    const [state, setState] = useState({isLoading: true, refetchLanguages: 0, error: ''});

    const initialUserProfile = {
        lastname: '',
        firstname: '',
        email: '',
        phone: '',
        role: null,
        language: '',
    };
    const [userProfile, setUserProfile] = useState(initialUserProfile);
    const [userOldProfile, setUserOldProfile] = useState({...initialUserProfile});

    const setFirstname = firstname => setUserProfile({...userProfile, firstname});
    const setLastname = lastname => setUserProfile({...userProfile, lastname});
    const setEmail = email => setUserProfile({...userProfile, email});
    const setPhone = phone => setUserProfile({...userProfile, phone});
    const setLanguage = language => setUserProfile({...userProfile, language});

    const [languages, setLanguages] = useState([]);

    useEffect(() => {
        const getLanguages = async () => {
            const availableLanguages = await i18nService.getAllLanguages();
            setLanguages(availableLanguages);
            if (!availableLanguages.isEmpty) {
                setUserProfile(prevState => ({
                    ...prevState,
                    language: !prevState.language ? availableLanguages[0].localeCode : prevState.language,
                }));
            }
        }

        getLanguages();
    }, [state.refetchLanguages]);

    const initialInputErrors = {
        lastname: '',
        firstname: '',
        email: '',
        phone: '',
        language: '',
    };
    const [inputErrors, setInputErrors] = useState(initialInputErrors);

    const resetErrors = () => {
        setInputErrors(initialInputErrors);
    }

    const getUserProfile = useCallback(() => {
        const getCurrentUserProfile = async () => {
            try {
                const currentUserProfile = await userService.getCurrentUser();
                const userProfileEntity = new ProfileEntity(currentUserProfile);
                setUserProfile(userProfileEntity);
                setUserOldProfile({...userProfileEntity});
                setState(prevState => ({...prevState, isLoading: false, error: ''}));
            } catch (error) {
                setState(prevState => ({...prevState, isLoading: false, error: error.message}));
            }
        };
        getCurrentUserProfile();
    }, []);

    useEffect(getUserProfile, [getUserProfile]);

    const validate = () => {
        const lastnameError = Validator.validateRequired(userProfile.lastname);
        const firstnameError = Validator.validateRequired(userProfile.firstname);
        const emailError = Validator.validateRequiredEmail(userProfile.email);
        const languageError = Validator.validateRequired(userProfile.language);
        const isValid = !lastnameError && !firstnameError && !emailError && !languageError;
        setInputErrors({
            ...initialInputErrors,
            lastname: T.translate(lastnameError).toString(),
            firstname: T.translate(firstnameError).toString(),
            email: T.translate(emailError).toString(),
            language: T.translate(languageError).toString(),
        })
        return isValid;
    }

    const onSubmit = async () => {
        setState({...state, isLoading: true});
        try {
            const profileDto = new ProfileDto(userProfile);
            await userService.editCurrentUser(profileDto);
            setState(prevState => ({...prevState, isLoading: false, error: ''}));
            setUserOldProfile({...userProfile});
            authUpdateCurrentUser(userProfile);
            showToast(T.translate('alert.successTitle'), T.translate('profile.successEditInfos'), ToastClass.SUCCESS);
            if (userProfile.language !== userOldProfile.language) {
                I18nController.getInstance().changeLang(userProfile.language);
                setState(prevState => ({...prevState, refetchLanguages: prevState.refetchLanguages + 1}));
            }
        } catch (error) {
            const field = error.response?.data?.entityIdField;
            if (field === emailField) {
                setInputErrors({...inputErrors, email: error.message});
            } else if (field === phoneField) {
                setInputErrors({...inputErrors, phone: error.message});
            } else {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
            setState({...state, isLoading: false});
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        resetErrors();
        if (validate()) {
            onSubmit();
        }
    }

    const handleCancel = () => {
        resetErrors();
        setUserProfile({...userOldProfile});
    }

    const languageOptions = () => {
        let options = [];
        languages.forEach((lang) => {
            options.push(
                <option key={lang.localeCode} value={lang.localeCode} selected={lang.localeCode === userProfile.language}>
                    {lang.displayName}
                </option>
            );
        });
        return options;
    }

    return (
        <Card className={className}>
            <Card.Header>
                <Card.Title>{T.translate('profile.title.userInfos')}</Card.Title>
            </Card.Header>
            <Card.Body className="position-relative px-3">
                <fieldset>
                    <InputLabel id="user-infos-lastname" className="mb-4" type="text"
                                label={T.translate('form.label.lastname')} required={true}
                                value={userProfile.lastname} onChange={e => setLastname(e.target.value)} error={inputErrors.lastname}/>
                    <InputLabel id="user-infos-firstname" className="mb-4" type="text"
                                label={T.translate('form.label.firstname')} required={true}
                                value={userProfile.firstname} onChange={e => setFirstname(e.target.value)} error={inputErrors.firstname}/>
                    <InputLabel id="user-infos-email" className="mb-4" type="text"
                                label={T.translate('form.label.email')} required={true}
                                value={userProfile.email} onChange={e => setEmail(e.target.value)} error={inputErrors.email}/>
                    <InputLabel id="user-infos-phone" className="mb-4" type="text"
                                label={T.translate('form.label.phone')}
                                value={userProfile.phone} onChange={e => setPhone(e.target.value)} error={inputErrors.phone}/>

                    <SelectLabel id="language" className="mb-4" label={T.translate('form.label.interfaceLanguage')} required={true}
                                 options={languageOptions()} defaultValue={userProfile.language} error={inputErrors.language}
                                 onChange={e => setLanguage(e.target.value)}/>
                </fieldset>
                <OverlayLoadingOrError isLoading={state.isLoading} error={state.error} onCloseError={() => setState({...state, error: ''})}/>
            </Card.Body>
            <Card.Footer className="text-end pb-3 pt-2">
                <Button type="button" variant="outline-secondary" size="sm" onClick={handleCancel} disabled={state.isLoading}>
                    {T.translate('form.button.cancel')}
                </Button>
                <Button type="button" variant="primary" size="sm" className="ms-3" onClick={handleSubmit} disabled={state.isLoading}>
                    {T.translate('form.button.confirm')}
                </Button>
            </Card.Footer>
        </Card>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        authUpdateCurrentUser: currentUser => dispatch(authActions.authUpdateCurrentUser(currentUser))
    }
}

export default connect(null, mapDispatchToProps)(ProfileInfos);