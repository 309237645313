import React, {useState} from 'react';
import T from 'i18n-react'
import CardTabsInfos from "../../../components/card-infos/card-tabs-infos";
import EditableText from "../../../components/editable-text/editable-text";
import {connect} from "react-redux";
import {UserRole} from "../../../utils/enums/user-role";
import {confirmDialogActions} from "../../../redux/confirm-dialog/actions-confirm-dialog";
import EditableTextArea from "../../../components/editable-text/editable-textarea";
import {InputMaxLength} from "../../../utils/enums/input-max-length";
import {ProductStatus} from "../../../utils/enums/product-status";
import CardTabs from "../../../components/card-infos/card-tabs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CardProductInfosAndComments = ({className, product, currentUser, showConfirmDialog, onEditValue, onEditComment}) => {

    const [clickCancel, setClickCancel] = useState(false)

    const handleArticleCodeChange = articleCode => {
        document.activeElement.blur();
        setClickCancel(false);

        showConfirmDialog(
            T.translate('dialog.title.confirmEdit'),
            T.translate('dialog.confirmEditArticleCode'),
            () => {
                setClickCancel(true)
            },
            () => onEditValue("articleCode", {articleCode}, articleCode),
        )
    }

    const handleCategoryChange = async category => {
        onEditValue("category", {category}, category)
    }

    const handleSupplierChange = async supplier => {
        onEditValue("supplier", {supplier}, supplier)
    }

    const handleLifeDurationChange = async lifeDuration => {
        onEditValue("lifeDuration", {lifeSpan: lifeDuration}, lifeDuration)
    }

    const handleDescriptionChange = async description => {
        onEditValue("description", {description}, description)
    }

    const canEditComment = currentUser.role === UserRole.SUPER_ADMINISTRATOR || currentUser.role === UserRole.COMPANY_ADMINISTRATOR || currentUser.role === UserRole.SUPERVISOR;
    const canEdit = product.status === ProductStatus.MODIFICATION_IN_PROGRESS && canEditComment;

    const dataInfos = [
        {
            label: T.translate('table.columns.product.articleCode'),
            component: !canEdit ? <div>{product.articleCode}</div> :
                <EditableText id="productArticleCode" defaultValue={product.articleCode} onSubmit={handleArticleCodeChange} onCancelConfirmDialog={clickCancel} shouldSubmitOnBlur/>
        },
        {
            label: T.translate('table.columns.category'),
            component: !canEdit ? <div>{product.category}</div> :
                <EditableText id="productCategory" defaultValue={product.category} onSubmit={handleCategoryChange} shouldSubmitOnBlur/>
        },
        {
            label: T.translate('table.columns.supplier'),
            component: !canEdit ? <div>{product.supplier}</div> :
                <EditableText id="productSupplier" defaultValue={product.supplier} onSubmit={handleSupplierChange} shouldSubmitOnBlur/>
        },
        {
            label: T.translate('table.columns.lifeDuration'),
            component: !canEdit ? <div>{product.lifeDuration}</div> :
                <EditableText id="productLifeDuration" defaultValue={product.lifeDuration} onSubmit={handleLifeDurationChange} shouldSubmitOnBlur/>
        },
        {
            label: T.translate('table.columns.description'),
            component: !canEdit ? <div>{product.description}</div> :
                <EditableTextArea id="productDescription" defaultValue={product.description} onSubmit={handleDescriptionChange} maxLength={InputMaxLength.DESCRIPTION} shouldSubmitOnBlur/>
        }
    ];

    return (
        <CardTabs className={className} tabs={[
            {
                key: "infos",
                title: T.translate('title.cardInfos'),
                content: <CardTabsInfos data={dataInfos}/>,
            },
            {
                key: "comments",
                title: (
                    <div>
                        {T.translate('title.comments')}
                        {!!product.comment && <FontAwesomeIcon icon="comment" className="ms-2"/>}
                    </div>
                ),
                content: !canEditComment ? (
                    <div className="enable-line-break p-3">{product.comment}</div>
                ) : (
                    <EditableTextArea id="productComment" className="p-3" defaultValue={product.comment} onSubmit={onEditComment} shouldSubmitOnBlur/>
                ),
            },
        ]}/>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardProductInfosAndComments);