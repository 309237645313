import React from 'react';
import './header.scss';

export default function Header({title, children, button}) {
    return (
        <header className="d-sm-flex align-items-end flex-wrap my-4 ms-4 me-3">
            {!!title && <div className="header-title-container">{title}</div>}
            <div className="min-width-45 d-flex align-items-end flex-wrap mw-100 me-auto">
                {children}
            </div>
            <div className="mt-2 flex-shrink-0">
                {button}
            </div>
        </header>
    )
}

