import React from "react";
import {connect} from "react-redux";
import {Redirect, Route} from "react-router-dom";
import {Routes, RouteType} from "../router-constants";
import {alertActions} from "../../redux/alert/actions-alert";
import {ToastClass} from "../../components/toast-alert/toast-alert-class";
import T from 'i18n-react';
import {UserRole} from "../../utils/enums/user-role";

const AuthRoute = props => {
    const {isAuth, type, rights, currentUser, showToast, path} = props;
    const userRole = !currentUser ? null : currentUser.role;

    if (path === Routes.LOGIN_PAGE && isAuth) return <Redirect to={currentUser.role === UserRole.READER ? Routes.ALL_DOCUMENTS_PAGE : Routes.DASHBOARD_PAGE}/>;

    else if (type === RouteType.PRIVATE && !isAuth) return <Redirect to={Routes.LOGIN_PAGE}/>;

    else if (!!rights && rights.length > 0 && !rights.includes(userRole)) {
        showToast(T.translate('alert.errorTitle'), T.translate('auth.noRights'), ToastClass.ERROR);
        return <Redirect to={currentUser.role === UserRole.READER ? Routes.ALL_DOCUMENTS_PAGE : Routes.DASHBOARD_PAGE}/>;
    }

    return <Route {...props} />;
};

const mapStateToProps = state => {
    return {
        isAuth: state.authReducer.isAuth,
        currentUser: state.authReducer.currentUser
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);