import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Col, Form, Row} from "react-bootstrap-v5";
import InputLabel from "../../../components/input-label/input-label";
import T from "i18n-react";
import Validator from "../../../utils/validators/validator";
import {alertActions} from "../../../redux/alert/actions-alert";
import {connect} from "react-redux";
import StatsDto from "../../../models/dto/stats-dto";
import {statsService} from "../../../services/stats-service";
import {UserRole} from "../../../utils/enums/user-role";
import CompanyAutocompleteLabel from "../../../components/input-label/company-autocomplete-label";
import {ping} from "../../../services/api/api";
import InputDateLabel from "../../../components/input-label/input-date-label";

const FormExportCsv = ({className, currentUser}) => {

    const linkRef = useRef();

    const [company, setCompany] = useState({id: '', name: ''});
    const [creationDateRange, setCreationDateRange] = useState({start: '', end: ''});
    const [closingDateRange, setClosingDateRange] = useState({start: '', end: ''});
    const [articleCode, setArticleCode] = useState('');
    const [productName, setProductName] = useState('');

    const [inputErrors, setInputErrors] = useState({
        creationDate: '',
        closingDate: ''
    });

    useEffect(() => {
        setCompany({
            id: currentUser?.companyId ?? '',
            name: '',
        });
    }, [currentUser]);

    const resetErrors = () => {
        setInputErrors({
            creationDate: '',
            closingDate: ''
        })
    }

    const validate = () => {
        const creationDateError = Validator.validateDateRangeWithBothStartAndEnd(creationDateRange.start, creationDateRange.end);
        const closingDateError = Validator.validateDateRangeWithBothStartAndEnd(closingDateRange.start, closingDateRange.end);
        const isValid = !creationDateError && !closingDateError;
        setInputErrors({
            ...inputErrors,
            creationDate: T.translate(creationDateError).toString(),
            closingDate: T.translate(closingDateError).toString()
        })
        return isValid;
    }

    const handleSubmitForm = async e => {
        e.preventDefault();
        resetErrors();
        if (validate()) {
            const statsDto = new StatsDto({company, creationDateRange, closingDateRange, articleCode, productName});
            linkRef.current.href = statsService.getExportCsvUrl(statsDto);
            // ici on ping pour éventuellement forcer axios à appeler le refresh token et éviter sinon que le download envoie un 401 car c'est un appel backend direct
            await ping();
            linkRef.current.click();
        }
    }

    const handleCompanyChange = companySelected => {
        setCompany(companySelected);
    }

    return (
        <Row className={`form-export-csv ${className}`}>
            <Col sm={12} lg={8}>
                <Form onSubmit={handleSubmitForm}>
                    <Card>
                        <Card.Header>
                            <Card.Title>{T.translate('title.exportCSV')}</Card.Title>
                        </Card.Header>
                        <Card.Body className="px-3">
                            <fieldset>
                                <legend className="ps-4">{T.translate('form.fieldset.creationDate')}</legend>

                                <InputDateLabel className="mb-4" label={T.translate('form.label.dateFrom')}
                                                error={inputErrors.creationDate} value={creationDateRange.start}
                                                onChange={date => setCreationDateRange({...creationDateRange, start: date})}/>

                                <InputDateLabel className="mb-4" label={T.translate('form.label.dateTo')}
                                                error={inputErrors.creationDate} value={creationDateRange.end}
                                                onChange={date => setCreationDateRange({...creationDateRange, end: date})}/>
                            </fieldset>
                            <fieldset>
                                <legend className="ps-4">{T.translate('form.fieldset.closingDate')}</legend>

                                <InputDateLabel className="mb-4" label={T.translate('form.label.dateFrom')}
                                                error={inputErrors.closingDate} value={closingDateRange.start}
                                                onChange={date => setClosingDateRange({...closingDateRange, start: date})}/>

                                <InputDateLabel className="mb-4" label={T.translate('form.label.dateTo')}
                                                error={inputErrors.closingDate} value={closingDateRange.end}
                                                onChange={date => setClosingDateRange({...closingDateRange, end: date})}/>
                            </fieldset>
                            <fieldset>
                                <legend className="ps-4">{T.translate('form.fieldset.otherFilters')}</legend>

                                {
                                    currentUser.role === UserRole.SUPER_ADMINISTRATOR &&
                                    <CompanyAutocompleteLabel id="companyId" className="mb-4" error={inputErrors.company}
                                                              defaultValue={company} onChange={handleCompanyChange}/>
                                }

                                <InputLabel className="mb-4" label={T.translate('form.label.articleCode')} type="text"
                                            value={articleCode} onChange={e => setArticleCode(e.target.value)}/>

                                <InputLabel className="mb-4" label={T.translate('form.label.productName')} type="text"
                                            value={productName} onChange={e => setProductName(e.target.value)}/>
                            </fieldset>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <a ref={linkRef} href="#0" hidden={true} download>download</a>
                            <Button variant="primary" type="submit" className="px-3 py-1">
                                {T.translate('form.button.export')}
                            </Button>
                        </Card.Footer>
                    </Card>
                </Form>
            </Col>
        </Row>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormExportCsv);