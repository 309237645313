import {HIDE_CONFIRM_DIALOG, SHOW_CONFIRM_DIALOG} from './type';

const initialStateConfirmDialog = {
    show: false,
    title: '',
    body: '',
    onCancel: () => {
    },
    onConfirm: () => {
    }
}

const confirmDialogReducer = (state = initialStateConfirmDialog, action) => {
    switch (action.type) {
        case SHOW_CONFIRM_DIALOG:
            return {
                show: true,
                title: action.title,
                body: action.body,
                onConfirm: action.onConfirm,
                onCancel: action.onCancel
            };
        case HIDE_CONFIRM_DIALOG:
            return {
                show: false
            };
        default:
            return state;
    }
}

export default confirmDialogReducer;