export const PRODUCT_SHOW_CREATE_MODAL = "PRODUCT_SHOW_CREATE_MODAL";
export const PRODUCT_HIDE_CREATE_MODAL = "PRODUCT_HIDE_CREATE_MODAL";
export const PRODUCT_DELETED = "PRODUCT_DELETED";
export const PRODUCT_DUPLICATED = "PRODUCT_DUPLICATED";
export const PRODUCT_CONTROL_EDIT = "PRODUCT_CONTROL_EDIT";
export const PRODUCT_CONTROL_DELETED = "PRODUCT_CONTROL_DELETED";
export const PRODUCT_CONTROL_AND_SPECS_GENERATED = "PRODUCT_CONTROL_AND_SPECS_GENERATED";
export const PRODUCT_CHECKBOX_CHECKED = "PRODUCT_CHECKBOX_CHECKED";
export const ADD_ANALYSIS_TO_PRODUCT = "ADD_ANALYSIS_TO_PRODUCT";
export const PRODUCT_SET_INITIAL_WARNING = "PRODUCT_SET_INITIAL_WARNING";
export const PRODUCT_UPDATE_SELECTED_ANALYSIS = "PRODUCT_UPDATE_SELECTED_ANALYSIS";
export const PRODUCT_INCREMENT_SELECTED_ANALYSIS = "PRODUCT_INCREMENT_SELECTED_ANALYSIS";
export const PRODUCT_SAVE_SEARCH_TEXT = "PRODUCT_SAVE_SEARCH_TEXT";
export const ACTUALISE_COUNT_PRODUCT_CONTROL_PLANS_EXPIRED = "ACTUALISE_COUNT_PRODUCT_CONTROL_PLANS_EXPIRED";