const Routes = {
    LOGIN_PAGE: '/login',
    CHANGE_PASSWORD_REQUEST_PAGE: '/change-password-request',
    CHANGE_PASSWORD_PAGE: '/change-password/:token',
    CREATE_PASSWORD_PAGE: '/create-password/:token/:onlyCGU',
    DASHBOARD_PAGE: '/',
    LOTS_PAGE: '/lots',
    PRODUCTS_PAGE: '/products',
    ANALYSES_PAGE: '/analyses',
    CATEGORIES_ANALYSES_PAGE: '/analyses/categories',
    LABORATORIES_PAGE: '/laboratories',
    PROFILE_PAGE: '/my-profile',
    COMPANIES_PAGE: '/companies',
    MY_COMPANY_PAGE: '/my-company',
    USERS_PAGE: '/users',
    STATS_PAGE: '/stats',
    ALL_DOCUMENTS_PAGE: '/documents',
    IMPORTS_PAGE: '/imports'
}

const RouteType = {
    PUBLIC: "PUBLIC",
    PRIVATE: "PRIVATE"
}

export {Routes, RouteType};