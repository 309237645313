import React, {useEffect, useState} from "react";
import PageWithMenuTemplate from "../../components/template-pages/page-with-menu-template";
import Header from "../../components/header/header";
import T from "i18n-react";
import {UserRole} from "../../utils/enums/user-role";
import {Button} from "react-bootstrap-v5";
import {connect} from "react-redux";
import {productActions} from "../../redux/product/actions-product";
import CreateProductModal from "./components/create-product-modal";
import TableProductList from "./components/table-product-list";
import HeaderTextLabel from "../../components/header/header-text-label";
import SearchInput from "../../components/input-label/search-input";
import CardIndicator from "../../components/card-indicator/card-indicator";
import {productService} from "../../services/product-service";
import I18NController from "../../utils/i18n/i18n-controller";
import {ping} from "../../services/api/api";
import {productsExportService} from "../../services/products-export-service";

const ProductsPage = ({currentUser, showCreateProductModal, productSaveSearchText, searchTextFromReducer}) => {
    // const linkRef = useRef();

    const [state, setState] = useState({isLoading: false, error: ''});
    const [searchText, setSearchText] = useState(searchTextFromReducer);
    const [closedLotsNb, setClosedLotsNb] = useState(0);
    const [closedLotsPrice, setClosedLotsPrice] = useState('0');
    const [closedLotsCompliant, setClosedLotsCompliant] = useState('0');
    const [nextMonitoringRate, setNextMonitoringRate] = useState('0');

    const getListingProducts = async () => {
        const linkRef = document.createElement('a');
        linkRef.download = 'download.csv';
        linkRef.href = await productsExportService.getLstingProductsCsvUrlByCompany(currentUser.companyId);
        // ici on ping pour éventuellement forcer axios à appeler le refresh token et éviter sinon que le download envoie un 401 car c'est un appel backend direct
        await ping();
        document.body.appendChild(linkRef);
        linkRef.click();
        document.body.removeChild(linkRef);
    }

    const getControlPlans = async () => {
        const linkRef = document.createElement('a');
        linkRef.download = 'download.csv';
        linkRef.href = await productsExportService.getControlPlansCsvUrlByCompany(currentUser.companyId);
        // ici on ping pour éventuellement forcer axios à appeler le refresh token et éviter sinon que le download envoie un 401 car c'est un appel backend direct
        await ping();
        document.body.appendChild(linkRef);
        linkRef.click();
        document.body.removeChild(linkRef);
    }

    useEffect(() => {
        const getIndicatorNbLotsClosed = async () => {
            const response = await productService.indicatorsLotsClosed(searchText);
            setClosedLotsNb(response.numberOfLots);
        }

        const getIndicatorLotsClosedControlPrice = async () => {
            const response = await productService.indicatorsLotsClosedControlPrice(searchText);
            const formattedPrice = I18NController.getInstance().formatPrice(response.sumOfControlPrice, currentUser.currency, currentUser.language);
            setClosedLotsPrice(formattedPrice);
        }

        const getIndicatorLotsClosedCompliant = async () => {
            const response = await productService.indicatorsLotsClosedCompliance(searchText);
            setClosedLotsCompliant(T.translate('indicators.percent', {nb: response.percentageOfCompliantLots}).toString());
        }

        const getIndicatorNextMonitoringRate = async () => {
            const response = await productService.indicatorNextMonitoringRate(searchText);
            setNextMonitoringRate(T.translate('indicators.percent', {nb: response.nextMonitoringRate}).toString());
        }

        const getIndicators = async () => {
            try {
                setState({isLoading: true, error: ''});
                await getIndicatorNbLotsClosed();
                await getIndicatorLotsClosedControlPrice();
                await getIndicatorLotsClosedCompliant();
                await getIndicatorNextMonitoringRate();
                setState({isLoading: false, error: ''});
            } catch (error) {
                setState({isLoading: false, error: error.message});
            }
        }

        getIndicators();
    }, [searchText, currentUser]);

    const handleSearchChange = e => {
        const text = e.target.value;
        if (text.length === 0 || text.length >= 3) {
            setSearchText(text);
            productSaveSearchText(text);
        }
    }

    const headerButtons = (
        currentUser.role !== UserRole.VISITOR && currentUser.role !== UserRole.CONTRIBUTOR &&
        <React.Fragment>
            <Button variant="primary" className="px-4 mb-2 mb-sm-0 me-3" onClick={getListingProducts}>
                {T.translate('product.button.listingProductExport')}
            </Button>
            <Button variant="primary" className="px-4 mb-2 mb-sm-0 me-3" onClick={getControlPlans}>
                {T.translate('product.button.controlPlansExport')}
            </Button>
            <Button variant="success" className="px-4 mb-2 mb-sm-0" onClick={showCreateProductModal}>
                {T.translate('product.button.createProduct')}
            </Button>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <PageWithMenuTemplate pageTitle={T.translate('pageTitle.products')}>
                <Header>
                    <HeaderTextLabel className="me-4" isMain={true}>
                        <h1 className="ps-1">{T.translate('menu.products')}</h1>
                    </HeaderTextLabel>
                </Header>
                <Header button={headerButtons}>
                    <div className="search-container">
                        <SearchInput onChange={handleSearchChange} initialValue={searchText}/>
                    </div>
                </Header>
                <div className="d-flex flex-wrap mx-3 mb-3 gap-3">
                    <CardIndicator isLoading={state.isLoading} error={state.error} label={T.translate('indicators.cost')} value={closedLotsPrice}
                                   showYear={true}/>
                    <CardIndicator isLoading={state.isLoading} error={state.error} label={T.translate('indicators.compliance')} value={closedLotsCompliant}
                                   showYear={true}/>
                    <CardIndicator isLoading={state.isLoading} error={state.error} label={T.translate('indicators.lotsClosed')} value={closedLotsNb}
                                   showYear={true}/>
                    <CardIndicator isLoading={state.isLoading} error={state.error} label={T.translate('indicators.nextMonitoringRate')}
                                   value={nextMonitoringRate}/>
                </div>
                <TableProductList className="mx-3"/>
            </PageWithMenuTemplate>
            <CreateProductModal/>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        searchTextFromReducer: state.productReducer.searchText
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showCreateProductModal: () => dispatch(productActions.productCreate()),
        productSaveSearchText: (text) => dispatch(productActions.saveSearchText(text))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);
