export const ImportTitleColumn = {
    PRODUCT: "PRODUCT",
    PRODUCT_REF: "PRODUCT_REF",
    BATCH_NUMBER: "BATCH",
    ORDER_REF: "ORDER_REF",
    ANALYSIS: "ANALYSIS",
    ANALYSIS_REF: "ANALYSIS_REF",
    ANALYSIS_RESULT: "ANALYSIS_RESULT",
    UNIT: "UNIT",
    PARAMETER: "PARAMETER",
    PARAMETER_REF: "PARAMETER_REF",
    PARAMETER_RESULT: "PARAMETER_RESULT",
};

export default ImportTitleColumn;