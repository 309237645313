import React, {useEffect, useState} from 'react';
import {VictoryAxis, VictoryChart, VictoryLine, VictoryTooltip, VictoryVoronoiContainer} from "victory";
import variables from '../../assets/styles/variables.scss';
import T from "i18n-react";

const LinesChart = ({className, datasets}) => {
    const colors = [variables.primary, variables.purpleGrey].reverse();

    const months = Array.from(Array(12).keys()).map((index) => T.translate(`chart.label.month${index}`));

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(datasets.reverse());
    }, [datasets]);

    const tooltip = (
        <VictoryTooltip
            cornerRadius={0}
            flyoutPadding={{left: 10, right: 10, top: 5, bottom: 5}}
            flyoutStyle={{fill: "white", strokeOpacity: 0.25}}/>
    );

    const voronoiContainer = (
        <VictoryVoronoiContainer
            className={`chart-lines ${className}`}
            labels={({datum}) => datum.y}
            labelComponent={tooltip}
        />
    );

    return (
        <VictoryChart height={150} containerComponent={voronoiContainer} minDomain={{y: -4}}>
            <VictoryAxis
                tickValues={Array.from(Array(12).keys())}
                tickFormat={months}
                style={{
                    axis: {stroke: "transparent"},
                    tickLabels: {fill: variables.dark},
                }}
            />
            {data.map((d, index) => (
                <VictoryLine
                    key={`line-${index}`}
                    data={d}
                    style={{
                        data: {stroke: colors[index % colors.length], strokeWidth: ({active}) => active ? 5 : 3},
                        labels: {fill: colors[index % colors.length]}
                    }}
                />
            ))}
        </VictoryChart>
    );
};

export default LinesChart;
