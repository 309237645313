import React, {useCallback, useEffect, useState} from 'react';
import RemoteTable from "../../../components/table/remote-table";
import {connect} from "react-redux";
import T from 'i18n-react'
import {UserRole} from "../../../utils/enums/user-role";
import {Button, Card} from "react-bootstrap-v5";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {alertActions} from "../../../redux/alert/actions-alert";
import {confirmDialogActions} from "../../../redux/confirm-dialog/actions-confirm-dialog";
import {useHistory} from "react-router-dom";
import {Routes} from "../../../router/router-constants";
import {productService} from "../../../services/product-service";
import {productActions} from "../../../redux/product/actions-product";
import {StatusActiveInactive, StatusActiveInactiveClassname} from "../../../utils/enums/status-active-inactive";
import {ProductStatusClassname} from "../../../utils/enums/product-status";
import {ComplianceLevelClassName} from "../../../utils/enums/compliant-type";
import I18NController from "../../../utils/i18n/i18n-controller";
import StatusProductsPageComponent from "../../../components/status-component/status-products-page-component";
import Moment from "react-moment";
import NextMonitoringMomentComponent from "../../../components/next-monitoring-date-component/next-monitoring-moment-component";

const TableProductList = ({className, showToast, currentUser, productDeleted, productDuplicated, showConfirmDialog, progress, searchTextFromReducer}) => {
    const currentYear = new Date().getFullYear();

    const history = useHistory();
    const [searchText, setSearchText] = useState(searchTextFromReducer);

    useEffect(() => {
        setSearchText(searchTextFromReducer);
    }, [searchTextFromReducer]);

    let columns = [
        {
            dataField: T.translate('table.fields.status').toString(),
            text: T.translate('table.columns.status').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.product.updateDate').toString(),
            text: T.translate('table.columns.product.updateDate').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.category').toString(),
            text: T.translate('table.columns.category').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.product.articleCode').toString(),
            text: T.translate('table.columns.product.articleCode').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.name').toString(),
            text: T.translate('table.columns.productName').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.supplier').toString(),
            text: T.translate('table.columns.supplier').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.lifeDuration').toString(),
            text: T.translate('table.columns.lifeDuration').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.nbClosedLots').toString(),
            text: `${T.translate('table.columns.nbClosedLots')} (${currentYear})`,
        },
        {
            dataField: T.translate('table.fields.compliance').toString(),
            text: `${T.translate('table.columns.percentageOfCompliance')} (${currentYear})`,
        },
        {
            dataField: T.translate('table.fields.price').toString(),
            text: `${T.translate('table.columns.cumulativeCost')} (${currentYear})`,
        },
        {
            dataField: T.translate('table.fields.nextMonitoringDate').toString(),
            text: T.translate('table.columns.nextMonitoringDate').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.actions').toString(),
            text: T.translate('table.columns.actions').toString()
        }
    ];

    if (currentUser.role === UserRole.SUPER_ADMINISTRATOR) {
        columns = [
            {
                dataField: T.translate('table.fields.product.companyName').toString(),
                text: T.translate('table.columns.company').toString(),
                sort: true
            },
            ...columns
        ];
    }

    const onFetchData = useCallback(async (page, size, sortedOrder, sortedField, searchText) => {
        const deleteProduct = async product => {
            try {
                await productService.deleteProduct(product.productId);
                productDeleted();
                showToast(T.translate('alert.successTitle'), T.translate('product.successDeleteProduct', {productName: product.name}), ToastClass.SUCCESS);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleDeleteProduct = async (e, product) => {
            e.stopPropagation();
            showConfirmDialog(
                T.translate('dialog.title.confirmDelete'),
                T.translate('dialog.confirmDeleteProduct', {productName: product.name}),
                () => {
                },
                () => deleteProduct(product))
        }

        const handleEditProduct = (e, product) => {
            e.stopPropagation();
            history.push(`${Routes.PRODUCTS_PAGE}/${product.productId}`)
        }

        const handleEditProductRightClick = (e, product) => {
            e.preventDefault();
            window.open(`${Routes.PRODUCTS_PAGE}/${product.productId}`, '_blank', 'noopener,noreferrer');
        }

        const handleDuplicateProduct = async (e, product) => {
            e.stopPropagation();
            try {
                await productService.duplicateProduct(product.productId);
                productDuplicated();
                showToast(T.translate('alert.successTitle'), T.translate('product.successDuplicateProduct', {productName: product.name}), ToastClass.SUCCESS);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const completeElements = allElements => {
            return allElements.map(element => {
                return {
                    ...element,
                    id: element.productId,
                    updateDate: !element.updateDate ? "-" : <Moment>{element.updateDate}</Moment>,
                    activated: <StatusProductsPageComponent
                        type={element.activated ? ProductStatusClassname[element.status] : StatusActiveInactiveClassname[StatusActiveInactive.INACTIVE]}/>,
                    nbClosedLots: <div className="pastille">{element.numberOfClosedLots}</div>,
                    compliance: <div
                        className={`pastille-compliance ${ComplianceLevelClassName[element.complianceLevel]}`}>{T.translate('indicators.percent', {nb: element.percentageOfCompliantLots})}</div>,
                    price: <div
                        className="fw-bold text-end pe-2">{I18NController.getInstance().formatPrice(element.controlPrice, currentUser.currency, currentUser.language)}</div>,
                    nextMonitoringDate: <NextMonitoringMomentComponent value={element.nextMonitoringDate}/>,
                    actions: (
                        <div className="actions">
                            <Button size="sm" className="edit" onClick={e => handleEditProduct(e, element)}
                                    onContextMenu={e => handleEditProductRightClick(e, element)}>
                                <FontAwesomeIcon icon="edit"/>
                            </Button>
                            <Button size="sm"
                                    className={`duplicate ms-1 ${currentUser.role === UserRole.COMPANY_ADMINISTRATOR || currentUser.role === UserRole.SUPER_ADMINISTRATOR || currentUser.role === UserRole.SUPERVISOR ? "" : "d-none"}`}
                                    onClick={e => handleDuplicateProduct(e, element)}>
                                <FontAwesomeIcon icon="clone"/>
                            </Button>
                            <Button size="sm"
                                    className={`delete ms-1 ${currentUser.role === UserRole.COMPANY_ADMINISTRATOR || currentUser.role === UserRole.SUPER_ADMINISTRATOR || currentUser.role === UserRole.SUPERVISOR ? "" : "d-none"}`}
                                    onClick={e => handleDeleteProduct(e, element)}>
                                <FontAwesomeIcon icon="times"/>
                            </Button>
                        </div>
                    )
                }
            })
        }

        const data = await productService.getAll(page, size, sortedOrder, sortedField, searchText, null, true);
        const elements = completeElements(data.content);
        return {
            ...data,
            elements: elements
        };
    }, [showToast, showConfirmDialog, productDeleted, productDuplicated, currentUser, history])

    return (
        <Card className={className}>
            <RemoteTable id="table-product-list"
                         defaultSortedField={T.translate('table.fields.name')}
                         columns={columns} onFetchData={onFetchData} reload={progress} searchText={searchText}/>
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        progress: state.productReducer.progress,
        searchTextFromReducer: state.productReducer.searchText
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        productDeleted: () => dispatch(productActions.productDeleted()),
        productDuplicated: () => dispatch(productActions.productDuplicated()),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableProductList);
