import { backendUrl, privateAPI } from './api/api';
import { ApiErrorHandler } from "./api/api-error";

const BASE_URL = "/documents/";

const config = {
    headers: {
        'accept': 'application/json',
        'content-type': 'multipart/form-data'
    }
}

const getAll = async (page = 1, size = 10, sortDirection = 'DESC', sortFieldName = 'createdDate', searchText = '') => {
    try {
        const response = await privateAPI.get(`${BASE_URL}?page=${page}&size=${size}&sortDirection=${!!sortDirection ? sortDirection : 'DESC'}&sortFieldName=${!!sortFieldName ? sortFieldName : 'createdDate'}&searchText=${searchText}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getAllByEntityId = async (entityId, page = 1, size = 10, sortDirection = 'DESC', sortFieldName = 'createdDate', searchText = '') => {
    try {
        const response = await privateAPI.get(`${BASE_URL}entity/${entityId}?page=${page}&size=${size}&sortDirection=${!!sortDirection ? sortDirection : 'DESC'}&sortFieldName=${!!sortFieldName ? sortFieldName : 'createdDate'}&searchText=${searchText}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const createDocument = async (documentData) => {
    try {
        const response = await privateAPI.post(`${BASE_URL}`, documentData, config);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const deleteDocument = async (id) => {
    try {
        const response = await privateAPI.delete(`${BASE_URL}${id}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const deleteDocumentByEntityId = async (entityId) => {
    try {
        const response = await privateAPI.delete(`${BASE_URL}by-entity/${entityId}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editDocument = async (id, documentData) => {
    try {
        const response = await privateAPI.put(`${BASE_URL}${id}`, documentData);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editValidityDate = async (id, validityDate) => {
    try {
        return await privateAPI.patch(`${BASE_URL}${id}/validity-date`, validityDate, { headers: { 'content-type': 'text/plain' } });
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getIndicatorExpiredDocuments = async () => {
    try {
        const response = await privateAPI.get(`${BASE_URL}count-expired-documents`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getDownloadUrl = fileUri => {
    return `${backendUrl}${fileUri}`
}

const getInlineUrlByEntityId = id => {
    return `${backendUrl}${BASE_URL}inline-by-entity/${id}`
}


export const documentService = {
    getAll,
    getAllByEntityId,
    createDocument,
    editDocument,
    editValidityDate,
    deleteDocument,
    deleteDocumentByEntityId,
    getInlineUrlByEntityId,
    getDownloadUrl,
    getIndicatorExpiredDocuments
};
