import React from 'react';
import {Table} from "react-bootstrap-v5";

const CardTabsInfos = ({data}) => (
    <Table striped={true} size="sm" className="mt-0 pt-0">
        <tbody>
        {data.map((row, index) => (
            <tr key={`infos-${index}`}>
                <td className="head">{row.label}</td>
                <td>{row.component}</td>
            </tr>
        ))}
        </tbody>
    </Table>
);

export default CardTabsInfos;