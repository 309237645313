import {privateAPI} from './api/api';
import {ApiErrorHandler} from "./api/api-error";

const BASE_URL = '/accounts/';

const getAll = async (page = 1, size = 10, sortDirection = 'ASC', sortFieldName = 'lastname', searchText = '') => {
    try {
        const response = await privateAPI.get(`${BASE_URL}?page=${page}&size=${size}&sortDirection=${!!sortDirection ? sortDirection : 'ASC'}&sortFieldName=${!!sortFieldName ? sortFieldName : 'lastname'}&searchText=${searchText}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const createUser = async (user) => {
    try {
        const response = await privateAPI.post(BASE_URL, user);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const deleteUser = async (email) => {
    try {
        return await privateAPI.delete(`${BASE_URL}${email}`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editUser = async (email, userData) => {
    try {
        return await privateAPI.put(`${BASE_URL}${email}`, userData);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getCurrentUser = async () => {
    try {
        const response = await privateAPI.get(`${BASE_URL}current`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editCurrentUser = async (user) => {
    try {
        await privateAPI.put(`${BASE_URL}current`, user);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const changeCurrentUserPassword = async (currentPassword, newPassword, newPasswordConfirmation) => {
    try {
        await privateAPI.post(`${BASE_URL}current/change-password`, {currentPassword, newPassword, newPasswordConfirmation});
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

export const userService = {
    getAll,
    createUser,
    deleteUser,
    editUser,
    getCurrentUser,
    editCurrentUser,
    changeCurrentUserPassword
};
