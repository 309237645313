import React from 'react';
import {Button, Modal} from "react-bootstrap-v5";
import {connect} from "react-redux";
import {confirmDialogActions} from "../../redux/confirm-dialog/actions-confirm-dialog";
import './confirm-dialog.scss'
import T from 'i18n-react'

const ConfirmDialog = ({confirmDialogState, hideConfirmDialog}) => {

    const {show, title, body, onCancel, onConfirm} = confirmDialogState;

    const handleClose = () => {
        onCancel();
        hideConfirmDialog();
    }

    const handleConfirm = () => {
        onConfirm();
        hideConfirmDialog();
    }

    return (
        <Modal className="confirm-dialog" show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={handleClose}>{T.translate('dialog.button.cancel')}</Button>
                <Button variant="primary" onClick={handleConfirm}>{T.translate('dialog.button.confirm')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = state => {
    return {
        confirmDialogState: state.confirmDialogReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideConfirmDialog: () => dispatch(confirmDialogActions.hideConfirmDialog())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDialog);