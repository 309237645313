import React from 'react';
import T from "i18n-react";
import LocalTable from "../table/local-table";

const AnalyseHistoryTable = ({data}) => {
    const columns = [
        {
            dataField: T.translate('table.fields.lotCreationDate').toString(),
            text: T.translate('table.columns.lot.creationDateShort').toString()
        },
        {
            dataField: T.translate('table.fields.lot.lotNumber').toString(),
            text: T.translate('table.columns.lot.lotNumber').toString()
        },
        {
            dataField: T.translate('table.fields.specifications').toString(),
            text: T.translate('table.columns.spec').toString()
        },
        {
            dataField: T.translate('table.fields.result').toString(),
            text: T.translate('table.columns.result').toString()
        },
        {
            dataField: T.translate('table.fields.analyse.unit').toString(),
            text: T.translate('table.columns.unit').toString()
        },
        {
            dataField: T.translate('table.fields.source').toString(),
            text: T.translate('table.columns.analyse.source').toString()
        },
        {
            dataField: T.translate('table.fields.lot.orderNumber').toString(),
            text: T.translate('table.columns.lot.orderNumber').toString()
        },
        {
            dataField: T.translate('table.fields.lastResultModificationDate').toString(),
            text: T.translate('table.columns.analyse.controlDate').toString()
        },
    ];

    return (
        <div className="analyse-history-table">
            <table className="infos mx-3 mb-2">
                <tbody>
                <tr>
                    <td>{T.translate('table.columns.lot.productName')} :</td>
                    <td>{data.productName}</td>
                </tr>
                <tr>
                    <td>{T.translate('table.columns.product.articleCode')} :</td>
                    <td>{data.articleCode}</td>
                </tr>
                <tr>
                    <td>{T.translate('table.columns.analyseName')} :</td>
                    <td>{data.analysisName}</td>
                </tr>
                {!!data.parameterName && <tr>
                    <td>{T.translate('table.columns.parameterName')} :</td>
                    <td>{data.parameterName}</td>
                </tr>}
                <tr>
                    <td>{T.translate('table.columns.method')} :</td>
                    <td>{data.method}</td>
                </tr>
                <tr>
                    <td>{T.translate('table.columns.periodicity')} :</td>
                    <td>{data.periodicity.displayName}</td>
                </tr>
                </tbody>
            </table>
            <LocalTable data={data.results} columns={columns}/>
        </div>
    );
}

export default AnalyseHistoryTable;