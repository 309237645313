import React from 'react';
import {Dropdown} from "react-bootstrap-v5";
import './buttons.scss'

const ButtonMoreOptions = ({className, title, variant = "primary", buttonOptions}) => {
    return (
        <Dropdown className={`button-more-options d-inline-block ${className}`}>
            <Dropdown.Toggle variant={variant} className="px-3">
                {title}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {buttonOptions.map(option => <Dropdown.Item onClick={option.onClick} className="px-4 py-2">{option.title}</Dropdown.Item>)}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default ButtonMoreOptions;