import React, {useEffect, useState} from 'react';
import {companyService} from "../../services/company-service";
import {SortDirection} from "../../utils/enums/sort-direction";
import T from "i18n-react";
import {ToastClass} from "../toast-alert/toast-alert-class";
import AutocompleteLabel from "./autocomplete-label";
import {connect} from "react-redux";
import {alertActions} from "../../redux/alert/actions-alert";

const CompanyAutocompleteLabel = ({defaultValue, id, className, label, required, error, onChange, disabled, placeholder, showToast}) => {

    const [company, setCompany] = useState({id: '', name: ''});

    useEffect(() => setCompany(defaultValue), [defaultValue]);

    const searchCompanyByName = async searchText => {
        try {
            const response = await companyService.getAll(1, 5, SortDirection.ASC.toUpperCase(), 'name', searchText);
            return response.content?.map(companyElement => {
                return {
                    id: companyElement.companyId,
                    name: companyElement.name
                }
            }) ?? [];
        } catch (err) {
            showToast(T.translate('alert.errorTitle'), err.message, ToastClass.ERROR);
            return [];
        }
    }

    const handleCompanyNameChange = e => {
        const newCompany = {id: '', name: e.target.value};
        setCompany(newCompany);
        onChange(newCompany);
    }

    const handleCompanySelected = companySelected => {
        setCompany(companySelected);
        onChange(companySelected);
    }

    return (
        <AutocompleteLabel id={id} className={className} label={label ?? T.translate('form.label.company')}
                           value={company.name} onSuggestionsFetch={searchCompanyByName}
                           onChange={handleCompanyNameChange} onSelect={handleCompanySelected}
                           required={required} error={error} disabled={disabled} placeholder={placeholder}/>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(null, mapDispatchToProps)(CompanyAutocompleteLabel);