import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import PageWithMenuTemplate from "../../components/template-pages/page-with-menu-template";
import T from "i18n-react";
import {laboratoryService} from "../../services/laboratory-service";
import LaboratoryEntity from "../../models/entities/laboratory-entity";
import {Col, Row} from "react-bootstrap-v5";
import CardLaboratoryInfosAndComments from "./components/card-laboratory-infos-and-comments";
import OverlayLoadingOrError from "../../components/overlay-component/overlay-loading-or-error";
import CardLaboratoryAnalyses from "./components/card-laboratory-analyses";
import './laboratory-details-page.scss'
import {ToastClass} from "../../components/toast-alert/toast-alert-class";
import {alertActions} from "../../redux/alert/actions-alert";
import {connect} from "react-redux";
import Header from "../../components/header/header";
import CardDocuments from "../../components/card-documents/card-documents";
import HeaderTextLabel from "../../components/header/header-text-label";
import EditableText from "../../components/editable-text/editable-text";
import CardLaboratoryIndicators from "./components/card-laboratory-indicators";
import StatusComponent from "../../components/status-component/status-component";
import {StatusActiveInactive, StatusActiveInactiveClassname} from "../../utils/enums/status-active-inactive";
import {UserRole} from "../../utils/enums/user-role";
import {confirmDialogActions} from "../../redux/confirm-dialog/actions-confirm-dialog";
import {DocumentType} from "../../utils/enums/document-type";

const LaboratoryDetailsPage = ({showToast, currentUser, showConfirmDialog}) => {
    const {id} = useParams();

    const [laboratory, setLaboratory] = useState({});
    const [state, setState] = useState({isLoading: false, error: ''});

    useEffect(() => {
        const getLaboratory = async () => {
            setState(prevState => {
                return {...prevState, isLoading: true};
            });
            try {
                const data = await laboratoryService.getLaboratoryById(id);
                const laboratoryEntity = new LaboratoryEntity(data);
                setLaboratory(laboratoryEntity)
                setState(prevState => {
                    return {...prevState, isLoading: false};
                });
            } catch (error) {
                setState(prevState => {
                    return {...prevState, isLoading: false, error: error.message};
                });
            }
        }
        getLaboratory();
    }, [id])

    const history = useHistory();

    const goToPreviousPage = () => {
        history.goBack();
    }

    const editLaboratory = async (laboratoryPatch) => {
        return await laboratoryService.editLaboratory(laboratory.id, laboratoryPatch);
    }

    const handleNameChange = async name => {
        try {
            setLaboratory({...laboratory, name});
            await editLaboratory({name})
            showToast(T.translate('alert.successTitle'), T.translate('laboratory.successEditName'), ToastClass.SUCCESS);
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleEditComment = async comment => {
        try {
            setLaboratory({...laboratory, comment});
            await laboratoryService.editLaboratoryComment(id, comment)
            showToast(T.translate('alert.successTitle'), T.translate('laboratory.successEditComment'), ToastClass.SUCCESS);
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleEditValue = async (name, tabNewValue, newValue) => {
        if (name === 'phone') {
            return await editLaboratory(tabNewValue);
        }

        const message = name.charAt(0).toUpperCase() + name.slice(1);
        try {
            await editLaboratory(tabNewValue)
            updateLaboratory(name, newValue);
            showToast(T.translate('alert.successTitle'), T.translate('laboratory.successEdit' + message), ToastClass.SUCCESS);
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const updateLaboratory = (name, newValue) => {
        switch (name) {
            case 'address':
                const address = newValue;
                return setLaboratory({...laboratory, address});

            case 'contact':
                const contact = newValue;
                return setLaboratory({...laboratory, contact});

            case 'phone':
                const phone = newValue;
                return setLaboratory({...laboratory, phone});

            case 'role':
                const role = newValue;
                return setLaboratory({...laboratory, role});

            case 'email':
                const email = newValue;
                return setLaboratory({...laboratory, email});

            case 'activated':
                const activated = newValue;
                return setLaboratory({...laboratory, activated});

            case 'description':
                const description = newValue;
                return setLaboratory({...laboratory, description});

            case 'customerNumber':
                const customerNumber = newValue;
                return setLaboratory({...laboratory, customerNumber});

            default:
        }
    }

    const handleEditLaboratoryActivated = activated => {
        showConfirmDialog(
            T.translate('dialog.title.confirmToggleStatus'),
            T.translate('dialog.confirmToggleLaboratoryStatus', {status: T.translate(activated ? 'status.active' : 'status.inactive')}),
            () => {
            },
            () => handleEditValue("activated", {activated}, activated)
        )
    }

    const headerTitle = (
        <HeaderTextLabel className="me-4" label={T.translate('laboratory.laboratory')} isMain={true}>
            <EditableText className="editable-h1" id="laboratoryName" type="text" defaultValue={laboratory.name} onSubmit={handleNameChange} adjustWidthWithText={true} shouldSubmitOnBlur/>
        </HeaderTextLabel>
    );

    return (
        <PageWithMenuTemplate pageTitle={T.translate('pageTitle.laboratory', {laboratoryName: laboratory.name})}>
            <Header title={headerTitle}>
                <HeaderTextLabel className="me-4" label={T.translate('table.columns.status')}>
                    <div className="px-2 pt-1">
                        <StatusComponent type={StatusActiveInactiveClassname[laboratory.activated ? StatusActiveInactive.ACTIVE : StatusActiveInactive.INACTIVE]}
                                         onClick={(currentUser.role !== UserRole.SUPER_ADMINISTRATOR && currentUser.role !== UserRole.COMPANY_ADMINISTRATOR && currentUser.role !== UserRole.SUPERVISOR) ? null : () => handleEditLaboratoryActivated(!laboratory.activated)}/>
                    </div>
                </HeaderTextLabel>
            </Header>
            <Row className="gx-4 mx-1">
                <Col className="mb-4">
                    <CardLaboratoryInfosAndComments
                        className="h-100"
                        laboratory={laboratory}
                        onEditValue={handleEditValue}
                        onEditComment={handleEditComment}/>
                </Col>
                <Col className="indicators-container mb-4">
                    <CardLaboratoryIndicators className="h-100" laboratoryId={id}/>
                </Col>
                <Col className="mb-4">
                    <CardDocuments className="h-100" entityId={id} documentType={DocumentType.LABORATORY}/>
                </Col>
                <Col sm={12} className="mt-4 mt-lg-0">
                    <CardLaboratoryAnalyses laboratoryId={id}/>
                </Col>
            </Row>
            <OverlayLoadingOrError isLoading={state.isLoading} error={state.error} onCloseError={goToPreviousPage}/>
        </PageWithMenuTemplate>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LaboratoryDetailsPage);
