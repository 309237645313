export default class StatsDto {
    constructor(statsData) {
        this.companyId = statsData?.company?.id ?? '';
        this.creationDateStart = statsData?.creationDateRange?.start ?? '';
        this.creationDateEnd = statsData?.creationDateRange?.end ?? '';
        this.closingDateStart = statsData?.closingDateRange?.start ?? '';
        this.closingDateEnd = statsData?.closingDateRange?.end ?? '';
        this.articleCode = statsData?.articleCode ?? '';
        this.productName = statsData?.productName ?? '';
    }
}