import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import PageWithMenuTemplate from "../../components/template-pages/page-with-menu-template";
import T from "i18n-react";
import Header from "../../components/header/header";
import {Button} from "react-bootstrap-v5";
import OverlayLoadingOrError from "../../components/overlay-component/overlay-loading-or-error";
import TableDocumentList from "./components/table-document-list";
import CustomDropZone from "../../components/drop-zone/custom-drop-zone";
import {documentActions} from "../../redux/document/actions-document";
import {connect} from "react-redux";
import {FileType} from "../../components/input-label/file-type";
import {ToastClass} from "../../components/toast-alert/toast-alert-class";
import {documentService} from "../../services/document-service";
import {alertActions} from "../../redux/alert/actions-alert";
import {laboratoryService} from "../../services/laboratory-service";
import {productService} from "../../services/product-service";
import HeaderTextLabel from "../../components/header/header-text-label";
import {lotService} from "../../services/lot-service";
import SearchInput from "../../components/input-label/search-input";
import {DocumentType} from "../../utils/enums/document-type";

const DocumentsPage = ({documentType, showCreateDocumentModal, showToast, documentAdded}) => {
    const acceptedTypes = FileType.PDF

    const {id} = useParams();

    const history = useHistory();

    const [entityName, setEntityName] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [state, setState] = useState({isLoading: false, error: ''});
    const [addDocumentLoading, setAddDocumentLoading] = useState(false)
    const [addDocumentError, setAddDocumentError] = useState('')
    const [searchText, setSearchText] = useState('');

    const handleSearchChange = e => {
        const text = e.target.value;
        if (text.length === 0 || text.length >= 3) {
            setSearchText(text);
        }
    }

    useEffect(() => {
        const callApi = async () => {
            setState(prevState => {
                return {...prevState, isLoading: true};
            });
            try {
                let entity = {};
                if (documentType === DocumentType.LABORATORY) {
                    entity = await laboratoryService.getLaboratoryById(id);
                } else if (documentType === DocumentType.PRODUCT) {
                    entity = await productService.getProductById(id);
                } else if (documentType === DocumentType.LOT) {
                    entity = await lotService.getLotById(id);
                }
                setEntityName(entity?.name);
                setCompanyId(entity?.company?.companyId)
                setState(prevState => {
                    return {...prevState, isLoading: false};
                });
            } catch (error) {
                setState(prevState => {
                    return {...prevState, isLoading: false, error: error.message};
                });
            }
        }
        callApi();
    }, [id, documentType]);

    const goBackToPreviousPage = () => {
        history.goBack();
    }

    const getGoBackButtonTitle = () => {
        switch (documentType) {
            case DocumentType.LABORATORY:
                return 'laboratory.button.goBackToLaboratory';
            case DocumentType.PRODUCT:
                return 'product.button.goBackToProduct';
            case DocumentType.LOT:
                return 'lot.button.goBackToLot';
            default:
                return 'button.goBack';
        }
    }

    const getPageTitle = () => {
        switch (documentType) {
            case DocumentType.LABORATORY:
                return T.translate('pageTitle.laboratoryDocuments', {laboratoryName: entityName});
            case DocumentType.PRODUCT:
                return T.translate('pageTitle.productDocuments', {productName: entityName});
            case DocumentType.LOT:
                return T.translate('pageTitle.lotDocuments', {lotName: entityName});
            default:
                return T.translate('pageTitle.documents')
        }
    }

    const resetErrors = () => {
        setAddDocumentError('')
    }

    const onDrop = useCallback(acceptedFiles => {
        const addDocument = async (documentData) => {
            setAddDocumentLoading(true)
            try {
                await documentService.createDocument(documentData)
                setAddDocumentLoading(false)
                resetErrors();
                documentAdded();
                showToast(T.translate('alert.successTitle'), T.translate('document.successAddDocument', {documentName: documentData.name}), ToastClass.SUCCESS)
            } catch (error) {
                setAddDocumentLoading(false)
                setAddDocumentError(error)
            }
        }

        resetErrors()
        if (acceptedFiles !== undefined && acceptedFiles.length > 0) {
            const formData = new FormData();
            formData.append('file', acceptedFiles[0]);
            formData.append('documentType', documentType);
            formData.append('name', acceptedFiles[0].name);
            formData.append('companyId', companyId);
            formData.append('entityId', id);
            formData.append('description', '');
            addDocument(formData);
        } else {
            showToast(T.translate('alert.errorTitle'), T.translate('error.document.badFormat', {formats: acceptedTypes.toString()}), ToastClass.ERROR);
        }

    }, [acceptedTypes, documentType, showToast, companyId, id, documentAdded])

    const headerButtons = (
        <React.Fragment>
            <Button variant="secondary" className="px-4 mb-2 mb-sm-0 me-3" onClick={goBackToPreviousPage}>
                {T.translate(getGoBackButtonTitle())}
            </Button>
            <Button variant="success" className="px-4 mb-2 mb-sm-0" onClick={() => showCreateDocumentModal(companyId, id, documentType)}>
                {T.translate('button.addDocument')}
            </Button>
        </React.Fragment>
    );

    return (
        <PageWithMenuTemplate pageTitle={getPageTitle()}>
            <Header>
                <HeaderTextLabel className="me-4" label={entityName} isMain={true}>
                    <h1 className="ps-1">{T.translate('menu.documents')}</h1>
                </HeaderTextLabel>
            </Header>
            <Header button={headerButtons}>
                <div className="search-container">
                    <SearchInput onChange={handleSearchChange}/>
                </div>
            </Header>
            <CustomDropZone acceptedTypes={acceptedTypes} onDrop={onDrop} isLoading={addDocumentLoading} error={addDocumentError}
                            onCloseError={goBackToPreviousPage}>
                <TableDocumentList className="mx-3" entityId={id} searchText={searchText}/>
            </CustomDropZone>
            <OverlayLoadingOrError isLoading={state.isLoading} error={state.error} onCloseError={goBackToPreviousPage}/>
        </PageWithMenuTemplate>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        showCreateDocumentModal: (companyId, entityId, documentType) => dispatch(documentActions.documentCreate(companyId, entityId, documentType)),
        documentAdded: () => dispatch(documentActions.documentAdd()),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
    }
}

export default connect(null, mapDispatchToProps)(DocumentsPage);
