import React from 'react';
import OverlayComponent from "./overlay-component";
import {Spinner} from "react-bootstrap-v5";
import AlertWarning from "../alert-closeable/alert-warning";

const OverlayLoadingOrError = ({isLoading, error, onCloseError, className}) => {
    return (
        <OverlayComponent
            className={`position-absolute top-0 left-0 w-100 h-100 align-items-center justify-content-center ${!!error || isLoading ? "d-flex" : "d-none"} ${className}`}>
            {
                isLoading
                    ? <Spinner animation="border"/>
                    : !!error &&
                    <AlertWarning className="m-3 position-relative" variant="danger"
                                  title={error} onClose={onCloseError}/>
            }
        </OverlayComponent>
    );
}

export default OverlayLoadingOrError;