import React, {useCallback} from 'react';
import T from 'i18n-react'
import {Button} from "react-bootstrap-v5";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useHistory, useParams} from "react-router-dom";
import RemoteTable from "../../../components/table/remote-table";
import {laboratoryService} from "../../../services/laboratory-service";
import {Routes} from "../../../router/router-constants";
import StatusComponent from "../../../components/status-component/status-component";
import {StatusPrimarySecondary, StatusPrimarySecondaryClassname} from "../../../utils/enums/status-primary-secondary";
import {connect} from "react-redux";
import {laboratoryActions} from "../../../redux/laboratory/actions-laboratory";
import Moment from "react-moment";

const TableLaboratoryAnalysesInProgress = ({className, currentUser, setAnalysisInProgressNumber}) => {

    const {id} = useParams();
    const history = useHistory();

    let columns = [
        {
            dataField: T.translate('table.fields.lot.categoryName').toString(),
            text: '', // On ne veut pas que le mot catégorie s'affiche, c'est une colonne fantôme
            sort: false
        },
        {
            dataField: T.translate('table.fields.analyse.inProgress.lotNumber').toString(),
            text: T.translate('table.columns.lot.lotNumber').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.inProgress.productName').toString(),
            text: T.translate('table.columns.lot.productName').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.inProgress.analysisName').toString(),
            text: T.translate('table.columns.analyseName').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.inProgress.analysisMethod').toString(),
            text: T.translate('table.columns.method').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.status').toString(),
            text: T.translate('table.columns.status').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.price').toString(),
            text: `${T.translate('table.columns.price')} ${!!currentUser.currencySymbol ? '(' + currentUser.currencySymbol + ')' : ''}`,
            sort: true
        },
        {
            dataField: T.translate('table.fields.delayInDays').toString(),
            text: T.translate('table.columns.delayInDays').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.quantityInGram').toString(),
            text: T.translate('table.columns.quantityInGram').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.lot.orderNumber').toString(),
            text: T.translate('table.columns.lot.orderNumber').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.dueDate').toString(),
            text: T.translate('table.columns.dueDate').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.actions').toString(),
            text: T.translate('table.columns.actions').toString(),
            sort: false
        },
    ];

    const onFetchData = useCallback(async (page, size, sortedOrder, sortedField) => {

        const handleEditAnalysis = (lotId) => {
            history.push(`${Routes.LOTS_PAGE}/${lotId}`);
        }

        const completeElements = allElements => {
            return allElements.map((element, index) => {
                return {
                    ...element,
                    id: index,
                    lot: {
                        ...element.lot,
                        lotNumber: <div className="cursor-pointer" onClick={() => handleEditAnalysis(element.lot.lotId)}>{element.lot.lotNumber}</div>
                    },
                    status: <StatusComponent type={StatusPrimarySecondaryClassname[element.mainLaboratory ? StatusPrimarySecondary.PRIMARY : StatusPrimarySecondary.SECONDARY]}/>,
                    dueDate: <Moment>{element.dueDate}</Moment>,
                    actions: (
                        <div className="actions">
                            <Button size="sm" className="edit" onClick={() => handleEditAnalysis(element.lot.lotId)}>
                                <FontAwesomeIcon icon="edit"/>
                            </Button>
                        </div>
                    )
                }
            })
        }
        if (sortedField === 'status') {
            sortedField = "mainLaboratory"
        }
        const data = await laboratoryService.getAnalysisInProgressForLaboratory(id, sortedOrder, sortedField);
        setAnalysisInProgressNumber(data.length)
        return {elements: completeElements(data)};
    }, [id, history, setAnalysisInProgressNumber])

    return (
        <RemoteTable id="table-laboratory-analyses-in-progress"
                     defaultSortedField={T.translate('table.fields.analyse.inProgress.lotNumber')}
                     className={className} columns={columns} onFetchData={onFetchData}
                     groupByName={T.translate('table.fields.lot.categoryName').toString()}
                     groupById={T.translate('table.fields.lot.categoryId')}
                     useSearchField={false} useSizeSelect={false} usePagination={false}/>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAnalysisInProgressNumber: (nbOfAnalysisInProgress) => dispatch(laboratoryActions.setAnalysisInProgressNumber(nbOfAnalysisInProgress))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableLaboratoryAnalysesInProgress);