import React from 'react';
import './overlay-component.scss';

const OverlayComponent = ({className, children}) => {
    return (
        <div className={`overlay-component ${className}`}>
            {children}
        </div>
    );
}

export default OverlayComponent;