import {StatusLot} from "../../utils/enums/status-lot";

export default class LotEntity {
    constructor(lotData) {
        this.id = lotData?.lotId ?? '';
        this.companyId = lotData?.company?.companyId ?? '';
        this.productId = lotData?.product?.productId ?? '';
        this.productName = lotData?.product?.name ?? '';
        this.productSupplier = lotData?.product?.supplier ?? '';
        this.name = lotData?.product?.name ?? '';
        this.articleCode = lotData?.product?.articleCode ?? '';
        this.category = lotData?.product?.category ?? '';
        this.lotNumber = lotData?.lotNumber ?? '';
        this.supplierLotNumber = lotData?.supplierLotNumber ?? '';
        this.supplier = lotData?.inheritedSupplier ?? '';
        this.endControlDate = lotData?.endControlDate ?? '';
        this.creationDate = lotData?.creationDate ?? '';
        this.manufacturingDate = lotData?.manufacturingDate ?? '';
        this.dluo = lotData?.dluo ?? '';
        this.purchaseOrderGenerated = lotData?.purchaseOrderGenerated ?? false;
        this.status = lotData?.status ?? StatusLot.IN_PROGRESS;
        this.lifeDuration = (this.status === StatusLot.NEW) ? lotData?.product?.lifeSpan ?? '' : lotData?.lifeSpan ?? '';
        this.closingReason = lotData?.closingInfo?.reason ?? "";
        this.notCompliantReason = lotData?.closingInfo?.notCompliantReason ?? "";
        this.closingReasonComment = lotData?.closingInfo?.comment ?? "";
        this.closingDate = lotData?.closingInfo?.date ?? "";
        this.productComment = lotData?.product?.comment ?? "";
        this.comment = lotData?.comment ?? "";
        this.orderFormGenerated = lotData?.orderFormGenerated ?? "";
        this.destinationLots = lotData?.destinationLots ?? [];
        this.sourceLots = lotData?.sourceLots ?? [];
        this.sourceArchived = lotData?.sourceArchived ?? false;
    }
}
