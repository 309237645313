import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Card} from "react-bootstrap-v5";
import OverlayLoadingOrError from "../../../components/overlay-component/overlay-loading-or-error";
import {lotService} from "../../../services/lot-service";
import T from "i18n-react";
import PieChart from "../../../components/charts/pie-chart";

const CardIndicatorsLotsClosedCompliance = ({className}) => {
    const lastYear = new Date().getFullYear() - 1;
    const ref = useRef(null);

    const [state, setState] = useState({isLoading: false, error: ''});

    const [currentYearPercentage, setCurrentYearPercentage] = useState(0);
    const [lastYearPercentage, setLastYearPercentage] = useState(0);

    const [pieChartSize, setPieChartSize] = useState(0);

    useEffect(() => {
        const getIndicators = async () => {
            try {
                setState({isLoading: true, error: ''});
                const indicators = await lotService.indicatorsLotsClosedCompliance();
                setCurrentYearPercentage(indicators.currentYear.percentageOfCompliantLots);
                setLastYearPercentage(indicators.lastYear.percentageOfCompliantLots);
                setState({isLoading: false, error: ''});
            } catch (error) {
                setState({isLoading: false, error: error.message})
            }
        }

        getIndicators();
    }, []);

    const handleResize = useCallback(() => {
        const newSize = ref.current.offsetWidth / 3;
        const sizeOrMaxSize = Math.min(newSize, 150);
        setPieChartSize(sizeOrMaxSize);
    }, []);

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize]);

    useEffect(() => {
        handleResize();
    }, [handleResize]);


    return (
        <Card className={`card-indicators h-100 ${className}`} ref={ref}>
            <Card.Header className="d-flex justify-content-between align-items-end pb-0">
                <Card.Title>{T.translate(`indicators.lotsClosedCompliance`)}</Card.Title>
            </Card.Header>
            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                <PieChart data={currentYearPercentage} size={pieChartSize}/>
                <div className="lastYear text-center py-3">{lastYear}: <span>{T.translate('indicators.percent', {nb: lastYearPercentage})}</span></div>
                <OverlayLoadingOrError isLoading={state.isLoading} error={state.error} onCloseError={() => setState({isLoading: false, error: ''})}/>
            </Card.Body>
        </Card>
    );
}

export default CardIndicatorsLotsClosedCompliance;