import React, {useState} from "react";
import PageWithMenuTemplate from "../../components/template-pages/page-with-menu-template";
import Header from "../../components/header/header";
import CreateOrEditLaboratoryModal from "./components/create-laboratory-modal";
import {Button} from "react-bootstrap-v5";
import T from "i18n-react";
import {UserRole} from "../../utils/enums/user-role";
import {connect} from "react-redux";
import {laboratoryActions} from "../../redux/laboratory/actions-laboratory";
import TableLaboratoryList from "./components/table-laboratory-list";
import HeaderTextLabel from "../../components/header/header-text-label";
import SearchInput from "../../components/input-label/search-input";

const LaboratoriesPage = ({showCreateLaboratoryModal, currentUserRole}) => {
    const [searchText, setSearchText] = useState('');

    const handleSearchChange = e => {
        const text = e.target.value;
        if (text.length === 0 || text.length >= 3) {
            setSearchText(text);
        }
    }

    const createLaboratoryButton = currentUserRole !== UserRole.VISITOR && (
        <Button variant="success" className="px-4" onClick={() => showCreateLaboratoryModal()}>
            {T.translate('laboratory.button.createLaboratory')}
        </Button>
    );

    return (
        <React.Fragment>
            <PageWithMenuTemplate pageTitle={T.translate('pageTitle.laboratories')}>
                <Header>
                    <HeaderTextLabel className="me-4" isMain={true}>
                        <h1 className="ps-1">{T.translate('menu.laboratories')}</h1>
                    </HeaderTextLabel>
                </Header>
                <Header button={createLaboratoryButton}>
                    <div className="search-container">
                        <SearchInput onChange={handleSearchChange}/>
                    </div>
                </Header>
                <TableLaboratoryList className="mx-3" searchText={searchText}/>
            </PageWithMenuTemplate>
            <CreateOrEditLaboratoryModal/>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        currentUserRole: state.authReducer.currentUser.role
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showCreateLaboratoryModal: () => dispatch(laboratoryActions.laboratoryCreate())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LaboratoriesPage);
