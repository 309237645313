export default class LaboratoryEntity {
    constructor(laboratoryData) {
        this.id = laboratoryData?.laboratoryId ?? '';
        this.name = laboratoryData?.name ?? '';
        this.companyId = laboratoryData?.company?.companyId ?? '';
        this.companyName = laboratoryData?.company?.name ?? '';
        this.address = laboratoryData?.address ?? '';
        this.phone = laboratoryData?.phoneNumber ?? '';
        this.contact = laboratoryData?.contact ?? '';
        this.role = laboratoryData?.functionName ?? '';
        this.email = laboratoryData?.email ?? '';
        this.activated = laboratoryData?.activated ?? '';
        this.comment = laboratoryData?.comment ?? '';
        this.customerNumber = laboratoryData?.customerNumber ?? '';
    }
}