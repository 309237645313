import React, {useEffect, useState} from 'react';
import T from "i18n-react";
import {Alert, Button, Form, Spinner} from "react-bootstrap-v5";
import CustomModal from "../../../components/custom-modal/custom-modal";
import Validator from "../../../utils/validators/validator";
import {alertActions} from "../../../redux/alert/actions-alert";
import {connect} from "react-redux";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {userActions} from "../../../redux/user/actions-user";
import {userService} from "../../../services/user-service";
import {UserRole, UserRoleMessageKey} from "../../../utils/enums/user-role";
import InputLabel from "../../../components/input-label/input-label";
import SelectLabel from "../../../components/input-label/select-label";
import CompanyAutocompleteLabel from "../../../components/input-label/company-autocomplete-label";
import {i18nService} from "../../../services/i18n-service";

const CreateOrEditUserModal = ({currentUser, userReducer, showToast, hideCreateOrEditModal}) => {

    const emailField = "email";
    const phoneField = `${userReducer.isCreationMode ? "userAccountDetails." : ""}phoneNumber`;

    const [isLoading, setLoading] = useState(false);

    const [companyOld, setCompanyOld] = useState({id: '', name: ''});
    const [company, setCompany] = useState({
        id: userReducer.user?.company?.companyId ?? currentUser.companyId ?? '',
        name: userReducer.user?.company?.name ?? '',
    });
    const [role, setRole] = useState(userReducer.user?.role ?? UserRole.CONTRIBUTOR);
    const [email, setEmail] = useState(userReducer.user?.email ?? '');
    const [firstname, setFirstname] = useState(userReducer.user?.firstname ?? '');
    const [lastname, setLastname] = useState(userReducer.user?.lastname ?? '');
    const [phone, setPhone] = useState(userReducer.user?.phoneNumber ?? '');
    const [language, setLanguage] = useState(userReducer.user?.language?.localeCode ?? '');

    const [languages, setLanguages] = useState([]);

    useEffect(() => {
        const getLanguages = async () => {
            const availableLanguages = await i18nService.getAllLanguages();
            setLanguages(availableLanguages);
            if (!availableLanguages.isEmpty) {
                setLanguage(prevState => !prevState ? availableLanguages[0].localeCode : prevState);
            }
        }

        if (userReducer.showCreateOrEditModal) {
            getLanguages();
        }
    }, [userReducer.showCreateOrEditModal]);

    const initialStateInputErrors = {
        company: '',
        email: '',
        firstname: '',
        lastname: '',
        phone: '',
        language: '',
    };

    const [inputErrors, setInputErrors] = useState(initialStateInputErrors);
    const [apiError, setApiError] = useState('');

    const resetErrors = () => {
        setInputErrors(initialStateInputErrors);
        setApiError('');
    }

    const resetForm = () => {
        setCompany({
            id: userReducer.user?.company?.companyId ?? currentUser.companyId ?? '',
            name: userReducer.user?.company?.name ?? '',
        });
        setCompanyOld({
            id: userReducer.user?.company?.companyId ?? currentUser.companyId ?? '',
            name: userReducer.user?.company?.name ?? '',
        });
        setRole(userReducer.user?.role ?? UserRole.CONTRIBUTOR);
        setEmail(userReducer.user?.email ?? '');
        setFirstname(userReducer.user?.firstname ?? '');
        setLastname(userReducer.user?.lastname ?? '');
        setPhone(userReducer.user?.phoneNumber ?? '');
        setLanguage(userReducer.user?.language?.localeCode ?? '');
    }

    const validate = () => {
        const companyError = role === UserRole.SUPER_ADMINISTRATOR ? '' : Validator.validateRequired(company.id);
        const emailError = Validator.validateRequiredEmail(email);
        const firstnameError = Validator.validateRequired(firstname);
        const lastnameError = Validator.validateRequired(lastname);
        const languageError = Validator.validateRequired(language);
        const isValid = !emailError && !firstnameError && !lastnameError && !companyError && !languageError;
        setInputErrors({
            ...inputErrors,
            email: T.translate(emailError).toString(),
            firstname: T.translate(firstnameError).toString(),
            lastname: T.translate(lastnameError).toString(),
            company: T.translate(companyError).toString(),
            language: T.translate(languageError).toString(),
        })
        return isValid;
    }

    const hideModal = () => {
        resetForm();
        resetErrors();
        hideCreateOrEditModal();
    }

    const setFieldError = (field, errorMessage) => {
        let errors = initialStateInputErrors;
        errors[field] = errorMessage;
        setInputErrors(errors)
    }

    const editorCreateUser = async (email, user) => {
        setLoading(true);
        try {
            if (userReducer.isCreationMode) {
                await userService.createUser(user);
            } else {
                await userService.editUser(email, user);
            }
            hideModal();
            setLoading(false);
            showToast(T.translate('alert.successTitle'), T.translate(userReducer.isCreationMode ? 'user.successCreateUser' : 'user.successEditUser'), ToastClass.SUCCESS);
        } catch (error) {
            const field = error.response?.data?.entityIdField;
            if (field === emailField) {
                setFieldError("email", error.message);
            } else if (field === phoneField) {
                setFieldError("phone", error.message);
            } else {
                setApiError(error.message);
            }
            setLoading(false);
        }
    }

    const submitCreateUserForm = e => {
        e.preventDefault();

        resetErrors();
        if (validate()) {
            let user = {
                role: role,
                email: email,
                firstname: firstname,
                lastname: lastname,
                phoneNumber: phone,
                languageCode: language,
            };
            if (userReducer.isCreationMode) {
                user = {
                    role: role,
                    companyId: company.id,
                    userAccountDetails: {
                        email: email,
                        firstname: firstname,
                        lastname: lastname,
                        phoneNumber: phone,
                        languageCode: language,
                    }
                };
            }
            editorCreateUser(userReducer.user?.email, user);
        }
    }

    useEffect(resetForm, [userReducer, currentUser.companyId])

    const handleCompanyChange = companySelected => {
        setCompany(companySelected);
    }

    const handleRoleChange = role => {
        if (role === UserRole.SUPER_ADMINISTRATOR) {
            setCompanyOld(company);
            setCompany({id: '', name: ''});
        } else {
            setCompany(companyOld);
        }
        setRole(role);
    }

    const userRoleOptions = () => {
        let options = [];
        for (const userRole in UserRole) {
            if (currentUser.role !== UserRole.SUPER_ADMINISTRATOR && userRole === UserRole.SUPER_ADMINISTRATOR) continue;
            if (userReducer.user?.role === UserRole.SUPER_ADMINISTRATOR && userRole !== UserRole.SUPER_ADMINISTRATOR) continue;
            if (!!userReducer.user?.role && userReducer.user.role !== UserRole.SUPER_ADMINISTRATOR && userRole === UserRole.SUPER_ADMINISTRATOR) continue;
            if (userReducer.user?.role === UserRole.COMPANY_ADMINISTRATOR && userRole !== UserRole.COMPANY_ADMINISTRATOR) continue;
            if ((userReducer.user?.role !== UserRole.COMPANY_ADMINISTRATOR && currentUser.role !== UserRole.SUPER_ADMINISTRATOR || userReducer.isCreationMode) && userRole === UserRole.COMPANY_ADMINISTRATOR) continue;

            options.push(<option key={userRole} value={userRole} selected={userRole === role}>{T.translate(UserRoleMessageKey[userRole])}</option>);
        }
        return options;
    }

    const languageOptions = () => {
        let options = [];
        languages.forEach((lang) => {
            options.push(
                <option key={lang.localeCode} value={lang.localeCode} selected={lang.localeCode === language}>
                    {lang.displayName}
                </option>
            );
        });
        return options;
    }

    const footer = (
        <React.Fragment>
            <Button variant="outline-secondary" onClick={hideModal} className="px-3 py-1">
                {T.translate('form.button.cancel')}
            </Button>
            <Button variant="primary" onClick={submitCreateUserForm} className="px-3 py-1" disabled={isLoading}>
                {isLoading ? <Spinner as="span" size="sm" animation="border"/> : ""}
                {T.translate('form.button.confirm')}
            </Button>
        </React.Fragment>
    );

    return (
        <CustomModal show={userReducer.showCreateOrEditModal} onHide={hideModal}
                     title={T.translate(`user.title.${userReducer.isCreationMode ? 'createUser' : 'editUser'}`)} footer={footer}>
            <Form>
                <Alert variant={"danger"} hidden={!apiError}>
                    <Alert.Heading>
                        {T.translate("alert.errorTitle")}
                    </Alert.Heading>
                    {apiError}
                </Alert>
                <fieldset>
                    <SelectLabel id="role" className="mb-4" label={T.translate('form.label.role')} required={true}
                                 options={userRoleOptions()} defaultValue={role}
                                 onChange={e => handleRoleChange(e.target.value)}/>
                    {
                        currentUser.role === UserRole.SUPER_ADMINISTRATOR &&
                        <CompanyAutocompleteLabel id="companyId" className="mb-4" error={inputErrors.company}
                                                  defaultValue={company} onChange={handleCompanyChange}
                                                  required={role !== UserRole.SUPER_ADMINISTRATOR}
                                                  disabled={role === UserRole.SUPER_ADMINISTRATOR || !userReducer.isCreationMode}/>
                    }
                    <InputLabel id="lastname" className="mb-4" type="text"
                                label={T.translate('form.label.lastname')} required={true}
                                value={lastname} onChange={e => setLastname(e.target.value)} error={inputErrors.lastname}/>
                    <InputLabel id="firstname" className="mb-4" type="text"
                                label={T.translate('form.label.firstname')} required={true}
                                value={firstname} onChange={e => setFirstname(e.target.value)} error={inputErrors.firstname}/>
                    <InputLabel id="email" className="mb-4" type="text"
                                label={T.translate('form.label.email')} required={true}
                                value={email} onChange={e => setEmail(e.target.value)} error={inputErrors.email}/>
                    <InputLabel id="phone" className="mb-4" type="text"
                                label={T.translate('form.label.phone')}
                                value={phone} onChange={e => setPhone(e.target.value)} error={inputErrors.phone}/>

                    <SelectLabel id="language" className="mb-4" label={T.translate('form.label.interfaceLanguage')} required={true}
                                 options={languageOptions()} defaultValue={language} error={inputErrors.language}
                                 onChange={e => setLanguage(e.target.value)}/>
                </fieldset>
            </Form>
        </CustomModal>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        userReducer: state.userReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideCreateOrEditModal: () => dispatch(userActions.hideCreateOrEditModal()),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrEditUserModal);