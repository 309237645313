import React, {useCallback} from 'react';
import RemoteTable from "../../../components/table/remote-table";
import {connect} from "react-redux";
import {laboratoryService} from "../../../services/laboratory-service";
import T from 'i18n-react'
import {UserRole} from "../../../utils/enums/user-role";
import StatusComponent from "../../../components/status-component/status-component";
import {Button, Card} from "react-bootstrap-v5";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {alertActions} from "../../../redux/alert/actions-alert";
import {confirmDialogActions} from "../../../redux/confirm-dialog/actions-confirm-dialog";
import {laboratoryActions} from "../../../redux/laboratory/actions-laboratory";
import {useHistory} from "react-router-dom";
import {Routes} from "../../../router/router-constants";
import {StatusActiveInactive, StatusActiveInactiveClassname} from "../../../utils/enums/status-active-inactive";
import I18NController from "../../../utils/i18n/i18n-controller";

const TableLaboratoryList = ({className, searchText, showToast, currentUser, laboratoryDeleted, showConfirmDialog, progress}) => {
    const currentYear = new Date().getFullYear();

    const history = useHistory()

    let columns = [
        {
            dataField: T.translate('table.fields.status').toString(),
            text: T.translate('table.columns.status').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.name').toString(),
            text: T.translate('table.columns.laboratory.name').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.address').toString(),
            text: T.translate('table.columns.address').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.email').toString(),
            text: T.translate('table.columns.email').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.phone').toString(),
            text: T.translate('table.columns.phone').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.contact').toString(),
            text: T.translate('table.columns.contact').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.laboratory.role').toString(),
            text: T.translate('table.columns.role').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.nbAnalyses').toString(),
            text: `${T.translate('table.columns.nbAnalyses')} (${currentYear})`,
        },
        {
            dataField: T.translate('table.fields.price').toString(),
            text: `${T.translate('table.columns.cost')} (${currentYear})`,
        },
        {
            dataField: T.translate('table.fields.actions').toString(),
            text: T.translate('table.columns.actions').toString()
        }
    ];

    if (currentUser.role === UserRole.SUPER_ADMINISTRATOR) {
        columns = [
            {
                dataField: T.translate('table.fields.laboratory.companyName').toString(),
                text: T.translate('table.columns.company').toString(),
                sort: true
            },
            ...columns
        ];
    }

    const onFetchData = useCallback(async (page, size, sortedOrder, sortedField, searchText) => {
        const deleteLaboratory = async laboratory => {
            if (laboratory.mainLaboratoryOnAnalysis) {
                showToast(T.translate('alert.errorTitle'), T.translate('error.laboratory.mainLaboratoryOnAnalysis'), ToastClass.ERROR);
                return;
            }
            try {
                await laboratoryService.deleteLaboratory(laboratory.laboratoryId);
                laboratoryDeleted();
                showToast(T.translate('alert.successTitle'), T.translate('laboratory.successDeleteLaboratory', {laboratoryName: laboratory.name}), ToastClass.SUCCESS);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleDeleteLaboratory = async (e, laboratory) => {
            e.stopPropagation();
            showConfirmDialog(
                T.translate('dialog.title.confirmDelete'),
                T.translate('dialog.confirmDeleteLaboratory', {laboratoryName: laboratory.name}),
                () => {
                },
                () => deleteLaboratory(laboratory))
        }

        const handleEditLaboratory = (e, laboratory) => {
            e.stopPropagation();
            history.push(`${Routes.LABORATORIES_PAGE}/${laboratory.laboratoryId}`)
        }

        const handleEditLaboratoryRightClick = (e, laboratory) => {
            e.preventDefault();
            window.open(`${Routes.LABORATORIES_PAGE}/${laboratory.laboratoryId}`, '_blank', 'noopener,noreferrer');
        }

        const completeElements = allElements => {
            return allElements.map(element => {
                return {
                    ...element,
                    id: element.laboratoryId,
                    activated: <StatusComponent
                        type={StatusActiveInactiveClassname[element.activated ? StatusActiveInactive.ACTIVE : StatusActiveInactive.INACTIVE]}/>,
                    nbAnalyses: <div className="pastille">{element.numberOfClosedLotsOrderedAnalyses}</div>,
                    price: <div
                        className="fw-bold text-end pe-2">{I18NController.getInstance().formatPrice(element.controlPrice, currentUser.currency, currentUser.language)}</div>,
                    actions: (
                        <div className="actions">
                            <Button size="sm" className="edit" onClick={e => handleEditLaboratory(e, element)}
                                    onContextMenu={e => handleEditLaboratoryRightClick(e, element)}>
                                <FontAwesomeIcon icon="edit"/>
                            </Button>
                            <Button size="sm"
                                    className={`delete ms-1 ${currentUser.role === UserRole.COMPANY_ADMINISTRATOR || currentUser.role === UserRole.SUPER_ADMINISTRATOR || currentUser.role === UserRole.SUPERVISOR ? "" : "d-none"}`}
                                    onClick={e => handleDeleteLaboratory(e, element)}>
                                <FontAwesomeIcon icon="times"/>
                            </Button>
                        </div>
                    )
                }
            })
        }

        const data = await laboratoryService.getAll(page, size, sortedOrder, sortedField, searchText);
        const elements = completeElements(data.content);
        return {
            ...data,
            elements: elements
        };
    }, [showToast, showConfirmDialog, laboratoryDeleted, currentUser, history])

    return (
        <Card className={className}>
            <RemoteTable id="table-laboratory-list"
                         defaultSortedField={T.translate('table.fields.name')}
                         columns={columns} onFetchData={onFetchData} reload={progress} searchText={searchText}/>
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        progress: state.laboratoryReducer.progress
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        laboratoryDeleted: () => dispatch(laboratoryActions.laboratoryDeleted()),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableLaboratoryList);
