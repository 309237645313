import React, {useState} from 'react';
import {Toast} from "react-bootstrap-v5";

const ToastAlert = ({title, message, className}) => {

    const [show, setShow] = useState(true);

    return (
        <Toast className={className} onClose={() => setShow(false)} show={show} delay={className === "error" ? 7000 : 3000} autohide>
            <Toast.Header>
                <strong className="me-auto">{title}</strong>
                <button type="button" className="btn-close align-self-start" onClick={() => setShow(false)}/>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
        </Toast>
    );
}

export default ToastAlert;