import {applyMiddleware, combineReducers, createStore} from 'redux';
import thunk from 'redux-thunk';
import authReducer from './auth/reducer-auth';
import alertReducer from './alert/reducer-alert';
import confirmDialogReducer from './confirm-dialog/reducer-confirm-dialog'
import companyReducer from './company/reducer-company'
import userReducer from './user/reducer-user'
import laboratoryReducer from './laboratory/reducer-laboratory'
import analyseReducer from './analyse/reducer-analyse'
import documentReducer from './document/reducer-document'
import productReducer from './product/reducer-product'
import lotReducer from "./lot/reducer-lot";
import analyseHistoryReducer from './analyse-history/reducer-analyse-history';

const rootReducer = combineReducers({
    authReducer,
    alertReducer,
    confirmDialogReducer,
    companyReducer,
    userReducer,
    laboratoryReducer,
    analyseReducer,
    documentReducer,
    productReducer,
    lotReducer,
    analyseHistoryReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
