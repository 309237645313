import React from 'react';
import {Form, FormControl, FormSelect} from "react-bootstrap-v5";
import './editable-text.scss'

const EditableSelect = ({className = '', id, defaultValue, onChange, onSelect, options, error}) => {

    return (
        <div className={`editable-select ${className}`}>
            <Form.Group controlId={id}>
                <FormSelect onChange={onChange} defaultValue={defaultValue} isInvalid={!!error} onSelect={onSelect}>
                    {options}
                </FormSelect>
            </Form.Group>
            <FormControl.Feedback type="invalid" className={!!error ? "d-block" : "d-none"}>{error}</FormControl.Feedback>
        </div>
    );
}

export default EditableSelect;