import React from 'react';
import './buttons.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Spinner} from "react-bootstrap-v5";

const ButtonIconAdd = ({onClick, isLoading}) => {
    return (
        <Button className="button-icon-add" variant="success" onClick={onClick}>
            {
                isLoading ? <Spinner animation="border" size="sm"/> : <FontAwesomeIcon icon="plus"/>
            }
        </Button>
    );
}

export default ButtonIconAdd;