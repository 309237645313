import React, { useCallback, useRef, useState } from 'react';
import RemoteTable from "../../../components/table/remote-table";
import { connect } from "react-redux";
import T from 'i18n-react'
import { UserRole } from "../../../utils/enums/user-role";
import { useHistory, useParams } from "react-router-dom";
import { alertActions } from "../../../redux/alert/actions-alert";
import { confirmDialogActions } from "../../../redux/confirm-dialog/actions-confirm-dialog";
import { lotService } from "../../../services/lot-service";
import { Button, FormCheck, Image, OverlayTrigger, Tooltip } from "react-bootstrap-v5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditableText from "../../../components/editable-text/editable-text";
import { ComplianceMessageKey, CompliantType, CompliantTypeClassName } from "../../../utils/enums/compliant-type";
import { ToastClass } from "../../../components/toast-alert/toast-alert-class";
import { lotActions } from "../../../redux/lot/actions-lot";
import Moment from "react-moment";
import EditableSelect from "../../../components/editable-text/editable-select";
import { StatusLot } from "../../../utils/enums/status-lot";
import ButtonIconValidate from "../../../components/buttons/button-icon-validate";
import ButtonIconCancel from "../../../components/buttons/button-icon-cancel";
import ButtonIcon from "../../../components/buttons/button-icon";
import { analyseHistoryActions } from "../../../redux/analyse-history/actions-analyse-history";
import iconTransferFrom from "../../../assets/images/icons/icon-transfer-from.svg";
import { Routes } from "../../../router/router-constants";
import CommentButton from "./comment-button";
import { documentService } from "../../../services/document-service";
import './table-controls-lot-list.scss';
import AttachFileAnalysisModal from './attach-file-analysis-modal';

const TableControlsLotList = ({
    className,
    showToast,
    currentUser,
    showConfirmDialog,
    progress,
    lotEntity,
    updateSelectedAnalysisNumber,
    incrementSelectedAnalysisNumber,
    decrementSelectedAnalysisNumber,
    lotControlDeleted,
    lotControlChangeDisplayOrder,
    showAnalyseHistoryModal,
    lotCancelTransfer,
    showAttachFileLotAnalysisModal
}) => {
    const { id } = useParams();
    const history = useHistory();
    const downloadDocRef = useRef();

    const [isEditingControl, setEditingControl] = useState(false);

    const [responseData, setResponseData] = useState();

    const isClosedStatusLot = lotEntity.status === StatusLot.CLOSED;
    const canEdit = currentUser.role !== UserRole.VISITOR && lotEntity.status !== StatusLot.CLOSED && (currentUser.role !== UserRole.CONTRIBUTOR || lotEntity.status !== StatusLot.TO_CLOSE);
    const canEditCheckbox = currentUser.role !== UserRole.VISITOR && currentUser.role !== UserRole.CONTRIBUTOR && !isClosedStatusLot

    let columns = [
        {
            dataField: T.translate('table.fields.lot.categoryName').toString(),
            text: '', // On ne veut pas que le mot catégorie s'affiche, c'est une colonne fantôme
        },
        {
            dataField: T.translate('table.fields.lot.analysisName').toString(),
            text: T.translate('table.columns.analyseName').toString(),
            targetForChevron: true,
        },
        {
            dataField: T.translate('table.fields.lot.analysisMethod').toString(),
            text: T.translate('table.columns.method').toString(),
        },
        {
            dataField: T.translate('table.fields.specifications').toString(),
            text: T.translate('table.columns.specifications').toString(),
        },
        {
            dataField: T.translate('table.fields.result').toString(),
            text: T.translate('table.columns.result').toString(),
        },
        {
            dataField: T.translate('table.fields.lot.unit').toString(),
            text: T.translate('table.columns.unit').toString(),
            padding: "ps-3",
        },
        {
            dataField: T.translate('table.fields.compliance').toString(),
            text: T.translate('table.columns.compliance').toString(),
        },
        {
            dataField: T.translate('table.fields.lot.checkedOrder').toString(),
            text: T.translate('table.columns.lot.checkedOrder').toString(),
        },
        {
            dataField: T.translate('table.fields.source').toString(),
            text: T.translate('table.columns.analyse.source').toString(),
        },
        {
            dataField: T.translate('table.fields.lot.orderNumber').toString(),
            text: T.translate('table.columns.lot.orderNumber').toString(),
        },
        {
            dataField: T.translate('table.fields.periodicity').toString(),
            text: T.translate('table.columns.periodicity').toString(),
        },
        {
            dataField: T.translate('table.fields.delayInDays').toString(),
            text: T.translate('table.columns.delayInDays').toString(),
        },
        {
            dataField: T.translate('table.fields.price').toString(),
            text: `${T.translate('table.columns.price')} ${!!currentUser.currencySymbol ? '(' + currentUser.currencySymbol + ')' : ''}`,
        },
        {
            dataField: T.translate('table.fields.quantityInGram').toString(),
            text: T.translate('table.columns.quantityInGram').toString(),
        },
        {
            dataField: T.translate('table.fields.dueDate').toString(),
            text: T.translate('table.columns.dueDate').toString(),
        },
        {
            dataField: T.translate('table.fields.actions').toString(),
            text: T.translate('table.columns.actions').toString(),
        }
    ];

    const downloadDocument = async (fileUri) => {
        downloadDocRef.current.href = fileUri;
        // ici on ping pour éventuellement forcer axios à appeler le refresh token et éviter sinon que le download envoie un 401 car c'est un appel backend direct
        //await ping();
        downloadDocRef.current.click();
    }

    const groupCheckbox = (leafValues) => {
        let checked = true;
        for (let leafValue of leafValues) {
            if (leafValue && !leafValue.props.checked) {
                checked = false
            }
        }
        return canEditCheckbox && (<FormCheck onChange={e => handleGroupCheck(leafValues, e)} checked={checked} />)
    }

    if (canEditCheckbox) {
        columns = [
            {
                dataField: T.translate('table.fields.checkbox').toString(),
                aggregate: groupCheckbox,
                text: <FontAwesomeIcon icon="eye" />,
                sort: true
            },
            ...columns
        ];
    }

    const handleCategoryChange = async (controlId, destinationCategoryId, destinationCategoryName) => {
        const data = lotEntity.orderFormGenerated ? { "analysisCategoryName": destinationCategoryName } : { "analysisCategoryId": destinationCategoryId };
        try {
            const response = await lotService.editControl(id, controlId, data);
            showToast(T.translate('alert.successTitle'), T.translate('lot.successEditControlCategory'), ToastClass.SUCCESS);
            setResponseData(response.data)
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleCategoryReorder = async (controlId, destinationDisplayOrder) => {
        try {
            await lotService.editControl(id, controlId, { "displayOrder": destinationDisplayOrder });
            lotControlChangeDisplayOrder();
            showToast(T.translate('alert.successTitle'), T.translate('product.successEditControlDisplayOrder'), ToastClass.SUCCESS);
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleGroupCheck = useCallback(async (checkBoxCells, e) => {
        const checkedCertificate = !!e.target.checked
        try {
            let atLeastOneControlEdited = false;
            for (let checkBoxCell of checkBoxCells) {
                if (checkBoxCell) {
                    const lotAnalysisId = checkBoxCell.props.className.split(' ').find(d => d.includes("lot-analysis-id")).split(':')[1]
                    const response = await lotService.editControl(id, lotAnalysisId, { checkedCertificate })
                    setResponseData(response.data)
                    atLeastOneControlEdited = true;
                }
            }
            if (atLeastOneControlEdited) {
                showToast(T.translate('alert.successTitle'), T.translate(checkedCertificate ? 'lot.successCertificateCategoryCheck' : 'lot.successCertificateCategoryUnCheck'), ToastClass.SUCCESS);
            }
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }, [id, showToast])

    // FIXME retirer elementIndex et utiliser une autre variable pour nommer les id du html, par exemple lotAnalysisId semblerait convenir
    const completeElement = useCallback((element, elementIndex) => {
        const editControl = async (controlId, controlPatch) => {
            setEditingControl(true)
            const response = await lotService.editControl(id, controlId, controlPatch);
            setEditingControl(false);
            setResponseData(response.data)
            return response
        }

        const deleteControl = async control => {
            try {
                await lotService.deleteControl(id, control.lotAnalysisId);
                lotControlDeleted();
                showToast(T.translate('alert.successTitle'), T.translate('lot.successDeleteControl'), ToastClass.SUCCESS);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleDeleteControl = async (e, control) => {
            e.stopPropagation();
            showConfirmDialog(
                T.translate('dialog.title.confirmDelete'),
                T.translate('dialog.confirmDeleteLotAnalysis'),
                () => {
                },
                () => deleteControl(control))
        }

        const handleSpecsChange = async (controlId, specs, event) => {
            try {
                await editControl(controlId, { specs });
                showToast(T.translate('alert.successTitle'), T.translate('lot.successEditSpecs'), ToastClass.SUCCESS);

                handleFocusEvent(event);
            } catch (error) {
                setEditingControl(false);
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleResultChange = async (controlId, result, event) => {
            try {
                await editControl(controlId, { result });
                handleFocusEvent(event);
                showToast(T.translate('alert.successTitle'), T.translate('lot.successEditResult'), ToastClass.SUCCESS);
            } catch (error) {
                setEditingControl(false);
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleUnitChange = async (controlId, unit, event) => {
            try {
                await editControl(controlId, { unit });

                showToast(T.translate('alert.successTitle'), T.translate('lot.successEditUnit'), ToastClass.SUCCESS);
                handleFocusEvent(event);
            } catch (error) {
                setEditingControl(false);
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleComplianceChange = async (controlId, newCompliance, currentCompliance, e) => {
            e.stopPropagation();
            if (currentCompliance !== CompliantType.NONE) {
                if (newCompliance === currentCompliance) {
                    newCompliance = CompliantType.NONE;
                }
                showConfirmDialog(
                    T.translate('dialog.title.confirmToggleStatus'),
                    (newCompliance === CompliantType.NONE) ? T.translate('dialog.confirmToggleComplianceStatusReset')
                        : T.translate('dialog.confirmToggleComplianceStatus', { newCompliance: (T.translate(ComplianceMessageKey[newCompliance]).toString()) }),
                    () => {
                    },
                    () => complianceChange(controlId, newCompliance, e))
            } else {
                await complianceChange(controlId, newCompliance, e);
            }
        }

        const complianceChange = async (controlId, compliance, e) => {
            e.stopPropagation();
            try {
                await editControl(controlId, { compliance });

                showToast(T.translate('alert.successTitle'), T.translate('lot.successEditCompliant'), ToastClass.SUCCESS);
            } catch (error) {
                setEditingControl(false);
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleLaboratoryChange = async (element, val) => {
            try {
                var controlId = element.lotAnalysisId;
                await editControl(controlId, { laboratoryId: val.id });
                showToast(T.translate('alert.successTitle'), T.translate('lot.successEditLaboratory'), ToastClass.SUCCESS);
                            
                //Gestion du compteur pour afficher le bouton "Edit Order"
                if(val.id.startsWith("SPECIAL") && !element.laboratoryId.startsWith("SPECIAL") && element.checkedOrder){
                    decrementSelectedAnalysisNumber();
                }
                else if(!val.id.startsWith("SPECIAL") && element.periodicity.periodicityCode === "EVERY_TIME" && !element.checkedOrder){
                    incrementSelectedAnalysisNumber(true);
                }
                else if(!val.id.startsWith("SPECIAL") && element.laboratoryId.startsWith("SPECIAL")){
                    incrementSelectedAnalysisNumber(true);
                }
            } catch (error) {
                setEditingControl(false);
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleCheckBoxClick = async (controlId, e) => {
            const checkedCertificate = !!e.target.checked;
            try {
                await editControl(controlId, { checkedCertificate });
                showToast(T.translate('alert.successTitle'), T.translate(checkedCertificate ? 'lot.successCertificateCheck' : 'lot.successCertificateUncheck'), ToastClass.SUCCESS);
            } catch (error) {
                setEditingControl(false);
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleCheckBoxOrderClick = async (controlId, e) => {
            const checkedOrder = !!e.target.checked;
            try {
                await editControl(controlId, { checkedOrder });

                showToast(T.translate('alert.successTitle'), T.translate(checkedOrder ? 'lot.successOrderCheck' : 'lot.successOrderUncheck'), ToastClass.SUCCESS);
                incrementSelectedAnalysisNumber(checkedOrder)
            } catch (error) {
                setEditingControl(false);
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleFocusEvent = (event) => {
            if (event !== undefined) {
                if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
                    handleFocusPreviousNext(event);
                } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                    handleFocusUpDown(event)
                } else if (event.key === 'Enter') {
                    handleFocusEnter(event);
                } else if (event.type === 'blur') {
                    handleFocusBlur(event);
                }
            }
        }

        const handleFocusBlur = (event) => {
            if (event.relatedTarget !== null) {
                if (event.relatedTarget.matches('input[type="text"]')) {
                    let inputToFocus = document.getElementById(event.relatedTarget.id);
                    inputToFocus.focus();
                }
            }
        }

        const handleFocusEnter = (event) => {
            let input = document.getElementById(event.target.id);
            let elementName = event.target.id.split('-')[0];

            let table = input.parentElement.parentElement.parentElement.parentElement.parentElement;
            let td = input.parentElement.parentElement.parentElement;

            if (elementName === "result") {
                let inputResult = table.querySelectorAll(('*[id^="' + elementName + '"]'));
                for (let i = 0; i < inputResult.length; i++) {
                    if (inputResult[i].parentElement.parentElement.parentElement === td) {
                        if (inputResult.length - 1 !== i) {
                            i++;
                        }
                        inputResult[i].focus();
                        break;
                    }
                }
            } else {
                input.focus()
            }
        }

        const handleFocusPreviousNext = (event) => {
            let input = document.getElementById(event.target.id);
            let td = input.parentNode.parentNode.parentNode;
            let row = td.parentNode;
            let inputs = row.querySelectorAll('input[type="text"]');

            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i] === input) {
                    if (event.key === "ArrowRight") {
                        if (inputs.length - 1 !== i) {
                            i++;
                        }
                        inputs[i++].focus();
                    } else {
                        i === 0 ? input.focus() : inputs[i - 1].focus();
                    }
                }
            }
        }

        const handleFocusUpDown = (event) => {
            let input = document.getElementById(event.target.id);
            let elementName = event.target.id.split('-')[0];
            let table = input.parentElement.parentElement.parentElement.parentElement.parentElement;
            let inputs = table.querySelectorAll(('*[id^="' + elementName + '"]'));

            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i] === input) {
                    if (event.key === "ArrowDown") {
                        if (inputs.length - 1 !== i) {
                            i++;
                        }
                        inputs[i++].focus();
                    } else {
                        i === 0 ? input.focus() : inputs[i - 1].focus();
                    }
                    break;
                }
            }
        }

        const onFocus = (event, newVal, currentVal, element) => {
            if (newVal !== currentVal) {
                const idElement = event.target.id;
                if (idElement.includes("unit")) {
                    handleUnitChangeWithEvent(event, newVal, element)
                } else if (idElement.includes("specs")) {
                    handleSpecsChangeWithEvent(event, newVal, element)
                } else if (idElement.includes("result")) {
                    handleResultChangeWithEvent(event, newVal, element)
                }
            } else {
                handleFocusEvent(event);
            }
        }

        const handleSpecsChangeWithEvent = (event, val, element) => {
            handleSpecsChange(element, val, event);
        }

        const handleUnitChangeWithEvent = (event, val, element) => {
            handleUnitChange(element, val, event);
        }

        const handleResultChangeWithEvent = (event, val, element) => {
            handleResultChange(element, val, event);
        }

        const handleShowAnalyseHistory = (event, lotAnalysisId) => {
            showAnalyseHistoryModal(lotAnalysisId, '', id);
        }

        const handleSubmitComment = async (comment) => {
            try {
                await lotService.editControlComment(id, element.lotAnalysisId, comment);
                setResponseData({ ...element, comment });
                showToast(T.translate('alert.successTitle'), T.translate('lot.successEditAnalysisComment'), ToastClass.SUCCESS);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }


        const hasOrder = !!element.analysisOrdered;
        const hasTransferredResult = !!element.sourceLot;
        const specialLaboratory = element.laboratoryId.startsWith("SPECIAL_LABORATORY");
        const contributorCanEdit = currentUser.role === UserRole.CONTRIBUTOR && !element.cameFromProduct && (!hasOrder || specialLaboratory);
        const allLaboratories = element.availableLaboratories.map(labo => {
            return { id: labo.laboratoryId, name: labo.name };
        });
        allLaboratories.push({ id: "SPECIAL_LABORATORY_SUPPLIER_DATA", name: T.translate('lot.label.supplierData') });
        allLaboratories.push({ id: "SPECIAL_LABORATORY_PERIODIC_PLAN", name: T.translate('lot.label.periodicPlan') })
        allLaboratories.push({ id: "SPECIAL_LABORATORY_RAW_MATERIAL", name: T.translate('lot.label.rawMaterial') })
        allLaboratories.push({ id: "SPECIAL_LABORATORY_COMPUTED_VALUE", name: T.translate('lot.label.computedValue') })
        allLaboratories.push({ id: "SPECIAL_LABORATORY_CLIENT_DATA", name: T.translate('lot.label.clientData') })
        const currentLaboratory = allLaboratories.find(labo => labo.id === element.laboratoryId);

        const getSource = () =>{
            if(hasTransferredResult){
                return <div className="transfer-container d-flex align-items-center">
                            <div className="transfer-from d-flex cursor-pointer px-1 py-2" onClick={() => lotEntity.sourceArchived ? null : history.push(`${Routes.LOTS_PAGE}/${element.sourceLot?.lotId}`)}>
                                <Image src={iconTransferFrom} className="me-2" />
                                <div>{T.translate('lot.label.lotWithLotNumber', { lotNumber: element.sourceLot?.lotNumber })}</div>
                            </div>

                            {lotEntity.status !== StatusLot.TO_CLOSE && lotEntity.status !== StatusLot.CLOSED &&(
                                <div className="actions">
                                    <Button onClick={e => handleCancelTransferResult(e, element)}>
                                        <FontAwesomeIcon icon="times" />
                                    </Button>
                                </div>
                            )}
                        </div>
            }else {
                return canEdit && !element.archived && (!hasOrder || specialLaboratory) ? (
                    <EditableSelect id={`laboratory-${elementIndex}`} className="source"
                        onChange={e => handleLaboratoryChange(element, allLaboratories[e.target.selectedIndex])}
                        options={allLaboratories.map(labo => (
                            <option key={`labo-option-${labo.id}`} id={`labo-option-${labo.id}`} value={labo}
                                selected={labo.id === currentLaboratory.id}>
                                {labo.name}
                            </option>
                        ))} />
                ) 
                :  element.laboratoryName ? (
                    <div className="px-2 mx-1 fw-bold">{element.laboratoryName}</div>
                )
                :   <div className="px-2 mx-1 fw-bold">
                        {allLaboratories.filter(labo => labo.id === currentLaboratory.id)[0]?.name || ""}
                    </div>
                ;
            }
        }

        const cancelTransferResult = async element => {
            try {
                const destinationLotId = element.lot.lotId;
                const destinationLotAnalysisId = element.lotAnalysisId;
                await lotService.cancelTransferAnalyseWithoutLink(destinationLotId, destinationLotAnalysisId);
                lotCancelTransfer();
                showToast(T.translate('alert.successTitle'), T.translate('lot.successCancelTransfer'), ToastClass.SUCCESS);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }
        
        const handleCancelTransferResult = async (e, element) => {
            e.stopPropagation();
            showConfirmDialog(
                T.translate('dialog.title.confirmDelete'),
                T.translate('dialog.confirmCancelTransferResultFromRawMaterial', { lotNumber: element.sourceLot.lotNumber, articleCode: element.sourceLot.product.articleCode, supplier: element.sourceLot.product.supplier }),
                () => { },
                () => cancelTransferResult(element))
        }

        const subRows = () => {
            var subRows = [];
            if (!element.archived) {
                if (element.parameters.length > 0) {
                    subRows = [...subRows, ...element.parameters.map((parameter, parameterIndex) => completeElementForSubRow(element, parameter, parameterIndex))]
                }
                if (element.rawMaterialLotDtos.length > 0 && element.laboratoryId === "SPECIAL_LABORATORY_RAW_MATERIAL") {
                    subRows = [...subRows, completeElementForRawMaterial(element)]
                }
                return subRows;
            }
            return [];
        }

        function getHistoryButton(handleShowHistory, elementId) {
            return <Button
                className={`ms-1 ${element.lastResultNotCompliant && !element.archived && !hasOrder && !hasTransferredResult ? 'text-danger' : ''}`}
                size="sm" onClick={e => handleShowHistory(e, elementId)}>
                <FontAwesomeIcon icon="chart-bar" />
            </Button>;
        }

        const completeElementForSubRow = (parent, parameter, parameterIndex) => {

            const handleCheckBoxParameterClick = async (e, controlId, name) => {
                const checkedCertificate = !!e.target.checked;
                const parameter = { name, checkedCertificate }
                try {
                    await editControl(controlId, { parameter });

                    showToast(T.translate('alert.successTitle'), T.translate(checkedCertificate ? 'lot.successCertificateCheckParameter' : 'lot.successCertificateUncheckParameter'), ToastClass.SUCCESS);
                } catch (error) {
                    setEditingControl(false);
                    showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
                }
            }

            const handleResultParameterChange = async (controlId, name, result, event) => {
                const parameter = { name, result }
                try {
                    await editControl(controlId, { parameter });

                    handleFocusEvent(event);
                    showToast(T.translate('alert.successTitle'), T.translate('lot.successEditResultParameter'), ToastClass.SUCCESS);
                } catch (error) {
                    setEditingControl(false);
                    showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
                }
            }

            const handleSpecsParameterChange = async (controlId, name, specs, event) => {
                const parameter = { name, specs }
                try {
                    await editControl(controlId, { parameter });

                    handleFocusEvent(event);
                    showToast(T.translate('alert.successTitle'), T.translate('lot.successEditSpecsParameter'), ToastClass.SUCCESS);
                } catch (error) {
                    setEditingControl(false);
                    showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
                }
            }

            const complianceParameterChange = async (controlId, name, compliance, e) => {
                e.stopPropagation();
                const parameter = { name, compliance }
                try {
                    await editControl(controlId, { parameter });

                    showToast(T.translate('alert.successTitle'), T.translate('lot.successEditCompliantParameter'), ToastClass.SUCCESS);
                } catch (error) {
                    setEditingControl(false);
                    showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
                }
            }

            const handleComplianceParameterChange = async (controlId, parameterName, newCompliance, currentCompliance, e) => {
                e.stopPropagation();
                if (currentCompliance !== CompliantType.NONE) {
                    if (newCompliance === currentCompliance) {
                        newCompliance = CompliantType.NONE;
                    }
                    showConfirmDialog(
                        T.translate('dialog.title.confirmToggleStatus'),
                        (newCompliance === CompliantType.NONE) ? T.translate('dialog.confirmToggleComplianceStatusReset')
                            : T.translate('dialog.confirmToggleComplianceStatus', { newCompliance: (T.translate(ComplianceMessageKey[newCompliance]).toString()) }),
                        () => {
                        },
                        () => complianceParameterChange(controlId, parameterName, newCompliance, e))
                } else {
                    await complianceParameterChange(controlId, parameterName, newCompliance, e);
                }
            }

            const onParameterFocus = (event, parameterName, newVal, currentVal, controlId) => {
                if (newVal !== currentVal) {
                    const idElement = event.target.id;

                    if (idElement.includes("specs")) {
                        handleSpecsParameterChange(controlId, parameterName, newVal, element, event)
                    } else if (idElement.includes("result")) {
                        handleResultParameterChange(controlId, parameterName, newVal, event)
                    }
                } else {
                    handleFocusEvent(event);
                }
            }

            const handleShowParameterHistory = (event, parameterName) => {
                showAnalyseHistoryModal(parent.lotAnalysisId, parameterName, id);
            }

            return {
                ...parent,
                id: '',
                displayOrder: '',
                color: parent.archived ? "archived" : !!parameter.compliance && parameter.compliance !== CompliantType.NONE ? CompliantTypeClassName[parameter.compliance] : (hasOrder || hasTransferredResult || specialLaboratory) ? "ordered" : "",
                disableDrag: true,
                analysisName: !parent.archived ? parameter.name : (
                    <div className="position-relative ps-4">
                        <div className="archived-icon position-absolute start-0">
                            <OverlayTrigger trigger={['hover', 'focus']} key={`tooltip-trigger-archived-${parent.lotAnalysisId}`} placement="top"
                                overlay={<Tooltip id={`tooltip-archived-${parent.lotAnalysisId}`}>{T.translate('lot.archivedAnalyse')}</Tooltip>}>
                                <FontAwesomeIcon icon="archive" />
                            </OverlayTrigger>
                        </div>
                        <div className="text-nowrap">{parameter.name}</div>
                    </div>
                ),
                checkbox: canEditCheckbox && !parent.archived && (
                    <FormCheck className={`text-center lot-analysis-id:${parent.lotAnalysisId}`}
                        onChange={e => handleCheckBoxParameterClick(e, parent.lotAnalysisId, parameter.name)}
                        checked={parameter.checkedCertificate} />
                ),
                specs: (canEdit && (currentUser.role !== UserRole.CONTRIBUTOR || contributorCanEdit)) && !parent.archived ?
                    <EditableText id={`specs-${elementIndex}-${parameterIndex}`} 
                        defaultValue={parameter.specs}
                        onSubmit={val => handleSpecsParameterChange(parent.lotAnalysisId, parameter.name, val)}
                        onFocus={(e, val) => onParameterFocus(e, parameter.name, val, parameter.specs, parent.lotAnalysisId)}
                        shouldSubmitOnBlur />
                    : parameter.specs,
                result: canEdit && !parent.archived && (hasOrder || specialLaboratory) && !hasTransferredResult ?
                    <EditableText id={`result-${elementIndex}-${parameterIndex}`} className={"editing-visible"} defaultValue={parameter.result ?? ""}
                        editionMode={!parameter.result}
                        onSubmit={val => handleResultParameterChange(parent.lotAnalysisId, parameter.name, val)}
                        onFocus={(e, val) => onParameterFocus(e, parameter.name, val, parameter.result, parent.lotAnalysisId)}
                        shouldSubmitOnBlur />
                    : parameter.result,
                compliance: canEdit && !parent.archived && (hasOrder || specialLaboratory || hasTransferredResult) ?
                    <div className="btn-actions text-center">
                        <ButtonIconValidate
                            selected={!!parameter.compliance && parameter.compliance !== CompliantType.NONE && parameter.compliance === CompliantType.COMPLIANT ? "selected" : "unselected"}
                            onClick={e => handleComplianceParameterChange(parent.lotAnalysisId, parameter.name, CompliantType.COMPLIANT, parameter.compliance, e)} />
                        <ButtonIconCancel
                            selected={!!parameter.compliance && parameter.compliance !== CompliantType.NONE && parameter.compliance === CompliantType.NOT_COMPLIANT ? "selected" : "unselected"}
                            onClick={e => handleComplianceParameterChange(parent.lotAnalysisId, parameter.name, CompliantType.NOT_COMPLIANT, parameter.compliance, e)} />
                    </div> :
                    !!parameter.compliance && parameter.compliance !== CompliantType.NONE ?
                        <div className="px-2 mx-1 fw-bold text-center">
                            <ButtonIcon className={parameter.compliance === CompliantType.COMPLIANT ? "icon-validate" : "icon-cancel"}
                                icon={!!parameter.compliance && parameter.compliance === CompliantType.COMPLIANT ? "check" : "times"} />
                        </div> : "",
                source: '',
                orderChecked: '',
                checkedOrder: '',
                orderNumber: '',
                periodicity: '',
                delayInDays: '',
                price: '',
                quantityInGram: '',
                dueDate: '',
                actions: (<div className="actions">{getHistoryButton(handleShowParameterHistory, parameter.name)}</div>),
                parameters: undefined,
                isParameter: true,
                parameterName: parameter.name,
                isRawMaterial: false,
                rawMaterialCollection: undefined,
                showRawMaterials: false
            }

        }

        const completeElementForRawMaterial = (parent) => {
            return {
                ...parent,
                id: '',
                displayOrder: '',
                color: '',
                disableDrag: true,
                analysisName: parent.analysisName,
                checkbox: '',
                specs: '',
                result: '',
                compliance: '',
                source: '',
                orderChecked: '',
                checkedOrder: '',
                orderNumber: '',
                periodicity: '',
                delayInDays: '',
                price: '',
                quantityInGram: '',
                dueDate: '',
                actions: '',
                parameterName: '',
                parameters: undefined,
                isParameter: false,
                isRawMaterial: parent.laboratoryId !== "SPECIAL_LABORATORY_RAW_MATERIAL" ? false : true,
                rawMaterialCollection: parent.rawMaterialLotDtos,
                showRawMaterials: parent.laboratoryId !== "SPECIAL_LABORATORY_RAW_MATERIAL" ? false : true
            }

        }

        function getAnalysisExpiredAlert() {
            const analysisExpiredIsdefined = !(element.analysisExpired === undefined || element.analysisExpired === null);

            return <>
                {analysisExpiredIsdefined && element.analysisExpired === true && (
                    <OverlayTrigger trigger={['hover', 'focus']} key={`tooltip-trigger-expired-${element.lotAnalysisId}`} placement="top"
                        overlay={<Tooltip id={`tooltip-expired-${element.lotAnalysisId}`}>{T.translate('lot.expiredAnalyse')}</Tooltip>}>
                        <FontAwesomeIcon icon="clock" size="2x" className="text-accent" />
                    </OverlayTrigger>
                )}
                {analysisExpiredIsdefined && element.analysisExpired === false && (
                    <OverlayTrigger trigger={['hover', 'focus']} key={`tooltip-trigger-expired-${element.lotAnalysisId}`} placement="top"
                        overlay={<Tooltip id={`tooltip-expired-${element.lotAnalysisId}`}>{T.translate('lot.notExpiredAnalyse')}</Tooltip>}>
                        <FontAwesomeIcon icon="clock" size="2x" className="text-info" />
                    </OverlayTrigger>
                )}
            </>;
        }

        function getDocumentButtons() { 
            const { sourceLot, sourceDocument, document } = element;
        
            const documentLotAnalysisUri = sourceDocument?.fileDownloadUri && sourceLot
                ? sourceDocument.fileDownloadUri
                : document?.fileDownloadUri;
        
            const showPaperclip = !(sourceDocument?.fileDownloadUri && sourceLot);

            if (documentLotAnalysisUri) {
                return (
                    <>
                        {showPaperclip && canEdit && currentUser.role !== UserRole.CONTRIBUTOR && (    
                            <Button size="sm" className="attach" onClick={() => showAttachFileLotAnalysisModal(element)}>
                                <FontAwesomeIcon icon="paperclip" />
                            </Button>)
                        }
                        {    
                            <Button size="sm" className="downloadPdf" onClick={() => downloadDocument(documentService.getDownloadUrl(documentLotAnalysisUri))}>
                                <FontAwesomeIcon icon="file-pdf" />
                            </Button>
                        }
                    </>
                );
            }
        
            return  <Button size="sm" className="attach" onClick={() => showAttachFileLotAnalysisModal(element)}>
                        <FontAwesomeIcon icon="paperclip" />
                    </Button>;
        };

        function getCommentButton() { 
            const isLotOpen = lotEntity.status !== StatusLot.CLOSED;
            const hasComment = !!element.comment;
        
            if (isLotOpen || hasComment) {
                return (
                    <CommentButton 
                        comment={element.comment} 
                        id={element.lotAnalysisId} 
                        canEdit={isLotOpen}
                        onSubmit={handleSubmitComment}
                        visible={isLotOpen || hasComment}
                    />
                );
            }
            return null;
        };

        function getDeleteButton() { 
            if(canEdit && currentUser.role !== UserRole.CONTRIBUTOR && !hasOrder){
                return  <Button size="sm" className="delete" onClick={e => handleDeleteControl(e, element)}>
                            <FontAwesomeIcon icon="times" />
                        </Button>
            }
            return null;
        };

        return {
            ...element,
            id: element.lotAnalysisId,
            displayOrder: element.displayOrder,
            color: element.archived ? "archived" : !!element.compliance && element.compliance !== CompliantType.NONE ? CompliantTypeClassName[element.compliance] : (hasOrder || hasTransferredResult || specialLaboratory) ? "ordered" : "",
            disableDrag: !canEdit || !!element.archived || !!hasOrder || hasTransferredResult,
            analysisName: !element.archived ? element.analysisName : (
                <div className="position-relative ps-4">
                    <div className="archived-icon position-absolute start-0">
                        <OverlayTrigger trigger={['hover', 'focus']} key={`tooltip-trigger-archived-${element.lotAnalysisId}`} placement="top"
                            overlay={<Tooltip id={`tooltip-archived-${element.lotAnalysisId}`}>{T.translate('lot.archivedAnalyse')}</Tooltip>}>
                            <FontAwesomeIcon icon="archive" />
                        </OverlayTrigger>
                    </div>
                    <div className="text-nowrap">{element.analysisName}</div>
                </div>
            ),
            checkbox: canEditCheckbox && !element.archived && (
                <FormCheck className={`lot-analysis-id:${element.lotAnalysisId}`} onChange={e => handleCheckBoxClick(element.lotAnalysisId, e)}
                    checked={element.checkedCertificate} />
            ),
            specs: (canEdit && (currentUser.role !== UserRole.CONTRIBUTOR || contributorCanEdit)) && !element.archived ?
                <EditableText id={`specs-${elementIndex}`} defaultValue={element.specs} onSubmit={val => handleSpecsChange(element.lotAnalysisId, val)}
                    onFocus={(e, val) => onFocus(e, val, element.specs, element.lotAnalysisId)}
                    shouldSubmitOnBlur
                />
                : element.specs,
            result: canEdit && !element.archived && (hasOrder || specialLaboratory) && !hasTransferredResult ?
                <EditableText id={`result-${elementIndex}`} className={"editing-visible"} defaultValue={element.result} editionMode={!element.result}
                    onSubmit={val => handleResultChange(element.lotAnalysisId, val)}
                    onFocus={(e, val) => onFocus(e, val, element.result, element.lotAnalysisId)}
                    shouldSubmitOnBlur />
                : element.result,
            unit: (canEdit && (currentUser.role !== UserRole.CONTRIBUTOR || contributorCanEdit)) && !element.archived ?
                <EditableText id={`unit-${elementIndex}`} defaultValue={element.unit}
                    onSubmit={val => handleUnitChange(element.lotAnalysisId, val)}
                    onFocus={(e, val) => onFocus(e, val, element.unit, element.lotAnalysisId)}
                    shouldSubmitOnBlur
                />
                : <div className="px-2 mx-1">{element.unit}</div>,
            compliance: canEdit && !element.archived && (hasOrder || specialLaboratory || hasTransferredResult) ?
                <div className="btn-actions text-center">
                    <ButtonIconValidate
                        selected={!!element.compliance && element.compliance !== CompliantType.NONE && element.compliance === CompliantType.COMPLIANT ? "selected" : "unselected"}
                        onClick={e => handleComplianceChange(element.lotAnalysisId, CompliantType.COMPLIANT, element.compliance, e)} />
                    <ButtonIconCancel
                        selected={!!element.compliance && element.compliance !== CompliantType.NONE && element.compliance === CompliantType.NOT_COMPLIANT ? "selected" : "unselected"}
                        onClick={e => handleComplianceChange(element.lotAnalysisId, CompliantType.NOT_COMPLIANT, element.compliance, e)} />
                </div> :
                !!element.compliance && element.compliance !== CompliantType.NONE ?
                    <div className="px-2 mx-1 fw-bold text-center">
                        <ButtonIcon className={element.compliance === CompliantType.COMPLIANT ? "icon-validate" : "icon-cancel"}
                            icon={!!element.compliance && element.compliance === CompliantType.COMPLIANT ? "check" : "times"} />
                    </div> : "",
            source: getSource(),
            orderChecked: element.checkedOrder,
            checkedOrder: canEdit && !element.archived && !hasOrder && !specialLaboratory && !hasTransferredResult && (
                <div className="d-flex align-items-center justify-content-start">
                    <FormCheck className="me-2" onChange={e => handleCheckBoxOrderClick(element.lotAnalysisId, e)} checked={element.checkedOrder} />
                    {getAnalysisExpiredAlert()}
                    {element.lastResultNotCompliant && (
                        <OverlayTrigger trigger={['hover', 'focus']} key={`tooltip-trigger-previouslyNotCompliant-${element.lotAnalysisId}`} placement="top"
                            overlay={<Tooltip
                                id={`tooltip-previouslyNotCompliant-${element.lotAnalysisId}`}>{T.translate('lot.previouslyNotCompliantAnalyse')}</Tooltip>}>
                            <FontAwesomeIcon icon="exclamation-circle" size="2x" className="text-danger" />
                        </OverlayTrigger>
                    )}
                </div>
            ),
            orderNumber: !!element.orderNumber ? element.orderNumber : "-",
            periodicity: element.periodicity.displayName,
            dueDate: !!element.dueDate ? <Moment>{element.dueDate}</Moment> : "-",
            actions: (
                <div className="actions">
                    {getHistoryButton(handleShowAnalyseHistory, element.lotAnalysisId)}
                    {getCommentButton()}
                    {getDocumentButtons()}
                    {getDeleteButton()}
                </div>
            ),
            subRows: subRows()
        }
    }, [canEdit, canEditCheckbox, currentUser.role, id, incrementSelectedAnalysisNumber, decrementSelectedAnalysisNumber, showConfirmDialog, showToast, lotControlDeleted, showAnalyseHistoryModal, history, lotEntity.status, lotEntity.sourceArchived, lotCancelTransfer]
    )

    const onFetchData = useCallback(async (page, size, sortedOrder, sortedField) => {

        if (sortedField === T.translate('table.fields.checkbox').toString()) {
            sortedField = "checkedCertificate";
        }

        const data = await lotService.getControlsByLotId(id, sortedOrder, sortedField);
        // il faut d'abord trier les données puis appliquer completeElement
        // sinon suite à une modification avec un call api, alors on applique completeElement mais au nouvel ordre après le tri
        // et cela crée des décalages avec le tableau d'origine qui lui se base sur les elementIndex avant le tri
        const elements = data
            .sort((a, b) => {
                if (a.categoryName < b.categoryName) return -1;
                if (a.categoryName > b.categoryName) return 1;
                return a.displayOrder - b.displayOrder;
            })
            .map((element, elementIndex) => completeElement(element, elementIndex));

        const nbCheckedAnalysis = elements.filter(element => !element.analysisOrdered && element.orderChecked && !element.laboratoryId.startsWith("SPECIAL")).length;
        updateSelectedAnalysisNumber(nbCheckedAnalysis);
        return {
            ...data,
            elements: elements
        };
    }, [id, updateSelectedAnalysisNumber, completeElement])

    return (
        <div id="table-controls-lot-list-container">
            <RemoteTable id="table-controls-lot-list"
                defaultSortedField={T.translate('table.fields.lot.analysisName')}
                className={className} columns={columns} reload={progress} onFetchData={onFetchData} responseData={responseData}
                useSearchField={false} useSizeSelect={false} usePagination={false} useDragRow={true}
                groupByName={T.translate('table.fields.lot.categoryName').toString()}
                groupById={T.translate('table.fields.lot.categoryId')}
                handleGroupChange={handleCategoryChange} 
                handleGroupReorder={handleCategoryReorder}
                indexName={T.translate('table.fields.lot.lotAnalysisId')} 
                completeElement={completeElement}
                leftGroupNameColumnId={T.translate('table.fields.lot.checkbox')}
                isSavingCell={isEditingControl} />
            <AttachFileAnalysisModal />
            <a ref={downloadDocRef} href="#0" hidden={true} download>download</a>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        progress: state.lotReducer.progress,
        selectedAnalysisNumber: state.lotReducer.selectedAnalysisNumber
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm)),
        updateSelectedAnalysisNumber: (nbAnalysis) => dispatch(lotActions.updateSelectedAnalysisNumber(nbAnalysis)),
        incrementSelectedAnalysisNumber: (checked) => dispatch(lotActions.incrementSelectedAnalysisNumber(checked)),
        decrementSelectedAnalysisNumber: () => dispatch(lotActions.decrementSelectedAnalysisNumber()),
        lotControlDeleted: () => dispatch(lotActions.lotControlDeleted()),
        lotControlChangeDisplayOrder: () => dispatch(lotActions.lotControlChangeDisplayOrder()),
        showAnalyseHistoryModal: (analyseId, parameterName, lotId) => dispatch(analyseHistoryActions.showAnalyseHistoryModal(analyseId, parameterName, '', lotId)),
        lotCancelTransfer: () => dispatch(lotActions.lotCancelTransfer()),
        showAttachFileLotAnalysisModal: (lot) => dispatch(lotActions.showAttachFileLotAnalysisModal(lot)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableControlsLotList);
