import {
    ACTUALISE_COUNT_PRODUCT_CONTROL_PLANS_EXPIRED,
    ADD_ANALYSIS_TO_PRODUCT,
    PRODUCT_CHECKBOX_CHECKED,
    PRODUCT_CONTROL_AND_SPECS_GENERATED,
    PRODUCT_CONTROL_DELETED,
    PRODUCT_CONTROL_EDIT,
    PRODUCT_DELETED,
    PRODUCT_DUPLICATED,
    PRODUCT_HIDE_CREATE_MODAL,
    PRODUCT_INCREMENT_SELECTED_ANALYSIS,
    PRODUCT_SAVE_SEARCH_TEXT,
    PRODUCT_SET_INITIAL_WARNING,
    PRODUCT_SHOW_CREATE_MODAL,
    PRODUCT_UPDATE_SELECTED_ANALYSIS
} from "./type";

const initialStateProduct = {
    showCreateModal: false,
    progress: 0,
    showWarning: false,
    selectedAnalysisNumber: 0,
    searchText: '',
    countNextMonitoringExpired: 0
}

const productReducer = (state = initialStateProduct, action) => {
    switch (action.type) {
        case PRODUCT_SHOW_CREATE_MODAL:
            return {
                ...state,
                showCreateModal: true
            }
        case PRODUCT_CONTROL_EDIT:
        case ADD_ANALYSIS_TO_PRODUCT:
            return {
                ...state,
                progress: state.progress + 1
            }
        case PRODUCT_CONTROL_DELETED:
            return {
                ...state,
                progress: state.progress + 1,
                showWarning: action.payload
            }
        case PRODUCT_DELETED:
        case PRODUCT_DUPLICATED:
        case PRODUCT_HIDE_CREATE_MODAL:
            return {
                ...state,
                showCreateModal: false,
                progress: state.progress + 1
            }
        case PRODUCT_CONTROL_AND_SPECS_GENERATED:
            return {
                ...state,
                showWarning: false
            }
        case PRODUCT_CHECKBOX_CHECKED:
        case PRODUCT_SET_INITIAL_WARNING:
            return {
                ...state,
                showWarning: action.payload
            }
        case PRODUCT_UPDATE_SELECTED_ANALYSIS:
            return {
                ...state,
                selectedAnalysisNumber: action.payload
            }
        case PRODUCT_INCREMENT_SELECTED_ANALYSIS:
            return {
                ...state,
                selectedAnalysisNumber: action.payload ? state.selectedAnalysisNumber + 1 : state.selectedAnalysisNumber - 1
            }
        case PRODUCT_SAVE_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.payload
            }
        case ACTUALISE_COUNT_PRODUCT_CONTROL_PLANS_EXPIRED:
            return {
                ...state,
                countNextMonitoringExpired: action.payload
            }
        default:
            return state;
    }
}

export default productReducer;