import React, {useEffect} from "react";
import {NavLink} from "react-router-dom";
import {Routes} from "../../router/router-constants";
import './menu.scss';
import logo from '../../assets/images/logo-ciklab.svg';
import {Nav, Navbar} from "react-bootstrap-v5";
import T from 'i18n-react';
import NavUser from "./components/user-infos/nav-user";
import {UserRole} from "../../utils/enums/user-role";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import {documentService} from "../../services/document-service";
import {ToastClass} from "../toast-alert/toast-alert-class";
import {alertActions} from "../../redux/alert/actions-alert";
import {documentActions} from "../../redux/document/actions-document";
import {productService} from "../../services/product-service";
import {productActions} from "../../redux/product/actions-product";

const Menu = ({
                  currentUser,
                  numberExpiredDocuments,
                  numberNextMonitoringExpired,
                  productProgress,
                  actualiseCountExpiredDocuments,
                  actualizeCountNextMonitoringExpired,
                  showToast
              }) => {

    useEffect(() => {
        const getNumberExpiredDocuments = async () => {
            try {
                const response = await documentService.getIndicatorExpiredDocuments();
                actualiseCountExpiredDocuments(response.numberOfExpiredDocuments);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        getNumberExpiredDocuments();
    }, [currentUser]);

    useEffect(() => {
        const getNumberNextMonitoringExpired = async () => {
            try {
                const response = await productService.getIndicatorNextMonitoringExpired();
                actualizeCountNextMonitoringExpired(response.countNextMonitoringExpired);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        getNumberNextMonitoringExpired();
    }, [currentUser, productProgress]);

    return (
        <Navbar variant="dark" bg="dark" expand="lg">
            <Navbar.Brand href={currentUser?.role === UserRole.READER ? Routes.ALL_DOCUMENTS_PAGE : Routes.DASHBOARD_PAGE}
                          className="ms-2 me-3 ms-xl-4 me-xl-5 py-0 px-2">
                <img src={logo} alt="Logo Ciklab" id="logo"/>
            </Navbar.Brand>
            <Nav className="flex-row align-items-center">
                <Navbar.Toggle aria-controls="basic-navbar-nav" className=""/>
            </Nav>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="text-uppercase me-auto mt-2 mt-lg-0" variant="pills">
                    {currentUser.role !== UserRole.READER && (
                        <React.Fragment>
                            <Nav.Link as={NavLink} exact to={Routes.DASHBOARD_PAGE} activeClassName='active'
                                      className="text-uppercase m-1 my-lg-0 mx-lg-1 mx-xl-2 px-3 px-lg-2 px-xl-3 py-2">{T.translate("menu.home")}</Nav.Link>
                            <Nav.Link as={NavLink} to={Routes.LOTS_PAGE} activeClassName='active'
                                      className="text-uppercase m-1 my-lg-0 mx-lg-1 mx-xl-2 px-3 px-lg-2 px-xl-3 py-2">{T.translate("menu.lots")}</Nav.Link>
                            <Nav.Link as={NavLink} to={Routes.PRODUCTS_PAGE} activeClassName='active'
                                      className="text-uppercase m-1 my-lg-0 mx-lg-1 mx-xl-2 px-3 px-lg-2 px-xl-3 py-2">{T.translate("menu.products")}
                                {numberNextMonitoringExpired > 0 && (
                                    <>
                                        {'\u00a0'}
                                        <FontAwesomeIcon icon="bell"/>
                                    </>
                                )}
                            </Nav.Link>
                            <Nav.Link as={NavLink} to={Routes.ANALYSES_PAGE} activeClassName='active'
                                      className="text-uppercase m-1 my-lg-0 mx-lg-1 mx-xl-2 px-3 px-lg-2 px-xl-3 py-2">{T.translate("menu.analyses")}</Nav.Link>
                            <Nav.Link as={NavLink} to={Routes.LABORATORIES_PAGE} activeClassName='active'
                                      className="text-uppercase m-1 my-lg-0 mx-lg-1 mx-xl-2 px-3 px-lg-2 px-xl-3 py-2">{T.translate("menu.laboratories")}</Nav.Link>
                        </React.Fragment>
                    )}
                    {(currentUser.role === UserRole.SUPER_ADMINISTRATOR || currentUser.role === UserRole.COMPANY_ADMINISTRATOR || currentUser.role === UserRole.SUPERVISOR) && (
                        <Nav.Link as={NavLink} to={Routes.STATS_PAGE} activeClassName='active'
                                  className="text-uppercase m-1 my-lg-0 mx-lg-1 mx-xl-2 px-3 px-lg-2 px-xl-3 py-2">{T.translate("menu.stats")}</Nav.Link>
                    )}
                    <Nav.Link as={NavLink} to={Routes.ALL_DOCUMENTS_PAGE} activeClassName='active'
                              className="text-uppercase m-1 my-lg-0 mx-lg-1 mx-xl-2 px-3 px-lg-2 px-xl-3 py-2">
                        {T.translate("menu.documents")}
                        {numberExpiredDocuments > 0 && (
                            <>
                                {'\u00a0'}
                                <FontAwesomeIcon icon="bell"/>
                            </>
                        )}
                    </Nav.Link>
                </Nav>
                <NavUser/>
            </Navbar.Collapse>
        </Navbar>
    );
}

const mapStateToProps = state => {
    return {
        numberExpiredDocuments: state.documentReducer.countExpiredDocuments,
        numberNextMonitoringExpired: state.productReducer.countNextMonitoringExpired,
        productProgress: state.productReducer.progress,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        actualiseCountExpiredDocuments: (count) => dispatch(documentActions.actualiseCountExpiredDocuments(count)),
        actualizeCountNextMonitoringExpired: (count) => dispatch(productActions.actualizeCountNextMonitoringExpired(count))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);