import React, {useCallback, useEffect, useState} from 'react';
import CustomModal from "../custom-modal/custom-modal";
import {Alert, Button, Form, Spinner} from "react-bootstrap-v5";
import T from "i18n-react";
import Validator from "../../utils/validators/validator";
import {ToastClass} from "../toast-alert/toast-alert-class";
import {PositionOptions, PositionOptionsMessageKey} from "../../utils/enums/position-options";
import {productService} from "../../services/product-service";
import SelectLabel from "../input-label/select-label";
import TextareaLabel from "../input-label/textarea-label";
import CheckLabel from "../input-label/check-label";
import {connect} from "react-redux";
import {alertActions} from "../../redux/alert/actions-alert";
import {lotService} from "../../services/lot-service";

const CreateOrEditAdditionalInformationModal = ({isLot = false, entityId, show, onHide, isCreationMode, additionalInformationToEdit, showToast}) => {

    const [isLoading, setLoading] = useState(false);
    const [title, setTitle] = useState(additionalInformationToEdit?.title ?? '');
    const [content, setContent] = useState(additionalInformationToEdit?.content ?? '');
    const [specsSheet, setSpecsSheet] = useState(additionalInformationToEdit?.specsSheet ?? false);
    const [certificate, setCertificate] = useState(additionalInformationToEdit?.certificate ?? false);
    const [position, setPosition] = useState(additionalInformationToEdit?.position ?? PositionOptions.TOP);

    const initialStateInputErrors = {title: ''};

    const [inputErrors, setInputErrors] = useState(initialStateInputErrors);
    const [apiError, setApiError] = useState('');

    const resetErrors = () => {
        setInputErrors(initialStateInputErrors);
        setApiError('');
    }

    const resetForm = useCallback(() => {
        setTitle(additionalInformationToEdit?.title ?? '');
        setContent(additionalInformationToEdit?.content ?? '');
        setSpecsSheet(additionalInformationToEdit?.specsSheet ?? false);
        setCertificate(additionalInformationToEdit?.certificate ?? false);
        setPosition(additionalInformationToEdit?.position ?? PositionOptions.TOP);
    }, [additionalInformationToEdit]);

    useEffect(() => resetForm(), [resetForm]);

    const validate = () => {
        const titleError = Validator.validateRequired(title);
        const isValid = !titleError;
        setInputErrors({
            ...inputErrors,
            title: T.translate(titleError).toString(),
        })
        return isValid;
    }

    const hideModal = returnData => {
        resetForm();
        resetErrors();
        onHide(returnData);
    }

    const editOrCreateAdditionalInformation = async (additionalInformationId, additionalInformation) => {
        setLoading(true);
        try {
            let createdAdditionalInformation;
            if (isLot) {
                if (isCreationMode) {
                    createdAdditionalInformation = await lotService.createAdditionalInformation(entityId, additionalInformation);
                } else {
                    createdAdditionalInformation = await lotService.editAdditionalInformation(entityId, additionalInformationId, additionalInformation);
                }
            } else {
                if (isCreationMode) {
                    createdAdditionalInformation = await productService.createAdditionalInformation(entityId, additionalInformation);
                } else {
                    createdAdditionalInformation = await productService.editAdditionalInformation(entityId, additionalInformationId, additionalInformation);
                }
            }
            hideModal(createdAdditionalInformation);
            setLoading(false);
            showToast(T.translate('alert.successTitle'), T.translate(isCreationMode ? 'product.successCreateAdditionalInformation' : 'product.successEditAdditionalInformation'), ToastClass.SUCCESS);
        } catch (error) {
            setApiError(error.message);
            setLoading(false);
        }
    }

    const submitCreateAdditionalInformationForm = e => {
        e.preventDefault();

        resetErrors();
        if (validate()) {
            let additionalInformation;
            if (isLot) {
                additionalInformation = {
                    title,
                    content,
                    certificate,
                    position,
                };
            } else {
                additionalInformation = {
                    title,
                    content,
                    specsSheet,
                    certificate,
                    position,
                };
            }
            editOrCreateAdditionalInformation(
                isLot ? additionalInformationToEdit?.lotAdditionalInfoId : additionalInformationToEdit?.productAdditionalInfoId,
                additionalInformation);
        }
    }

    const positionOptions = () => {
        let options = [];
        for (const pos in PositionOptions) {
            options.push(
                <option key={pos} value={pos} selected={pos === position}>
                    {T.translate(PositionOptionsMessageKey[pos])}
                </option>
            );
        }
        return options;
    }

    const footer = (
        <React.Fragment>
            <Button variant="outline-secondary" onClick={() => hideModal()} className="px-3 py-1">
                {T.translate('form.button.cancel')}
            </Button>
            <Button variant="primary" onClick={submitCreateAdditionalInformationForm} className="px-3 py-1" disabled={isLoading}>
                {isLoading ? <Spinner as="span" size="sm" animation="border"/> : ""}
                {T.translate('form.button.confirm')}
            </Button>
        </React.Fragment>
    );

    return (
        <CustomModal show={show} onHide={() => hideModal()} footer={footer}
                     title={T.translate(`product.title.${isCreationMode ? 'createAdditionalInformation' : 'editAdditionalInformation'}`)}>
            <Form>
                <Alert variant="danger" hidden={!apiError}>
                    <Alert.Heading>
                        {T.translate("alert.errorTitle")}
                    </Alert.Heading>
                    {apiError}
                </Alert>
                <fieldset>
                    <TextareaLabel id="title" className="mb-4" type="text"
                                   label={T.translate('form.label.title')} required={true}
                                   value={title} onChange={e => setTitle(e.target.value)} error={inputErrors.title}/>
                    <TextareaLabel id="content" className="mb-4" type="text"
                                   label={T.translate('form.label.description')}
                                   value={content} onChange={e => setContent(e.target.value)} error={inputErrors.content}/>
                    {!isLot && (
                        <CheckLabel id="specsSheet" className="mb-4" label={T.translate('form.label.specsSheet')}
                                    checked={specsSheet} onChange={e => setSpecsSheet(e.target.checked)}/>
                    )}
                    <CheckLabel id="certificate" className="mb-4" label={T.translate('form.label.certificate')}
                                checked={certificate} onChange={e => setCertificate(e.target.checked)}/>
                    <SelectLabel id="position" className="mb-4" required={true} label={T.translate('form.label.position')}
                                 defaultValue={position} options={positionOptions()} onChange={e => setPosition(e.target.value)}/>
                </fieldset>
            </Form>
        </CustomModal>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(null, mapDispatchToProps)(CreateOrEditAdditionalInformationModal);
