import {backendUrl} from "./api/api";

const BASE_URL = '/audit-trail/';

const getExportCsvUrlByCompany = (data) => {
    return `${backendUrl}${BASE_URL}export/csv?companyId=${data.companyId}`;
}

export const auditTrailService = {
    getExportCsvUrlByCompany
};
