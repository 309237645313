import React from 'react';
import {Form} from "react-bootstrap-v5";
import './input-label.scss'
import {InputMaxLength} from "../../utils/enums/input-max-length";

const InputLabel = ({id, label, type, placeholder, required, value, onChange, error, className, disabled = false}) => {
    return (
        <Form.Group controlId={id} className={`input-label row align-items-center ${className}`}>
            <Form.Label className="col col-4 text-end mb-0">{label}{!!required ? <span className="required">*</span> : ""}</Form.Label>
            <div className="col col-8">
                <Form.Control type={type} placeholder={placeholder ?? label} value={value} onChange={onChange} isInvalid={!!error} maxLength={InputMaxLength.STANDARD_TEXT} disabled={disabled}/>
                <Form.Control.Feedback type='invalid'>
                    {error}
                </Form.Control.Feedback>
            </div>
        </Form.Group>
    );
}

export default InputLabel;

