import React, {useEffect, useState} from 'react';
import {Pagination} from "react-bootstrap-v5";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const nbSiblingItems = 2;
const nbStartAndEndVisibleItems = 5;

const TablePagination = ({page, totalPages, onPageChange}) => {

    const [paginationItems, setPaginationItems] = useState([]);

    useEffect(() => {
        const pages = [...Array(totalPages).keys()].map(index => index + 1);

        const items = pages.map(pageNumber => {
            if (pageNumber === 1 || pageNumber === totalPages
                || (page <= nbStartAndEndVisibleItems - nbSiblingItems && pageNumber <= nbStartAndEndVisibleItems)
                || (totalPages - page <= nbStartAndEndVisibleItems - nbSiblingItems && totalPages - pageNumber < nbStartAndEndVisibleItems)
                || Math.abs(pageNumber - page) <= nbSiblingItems) {
                return <Pagination.Item key={pageNumber} active={pageNumber === page} onClick={() => pageNumber === page ? null : onPageChange(pageNumber)}>{pageNumber}</Pagination.Item>
            }
            if (pageNumber === 2 || pageNumber === totalPages - 1) {
                return <Pagination.Item key={pageNumber} active={false} disabled={true}>...</Pagination.Item>
            }
            return null;
        }).filter(paginationItem => !!paginationItem);
        setPaginationItems(items);
    }, [page, totalPages, onPageChange]);

    return (
        <Pagination className="m-0">
            <Pagination.Item disabled={page === 1} onClick={() => onPageChange(1)}><FontAwesomeIcon icon="backward"/></Pagination.Item>
            <Pagination.Item disabled={page === 1} onClick={() => onPageChange(page - 1)}><FontAwesomeIcon icon="step-backward"/></Pagination.Item>
            {paginationItems}
            <Pagination.Item disabled={page === totalPages} onClick={() => onPageChange(page + 1)}><FontAwesomeIcon icon="step-forward"/></Pagination.Item>
            <Pagination.Item disabled={page === totalPages} onClick={() => onPageChange(totalPages)}><FontAwesomeIcon icon="forward"/></Pagination.Item>
        </Pagination>
    );
}

export default TablePagination;