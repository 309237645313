import {privateAPI} from './api/api';
import {ApiErrorHandler} from "./api/api-error";

const BASE_URL = '/companies/';

const getAll = async (page = 1, size = 10, sortDirection = 'ASC', sortFieldName = 'name', searchText = '') => {
    try {
        const response = await privateAPI.get(`${BASE_URL}?page=${page}&size=${size}&sortDirection=${!!sortDirection ? sortDirection : 'ASC'}&sortFieldName=${!!sortFieldName ? sortFieldName : 'name'}&searchText=${searchText}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getCompanyById = async (id) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}${id}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const createCompany = async (company) => {
    try {
        const response = await privateAPI.post(BASE_URL, company);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const deleteCompany = async (id) => {
    try {
        return await privateAPI.delete(`${BASE_URL}${id}`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editCompany = async (id, companyData) => {
    try {
        return await privateAPI.put(`${BASE_URL}${id}`, companyData);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

export const companyService = {
    getAll,
    getCompanyById,
    createCompany,
    deleteCompany,
    editCompany
};
