import React from 'react';
import {Modal} from "react-bootstrap-v5";
import './custom-modal.scss'
import ButtonClose from "../buttons/button-close";

const CustomModal = ({show, onHide, title, children, footer, className, bodyClassName, centered = false, dismissOnClickOutside = true}) => {
    return (
        <Modal size="lg" show={show} onHide={onHide} className={`custom-modal ${className}`} centered={centered} backdrop={dismissOnClickOutside ? true : "static"}>
            <Modal.Header className={!!title ? "px-4" : "p-0"}>
                <Modal.Title>{title}</Modal.Title>
                <ButtonClose onClick={onHide}/>
            </Modal.Header>
            <Modal.Body className={bodyClassName}>
                {children}
            </Modal.Body>
            <Modal.Footer className={!!footer ? "" : "p-0"}>
                {footer}
            </Modal.Footer>
        </Modal>
    );
}

export default CustomModal;