import React, {useEffect, useState} from 'react';
import T from "i18n-react";
import CustomModal from "../../../components/custom-modal/custom-modal";
import {Alert, Button, Form, Spinner} from "react-bootstrap-v5";
import TextareaLabel from "../../../components/input-label/textarea-label";
import {ClosingReason, ClosingReasonMessageKey} from "../../../utils/enums/closing-reason";
import SelectLabel from "../../../components/input-label/select-label";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {lotService} from "../../../services/lot-service";
import {connect} from "react-redux";
import {alertActions} from "../../../redux/alert/actions-alert";
import CloseLotDto from "../../../models/dto/close-lot-dto";
import {NotCompliantReason, NotCompliantReasonMessageKey} from "../../../utils/enums/not-compliant-reason";

const CloseLotModal = ({lotId, show, hideModal, initialComment, showToast}) => {

    const [isLoading, setLoading] = useState(false);

    const [closingReason, setClosingReason] = useState(ClosingReason.COMPLIANT);
    const [notCompliantReason, setNotCompliantReason] = useState(NotCompliantReason.TO_DESTROY);
    const [comment, setComment] = useState(initialComment);

    const [apiError, setApiError] = useState('');

    useEffect(() => {
        setComment(initialComment);
    }, [initialComment]);


    const resetErrors = () => {
        setApiError('');
    }

    const resetForm = () => {
        setClosingReason(ClosingReason.COMPLIANT);
        setNotCompliantReason(NotCompliantReason.TO_DESTROY);
        setComment(initialComment);
    }

    const onHideModal = (success = false) => {
        resetForm();
        resetErrors();
        hideModal(success);
    }

    const submitCloseLotForm = async () => {
        try {
            setLoading(true);
            const closeData = new CloseLotDto({closingReason, notCompliantReason, comment});
            await lotService.closeLot(lotId, closeData);
            setLoading(false);
            onHideModal(true);
            showToast(T.translate('alert.successTitle'), T.translate('lot.successCloseLot'), ToastClass.SUCCESS);
        } catch (error) {
            setApiError(error.message);
            setLoading(false);
        }
    }

    const closingReasonOptions = () => {
        let options = [];
        for (const reason in ClosingReason) {
            options.push(
                <option key={reason} value={reason} selected={reason === closingReason}>
                    {T.translate(ClosingReasonMessageKey[reason])}
                </option>
            );
        }
        return options;
    }

    const notCompliantReasonOptions = () => {
        let options = [];
        for (const reason in NotCompliantReason) {
            options.push(
                <option key={reason} value={reason} selected={reason === notCompliantReason}>
                    {T.translate(NotCompliantReasonMessageKey[reason])}
                </option>
            );
        }
        return options;
    }

    const footer = (
        <React.Fragment>
            <Button variant="outline-secondary" onClick={() => onHideModal(false)} className="px-3 py-1">
                {T.translate('form.button.cancel')}
            </Button>
            <Button variant="primary" onClick={submitCloseLotForm} className="px-3 py-1" disabled={isLoading}>
                {isLoading ? <Spinner as="span" size="sm" animation="border"/> : ""}
                {T.translate('form.button.confirm')}
            </Button>
        </React.Fragment>
    );

    return (
        <CustomModal show={show} onHide={() => onHideModal(false)} title={T.translate('lot.title.closeLot')} footer={footer}>
            <Form>
                <Alert variant={"danger"} hidden={!apiError}>
                    <Alert.Heading>
                        {T.translate("alert.errorTitle")}
                    </Alert.Heading>
                    {apiError}
                </Alert>
                <fieldset>
                    <SelectLabel id="status" className="mb-4" label={T.translate('form.label.status')} required={true}
                                 options={closingReasonOptions()} defaultValue={closingReason}
                                 onChange={e => setClosingReason(e.target.value)}/>

                    {closingReason === ClosingReason.NOT_COMPLIANT && (
                        <SelectLabel id="subStatus" className="mb-4" label={T.translate('form.label.subStatus')}
                                     options={notCompliantReasonOptions()} defaultValue={notCompliantReason} required={true}
                                     onChange={e => setNotCompliantReason(e.target.value)}/>
                    )}

                    <TextareaLabel id="comment" className="mb-4" label={T.translate('form.label.commentCoA')}
                                   value={comment} onChange={e => setComment(e.target.value)}/>
                </fieldset>
            </Form>
        </CustomModal>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(null, mapDispatchToProps)(CloseLotModal);