import {COMPANY_DELETED, COMPANY_HIDE_CREATE_OR_EDIT_MODAL, COMPANY_SHOW_CREATE_MODAL, COMPANY_SHOW_EDIT_MODAL} from "./type";

const initialStateCompany = {
    showCreateOrEditModal: false,
    isCreationMode: false,
    company: null,
    progress: 0,
}

const companyReducer = (state = initialStateCompany, action) => {
    switch (action.type) {
        case COMPANY_SHOW_CREATE_MODAL:
            return {
                ...state,
                showCreateOrEditModal: true,
                isCreationMode: true,
                company: null
            }
        case COMPANY_SHOW_EDIT_MODAL:
            return {
                ...state,
                showCreateOrEditModal: true,
                isCreationMode: false,
                company: action.payload
            }
        case COMPANY_DELETED:
        case COMPANY_HIDE_CREATE_OR_EDIT_MODAL:
            return {
                ...state,
                showCreateOrEditModal: false,
                isCreationMode: false,
                company: null,
                progress: state.progress + 1
            }
        default:
            return state;
    }
}

export default companyReducer;