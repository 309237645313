export default class ProductDto {
    constructor(productData) {
        this.companyId = productData?.company?.id ?? ''
        this.name = productData?.name ?? ''
        this.category = productData?.category ?? ''
        this.articleCode = productData?.articleCode ?? ''
        this.supplier = productData?.supplier ?? ''
        this.lifeSpan = productData?.lifeDuration ?? ''
        this.description = productData?.description ?? ''
    }
}