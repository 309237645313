import React, {useEffect, useState} from 'react';
import T from "i18n-react";
import {Button, Col, FormCheck, FormControl, FormGroup, Row} from "react-bootstrap-v5";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LocalTable from "../../../components/table/local-table";
import {laboratoryService} from "../../../services/laboratory-service";
import {SortDirection} from "../../../utils/enums/sort-direction";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {connect} from "react-redux";
import {alertActions} from "../../../redux/alert/actions-alert";
import Autocomplete from "../../../components/input-label/autocomplete";
import Validator from "../../../utils/validators/validator";
import ButtonIconAdd from "../../../components/buttons/button-icon-add";
import {InputMaxLength} from "../../../utils/enums/input-max-length";

const CreateOrEditAnalysisLaboratoryTable = ({laboratoryList, currentUser, onLaboratoryListChange, onAddLaboratory, onRemoveLaboratory, mainLaboratoryId, onMainLaboratoryIdChange, companyId, showToast}) => {

    const [laboratory, setLaboratory] = useState({id: '', name: ''});
    const [tableRows, setTableRows] = useState([]);
    const [price, setPrice] = useState('');
    const [delay, setDelay] = useState('');
    const [quantity, setQuantity] = useState('');
    const [reference, setReference] = useState('');

    const initialStateInputErrors = {laboratory: '', price: '', delay: '', quantity: ''};

    const [inputErrors, setInputErrors] = useState({...initialStateInputErrors});

    useEffect(() => setTableRows([...laboratoryList]), [laboratoryList]);

    const updateTableRow = (index, row) => {
        const newRows = [...tableRows];
        newRows[index] = row;
        onLaboratoryListChange(newRows);
    }

    const resetErrors = () => {
        setInputErrors({...initialStateInputErrors});
    }

    const resetForm = () => {
        setLaboratory({id: '', name: ''});
        setPrice('');
        setDelay('');
        setQuantity('');
        setReference('');
    }

    const validate = () => {
        const laboratoryError = Validator.validateRequired(laboratory.id);
        const priceError = Validator.validatePositiveNumber(price);
        const delayError = Validator.validatePositiveNumber(delay);
        const quantityError = Validator.validatePositiveNumber(quantity);
        const isValid = !laboratoryError && !priceError && !delayError && !quantityError;
        setInputErrors({
            ...inputErrors,
            laboratory: T.translate(laboratoryError).toString(),
            price: T.translate(priceError).toString(),
            delay: T.translate(delayError).toString(),
            quantity: T.translate(quantityError).toString()
        })
        return isValid;
    }

    const submitAddLaboratoryForm = () => {
        resetErrors();
        if (validate()) {
            const lab = {...laboratory, price, delay, quantity, reference};
            resetForm();
            onAddLaboratory(lab);
        }
    }

    const searchLaboratoryByName = async name => {
        if (!name || !companyId) return [];
        try {
            const response = await laboratoryService.getAll(1, 1000, SortDirection.ASC.toUpperCase(), 'name', name, companyId);
            return response.content?.filter(laboratoryElement => !laboratoryList.some(lab => lab.id === laboratoryElement.laboratoryId))
                .map(laboratoryElement => {
                    return {
                        id: laboratoryElement.laboratoryId,
                        name: laboratoryElement.name
                    }
                }) ?? [];
        } catch (e) {
            showToast(T.translate('alert.errorTitle'), T.translate('error.http.network'), ToastClass.ERROR);
            return [];
        }
    }

    const handleLaboratoryChange = e => {
        const name = e.target.value;
        setLaboratory({id: '', name});
    }

    const handleLaboratorySelect = labo => {
        setLaboratory(labo);
    }

    const handlePriceChange = e => {
        const newPrice = e.target.value;
        setPrice(newPrice);
    }

    const handleDelayChange = e => {
        const newDelay = e.target.value;
        setDelay(newDelay);
    }

    const handleQuantityChange = e => {
        const newQuantity = e.target.value;
        setQuantity(newQuantity);
    }

    const handleReferenceChange = e => {
        const newReference = e.target.value;
        setReference(newReference);
    }

    const laboratoryTableColumns = [
        {
            dataField: T.translate('table.fields.analyse.laboratory').toString(),
            text: T.translate('table.columns.laboratory.name').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.mainLaboratory').toString(),
            text: T.translate('table.columns.analyse.main').toString()
        },
        {
            dataField: T.translate('table.fields.price').toString(),
            text: `${T.translate('table.columns.price')} ${!!currentUser.currencySymbol ? '(' + currentUser.currencySymbol + ')' : ''}`,
            sort: true
        },
        {
            dataField: T.translate('table.fields.delay').toString(),
            text: T.translate('table.columns.delayInDays').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.quantity').toString(),
            text: T.translate('table.columns.quantityInGram').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.reference').toString(),
            text: T.translate('table.columns.reference').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.actions').toString(),
            text: ""
        }
    ];

    const laboratoryTableRows = tableRows.map((lab, index) => {
        return {
            ...lab,
            laboratory: lab.name,
            mainLaboratory: <FormCheck className="text-center" checked={mainLaboratoryId === lab.id}
                                       onChange={e => onMainLaboratoryIdChange(e, lab.id)}/>,
            price: (
                <FormControl value={lab.price} onChange={e => updateTableRow(index, {...lab, price: e.target.value})} type="number" min={0} maxLength={InputMaxLength.STANDARD_TEXT}/>
            ),
            delay: (
                <FormControl value={lab.delay} onChange={e => updateTableRow(index, {...lab, delay: e.target.value})} type="number" min={0} maxLength={InputMaxLength.STANDARD_TEXT}/>
            ),
            quantity: (
                <FormControl value={lab.quantity} onChange={e => updateTableRow(index, {...lab, quantity: e.target.value})} type="number" min={0} maxLength={InputMaxLength.STANDARD_TEXT}/>
            ),
            reference: (
                <FormControl value={lab.reference} onChange={e => updateTableRow(index, {...lab, reference: e.target.value})} type="text" maxLength={InputMaxLength.STANDARD_TEXT}/>
            ),
            actions: (
                <div className="actions">
                    <Button size="sm" className="delete"
                            onClick={() => onRemoveLaboratory(index)}>
                        <FontAwesomeIcon icon="times"/>
                    </Button>
                </div>
            )
        }
    });

    return (
        <div className="analysis-laboratory-table">
            <LocalTable className="overflow-scroll-x" data={laboratoryTableRows} columns={laboratoryTableColumns}/>
            <Row className="mw-100 gx-2">
                <FormGroup controlId="laboratory" className="col-6 col-sm-3">
                    <Autocomplete id="laboratory" error={inputErrors.laboratory} placeholder={T.translate('analyse.searchLaboratory')}
                                  onChange={handleLaboratoryChange} onSelect={handleLaboratorySelect} value={laboratory.name}
                                  onSuggestionsFetch={searchLaboratoryByName}/>
                    <FormControl.Feedback type='invalid' className={!!inputErrors.laboratory ? "d-block" : ""}>
                        {inputErrors.laboratory}
                    </FormControl.Feedback>
                </FormGroup>
                <FormGroup controlId="price" className="col-3 col-sm-2 mb-2">
                    <FormControl value={price} onChange={handlePriceChange} type="number" min={0}
                                 placeholder={T.translate('table.columns.price')} isInvalid={!!inputErrors.price} maxLength={InputMaxLength.STANDARD_TEXT}/>
                    <FormControl.Feedback type="invalid">{inputErrors.price}</FormControl.Feedback>
                </FormGroup>
                <FormGroup controlId="delay" className="col-3 col-sm-2 mb-2">
                    <FormControl value={delay} onChange={handleDelayChange} type="number" min={0}
                                 placeholder={T.translate('table.columns.delay')} isInvalid={!!inputErrors.delay} maxLength={InputMaxLength.STANDARD_TEXT}/>
                    <FormControl.Feedback type="invalid">{inputErrors.delay}</FormControl.Feedback>
                </FormGroup>
                <FormGroup controlId="quantity" className="col-3 col-sm-2 mb-2">
                    <FormControl value={quantity} onChange={handleQuantityChange} type="number" min={0}
                                 placeholder={T.translate('table.columns.quantity')} isInvalid={!!inputErrors.quantity} maxLength={InputMaxLength.STANDARD_TEXT}/>
                    <FormControl.Feedback type="invalid">{inputErrors.quantity}</FormControl.Feedback>
                </FormGroup>
                <FormGroup controlId="reference" className="col-3 col-sm-2 mb-2">
                    <FormControl value={reference} onChange={handleReferenceChange} type="text"
                                 placeholder={T.translate('table.columns.reference')} maxLength={InputMaxLength.STANDARD_TEXT}/>
                </FormGroup>
                <Col className="col-1 text-center">
                    <ButtonIconAdd onClick={submitAddLaboratoryForm}/>
                </Col>
            </Row>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrEditAnalysisLaboratoryTable);
