import React from 'react';
import {Button} from "react-bootstrap-v5";
import './buttons.scss'

const ButtonWithOptions = ({className, title, onClick, variant, children}) => {
    return (
        <Button className={`button-with-options ${className}`} variant={variant} onClick={onClick}>
            {title}
            <div className="button-option-container">{children}</div>
        </Button>
    );
}

export default ButtonWithOptions;


export const ButtonOption = ({className, title, onClick}) => {
    return (
        <div className={`button-option ${className}`} onClick={onClick}>
            {title}
        </div>
    );
}