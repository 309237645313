import React, {useState} from 'react';
import T from "i18n-react";
import CustomModal from "../../../components/custom-modal/custom-modal";
import {alertActions} from "../../../redux/alert/actions-alert";
import {connect} from "react-redux";
import {laboratoryActions} from "../../../redux/laboratory/actions-laboratory";
import {Alert, Button, Form, Spinner} from "react-bootstrap-v5";
import {UserRole} from "../../../utils/enums/user-role";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import TextareaLabel from "../../../components/input-label/textarea-label";
import InputLabel from "../../../components/input-label/input-label";
import Validator from "../../../utils/validators/validator";
import {laboratoryService} from "../../../services/laboratory-service";
import LaboratoryDto from "../../../models/dto/laboratory-dto";
import CompanyAutocompleteLabel from "../../../components/input-label/company-autocomplete-label";

const CreateLaboratoryModal = ({showCreateModal, currentUser, showToast, hideCreateModal}) => {

    const emailField = "email";
    const phoneField = "phoneNumber";

    const [isLoading, setLoading] = useState(false);

    const [company, setCompany] = useState({
        id: currentUser.companyId ?? '',
        name: '',
    });
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [contact, setContact] = useState('');
    const [role, setRole] = useState('');
    const [email, setEmail] = useState('');
    const [customerNumber, setCustomerNumber] = useState('');

    const initialStateInputErrors = {
        company: '',
        name: '',
        address: '',
        phone: '',
        contact: '',
        role: '',
        email: '',
        customerNumber: '',
    };

    const [inputErrors, setInputErrors] = useState({...initialStateInputErrors});
    const [apiError, setApiError] = useState('');

    const resetErrors = () => {
        setInputErrors({...initialStateInputErrors});
        setApiError('');
    }

    const resetForm = () => {
        setCompany({
            id: currentUser.companyId ?? '',
            name: '',
        });
        setName('');
        setAddress('');
        setPhone('');
        setContact('');
        setRole('');
        setEmail('');
        setCustomerNumber('');
    }

    const validate = () => {
        const companyError = Validator.validateRequired(company.id);
        const nameError = Validator.validateRequired(name);
        const emailError = Validator.validateEmail(email);
        const isValid = !companyError && !nameError && !emailError;
        setInputErrors({
            ...inputErrors,
            company: T.translate(companyError).toString(),
            name: T.translate(nameError).toString(),
            email: T.translate(emailError).toString()
        })
        return isValid;
    }

    const hideModal = () => {
        resetForm();
        resetErrors();
        hideCreateModal();
    }

    const setFieldError = (field, errorMessage) => {
        let errors = initialStateInputErrors;
        errors[field] = errorMessage;
        setInputErrors(errors);
    }

    const createLaboratory = async laboratory => {
        setLoading(true);
        try {
            await laboratoryService.createLaboratory(laboratory);
            hideModal();
            setLoading(false);
            showToast(T.translate('alert.successTitle'), T.translate('laboratory.successCreateLaboratory'), ToastClass.SUCCESS);
        } catch (error) {
            const field = error.response?.data?.entityIdField;
            if (field === emailField) {
                setFieldError("email", error.message);
            } else if (field === phoneField) {
                setFieldError("phone", error.message);
            } else {
                setApiError(error.message);
            }
            setLoading(false);
        }
    }

    const submitCreateLaboratoryForm = e => {
        e.preventDefault();

        resetErrors();
        if (validate()) {
            const laboratory = new LaboratoryDto({
                company,
                name,
                address,
                phone,
                contact,
                role,
                email,
                customerNumber,
            });
            createLaboratory(laboratory);
        }
    }

    const handleCompanyChange = companySelected => {
        setCompany(companySelected);
    }

    const footer = (
        <React.Fragment>
            <Button variant="outline-secondary" onClick={hideModal} className="px-3 py-1">
                {T.translate('form.button.cancel')}
            </Button>
            <Button variant="primary" onClick={submitCreateLaboratoryForm} className="px-3 py-1" disabled={isLoading}>
                {isLoading ? <Spinner as="span" size="sm" animation="border"/> : ""}
                {T.translate('form.button.confirm')}
            </Button>
        </React.Fragment>
    );

    return (
        <CustomModal show={showCreateModal} onHide={hideModal} title={T.translate('laboratory.title.createLaboratory')} footer={footer}>
            <Form>
                <Alert variant={"danger"} hidden={!apiError}>
                    <Alert.Heading>
                        {T.translate("alert.errorTitle")}
                    </Alert.Heading>
                    {apiError}
                </Alert>
                <fieldset>
                    {
                        currentUser.role === UserRole.SUPER_ADMINISTRATOR &&
                        <CompanyAutocompleteLabel id="companyId" className="mb-4" required={true} error={inputErrors.company}
                                                  defaultValue={company} onChange={handleCompanyChange}/>
                    }
                    <InputLabel id="name" className="mb-4" type="text" label={T.translate('form.label.name')} required={true}
                                value={name} onChange={e => setName(e.target.value)} error={inputErrors.name}/>

                    <TextareaLabel id="address" className="mb-4" label={T.translate('form.label.address')}
                                   value={address} onChange={e => setAddress(e.target.value)} error={inputErrors.address}/>

                    <InputLabel id="phone" className="mb-4" type="text" label={T.translate('form.label.phone')}
                                value={phone} onChange={e => setPhone(e.target.value)} error={inputErrors.phone}/>

                    <InputLabel id="contact" className="mb-4" type="text" label={T.translate('form.label.contact')}
                                value={contact} onChange={e => setContact(e.target.value)} error={inputErrors.contact}/>

                    <InputLabel id="role" className="mb-4" type="text" label={T.translate('form.label.role')}
                                value={role} onChange={e => setRole(e.target.value)} error={inputErrors.role}/>

                    <InputLabel id="email" className="mb-4" type="text" label={T.translate('form.label.email')}
                                value={email} onChange={e => setEmail(e.target.value)} error={inputErrors.email}/>

                    <InputLabel id="customerNumber" className="mb-4" type="text" label={T.translate('form.label.customerNumber')}
                                value={customerNumber} onChange={e => setCustomerNumber(e.target.value)} error={inputErrors.customerNumber}/>
                </fieldset>
            </Form>
        </CustomModal>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        showCreateModal: state.laboratoryReducer.showCreateModal
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideCreateModal: () => dispatch(laboratoryActions.hideCreateModal()),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLaboratoryModal);