import {ApiErrorHandler} from "./api/api-error";
import {onceGet, privateAPI} from "./api/api";
import axios from "axios";

const BASE_URL = '/products/';

let sourceGetControlsByProductId;

const getAll = async (page = 1, size = 10, sortDirection = 'ASC', sortFieldName = 'name', searchText = '', companyId = null, cancelPreviousCall) => {
    try {
        if (!!cancelPreviousCall) {
            if (sourceGetControlsByProductId) {
                sourceGetControlsByProductId.cancel();
            }
            sourceGetControlsByProductId = axios.CancelToken.source();
        }
        const response = await privateAPI.get(`${BASE_URL}?page=${page}&size=${size}&sortDirection=${!!sortDirection ? sortDirection : 'ASC'}&sortFieldName=${!!sortFieldName ? sortFieldName : 'name'}&searchText=${searchText}${!!companyId ? "&companyId=" + companyId : ""}`, cancelPreviousCall ? {cancelToken: sourceGetControlsByProductId.token} : {});
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getAllActivated = async (searchText, companyId) => {
    try {
        const response = await onceGet(`${BASE_URL}activated?searchText=${searchText}&companyId=${companyId}`, 'getAllActivated');
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getProductById = async (id) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}${id}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getIndicatorNextMonitoringExpired = async () => {
    try {
        const response = await privateAPI.get(`${BASE_URL}count-next-monitoring-expired`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const createProduct = async (product) => {
    try {
        const response = await privateAPI.post(BASE_URL, product);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const deleteProduct = async (id) => {
    try {
        return await privateAPI.delete(`${BASE_URL}${id}`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const duplicateProduct = async (id) => {
    try {
        const response = await privateAPI.post(`${BASE_URL}${id}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editProduct = async (id, data) => {
    try {
        return await privateAPI.patch(`${BASE_URL}${id}`, data);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editProductComment = async (id, comment) => {
    try {
        return await privateAPI.put(`${BASE_URL}${id}/comment`, comment, {headers: {'content-type': 'text/plain'}});
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const addAnalysisToProduct = async (productId, analysisIdList) => {
    try {
        return await privateAPI.post(`${BASE_URL}${productId}/analyses/`, analysisIdList);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editProductsAnalysis = async (analysisId, data) => {
    try {
        const response = await privateAPI.put(`${BASE_URL}analyse/${analysisId}`, data);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getControlsByProductId = async (productId, sortDirection = 'ASC', sortFieldName = 'analysis.analysisInfo.name') => {
    try {
        const response = await privateAPI.get(`${BASE_URL}${productId}/analyses?sortDirection=${!!sortDirection ? sortDirection : 'ASC'}&sortFieldName=${!!sortFieldName ? sortFieldName : 'analysis.analysisInfo.name'}`);
        return response.data
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editControl = async (productId, controlId, data) => {
    try {
        return await privateAPI.patch(`${BASE_URL}${productId}/analyses/${controlId}`, data);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

export const CUSTOMIZED_EMPTY_NEXT_MONITORING_DATE = 'CUSTOMIZED_EMPTY_NEXT_MONITORING_DATE';
const editNextMonitoringDate = async (productId, controlId, nextMonitoringDate) => {
    try {
        return await privateAPI.patch(`${BASE_URL}${productId}/analyses/${controlId}/next-monitoring-date/${nextMonitoringDate}`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const deleteControl = async (productId, controlId) => {
    try {
        return await privateAPI.delete(`${BASE_URL}${productId}/analyses/${controlId}`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const deleteProductsAnalysis = async (analysisId, data) => {
    try {
        const response = await privateAPI.post(`${BASE_URL}analyses/${analysisId}`, data);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const generateFiles = async (productId) => {
    try {
        return await privateAPI.post(`${BASE_URL}${productId}/controlplan`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const generateControlFile = async (productId) => {
    try {
        return await privateAPI.post(`${BASE_URL}${productId}/controlfile`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getAnalyseHistory = async (analyseId, productId) => {
    try {
        const response = await privateAPI.get(`/lots/results/from-product/${productId}/analyses/${analyseId}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getParameterHistory = async (analyseId, name, productId) => {
    try {
        const response = await privateAPI.get(`/lots/results/from-product/${productId}/analyses/${analyseId}/parameter-base-64/${name}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getAdditionalInformations = async (productId) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}${productId}/additional-infos`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const createAdditionalInformation = async (productId, data) => {
    try {
        return await privateAPI.post(`${BASE_URL}${productId}/additional-infos`, data);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const deleteAdditionalInformation = async (productId, additionalInformationId) => {
    try {
        const response = await privateAPI.delete(`${BASE_URL}${productId}/additional-infos/${additionalInformationId}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editAdditionalInformation = async (productId, additionalInformationId, data) => {
    try {
        return await privateAPI.put(`${BASE_URL}${productId}/additional-infos/${additionalInformationId}`, data);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const modify = async (productId) => {
    try {
        return await privateAPI.put(`${BASE_URL}${productId}/start-modifications`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const validateModification = async (productId) => {
    try {
        return await privateAPI.put(`${BASE_URL}${productId}/validate-modifications`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const indicatorsLotsClosed = async (searchText) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}indicators/closed-lots-number?searchText=${searchText}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const indicatorsLotsClosedCompliance = async (searchText) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}indicators/closed-lots-compliant?searchText=${searchText}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const indicatorsLotsClosedControlPrice = async (searchText = '') => {
    try {
        const response = await privateAPI.get(`${BASE_URL}indicators/closed-lots-control-price?searchText=${searchText}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const indicatorNextMonitoringRate = async (searchText) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}indicators/control-plans-rate?searchText=${searchText}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const indicatorsLotsClosedForProduct = async (productId) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}${productId}/indicators/closed-lots`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const indicatorsLotsClosedComplianceForProduct = async (productId) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}${productId}/indicators/closed-lots-compliant`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const indicatorsLotsClosedControlPriceForProduct = async (productId) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}${productId}/indicators/closed-lots-control-price`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

export const productService = {
    getAll,
    getAllActivated,
    getProductById,
    getIndicatorNextMonitoringExpired,
    createProduct,
    deleteProduct,
    duplicateProduct,
    editProduct,
    editProductComment,
    addAnalysisToProduct,
    getControlsByProductId,
    editControl,
    editNextMonitoringDate,
    deleteControl,
    deleteProductsAnalysis,
    editProductsAnalysis,
    generateFiles,
    generateControlFile,
    getAnalyseHistory,
    getParameterHistory,
    getAdditionalInformations,
    createAdditionalInformation,
    deleteAdditionalInformation,
    editAdditionalInformation,
    modify,
    validateModification,
    indicatorsLotsClosed,
    indicatorsLotsClosedCompliance,
    indicatorsLotsClosedControlPrice,
    indicatorNextMonitoringRate,
    indicatorsLotsClosedForProduct,
    indicatorsLotsClosedComplianceForProduct,
    indicatorsLotsClosedControlPriceForProduct,
};
