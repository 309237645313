import React from 'react';
import {Form} from "react-bootstrap-v5";
import './input-label.scss'

const ReadonlyLabel = ({label, value, className}) => {
    return (
        <Form.Group className={`readonly-label row align-items-center ${className}`}>
            <Form.Label className="col col-4 text-end mb-0">{label}</Form.Label>
            <div className="col col-8">
                <div className="readonly">{value}</div>
            </div>
        </Form.Group>
    );
}

export default ReadonlyLabel;

