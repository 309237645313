import React, { useCallback, useEffect, useState } from 'react';
import { connect } from "react-redux";
import T from 'i18n-react'
import { Alert, Button, Row, Col, FormCheck } from "react-bootstrap-v5";
import { confirmDialogActions } from "../../../../redux/confirm-dialog/actions-confirm-dialog";
import { alertActions } from "../../../../redux/alert/actions-alert";
import { analyseActions } from "../../../../redux/analyse/actions-analyse";
import RemoteTable from "../../../../components/table/remote-table";
import { analyseService } from "../../../../services/analyse-service";
import { SortDirection } from "../../../../utils/enums/sort-direction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableAnalysisProduct from "../table-analysis-product";
import { UserRole } from "../../../../utils/enums/user-role";
import { ToastClass } from "../../../../components/toast-alert/toast-alert-class";
import { productService } from '../../../../services/product-service';
import AnalyseEntity from "../../../../models/entities/analyse-entity";
import LinkAnalysisSearchInput from "../link-analysis/link-analysis-search-input";

const CurrentAnalysisProductsTab = ({ analyseReducer, currentUser, showConfirmDialog, showToast, sendToAddAnalysisProductsTab, dataFromAddAnalysisProductsTab }) => {
    const [progress, setProgress] = useState(0);
    const [apiError, setApiError] = useState('');
    const [isSelectedProduct, setIsSelectedProduct] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [showEditForm, setShowEditForm] = useState(false);
    const [parameters, setParameters] = useState([]);
    const [analyseEntity, setAnalyseEntity] = useState({});
    const [searchText, setSearchText] = useState({
        category: '',
        productName: '',
        productNumber: '',
        productSupplier: ''
    });

    
    const canEditCheckbox = currentUser.role !== UserRole.VISITOR && currentUser.role !== UserRole.CONTRIBUTOR;

    const incrementProgress = () => {
        setProgress(prevState => prevState + 1);
        sendToAddAnalysisProductsTab(true);
        setSelectedProducts([]);
    };


    useEffect(() => {
        setSelectedProducts([]);
        setIsSelectedProduct(false);
    }, [dataFromAddAnalysisProductsTab]);

    useEffect(() => {
        const getAnalyse = async () => {
            try {
                const analyseData = await analyseService.getAnalyse(analyseReducer.analyseId)
                const analyseFetched = new AnalyseEntity(analyseData);
                setAnalyseEntity(analyseFetched);
            } catch (error) {
                setApiError(error.message);
            }
        }
        if (!analyseReducer.showLinkModal) {
            setSelectedProducts([]);
        }
        if (analyseReducer.analyseId) {
            getAnalyse()
        }
    }, [analyseReducer.showLinkModal, analyseReducer.analyseId]);

    useEffect(() => {
        setParameters(analyseEntity.laboratoryParameterNames);
    }, [analyseEntity]);

    useEffect(() => {
        if (selectedProducts.length === 0) {
            setIsSelectedProduct(false);
            setShowEditForm(false);
        } else {
            setIsSelectedProduct(true);
        }
    }, [selectedProducts]);

    let columns = [
        {
            dataField: T.translate('table.fields.analyse.analyseName').toString(),
            text: '',
            sort: false
        },
        {
            dataField: T.translate('table.fields.category').toString(),
            text: T.translate('table.columns.category'),
            sort: true
        },
        {
            dataField: T.translate('table.fields.product.name').toString(),
            text: T.translate('table.columns.product.name'),
            sort: true
        },
        {
            dataField: T.translate('table.fields.product.articleCode'),
            text: T.translate('table.columns.product.articleCode'),
            sort: true
        },
        {
            dataField: T.translate('table.fields.product.supplier'),
            text: T.translate('table.columns.product.supplier'),
            sort: true
        },
        {
            dataField: T.translate('table.fields.specifications'),
            text: T.translate('table.columns.specifications'),
            sort: true
        },
        {
            dataField: T.translate('table.fields.product.unit'),
            text: T.translate('table.columns.unit'),
            sort: true
        },
        {
            dataField: T.translate('table.fields.source'),
            text: T.translate('table.columns.analyse.source'),
            sort: true
        },
        {
            dataField: T.translate('table.fields.periodicity'),
            text: T.translate('table.columns.periodicity'),
            sort: true
        },
        {
            dataField: T.translate('table.fields.checked'),
            text: T.translate('table.columns.specsSheet'),
            sort: true
        }
    ];


    const groupCheckbox = (leafValues) => {
        let checked = true;
        for (let leafValue of leafValues) {
            if (leafValue && !leafValue.props.checked) {
                checked = false
            }
        }
        return canEditCheckbox && (<FormCheck onChange={e => handleGroupCheck(leafValues, e)} checked={checked} />)
    }

    if (canEditCheckbox) {
        columns = [
            {
                dataField: T.translate('table.fields.checkbox').toString(),
                aggregate: groupCheckbox,
                text: '',
                sort: false
            },
            ...columns
        ];
    }
    const handleGroupCheck = useCallback(async (checkBoxCells, e) => {
        const checkedCertificate = !!e.target.checked
        const productIds = []; 
        let atLeastOneControlEdited = false;
        for (let checkBoxCell of checkBoxCells) {
            if (checkBoxCell) {
                const productId = checkBoxCell.props.className.split(' ').find(d => d.includes("product-id")).split(':')[1];
                if(!selectedProducts.includes(productId) && !productIds.includes(productId)){
                    productIds.push(productId); 
                    atLeastOneControlEdited = true;
                }
            }
        }

        if (atLeastOneControlEdited && checkedCertificate) {
            setSelectedProducts(prevSelectedProducts => [
                ...new Set([...prevSelectedProducts, ...productIds])
            ]);
        } else if (atLeastOneControlEdited && !checkedCertificate) {
            setSelectedProducts(prevSelectedProducts => 
                prevSelectedProducts.filter(productId => !productIds.includes(productId))
            );
        }
    }, []);
    


    const deleteAnalysisForProducts = async selectedProducts => {
        try {
            const analysisId = analyseReducer.analyseId;
            const data =
            {
                productsId: selectedProducts
            };
            await productService.deleteProductsAnalysis(analysisId, data);
            setProgress(prevState => prevState + 1);
            sendToAddAnalysisProductsTab(true);
            setSelectedProducts([]);
            showToast(T.translate('alert.successTitle'), T.translate('lot.successDeleteProductsAnalysis'), ToastClass.SUCCESS);
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleRemoveAnalysisFromProducts = async (e) => {
        e.stopPropagation();
        showConfirmDialog(
            T.translate('dialog.title.confirmDelete'),
            T.translate('dialog.confirmDeleteProductsAnalysis', { lotAnalysisName: analyseReducer.name, selectedProductNumber: selectedProducts.length }),
            () => {
            },
            () => deleteAnalysisForProducts(selectedProducts))
    }

    const showEditAnalysisFromProducts = async (e) => {
        setShowEditForm(!showEditForm);
    }



    const completeElement = useCallback((element, elementIndex) =>{

        const checkIfSelected = (id) => {
            return selectedProducts.includes(id);
        }

        const getSourceValue = (element) => {
            if (element.sourceId && element.sourceName) {
                return element.sourceName;
            }
            let value = '';
            if (element.sourceId) {
                switch (element.sourceId) {
                    case "SPECIAL_LABORATORY_SUPPLIER_DATA":
                        value = T.translate('product.label.supplierData');
                        break;
                    case "SPECIAL_LABORATORY_PERIODIC_PLAN":
                        value = T.translate('product.label.periodicPlan');
                        break;
                    case "SPECIAL_LABORATORY_RAW_MATERIAL":
                        value = T.translate('product.label.rawMaterial');
                        break;
                    case "SPECIAL_LABORATORY_COMPUTED_VALUE":
                        value = T.translate('product.label.computedValue');
                        break;
                    case "SPECIAL_LABORATORY_CLIENT_DATA":
                        value = T.translate('product.label.clientData');
                        break;
                    default:
                        value = '';
                }
            }
            return value;
        }

        const handleCheckBoxProductClick = (e, productId) => {
            const checkedProduct = e.target.checked;
            setSelectedProducts((prevSelectedProducts) => {
                if (checkedProduct) {
                    if (!prevSelectedProducts.includes(productId)) {
                        return [...prevSelectedProducts, productId];
                    }
                } else {
                    return prevSelectedProducts.filter(id => id !== productId);
                }
                return prevSelectedProducts;
            });
        };

        return {
            ...element,
            id: element.product.productId,
            analysisName: element.analysisName,   
            checkbox:
                <FormCheck
                    className={`text-center product-id:${element.product.productId}`}
                    onChange={e => handleCheckBoxProductClick(e, element.product.productId, element.product.productName)}
                    checked={checkIfSelected(element.product.productId)}
                />,
            category: element.product.category,
            name: element.product.name,
            articleCode: element.product.articleCode,
            supplier: element.product.supplier,
            specs: element.specs,
            unit: element.unit,
            source: getSourceValue(element),
            periodicity: element.periodicity.displayName,
            checked: <FormCheck className="ms-2" disabled={true} checked={element.checked} />,
        }
        
    }, [currentUser, analyseReducer, selectedProducts, showConfirmDialog, analyseEntity, progress, dataFromAddAnalysisProductsTab, searchText])

    const onFetchData = useCallback(async (page, size, sortedOrder, sortedField) => {
        const data = await analyseService.getRelatedProductsToAnalysis(analyseReducer.analyseId, page, size, sortedOrder, sortedField, searchText);
        const elements = data.content.map((element, elementIndex) => completeElement(element, elementIndex));
        return {
            ...data,
            elements: elements
        };
    }, [completeElement])

    const handleSearchChange = e => {
        const filters = e;
        if ((filters.category.length >= 3 || filters.category.length === 0) &&
            (filters.productName.length >= 3 || filters.productName.length === 0) &&
            (filters.productNumber.length >= 3 || filters.productNumber.length === 0) &&
            (filters.productSupplier.length >= 3 || filters.productSupplier.length === 0)
        ) {
            setSearchText(filters);
        }
    }

    return (
        <>
            <Alert variant="danger" hidden={!apiError}>
                <Alert.Heading>
                    {T.translate("alert.errorTitle")}
                </Alert.Heading>
                {apiError}
            </Alert>

            {!isSelectedProduct ? (
                <Row className="ms-3">{T.translate('analyse.currentProductAnalysisDescription')}</Row>
            ) : (
                <Row className="d-flex justify-content-between align-items-center">
                    <Col xs="auto" className="mt-3">
                        <Button className="px-4 mb-2 mb-sm-0" size="sm" onClick={showEditAnalysisFromProducts}>
                            {T.translate('analyse.button.updateAnalysisDataForProducts')}
                            <FontAwesomeIcon className={"ms-3"} icon={showEditForm ? "chevron-up" : "chevron-down"} />
                        </Button>
                    </Col>
                    <Col xs="auto" className="mt-3">
                        <Button className="px-4 mb-2 mb-sm-0 removeButton" size="sm" onClick={handleRemoveAnalysisFromProducts}>
                            <FontAwesomeIcon icon="trash" className={"me-3"} />
                            {T.translate('analyse.button.removeAnalysisFromProducts')}
                        </Button>
                    </Col>
                </Row>
            )}
            {showEditForm && (
                <Row className="mt-4">
                    <Col>
                        <TableAnalysisProduct parameters={parameters} selectedProducts={selectedProducts} analysis={analyseEntity} onProgress={incrementProgress} isCreation={false} />
                    </Col>
                </Row>
            )}
            <div className="search-container-modal mt-4 ms-3">
                <LinkAnalysisSearchInput onChange={handleSearchChange} />
            </div>

            <RemoteTable id="table-related-product-list"
                defaultSortedField={'name'}
                defaultSortedOrder={SortDirection.DESC}
                columns={columns}
                onFetchData={onFetchData}
                leftGroupNameColumnId={T.translate('table.fields.product.checkbox')}
                searchText={searchText}
                tableSize="20"
                groupByName={T.translate('table.fields.analyse.analyseName').toString()}
                completeElement={completeElement}
                checkAll={true}
                />
        </>
    );
};

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        analyseReducer: state.analyseReducer,
        searchTextFromReducer: state.productReducer.searchText
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideLinkModal: () => dispatch(analyseActions.hideLinkModal()),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentAnalysisProductsTab);