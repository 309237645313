import {
    LOT_ADD_ANALYSIS,
    LOT_CANCEL_TRANSFER,
    LOT_CONTROL_CHANGE_DISPLAY_ORDER,
    LOT_CONTROL_DELETED,
    LOT_DELETED,
    LOT_EDIT_LOT,
    LOT_HIDE_CREATE_MODAL,
    LOT_INCREMENT_SELECTED_ANALYSIS,
    LOT_DECREMENT_SELECTED_ANALYSIS,
    LOT_SAVE_SEARCH_TEXT,
    LOT_SHOW_CREATE_MODAL,
    LOT_SHOW_ATTACH_ANALYSIS_MODAL,
    LOT_HIDE_ATTACH_ANALYSIS_MODAL,
    LOT_UPDATE_SELECTED_ANALYSIS,
    LOT_TRANSFER_RESULT
} from "./type";

const initialStateLot = {
    showCreateModal: false,
    showAttachFileAnalysisModal: false,
    selectedAnalysisNumber: 0,
    progress: 0,
    searchText: '',
}

const lotReducer = (state = initialStateLot, action) => {
    switch (action.type) {
        case LOT_SHOW_CREATE_MODAL:
            return {
                ...state,
                showCreateModal: true
            }
        case LOT_DELETED:
        case LOT_HIDE_CREATE_MODAL:
            return {
                ...state,
                showCreateModal: false,
                progress: state.progress + 1
            }
        case LOT_ADD_ANALYSIS:
        case LOT_CONTROL_DELETED:
        case LOT_CONTROL_CHANGE_DISPLAY_ORDER:
        case LOT_CANCEL_TRANSFER: 
            return {
            ...state,
            progress: state.progress + 1
        }
        case LOT_TRANSFER_RESULT: 
            return {
            ...state,
            progress: state.progress + 1
        }
        case LOT_EDIT_LOT:
            return {
                ...state,
                progress: state.progress + 1
            }
        case LOT_UPDATE_SELECTED_ANALYSIS:
            return {
                ...state,
                selectedAnalysisNumber: action.payload
            }

        case LOT_SHOW_ATTACH_ANALYSIS_MODAL:
            return {
                ...state,
                showAttachFileAnalysisModal: true,
                lot: action.payload
            }
        case LOT_HIDE_ATTACH_ANALYSIS_MODAL:
            return {
                ...state,
                showAttachFileAnalysisModal: false,
                progress: state.progress + 1
            }
        case LOT_INCREMENT_SELECTED_ANALYSIS:
            return {
                ...state,
                selectedAnalysisNumber: action.payload ? state.selectedAnalysisNumber + 1 : state.selectedAnalysisNumber - 1
            }
        case LOT_DECREMENT_SELECTED_ANALYSIS:
            return {
                ...state,
                selectedAnalysisNumber: state.selectedAnalysisNumber - 1 
            }
        case LOT_SAVE_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.payload
            }
        default:
            return state;
    }
}

export default lotReducer;