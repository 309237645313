import React from "react";
import Header from '../../components/header/header'
import PageWithMenuTemplate from "../../components/template-pages/page-with-menu-template";
import T from "i18n-react";
import HeaderTextLabel from "../../components/header/header-text-label";
import {Col, Row} from "react-bootstrap-v5";
import "./imports-page.scss";
import CardImportProducts from "./components/card-import-products";
import CardDeleteProducts from "./components/card-delete-products";

const ImportsPage = () => {
    return (
        <PageWithMenuTemplate pageTitle={T.translate('pageTitle.imports')}>
            <Header>
                <HeaderTextLabel className="me-4" isMain={true}>
                    <h1 className="ps-1">{T.translate('menu.imports')}</h1>
                </HeaderTextLabel>
            </Header>
            <Col className="mx-4">
                <Row className="mb-3">
                    <CardImportProducts />
                </Row>
                <Row className="mb-3">
                    <CardDeleteProducts />
                </Row>
            </Col>
        </PageWithMenuTemplate>
    );
}

export default ImportsPage;
