import {
    ADD_ANALYSIS_TO_LABORATORY,
    LABORATORY_DELETED,
    LABORATORY_HIDE_CREATE_MODAL,
    LABORATORY_HIDE_FORMAT_MODAL,
    LABORATORY_SHOW_CREATE_MODAL,
    LABORATORY_SHOW_FORMAT_MODAL,
    SET_ANALYSIS_IN_PROGRESS_NUMBER
} from "./type";

const laboratoryCreate = () => {
    return {
        type: LABORATORY_SHOW_CREATE_MODAL
    }
}

const showFormatModal = (laboratoryId) => {
    return {
        type: LABORATORY_SHOW_FORMAT_MODAL,
        payload: laboratoryId
    }
}

const laboratoryDeleted = () => {
    return {
        type: LABORATORY_DELETED
    }
}

const hideCreateModal = () => {
    return {
        type: LABORATORY_HIDE_CREATE_MODAL
    }
}

const hideFormatModal = () => {
    return {
        type: LABORATORY_HIDE_FORMAT_MODAL
    }
}

const addAnalysisToLaboratory = () => {
    return {
        type: ADD_ANALYSIS_TO_LABORATORY
    }
}

const setAnalysisInProgressNumber = (numberOfAnalysisInProgress) => {
    return {
        type: SET_ANALYSIS_IN_PROGRESS_NUMBER,
        payload: numberOfAnalysisInProgress
    }
}

export const laboratoryActions = {
    laboratoryCreate,
    showFormatModal,
    laboratoryDeleted,
    hideCreateModal,
    hideFormatModal,
    addAnalysisToLaboratory,
    setAnalysisInProgressNumber
};