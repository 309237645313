import {
    ADD_ANALYSIS_TO_LABORATORY,
    LABORATORY_DELETED,
    LABORATORY_HIDE_CREATE_MODAL,
    LABORATORY_HIDE_FORMAT_MODAL,
    LABORATORY_SHOW_CREATE_MODAL,
    LABORATORY_SHOW_FORMAT_MODAL,
    SET_ANALYSIS_IN_PROGRESS_NUMBER
} from "./type";

const initialStateLaboratory = {
    showCreateModal: false,
    showFormatModal: false,
    laboratoryId: '',
    progress: 0,
    nbOfAnalysisInProgress: 0
}

const laboratoryReducer = (state = initialStateLaboratory, action) => {
    switch (action.type) {
        case LABORATORY_SHOW_CREATE_MODAL:
            return {
                ...state,
                showCreateModal: true
            }
        case LABORATORY_SHOW_FORMAT_MODAL:
            return {
                ...state,
                showFormatModal: true,
                laboratoryId: action.payload
            }
        case LABORATORY_DELETED:
        case LABORATORY_HIDE_CREATE_MODAL:
            return {
                ...state,
                showCreateModal: false,
                progress: state.progress + 1
            }
        case LABORATORY_HIDE_FORMAT_MODAL:
            return {
                ...state,
                showFormatModal: false,
                laboratoryId: ''
            }
        case ADD_ANALYSIS_TO_LABORATORY:
            return {
                ...state,
                progress: state.progress + 1
            }
        case SET_ANALYSIS_IN_PROGRESS_NUMBER:
            return {
                ...state,
                nbOfAnalysisInProgress: action.payload
            }
        default:
            return state;
    }
}

export default laboratoryReducer;