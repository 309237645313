import React, {useCallback, useState} from 'react';
import T from "i18n-react";
import {Button, Card, Col, FormControl, FormGroup, Row} from "react-bootstrap-v5";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RemoteTable from "../../../components/table/remote-table";
import {connect} from "react-redux";
import {confirmDialogActions} from "../../../redux/confirm-dialog/actions-confirm-dialog";
import {analyseCategoryService} from "../../../services/analyse-category-service";
import {UserRole} from "../../../utils/enums/user-role";
import EditableText from "../../../components/editable-text/editable-text";
import {alertActions} from "../../../redux/alert/actions-alert";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import ButtonIconAdd from "../../../components/buttons/button-icon-add";
import Validator from "../../../utils/validators/validator";
import {companyService} from "../../../services/company-service";
import {SortDirection} from "../../../utils/enums/sort-direction";
import Autocomplete from "../../../components/input-label/autocomplete";
import {InputMaxLength} from "../../../utils/enums/input-max-length";

const TableAnalysesCategoryList = ({className, searchText, showConfirmDialog, currentUser, showToast}) => {

    let columns = [
        {
            dataField: T.translate('table.fields.name').toString(),
            text: T.translate('table.columns.category').toString(),
            sort: true
        }
    ];

    if (currentUser.role !== UserRole.CONTRIBUTOR) {
        columns.push({
            dataField: T.translate('table.fields.actions').toString(),
            text: T.translate('table.columns.actions').toString()
        })
    }

    if (currentUser.role === UserRole.SUPER_ADMINISTRATOR) {
        columns = [
            {
                dataField: T.translate('table.fields.analyse.company').toString(),
                text: T.translate('table.columns.company'),
                sort: true
            },
            ...columns
        ]
    }

    const [isLoading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const [company, setCompany] = useState({
        id: currentUser?.companyId ?? '',
        name: '',
    });
    const [category, setCategory] = useState('');

    const [inputErrors, setInputErrors] = useState({company: '', category: ''});

    const resetErrors = () => {
        setInputErrors({company: '', category: ''});
    }

    const resetForm = () => {
        setCompany({
            id: currentUser?.companyId ?? '',
            name: '',
        });
        setCategory('');
    }

    const onFetchData = useCallback(async (page, size, sortedOrder, sortedField, searchText) => {
        const deleteCategory = async categoryData => {
            if (!!categoryData.usedByAnalysis) {
                showToast(T.translate('alert.errorTitle'), T.translate('error.category.usedByAnalysis'), ToastClass.ERROR);
                return;
            }
            try {
                await analyseCategoryService.deleteCategory(categoryData.analysisCategoryId);
                setProgress(prevState => prevState + 1);
                showToast(T.translate('alert.successTitle'), T.translate('analyse.successDeleteCategory', {categoryName: categoryData.name}), ToastClass.SUCCESS);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleDeleteCategory = async (e, categoryData) => {
            e.stopPropagation();
            showConfirmDialog(
                T.translate('dialog.title.confirmDelete'),
                T.translate('dialog.confirmDeleteCategory', {categoryName: categoryData.name}),
                () => {
                },
                () => deleteCategory(categoryData))
        }

        const handleEditCategory = async (id, categoryData) => {
            try {
                await analyseCategoryService.editCategory(id, categoryData);
                showToast(T.translate('alert.successTitle'), T.translate('analyse.successEditCategory'), ToastClass.SUCCESS);
            } catch (error) {
                const exceptionClass = error.response?.data?.exceptionClass;
                if (exceptionClass === "ObjectAlreadyExistsException") {
                    showToast(T.translate('alert.errorTitle'), T.translate('error.category.alreadyExists'), ToastClass.ERROR);
                } else {
                    showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
                }
            }
            setProgress(prevState => prevState + 1);
        }

        const completeElements = allElements => {
            return allElements.map(element => {
                return {
                    ...element,
                    id: element.analysisCategoryId,
                    name: (
                        <EditableText id={element.analysisCategoryId} defaultValue={element.name} reload={progress}
                                      onSubmit={val => handleEditCategory(element.analysisCategoryId, {name: val})} shouldSubmitOnBlur/>
                    ),
                    actions: currentUser.role === UserRole.CONTRIBUTOR ? "" : (
                        <div className="actions">
                            <Button size="sm" className={`delete ms-1 ${element.deactivated ? "d-none" : ""}`}
                                    onClick={e => handleDeleteCategory(e, element)}>
                                <FontAwesomeIcon icon="times"/>
                            </Button>
                        </div>
                    )
                }
            })
        }

        const data = await analyseCategoryService.getAll(page, size, sortedOrder.toUpperCase(), sortedField, searchText);
        const elements = completeElements(data.content)
        return {
            ...data,
            elements: elements
        };
    }, [showConfirmDialog, currentUser.role, showToast, progress])

    const searchCompanyByName = async name => {
        try {
            const response = await companyService.getAll(1, 5, SortDirection.ASC.toUpperCase(), 'name', name);
            return response.content?.map(companyElement => {
                return {
                    id: companyElement.companyId,
                    name: companyElement.name
                }
            }) ?? [];
        } catch (e) {
            showToast(T.translate('alert.errorTitle'), T.translate('error.http.network'), ToastClass.ERROR);
            return [];
        }
    }

    const handleCompanyNameChange = e => {
        const name = e.target.value;
        setCompany({id: '', name})
    }

    const handleCompanySelected = companySelected => {
        setCompany(companySelected);
    }

    const validate = () => {
        const companyError = Validator.validateRequired(company.id);
        const categoryError = Validator.validateRequired(category);
        const isValid = !companyError && !categoryError;
        setInputErrors({
            company: T.translate(companyError).toString(),
            category: T.translate(categoryError).toString()
        });
        return isValid;
    }

    const createCategory = async (categoryData) => {
        setLoading(true);
        try {
            await analyseCategoryService.createCategory(categoryData);
            setLoading(false);
            setProgress(prevState => prevState + 1);
            resetForm();
            showToast(T.translate('alert.successTitle'), T.translate('analyse.successCreateCategory'), ToastClass.SUCCESS);
        } catch (error) {
            setLoading(false);
            const field = error.response?.data?.entityIdField;
            if (field === "name") {
                setInputErrors({...inputErrors, category: error.message});
            } else {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }
    }

    const handleAddCategory = () => {
        resetErrors();
        if (validate()) {
            const categoryData = {
                companyId: company.id,
                name: category
            }
            createCategory(categoryData);
        }
    }

    return (
        <Card className={className}>
            <RemoteTable id="table-analyses-category-list"
                         defaultSortedField={T.translate('table.fields.name')}
                         columns={columns} onFetchData={onFetchData} reload={progress} searchText={searchText}>
                <Row className="mw-100 ps-3 pb-1">
                    {
                        currentUser?.role === UserRole.SUPER_ADMINISTRATOR &&
                        <Col>
                            <Autocomplete id="companyId" placeholder={T.translate('form.label.company')}
                                          value={company.name} onSuggestionsFetch={searchCompanyByName}
                                          onChange={handleCompanyNameChange} onSelect={handleCompanySelected}
                                          error={inputErrors.company}/>
                            <FormControl.Feedback type="invalid" className={`text-start ${!!inputErrors.company ? "d-block" : ""}`}>
                                {inputErrors.company}
                            </FormControl.Feedback>
                        </Col>
                    }
                    <Col>
                        <FormGroup controlId="category">
                            <FormControl value={category} onChange={e => setCategory(e.target.value)}
                                         type="text" placeholder={T.translate('analyse.addCategory')}
                                         isInvalid={!!inputErrors.category} maxLength={InputMaxLength.STANDARD_TEXT}/>
                            <FormControl.Feedback type="invalid" className="text-start">
                                {inputErrors.category}
                            </FormControl.Feedback>
                        </FormGroup>
                    </Col>
                    <Col xs="auto">
                        <ButtonIconAdd type="submit" onClick={handleAddCategory} isLoading={isLoading}/>
                    </Col>
                </Row>
            </RemoteTable>
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm)),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableAnalysesCategoryList);
