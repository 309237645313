export const CompliantType = {
    NONE: "NONE",
    COMPLIANT: "COMPLIANT",
    NOT_COMPLIANT: "NOT_COMPLIANT"
}

export const ComplianceMessageKey = {
    NONE: "compliance.none",
    COMPLIANT: "compliance.compliant",
    NOT_COMPLIANT: "compliance.notCompliant"
}

export const CompliantTypeClassName = {
    NONE: "",
    COMPLIANT: "success",
    NOT_COMPLIANT: "danger"
}

export const ComplianceLevelClassName = {
    HIGHEST: "highest",
    HIGH: "high",
    LOW: "low",
    LOWEST: "lowest",
}
