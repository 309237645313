export default class CompanyEntity {
    constructor(companyData) {
        this.id = companyData?.companyId ?? '';
        this.name = companyData?.name ?? '';
        this.contactAddress = companyData?.companyContactDetails?.postalAddress ?? '';
        this.contactPhone = companyData?.companyContactDetails?.phoneNumber ?? '';
        this.contactEmail = companyData?.companyContactDetails?.email ?? '';
        this.billingContactEmail = companyData?.billingContactDetails?.email ?? '';
        this.billingContactFirstname = companyData?.billingContactDetails?.firstname ?? '';
        this.billingContactLastname = companyData?.billingContactDetails?.lastname ?? '';
        this.billingContactPhone = companyData?.billingContactDetails?.phoneNumber ?? '';
        this.reportContactEmail = companyData?.reportContactDetails?.email ?? '';
        this.reportContactFirstname = companyData?.reportContactDetails?.firstname ?? '';
        this.reportContactLastname = companyData?.reportContactDetails?.lastname ?? '';
        this.reportContactPhone = companyData?.reportContactDetails?.phoneNumber ?? '';
        this.website = companyData?.website ?? '';
        this.deliveryAccountNumber = companyData?.deliveryAccountNumber ?? '';
        this.socialName = companyData?.legalInformationsDetails?.socialName ?? '';
        this.rcsNumber = companyData?.legalInformationsDetails?.rcsNumber ?? '';
        this.legalForm = companyData?.legalInformationsDetails?.legalForm ?? '';
        this.socialCapital = companyData?.legalInformationsDetails?.socialCapital ?? '';
        this.vatNumber = companyData?.legalInformationsDetails?.vatNumber ?? '';
        this.currency = companyData?.currency?.currencyCode ?? '';
        this.lotUnicity = companyData?.lotUnicity ?? '';
        this.customTemplates = companyData?.customTemplates ?? '';
    }
}