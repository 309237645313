import React, {useEffect, useState} from 'react';
import {VictoryPie} from "victory";
import variables from '../../assets/styles/variables.scss';
import './charts.scss';
import T from "i18n-react";

const minSize = 100;
const defaultSize = 120;
const defaultThickness = 12;

const PieChart = ({data, size}) => {
    const [dataset, setDataset] = useState([]);
    const [chartSize, setChartSize] = useState(defaultSize);

    useEffect(() => {
        setDataset([
            {x: 0, y: data},
            {x: 1, y: 100 - data},
        ]);
    }, [data]);

    useEffect(() => {
        const newSize = size ?? defaultSize;
        const sizeOrMinSize = Math.max(minSize, newSize);
        setChartSize(sizeOrMinSize);
    }, [size]);

    const isFullPie = data === 100 || data === 0;

    return (
        <div className="position-relative text-center">
            <svg width={chartSize} height={chartSize}>
                <VictoryPie
                    standalone={false}
                    width={chartSize} height={chartSize}
                    colorScale={[variables.primary, variables.purpleGrey]}
                    innerRadius={chartSize / 2}
                    radius={chartSize / 2 - defaultThickness}
                    padAngle={isFullPie ? 0 : 1}
                    cornerRadius={isFullPie ? 0 : 2}
                    labels={[]}
                    data={dataset}
                />
            </svg>
            <div className="position-absolute top-50 start-50 translate-middle pie-value">{T.translate('indicators.percent', {nb: data})}</div>
        </div>
    );
};

export default PieChart;
