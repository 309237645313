import React from 'react'
import Menu from '../menu/menu'
import Page from "./page";
import {connect} from "react-redux";

const PageWithMenuTemplate = ({pageTitle, children, currentUser}) => {
    return (
        <Page pageTitle={pageTitle}>
            <div id="App" className="bg-light h-100">
                <Menu currentUser={currentUser}/>
                <main>
                    {children}
                </main>
            </div>
        </Page>
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
    };
}

export default connect(mapStateToProps)(PageWithMenuTemplate);
