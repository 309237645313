import React from 'react';
import './table.scss'
import CustomTable from "./custom-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SortDirection} from "../../utils/enums/sort-direction";

const LocalTable = ({className, columns, data, groupBy, useDragRow = false}) => {

    const sortableColumns = columns.map(col => {
        return {
            ...col,
            id: col.dataField,
            accessor: col.dataField,
            Header: ({column}) => {
                return (
                    <div className={`column-title d-flex justify-content-between align-items-center ${col.sort ? "sortable-column" : ""}`}
                         onClick={col.sort ? null : e => e.stopPropagation()}>
                        <div>{column.text}</div>
                        {
                            col.sort &&
                            <div className={`order-icon order-${!column.isSorted ? "none" : column.isSortedDesc ? SortDirection.DESC : SortDirection.ASC}`}>
                                <FontAwesomeIcon
                                    className={`order-icon-desc ${column.isSorted && column.isSortedDesc ? "d-none" : "d-block"}`}
                                    icon="chevron-up"/>
                                <FontAwesomeIcon
                                    className={`order-icon-asc ${column.isSorted && !column.isSortedDesc ? "d-none" : "d-block"}`}
                                    icon="chevron-down"/>
                            </div>
                        }
                    </div>
                )
            },
        };
    });

    return (
        <div className={`local-table ${className}`}>
            <CustomTable columns={sortableColumns} data={data} useDragRow={useDragRow} groupBy={groupBy}/>
        </div>
    );
}

export default LocalTable;
