import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import PageWithMenuTemplate from "../../components/template-pages/page-with-menu-template";
import Header from "../../components/header/header";
import T from "i18n-react";
import {Button} from "react-bootstrap-v5";
import TableAnalysesCategoryList from "./components/table-analyses-category-list";
import './analyses-category-page.scss'
import HeaderTextLabel from "../../components/header/header-text-label";
import SearchInput from "../../components/input-label/search-input";

const AnalysesCategoriesPage = () => {
    const [searchText, setSearchText] = useState('');

    const history = useHistory();

    const navigateBackToAnalysesPage = () => {
        history.goBack();
    }

    const handleSearchChange = e => {
        const text = e.target.value;
        if (text.length === 0 || text.length >= 3) {
            setSearchText(text);
        }
    }

    const goBackButton = (
        <Button variant="secondary" className="px-4" onClick={navigateBackToAnalysesPage}>
            {T.translate('analyse.button.goBackToAnalyses')}
        </Button>
    );

    return (
        <PageWithMenuTemplate pageTitle={T.translate('pageTitle.analysisCategories')}>
            <Header>
                <HeaderTextLabel className="me-4" label={T.translate('menu.analyses')} isMain={true}>
                    <h1 className="ps-1">{T.translate('menu.categories')}</h1>
                </HeaderTextLabel>
            </Header>
            <Header button={goBackButton}>
                <div className="search-container">
                    <SearchInput onChange={handleSearchChange}/>
                </div>
            </Header>
            <TableAnalysesCategoryList className="mx-3" searchText={searchText}/>
        </PageWithMenuTemplate>
    );
}

export default AnalysesCategoriesPage;