import {
    ACTUALISE_COUNT_PRODUCT_CONTROL_PLANS_EXPIRED,
    ADD_ANALYSIS_TO_PRODUCT,
    PRODUCT_CHECKBOX_CHECKED,
    PRODUCT_CONTROL_AND_SPECS_GENERATED,
    PRODUCT_CONTROL_DELETED,
    PRODUCT_CONTROL_EDIT,
    PRODUCT_DELETED,
    PRODUCT_DUPLICATED,
    PRODUCT_HIDE_CREATE_MODAL,
    PRODUCT_INCREMENT_SELECTED_ANALYSIS,
    PRODUCT_SAVE_SEARCH_TEXT,
    PRODUCT_SET_INITIAL_WARNING,
    PRODUCT_SHOW_CREATE_MODAL,
    PRODUCT_UPDATE_SELECTED_ANALYSIS
} from "./type";

const productCreate = () => {
    return {
        type: PRODUCT_SHOW_CREATE_MODAL
    }
}

const productDeleted = () => {
    return {
        type: PRODUCT_DELETED
    }
}

const productDuplicated = () => {
    return {
        type: PRODUCT_DUPLICATED
    }
}

const hideCreateModal = () => {
    return {
        type: PRODUCT_HIDE_CREATE_MODAL
    }
}

const editProductControl = () => {
    return {
        type: PRODUCT_CONTROL_EDIT
    }
}

const deleteProductControl = (checked) => {
    return {
        type: PRODUCT_CONTROL_DELETED,
        payload: checked
    }
}

const generateControlAndSpecs = () => {
    return {
        type: PRODUCT_CONTROL_AND_SPECS_GENERATED,
    }
}

const checkCheckBox = (showWarning) => {
    return {
        type: PRODUCT_CHECKBOX_CHECKED,
        payload: showWarning
    }
}

const addAnalysisToProduct = () => {
    return {
        type: ADD_ANALYSIS_TO_PRODUCT
    }
}

const setInitialWarning = (initialWarning) => {
    return {
        type: PRODUCT_SET_INITIAL_WARNING,
        payload: initialWarning
    }
}

const updateSelectedAnalysisNumber = (selectedAnalysisNumber) => {
    return {
        type: PRODUCT_UPDATE_SELECTED_ANALYSIS,
        payload: selectedAnalysisNumber
    }
}

const incrementSelectedAnalysisNumber = (checked) => {
    return {
        type: PRODUCT_INCREMENT_SELECTED_ANALYSIS,
        payload: checked
    }
}

const saveSearchText = (text) => {
    return {
        type: PRODUCT_SAVE_SEARCH_TEXT,
        payload: text
    }
}

const actualizeCountNextMonitoringExpired = (count) => {
    return {
        type: ACTUALISE_COUNT_PRODUCT_CONTROL_PLANS_EXPIRED,
        payload: count
    }
}

export const productActions = {
    productCreate,
    productDuplicated,
    productDeleted,
    hideCreateModal,
    editProductControl,
    deleteProductControl,
    generateControlAndSpecs,
    checkCheckBox,
    addAnalysisToProduct,
    setInitialWarning,
    updateSelectedAnalysisNumber,
    incrementSelectedAnalysisNumber,
    saveSearchText,
    actualizeCountNextMonitoringExpired
};