import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card, Col, Spinner, Image} from "react-bootstrap-v5";
import {FileType} from "../../../components/input-label/file-type";
import {UserRole} from "../../../utils/enums/user-role";
import Validator from "../../../utils/validators/validator";
import {productsImportService} from "../../../services/products-import-service";
import InputFileLabel from "../../../components/input-label/input-file-label";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import iconWarningGreen from "../../../assets/images/icons/info.svg";
import iconWarningRed from "../../../assets/images/icons/info-red.svg";
import T from "i18n-react";
import {connect} from "react-redux";
import template_products_import from '../../../assets/templates-import/template-products-import.xlsx'

const CardImportProducts = ({className, currentUser}) => {

    const companyInitialState = {id: currentUser?.role === UserRole.SUPER_ADMINISTRATOR ? '' : currentUser?.companyId, name: ''};

    const [isLoading, setLoading] = useState(false);
    const [iconVariant, setIconVariant] = useState('');
    const [resultMessage, setResultMessage] = useState('');
    const [company, setCompany] = useState(companyInitialState);
    const [file, setFile] = useState(null);
    const [inputErrors, setInputErrors] = useState({compagny: '', file: ''});

    useEffect(() => {
        resetForm();
    }, [currentUser])


    const resetForm = useCallback(
        () => {
            setCompany(companyInitialState);
            setFile(null);
        }, [],
    );

    const resetErrors = () => {
        setInputErrors({compagny: '', file: ''});
        setResultMessage('');
    }


    const validate =  () => {
        const companyError = Validator.validateRequired(company.id);
        const fileError = Validator.validateRequiredXLSXFile(file);
        const isValid = !fileError && !companyError;
        setInputErrors({
            company: T.translate(companyError).toString(),
            file: T.translate(fileError).toString()
        })
        return isValid;
    }
    const submitForm = e => {
        e.preventDefault();

        resetErrors();
        if (validate()) {
            const formData = new FormData();
            formData.append('file', file);
            importProducts(formData)
        }
    }

    const importProducts = async (formData) => {
        setLoading(true);
        try {
            const data = await productsImportService.importProducts(formData);
            setResultMessage(data.message);
            setIconVariant("ok");
            setLoading(false);
            
        } catch (error) {
            setLoading(false);
            const message = error.response?.data?.message;
            const exceptionClass = error.response?.data?.exceptionClass;

            if (exceptionClass === "ExcelImportException") {
                if (message.includes('File not imported :')) {
                    setResultMessage(message);
                } else {
                    setResultMessage(T.translate('error.imports.wrongFileForImport'));
                }
            } else {
                setResultMessage(error.message);
            }
            
            setIconVariant("danger");
        }
        finally{
            setLoading(false);
        }
    }

    const getImportProductTemplate = () => {
        return template_products_import;
    }


    return (
        <Col sm={12} lg={8}>
            <Card className={`card-indicators h-100 ${className}`}>
                <Card.Header className="d-flex justify-content-between align-items-end pb-0">
                    <Card.Title>{T.translate(`imports.products.title`)}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Col sm={12} lg={12} >
                        <div className='mb-3'>
                            <a href={getImportProductTemplate()} rel="noreferrer" target="_blank">
                                <FontAwesomeIcon icon="download" className="icon"/>
                                {T.translate(`imports.products.downloadTemplate`)}
                            </a>
                        </div>
                    </Col>
                    <Col xs={12} lg={12} className='mt-3'>
                        <InputFileLabel id="file" className="mb-3" label={T.translate('imports.importFile')} required={true}
                                        onChange={val => setFile(val)} error={inputErrors.file} accept={FileType.OOXML} inModal={false}/>
                    </Col>
                    <Col xs={12} lg={4} >
                        <Button size="sm" variant="primary" onClick={submitForm} className="mb-3 mt-2 btn-import btn-success"
                                disabled={isLoading}>
                            {isLoading ? <Spinner as="span" size="sm" animation="border"/> : ""}
                            {T.translate('form.button.import')}
                            <FontAwesomeIcon icon="upload" className='btn-icon' />
                        </Button>
                    </Col >
                    {!isLoading && resultMessage ? 
                        <Col xs={12} lg={12} className='d-flex flex-auto resultContainer' >
                            <Image src={iconVariant === "danger" ? iconWarningRed : iconWarningGreen} />
                            <div className={iconVariant === "danger" ? "error" : "infos"}>{resultMessage}</div>
                        </Col> : ""
                    }
                    
                </Card.Body>
            </Card>

        </Col>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser
    }
}

export default connect(mapStateToProps)(CardImportProducts);