export const defaultDebounceDelay = 250;

const timerMap = new Map();

export const debounceFunc = (func, idFunc, delay = defaultDebounceDelay) => {
    let timer;
    if (timerMap.has(idFunc)) {
        timer = timerMap.get(idFunc);
    }
    return function (...args) {
        const context = this;
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            func.apply(context, args);
        }, delay)
        timerMap.set(idFunc, timer);
    }
}

export const cancelDebounce = (idFunc) => {
    if (timerMap.has(idFunc)) {
        let timer = timerMap.get(idFunc);
        clearTimeout(timer);
    }
}