import {privateAPI, publicAPI} from './api/api';
import T from "i18n-react";
import {ApiErrorHandler} from "./api/api-error";

const login = async (username, password) => {
    try {
        const response = await privateAPI.post(`/login`, {username, password});
        return response.data;
    } catch (error) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
            error.message = T.translate('error.auth')
        } else {
            error.message = ApiErrorHandler.getErrorMessage(error);
        }
        throw error;
    }
}

const changePasswordRequest = async (email, token) => {
    try {
        const response = await publicAPI.post(`/accounts/change-password-request/${email}?token=${token}`);
        return response.data;
    } catch (error) {
        const statusCode = error.response.status;
        if (statusCode === 404) {
            error.message = T.translate('error.changePasswordRequest.noUserForEmail')
        } else {
            error.message = ApiErrorHandler.getErrorMessage(error);
        }
        throw error;
    }
}

const changePassword = async (token, onlyCGU, newPassword, newPasswordConfirmation) => {
    try {
        const response = await publicAPI.post(`/accounts/change-password/${token}`, {onlyCGU, newPassword, newPasswordConfirmation});
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const logout = async () => {
    await privateAPI.post('/logout');
}

export const authService = {
    login,
    logout,
    changePasswordRequest,
    changePassword
};
