import React, {useState} from 'react';
import {Image} from "react-bootstrap-v5";
import iconCalendar from "../../../assets/images/icons/calendar.svg";
import Moment from "react-moment";
import i18nController from "../../../utils/i18n/i18n-controller";
import moment from "moment";
import DatePicker from "react-date-picker";
import iconCalendarSuccess from "../../../assets/images/icons/calendar-green.svg";
import T from "i18n-react";
import AppDropdown from "../../../components/dropdowns/app-dropdown";

const ClosingDateComponent = ({isMainDateEditable, mainDate, secondaryDate, onMainDateChange}) => {
    const [isDatepickerOpened, setIsDatepickerOpened] = useState(false);

    const handleDateChange = newDate => {
        let newDateFormatted = !newDate ? newDate : moment(newDate).format('yyyy-MM-DD')
        onMainDateChange(newDateFormatted);
    }

    const handleClick = () => {
        if (isMainDateEditable) {
            setIsDatepickerOpened(true);
        }
    }

    const handleDatepickerClose = () => {
        setIsDatepickerOpened(false);
    }

    const dateElement = (date, icon) => {
        return (
            <div>
                <Image src={icon} className="me-2"/>
                {!date ? "-" : <Moment>{date}</Moment>}
            </div>
        )
    }

    return (
        <div className={`closing-date-component position-relative ms-2 mt-1 ${isMainDateEditable || !!secondaryDate ? "cursor-pointer" : ""}`} onClick={handleClick}>
            <AppDropdown title={dateElement(mainDate, isMainDateEditable || !mainDate ? iconCalendar : iconCalendarSuccess)} enabled={!!secondaryDate} menuClassName="px-2">
                <div className="label py-1">{T.translate('lot.label.endControlDate')}</div>
                {dateElement(secondaryDate, iconCalendar)}
            </AppDropdown>
            <DatePicker
                className={`date-picker position-absolute ${!mainDate && "empty-date"}`}
                locale={i18nController.getInstance().getLanguageCode()}
                value={!mainDate ? null : moment(mainDate, 'yyyy-MM-DD').toDate()}
                onChange={handleDateChange}
                onCalendarClose={handleDatepickerClose}
                isOpen={isDatepickerOpened}
            />
        </div>
    );
};

export default ClosingDateComponent;
