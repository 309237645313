import React from 'react';
import './next-monitoring-date-component.scss'
import moment from "moment/moment";
import Moment from "react-moment";
import T from "i18n-react";
import {CUSTOMIZED_EMPTY_NEXT_MONITORING_DATE} from "../../services/product-service";

const NextMonitoringMomentComponent = ({value}) => {
    const isCustomizedEmptyValue = value === CUSTOMIZED_EMPTY_NEXT_MONITORING_DATE;

    let colorClass = 'monitoring-inactive';

    if (!isCustomizedEmptyValue) {
        const today = moment(moment(new Date()).format('yyyy-MM-DD'));
        const date = moment(value, 'yyyy-MM-DD');
        if (today.isBefore(date)) colorClass = 'monitoring-up-to-date'
        else colorClass = 'monitoring-late';
    }

    return (
        !value ? "" :
            <div className="status-container">
                <div className="status-component">
                    <div className={`${colorClass}`} style={{width: "90px"}}>
                        {isCustomizedEmptyValue ? <span>&nbsp;</span>
                            : <Moment
                                format={T.translate('i18n.format.dateMonthAndYear').toString()}>{value}</Moment>}
                    </div>
                </div>
            </div>
    );
}

export default NextMonitoringMomentComponent;