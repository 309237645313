import {USER_DELETED, USER_HIDE_CREATE_OR_EDIT_MODAL, USER_SHOW_CREATE_MODAL, USER_SHOW_EDIT_MODAL} from "./type";

const userCreate = () => {
    return {
        type: USER_SHOW_CREATE_MODAL
    }
}

const userEdit = user => {
    return {
        type: USER_SHOW_EDIT_MODAL,
        payload: user
    }
}

const userDeleted = () => {
    return {
        type: USER_DELETED
    }
}

const hideCreateOrEditModal = () => {
    return {
        type: USER_HIDE_CREATE_OR_EDIT_MODAL
    }
}

export const userActions = {
    userCreate,
    userEdit,
    userDeleted,
    hideCreateOrEditModal
};