import ButtonClose from "../buttons/button-close";
import T from "i18n-react";
import React from "react";
import {Col, Form, Row} from "react-bootstrap-v5";
import {InputMaxLength} from "../../utils/enums/input-max-length";

const PaneSelectedAnalyseElement = ({analyse, useDetails = false, onAnalyseChanged, onUnselectItem}) => {

    const handleChanges = changes => {
        const newAnalyse = {
            ...analyse,
            ...changes
        }
        onAnalyseChanged(newAnalyse);
    }

    return (
        <div className={`selected-analyse-element p-3 d-inline-flex flex-column justify-content-between m-2 position-relative ${useDetails ? "use-details" : ""}`}>
            <ButtonClose onClick={onUnselectItem} className="position-absolute"/>
            <div className="text-start">
                <div className="title p-0">{T.translate('table.columns.name')}</div>
                <div className="value p-0">{analyse.analysisInfo.name}</div>
            </div>
            <div className="text-start">
                <div className="title p-0">{T.translate('table.columns.method')}</div>
                <div className="value p-0">{analyse.analysisInfo.method}</div>
            </div>
            <div className="text-start">
                <div className="title p-0">{T.translate('table.columns.technique')}</div>
                <div className="value p-0">{analyse.analysisInfo.technique ?? "-"}</div>
            </div>
            {useDetails && (
                <React.Fragment>
                    <Row>
                        <Col>
                            <div className="text-start">
                                <div className="title p-0">{T.translate('table.columns.price')}</div>
                                <div className="value p-0">
                                    <Form.Control type="number" placeholder={T.translate('table.columns.price')} min={0}
                                                  value={analyse.price} onChange={e => handleChanges({price: e.target.value})} maxLength={InputMaxLength.STANDARD_TEXT}/>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="text-start">
                                <div className="title p-0">{T.translate('table.columns.delayInDays')}</div>
                                <div className="value p-0">
                                    <Form.Control type="number" placeholder={T.translate('table.columns.delayInDays')} min={0}
                                                  value={analyse.delayInDays} onChange={e => handleChanges({delayInDays: e.target.value})} maxLength={InputMaxLength.STANDARD_TEXT}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-start">
                                <div className="title p-0">{T.translate('table.columns.quantityInGram')}</div>
                                <div className="value p-0">
                                    <Form.Control type="number" placeholder={T.translate('table.columns.quantityInGram')} min={0}
                                                  value={analyse.quantityInGram} onChange={e => handleChanges({quantityInGram: e.target.value})} maxLength={InputMaxLength.STANDARD_TEXT}/>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="text-start">
                                <div className="title p-0">{T.translate('table.columns.reference')}</div>
                                <div className="value p-0">
                                    <Form.Control type="text" placeholder={T.translate('table.columns.reference')}
                                                  value={analyse.reference} onChange={e => handleChanges({reference: e.target.value})} maxLength={InputMaxLength.STANDARD_TEXT}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </React.Fragment>
            )}
        </div>
    );
}

export default PaneSelectedAnalyseElement;