import React, {useState} from 'react';
import iconCommentFilled from "../../../assets/images/icons/comment-filled.svg";
import iconComment from "../../../assets/images/icons/comment.svg";
import {Button, Image} from "react-bootstrap-v5";
import {InputMaxLength} from "../../../utils/enums/input-max-length";
import T from "i18n-react";
import EditableText from "../../../components/editable-text/editable-text";

const CommentButton = ({comment, id, canEdit, onSubmit, visible = true}) => {
    // Quand on tape sur la barre d'espace, cela déclenche la méthode onClick du bouton.
    // Ce booléen est la pour empêcher ce comportement
    let isSpacePressed = false;

    const [showComment, setShowComment] = useState(!!comment);

    const handleKeyPressed = event => {
        if (event.key === " ") {
            isSpacePressed = true;
        }
    }

    const handleToggle = event => {
        if (!visible) return;

        if (isSpacePressed) {
            isSpacePressed = false;
            event.preventDefault();
            return;
        }
        setShowComment(prevState => !prevState);
    }

    const handleSubmit = c => {
        let realComment = c.trim();
        if (realComment.length > 0 || comment.length > 0) {
            onSubmit(c);
        }
    }

    return (
        <Button size="sm" className={`comment position-relative ${visible ? "" : "invisible"}`} onClick={handleToggle} onKeyDown={handleKeyPressed}>
            <Image src={comment ? iconCommentFilled : iconComment}/>
            <div className={`tooltip-analysis-comment ${showComment ? "cursor-default" : "d-none"} ${comment ? "" : "empty"}`} onClick={e => e.stopPropagation()}>
                {canEdit ? (
                    <EditableText id={`input-comment-${id}`} maxLength={InputMaxLength.SMALL_TEXT}
                                  shouldSubmitOnBlur={true}
                                  placeholder={`${T.translate('form.label.comment')}...`}
                                  className={comment ? "" : "empty"} adjustWidthWithText={true}
                                  defaultValue={comment} onSubmit={handleSubmit}/>
                ) : (
                    <div className="readonly-comment custom-horizontal-scrollbar">{comment}</div>
                )}
            </div>
        </Button>
    );
};

export default CommentButton;
