import * as EmailValidator from 'email-validator';
import moment from "moment";

const isNullOrEmpty = (value) => !value || value.trim().length === 0;

const isPasswordWeak = (password) => {
    const strongPasswordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{12,}$/;
    return !password || !strongPasswordRegex.test(password);
}

const isNotSamePassword = (password, confirmPassword) => password !== confirmPassword;

const isNotValidEmail = (email) => !EmailValidator.validate(email);

const isNullOrEmptyList = (list) => !list || list.length === 0;

const validateRequired = text => {
    if (isNullOrEmpty(text)) {
        return 'form.validation.required';
    }
    return '';
}

const validateRequiredObject = obj => {
    if (!obj) {
        return 'form.validation.required';
    }
    return '';
}

const validateEmail = email => {
    if (!!email && isNotValidEmail(email)) {
        return 'form.validation.invalidEmail';
    }
    return '';
}

const validateRequiredEmail = email => {
    const requiredValidation = validateRequired(email)
    return !!requiredValidation ? requiredValidation : validateEmail(email);
}

const validatePassword = password => {
    if (isPasswordWeak(password)) {
        return 'form.validation.weakPassword';
    }
    return '';
}

const validateRequiredPassword = password => {
    const requiredValidation = validateRequired(password)
    return !!requiredValidation ? requiredValidation : validatePassword(password);
}

const validateSamePassword = (password, confirmPassword) => {
    if (isNotSamePassword(password, confirmPassword)) {
        return 'form.validation.passwordNotCorresponding';
    }
    return '';
}

const validateConfirmPassword = (password, confirmPassword) => {
    const requiredValidation = validateRequired(confirmPassword);
    return !!requiredValidation ? requiredValidation : validateSamePassword(password, confirmPassword);
}

const validateNonEmptyList = list => {
    if (!!isNullOrEmptyList(list)) {
        return 'form.validation.emptyList';
    }
    return '';
}

const validatePositiveNumber = n => {
    if (!!n && n < 0) {
        return 'form.validation.negativeNumber';
    }
    return '';
}

const validateRequiredFile = file => {
    if (!file) {
        return 'form.validation.required';
    }
    return '';
}

const validatePDFFile = file => {
    if (!file || file.type !== "application/pdf") {
        return 'form.validation.shouldBePDF';
    }
    return '';
}

const validateRequiredPDFFile = file => {
    const requiredValidation = validateRequiredFile(file)
    return !!requiredValidation ? requiredValidation : validatePDFFile(file);
}

const validateXLSXFile = file => {
    if (!file || file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        return 'form.validation.shouldBeXLSX';
    }
    return '';
}

const validateRequiredXLSXFile = file => {
    const requiredValidation = validateRequiredFile(file)
    return !!requiredValidation ? requiredValidation : validateXLSXFile(file);
}

const validateChecked = checked => {
    if (checked === false) {
        return 'form.validation.shouldBeChecked'
    }
    return '';
}

const validateDateRange = (startDate, endDate) => {
    if (!!startDate || !!endDate) {
        const startMoment = moment(startDate);
        const endMoment = moment(endDate);
        if (startMoment.diff(endMoment) > 0) {
            return 'form.validation.dateRange'
        }
    }
    return '';
}

const validateDateRangeWithBothStartAndEnd = (startDate, endDate) => {
    if ((!!startDate && !endDate) || (!startDate && !!endDate)) {
        return 'form.validation.dateRangeStartOrEndIsNull'
    }
    return validateDateRange(startDate, endDate);
}

const validateDateRangeWithStartAndEndNotNull = (startDate, endDate) => {
    if (!startDate || !endDate) {
        return 'form.validation.dateRangeStartOrEndAreNull';
    }
    return validateDateRange(startDate, endDate);
}


const validateTimeRange = (startTime, endTime, format) => {   
    if (startTime && endTime) {
        const startMoment = moment(startTime, format, true);
        const endMoment = moment(endTime, format, true);
        
        if (!startMoment.isValid() || !endMoment.isValid()) {
            return 'form.validation.timeRange';
        }
    }
    
    return '';
}

const validateTimeRangeWithBothStartAndEnd = (startTime, endTime, format) => {
    if (!startTime || !endTime) {
        return 'form.validation.timeRangeStartOrEndIsNull';
    }
    return validateTimeRange(startTime, endTime, format);
}


const Validator = {
    isNullOrEmpty,
    isPasswordWeak,
    isNotSamePassword,
    isNotValidEmail,
    isNullOrEmptyList,
    validateRequired,
    validateRequiredObject,
    validateEmail,
    validateRequiredEmail,
    validatePassword,
    validateRequiredPassword,
    validateSamePassword,
    validateConfirmPassword,
    validateNonEmptyList,
    validatePositiveNumber,
    validateRequiredFile,
    validateRequiredPDFFile,
    validateRequiredXLSXFile,
    validateChecked,
    validateDateRange,
    validateDateRangeWithBothStartAndEnd,
    validateDateRangeWithStartAndEndNotNull,
    validateTimeRange,
    validateTimeRangeWithBothStartAndEnd
}

export default Validator;
