import React, {useState} from 'react';
import {FormControl, FormText} from "react-bootstrap-v5";
import {FileType} from "./file-type";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import T from "i18n-react";

const InputFile = ({className, onChange, error, defaultFileName, readOnly, accept = FileType.ALL}) => {

    const [fileName, setFileName] = useState(defaultFileName ?? '');

    const hiddenFileInput = React.useRef(null);

    const handleClick = () => {
        if (readOnly) return;
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        setFileName(fileUploaded?.name ?? '');
        onChange(fileUploaded);
    };

    return (
        <div className={`input-file input-group cursor-pointer ${className} ${!!error ? "is-invalid" : ""} ${!!readOnly ? "readonly" : ""}`} onClick={handleClick}>
            <FormText className="input-group-text m-0"><FontAwesomeIcon icon="file-pdf"/></FormText>
            <input ref={hiddenFileInput} type="file" onChange={handleChange} accept={accept} hidden={true}/>
            <FormControl type="text" value={fileName} placeholder={T.translate('form.label.chooseFile')} readOnly={true}
                         className="cursor-pointer" isInvalid={!!error}/>
        </div>
    );
}

export default InputFile;