import React, {useCallback, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {alertActions} from "../../../redux/alert/actions-alert";
import {confirmDialogActions} from "../../../redux/confirm-dialog/actions-confirm-dialog";
import RemoteTable from "../../../components/table/remote-table";
import {Button, Card, Image, OverlayTrigger, Tooltip} from "react-bootstrap-v5";
import {useHistory} from "react-router-dom";
import T from "i18n-react";
import {UserRole} from "../../../utils/enums/user-role";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {Routes} from "../../../router/router-constants";
import StatusComponent from "../../../components/status-component/status-component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {lotActions} from "../../../redux/lot/actions-lot";
import {lotService} from "../../../services/lot-service";
import {StatusLot, StatusLotClassname} from "../../../utils/enums/status-lot";
import {ClosingReason, ClosingReasonClassname, ClosingReasonMessageKey} from "../../../utils/enums/closing-reason";
import Moment from "react-moment";
import SelectLabel from "../../../components/input-label/select-label";
import "../lots-page.scss"
import I18NController from "../../../utils/i18n/i18n-controller";
import {SortDirection} from "../../../utils/enums/sort-direction";
import SubStatusComponent from "../../../components/status-component/sub-status-component";
import iconResultsWithoutCompliance from "../../../assets/images/icons/results-without-compliance.svg";

const TableLotList = ({className, showToast, currentUser, lotDeleted, showConfirmDialog, progress, searchTextFromReducer}) => {

    const history = useHistory()

    const [statusLot, setStatusLot] = useState("");
    const [closingReason, setClosingReason] = useState("");
    const [searchText, setSearchText] = useState(searchTextFromReducer);

    useEffect(() => {
        setSearchText(searchTextFromReducer);
    }, [searchTextFromReducer]);

    const statusLotsOptions = () => {
        let options = [];
        options.push(<option key="status" value="">{T.translate('table.columns.status').toString()}</option>)


        for (const status in StatusLot) {
            if (StatusLotClassname[status] !== StatusLotClassname.CLOSED) {
                options.push(
                    <option key={status} className={StatusLotClassname[status]}
                            value={StatusLot[status]}
                            selected={StatusLot[status] === statusLot && ClosingReason[closingReason] === ""}>
                        {T.translate('status.' + StatusLotClassname[status]).toString()}
                    </option>
                );
            }
        }
        for (const closingReason in ClosingReason) {
            options.push(
                <option key={closingReason} className={ClosingReasonClassname[closingReason]} selected={ClosingReason[closingReason] === closingReason}
                        value={ClosingReason[closingReason]}>{T.translate(ClosingReasonMessageKey[closingReason]).toString()}
                </option>
            );
        }

        return options;
    }

    const handleChangeLotStatus = (statusFilter) => {
        if (statusFilter === ClosingReason.COMPLIANT || statusFilter === ClosingReason.NOT_COMPLIANT || statusFilter === ClosingReason.INTERRUPTED) {
            setStatusLot(StatusLot.CLOSED);
            setClosingReason(statusFilter);
        } else {
            setStatusLot(statusFilter);
            setClosingReason("");
        }
    }

    const handleClickStatus = e => {
        e.stopPropagation();
    }

    let columns = [
        {
            dataField: T.translate('table.fields.lot.category').toString(),
            text: T.translate('table.columns.category').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.lot.articleCode').toString(),
            text: T.translate('table.columns.product.articleCode'),
            sort: true
        },
        {
            dataField: T.translate('table.fields.lot.productName').toString(),
            text: T.translate('table.columns.lot.productName').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.lot.creationDate').toString(),
            text: T.translate('table.columns.lot.creationDateShort').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.lot.lotNumber').toString(),
            text: T.translate('table.columns.lot.lotNumber').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.lot.supplier').toString(),
            text: T.translate('table.columns.supplier').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.lot.supplierLotNumber').toString(),
            text: T.translate('table.columns.lot.supplierLotNumber').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.lot.endControlDate').toString(),
            text: T.translate('table.columns.lot.endControlDate').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.lot.closingDate').toString(),
            text: T.translate('table.columns.lot.closingDateShort').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.lot.status').toString(),
            text: <SelectLabel id={T.translate('table.columns.status')} className="filter-statusLot"
                               defaultValue={closingReason !== "" ? closingReason : statusLot}
                               options={statusLotsOptions()}
                               onClick={e => handleClickStatus(e)}
                               onChange={e => handleChangeLotStatus(e.target.value)}/>,
            sort: true
        },
        {
            dataField: T.translate('table.fields.lot.subStatus').toString(),
            text: T.translate('table.columns.subStatus').toString(),
            sort: statusLot === StatusLot.IN_PROGRESS
        },
        {
            dataField: T.translate('table.fields.lot.dluo').toString(),
            text: T.translate('table.columns.lot.dluo').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.price').toString(),
            text: T.translate('table.columns.cost').toString(),
        },
        {
            dataField: T.translate('table.fields.actions').toString(),
            text: T.translate('table.columns.actions').toString()
        }
    ];

    if (currentUser.role === UserRole.SUPER_ADMINISTRATOR) {
        columns = [
            {
                dataField: T.translate('table.fields.lot.companyName').toString(),
                text: T.translate('table.columns.company').toString(),
                sort: true
            },
            ...columns
        ];
    }

    const onFetchData = useCallback(async (page, size, sortedOrder, sortedField, searchText) => {
            const deleteLot = async lot => {
                if (lot.status === StatusLot.TO_CLOSE || lot.status === StatusLot.CLOSED) {
                    showToast(T.translate('alert.errorTitle'), T.translate('error.lot.hasGeneratedCommand'), ToastClass.ERROR);
                    return;
                }
                try {
                    await lotService.deleteLot(lot.lotId);
                    lotDeleted();
                    showToast(T.translate('alert.successTitle'), T.translate('lot.successDeleteLot', {lotName: lot.name}), ToastClass.SUCCESS);
                } catch (error) {
                    showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
                }
            }

            const handleDeleteLot = async (e, lot) => {
                e.stopPropagation();
                showConfirmDialog(
                    T.translate('dialog.title.confirmDelete'),
                    T.translate('dialog.confirmDeleteLot', {lotName: lot.name}),
                    () => {
                    },
                    () => deleteLot(lot))
            }

            const handleEditLot = (e, lot) => {
                e.stopPropagation();
                history.push(`${Routes.LOTS_PAGE}/${lot.lotId}`)
            }

            const handleEditLotRightClick = (e, lot) => {
                e.preventDefault();
                window.open(`${Routes.LOTS_PAGE}/${lot.lotId}`, '_blank', 'noopener,noreferrer');
            }

            const tooltip = (id) => {
                return (<Tooltip id={`tooltip-sub-status-component-${id}`} className="tooltip-sub-status-component d-flex align-items-center">
                    {T.translate('tooltip.newResults').toUpperCase()}
                </Tooltip>)
            }

            const completeElements = allElements => {
                return allElements.map(element => {
                    return {
                        ...element,
                        id: element.lotId,
                        creationDate: !!element.creationDate ? <Moment>{element.creationDate}</Moment> : "-",
                        endControlDate: !!element.endControlDate ? <Moment>{element.endControlDate}</Moment> : "-",
                        closingDate: !!element.closingInfo?.date ? <Moment>{element.closingInfo.date}</Moment> : "-",
                        status: <StatusComponent
                            type={element.status === StatusLot.CLOSED ? ClosingReasonClassname[element.closingInfo.reason] : StatusLotClassname[element.status]}/>,
                        subStatus: (
                            (
                                !!element.closingInfo?.notCompliantReason &&
                                <SubStatusComponent subStatus={element.closingInfo?.notCompliantReason} id={element.lotId}/>
                            )
                            ||
                            (
                                element.status === StatusLot.IN_PROGRESS && element.resultWithoutCompliance && <div className="sub-status-component">
                                    <OverlayTrigger trigger={['hover', 'focus']} key={`tooltip-trigger-sub-status-component-${element.lotId}`} placement="right"
                                                    overlay={tooltip(element.lotId)}>
                                        <Image src={iconResultsWithoutCompliance}></Image>
                                    </OverlayTrigger>
                                </div>
                            )
                        ),
                        dluo: !!element.dluo ? <Moment>{element.dluo}</Moment> : "-",
                        price: <div
                            className="fw-bold text-end pe-2">{I18NController.getInstance().formatPrice(element.controlPrice, currentUser.currency, currentUser.language)}</div>,
                        actions: (
                            <div className="actions">
                                <Button size="sm" className="edit" onClick={e => handleEditLot(e, element)}
                                        onContextMenu={e => handleEditLotRightClick(e, element)}>
                                    <FontAwesomeIcon icon="edit"/>
                                </Button>
                                <Button size="sm"
                                        className={`delete ms-1 ${currentUser.role === UserRole.COMPANY_ADMINISTRATOR || currentUser.role === UserRole.SUPER_ADMINISTRATOR || currentUser.role === UserRole.SUPERVISOR ? "" : "d-none"}`}
                                        onClick={e => handleDeleteLot(e, element)}>
                                    <FontAwesomeIcon icon="times"/>
                                </Button>
                            </div>
                        )
                    }
                })
            }
            let effectiveSortedField = sortedField;
            if (sortedField === T.translate('table.fields.lot.closingDate').toString()) {
                effectiveSortedField = T.translate('table.fields.lot.closingDateBack').toString()
            }
            const data =
                !!statusLot
                    ? await lotService.getAllByStatus(statusLot, closingReason, page, size, sortedOrder, effectiveSortedField, searchText)
                    : await lotService.getAllAndCancelPreviousCall(page, size, sortedOrder, effectiveSortedField, searchText);

            const elements = completeElements(data.content);
            return {
                ...data,
                elements: elements
            };

        }, [showToast, showConfirmDialog, lotDeleted, currentUser, history, statusLot, closingReason]
    )

    return (
        <Card className={className}>
            <RemoteTable id="table-lot-list"
                         defaultSortedField={T.translate('table.fields.lot.creationDate')} defaultSortedOrder={SortDirection.DESC}
                         columns={columns} onFetchData={onFetchData} reload={progress} searchText={searchText}/>
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        progress: state.lotReducer.progress,
        searchTextFromReducer: state.lotReducer.searchText
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        lotDeleted: () => dispatch(lotActions.lotDeleted()),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableLotList);