import React, {useEffect, useState} from 'react';
import T from 'i18n-react'
import CardTabsInfos from "../../../components/card-infos/card-tabs-infos";
import EditableTextArea from "../../../components/editable-text/editable-textarea";
import EditableText from "../../../components/editable-text/editable-text";
import {connect} from "react-redux";
import {alertActions} from "../../../redux/alert/actions-alert";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {UserRole} from "../../../utils/enums/user-role";
import {confirmDialogActions} from "../../../redux/confirm-dialog/actions-confirm-dialog";
import CardTabs from "../../../components/card-infos/card-tabs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Validator from "../../../utils/validators/validator";

const CardLaboratoryInfosAndComments = ({className, laboratory, showToast, currentUser, onEditValue, onEditComment}) => {

    const [emailAddress, setEmailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [inputErrors, setInputErrors] = useState({email: '', phone: ''});

    useEffect(() => setEmailAddress(laboratory.email), [laboratory.email]);
    useEffect(() => setPhoneNumber(laboratory.phone), [laboratory.phone]);

    const handleAddressChange = async address => {
        onEditValue("address", {address}, address);
    }

    const handleContactChange = async contact => {
        onEditValue("contact", {contact}, contact);
    }

    const handlePhoneChange = async phone => {
        try {
            setInputErrors({...inputErrors, phone: ''});
            setPhoneNumber(phone);
            const response = await onEditValue("phone", {phoneNumber: phone}, phone);
            const phoneFormatted = response?.data?.phoneNumber;
            setPhoneNumber(phoneFormatted);
            showToast(T.translate('alert.successTitle'), T.translate('laboratory.successEditPhone'), ToastClass.SUCCESS);
        } catch (error) {
            if (error.response.data.entityIdField === "phoneNumber") {
                setInputErrors({...inputErrors, phone: error.message});
                return;
            }
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleRoleChange = async role => {
        onEditValue("role", {functionName: role}, role);
    }

    const handleEmailChange = async email => {
        setInputErrors({...inputErrors, email: ''});
        setEmailAddress(email);
        const emailError = Validator.validateEmail(email);
        if (!!emailError) {
            setInputErrors({...inputErrors, email: T.translate(emailError).toString()});
            return;
        }
        onEditValue("email", {email}, email);
    }

    const handleCustomerNumberChange = async customerNumber => {
        onEditValue("customerNumber", {customerNumber}, customerNumber);
    }

    const handleCancelChangePhone = () => {
        setInputErrors({...inputErrors, phone: ''});
    }

    const handleCancelChangeEmail = () => {
        setInputErrors({...inputErrors, email: ''});
    }

    const canEdit = currentUser.role !== UserRole.VISITOR;

    const dataInfos = [
        {
            label: T.translate('table.columns.address'),
            component: !canEdit ? <div className="enable-line-break">{laboratory.address}</div> :
                <EditableTextArea id="laboratoryAddress" defaultValue={laboratory.address} onSubmit={handleAddressChange} shouldSubmitOnBlur/>
        },
        {
            label: T.translate('table.columns.contact'),
            component: !canEdit ? <div className="enable-line-break">{laboratory.contact}</div> :
                <EditableText id="laboratoryContact" defaultValue={laboratory.contact} onSubmit={handleContactChange} shouldSubmitOnBlur/>
        },
        {
            label: T.translate('table.columns.phone'),
            component: !canEdit ? <div className="enable-line-break">{phoneNumber}</div> :
                <EditableText id="laboratoryPhone" defaultValue={phoneNumber} onSubmit={handlePhoneChange} onCancel={handleCancelChangePhone}
                              error={inputErrors.phone} shouldSubmitOnBlur/>
        },
        {
            label: T.translate('table.columns.role'),
            component: !canEdit ? <div className="enable-line-break">{laboratory.role}</div> :
                <EditableText id="laboratoryRole" defaultValue={laboratory.role} onSubmit={handleRoleChange} shouldSubmitOnBlur/>
        },
        {
            label: T.translate('table.columns.email'),
            component: !canEdit ? <div className="enable-line-break">{emailAddress}</div> :
                <EditableText id="laboratoryEmail" defaultValue={emailAddress} onSubmit={handleEmailChange} onCancel={handleCancelChangeEmail}
                              error={inputErrors.email} shouldSubmitOnBlur/>
        },
        {
            label: T.translate('table.columns.customerNumber'),
            component: currentUser.role === UserRole.VISITOR ? <div className="enable-line-break">{laboratory.customerNumber}</div> :
                <EditableText id="customerNumber" defaultValue={laboratory.customerNumber} onSubmit={handleCustomerNumberChange}
                              error={inputErrors.customerNumber} shouldSubmitOnBlur/>
        }
    ];

    return (
        <CardTabs className={className} tabs={[
            {
                key: "infos",
                title: T.translate('title.cardInfos'),
                content: <CardTabsInfos data={dataInfos}/>,
            },
            {
                key: "comments",
                title: (
                    <div>
                        {T.translate('title.comments')}
                        {!!laboratory.comment && <FontAwesomeIcon icon="comment" className="ms-2"/>}
                    </div>
                ),
                content: !canEdit ? (
                    <div className="enable-line-break p-3">{laboratory.comment}</div>
                ) : (
                    <EditableTextArea id="productComment" className="p-3" defaultValue={laboratory.comment} onSubmit={onEditComment} shouldSubmitOnBlur/>
                ),
            },
        ]}/>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardLaboratoryInfosAndComments);
