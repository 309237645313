import {ANALYSE_HISTORY_HIDE_HISTORY_MODAL, ANALYSE_HISTORY_SHOW_HISTORY_MODAL} from "./type";

const initialStateAnalyse = {
    showHistoryModal: false,
    analyseId: '',
    productId: '',
    lotId: '',
    parameterName: '',
}

const AnalyseHistoryReducer = (state = initialStateAnalyse, action) => {
    switch (action.type) {
        case ANALYSE_HISTORY_SHOW_HISTORY_MODAL:
            return {
                ...state,
                showHistoryModal: true,
                analyseId: action.analyseId,
                parameterName: action.parameterName,
                productId: action.productId,
                lotId: action.lotId
            }
        case ANALYSE_HISTORY_HIDE_HISTORY_MODAL:
            return {
                ...state,
                showHistoryModal: false,
                analyseId: '',
                productId: '',
                lotId: ''
            }
        default:
            return state;
    }
}

export default AnalyseHistoryReducer;