import {
    ACTUALISE_COUNT_EXPIRED_DOCUMENTS,
    DOCUMENT_ADDED,
    DOCUMENT_DELETED,
    DOCUMENT_GENERATED,
    DOCUMENT_HIDE_CREATE_OR_EDIT_MODAL,
    DOCUMENT_SHOW_CREATE_MODAL,
    DOCUMENT_SHOW_EDIT_MODAL,
    INCREMENT_PROGRESS
} from './type';

const documentAdd = () => {
    return {
        type: DOCUMENT_ADDED,
    }
}

const documentCreate = (companyId, entityId, documentType) => {
    return {
        type: DOCUMENT_SHOW_CREATE_MODAL,
        companyId,
        entityId,
        documentType,
    };
}

const documentEdit = (doc) => {
    return {
        type: DOCUMENT_SHOW_EDIT_MODAL,
        document: doc
    };
}

const documentDeleted = () => {
    return {
        type: DOCUMENT_DELETED
    };
}

const hideCreateOrEditDocumentModal = () => {
    return {
        type: DOCUMENT_HIDE_CREATE_OR_EDIT_MODAL
    }
}

const generateDocument = () => {
    return {
        type: DOCUMENT_GENERATED
    }
}

const incrementProgress = () => {
    return {
        type: INCREMENT_PROGRESS
    }
}

const actualiseCountExpiredDocuments = (count) => {
    return {
        type: ACTUALISE_COUNT_EXPIRED_DOCUMENTS,
        payload: count
    }
}
export const documentActions = {
    documentAdd,
    documentCreate,
    documentEdit,
    documentDeleted,
    hideCreateOrEditDocumentModal,
    generateDocument,
    incrementProgress,
    actualiseCountExpiredDocuments
};