import {
    ACTUALISE_COUNT_EXPIRED_DOCUMENTS,
    DOCUMENT_ADDED,
    DOCUMENT_DELETED,
    DOCUMENT_GENERATED,
    DOCUMENT_HIDE_CREATE_OR_EDIT_MODAL,
    DOCUMENT_SHOW_CREATE_MODAL,
    DOCUMENT_SHOW_EDIT_MODAL,
    INCREMENT_PROGRESS
} from './type';

const initialStateConfirmDialog = {
    showCreateOrEditModal: false,
    isCreationMode: true,
    companyId: '',
    entityId: '',
    document: null,
    documentType: null,
    countExpiredDocuments: 0,
    progress: 0
}

const documentReducer = (state = initialStateConfirmDialog, action) => {
    switch (action.type) {
        case DOCUMENT_SHOW_CREATE_MODAL:
            return {
                ...state,
                showCreateOrEditModal: true,
                isCreationMode: true,
                companyId: action.companyId,
                entityId: action.entityId,
                documentType: action.documentType,
            };
        case DOCUMENT_SHOW_EDIT_MODAL:
            return {
                ...state,
                showCreateOrEditModal: true,
                isCreationMode: false,
                document: action.document
            };
        case DOCUMENT_HIDE_CREATE_OR_EDIT_MODAL:
            return {
                ...state,
                showCreateOrEditModal: false,
                companyId: '',
                entityId: '',
                document: null,
                documentType: null,
                progress: state.progress + 1
            };
        case INCREMENT_PROGRESS:
        case DOCUMENT_ADDED:
        case DOCUMENT_DELETED:
            return {
                ...state,
                progress: state.progress + 1
            };
        case DOCUMENT_GENERATED:
            return {
                ...state,
                progress: state.progress + 1
            }
        case ACTUALISE_COUNT_EXPIRED_DOCUMENTS:
            return {
                ...state,
                countExpiredDocuments: action.payload
            };
        default:
            return state;
    }
}

export default documentReducer;