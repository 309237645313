import React from 'react';
import './next-monitoring-date-component.scss'
import DateInput from "../input-label/date-input";
import moment from "moment/moment";

const NextMonitoringDateInputComponent = ({onChange, value}) => {
    let colorClass = 'monitoring-inactive';

    if (!!value) {
        const today = moment(moment(new Date()).format('yyyy-MM-DD'));
        const date = moment(value, 'yyyy-MM-DD');
        if (today.isBefore(date)) colorClass = 'monitoring-up-to-date'
        else colorClass = 'monitoring-late';
    }

    return (
        <div className="status-container">
            <div className="status-component">
                <div>
                    <DateInput inputClassName={`${colorClass}`} className={`${colorClass === 'monitoring-inactive' ? colorClass : ''} editable-date`}
                               id="nextMonitoringDate" defaultValue={value}
                               onChange={onChange}
                               adjustWidthWithText={true} adjustWidthForEmptyText={true}/>
                </div>
            </div>
        </div>
    );
}

export default NextMonitoringDateInputComponent;