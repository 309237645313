import React, {useState} from 'react';
import {Button, Card, Tab, Tabs} from "react-bootstrap-v5";
import T from "i18n-react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {connect} from "react-redux";
import TableControlsProductList from "./table-controls-product-list";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {productService} from "../../../services/product-service";
import {alertActions} from "../../../redux/alert/actions-alert";
import {analyseService} from "../../../services/analyse-service";
import {SortDirection} from "../../../utils/enums/sort-direction";
import PaneAddAnalysis from "../../../components/pane-add-analysis/pane-add-analysis";
import {UserRole} from "../../../utils/enums/user-role";
import TableAdditionalInformationsList from "../../../components/additional-informations/table-additional-information-list";
import {productActions} from "../../../redux/product/actions-product";
import {ProductStatus} from "../../../utils/enums/product-status";

const CardProductControlPlan = ({className, product, currentUser, showWarning, selectedAnalysisNumber, showToast, addedAnalysisToProduct}) => {

    const [showAddAnalysisPane, setShowAddAnalysisPane] = useState(false);
    const [showAdditionalInformationModal, setShowAdditionalInformationModal] = useState(false);
    const [isCreationMode, setIsCreationMode] = useState(false);
    const [additionalInformationToEdit, setAdditionalInformationToEdit] = useState(null);
    const [selectedTab, setSelectedTab] = useState('controls');

    const canEdit = currentUser.role === UserRole.SUPER_ADMINISTRATOR || currentUser.role === UserRole.COMPANY_ADMINISTRATOR || currentUser.role === UserRole.SUPERVISOR;

    const handleHidePane = () => {
        setShowAddAnalysisPane(false);
    }

    const handleAddAnalysis = async (analysisList) => {
        const analysisIdList = analysisList.map(analyse => analyse.analysisId);
        await productService.addAnalysisToProduct(product.id, analysisIdList);
        addedAnalysisToProduct();
        showToast(T.translate('alert.successTitle'), T.translate('product.successAddAnalysis', {count: analysisIdList.length}), ToastClass.SUCCESS);
    }

    const handleChangeTab = tab => {
        setSelectedTab(tab);
    }

    const onFetchAnalysis = async (searchText) => {
        const data = await analyseService.getAll(1, 50, SortDirection.ASC.toUpperCase(), 'analysisInfo.name', searchText, product.companyId, false);
        return data.content;
    }

    const onCreateAdditionalInformationModal = () => {
        setIsCreationMode(true);
        setAdditionalInformationToEdit(null);
        setShowAdditionalInformationModal(true);
    }

    const onEditAdditionalInformation = additionalInformation => {
        setIsCreationMode(false);
        setAdditionalInformationToEdit(additionalInformation);
        setShowAdditionalInformationModal(true);
    }

    const onHideAdditionalInformationModal = () => {
        setAdditionalInformationToEdit(null);
        setShowAdditionalInformationModal(false);
    }

    const handleShowAddAnalysisPane = () => setShowAddAnalysisPane(true);

    const isModificationInProgress = product.status === ProductStatus.MODIFICATION_IN_PROGRESS;

    return (
        <React.Fragment>
            <Card className={`card-control-plan ${className}`}>
                <Card.Body className="pt-4 position-relative">
                    {selectedTab === 'controls' && showWarning && (
                        <div className="px-4 mb-2 warning-text">
                            <FontAwesomeIcon icon="exclamation-triangle" className="d-inline-block me-2"/>
                            {T.translate('product.warningShouldGenerateControlPlan')}
                        </div>
                    )}
                    {selectedTab === 'controls' && canEdit && (
                        <div className={`btn-right-container d-flex flex-wrap flex-md-nowrap ${showWarning && 'pt-xl-2 mt-xl-4'}`}>
                            {isModificationInProgress && (
                                <Button variant="success" className="px-4 mb-2 mb-sm-0" onClick={handleShowAddAnalysisPane}>
                                    <FontAwesomeIcon icon="plus-circle" className="me-2"/>
                                    {T.translate('product.button.addAnalysis')}
                                </Button>)}
                        </div>
                    )}
                    {selectedTab === 'additionalInformations' && canEdit && isModificationInProgress && (
                        <div className="btn-right-container">
                            <Button variant="success" className="px-4 mb-2 mb-sm-0" onClick={onCreateAdditionalInformationModal}>
                                <FontAwesomeIcon icon="plus-circle" className="me-2"/>
                                {T.translate('product.button.addAdditionalInformation')}
                            </Button>
                        </div>
                    )}
                    <Tabs id="controls-tab" className="pt-2 px-4" defaultActiveKey="controls" onSelect={handleChangeTab} mountOnEnter={true}
                          unmountOnExit={false}>
                        <Tab eventKey="controls" title={T.translate('title.controls')}>
                            <Tab.Content>
                                <TableControlsProductList product={product}/>
                            </Tab.Content>
                        </Tab>
                        <Tab eventKey="additionalInformations" title={T.translate('title.additionalInformations')} tabClassName="large">
                            <Tab.Content>
                                <TableAdditionalInformationsList showAdditionalInformationsModal={showAdditionalInformationModal}
                                                                 onHideAdditionalInformationsModal={onHideAdditionalInformationModal}
                                                                 onEditAdditionalInformation={onEditAdditionalInformation}
                                                                 isCreationMode={isCreationMode}
                                                                 additionalInformationToEdit={additionalInformationToEdit}
                                                                 isModificationInProgress={isModificationInProgress}/>
                            </Tab.Content>
                        </Tab>
                    </Tabs>
                </Card.Body>
            </Card>
            <PaneAddAnalysis show={showAddAnalysisPane} onHide={handleHidePane} companyId={product.companyId} onSubmit={handleAddAnalysis}
                             onFetchAnalysis={onFetchAnalysis} allowMultipleSelection={true} useDetails={false}/>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        showWarning: state.productReducer.showWarning,
        selectedAnalysisNumber: state.productReducer.selectedAnalysisNumber
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        addedAnalysisToProduct: () => dispatch(productActions.addAnalysisToProduct())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardProductControlPlan);
