import React from 'react';
import {Form, FormLabel, Row,Col} from "react-bootstrap-v5";
import {FileType} from "./file-type";
import InputFile from "./input-file";
import './input-label.scss'

const InputFileLabel = ({id, className, label, required, onChange, error, defaultFileName, readOnly, accept = FileType.ALL, inModal = true}) => {

    return (
        <Form.Group controlId={id} className={`input-file-label ${className}`}>
            <Row className={`align-items-center ${inModal ? 'row align-items-center' : ''}`} lg={12} xs={12}>
                <Col xs={12} lg={inModal ? 4 : 2} className={`${inModal ? 'text-end' : ''}`}>
                    <FormLabel className="mb-0">
                        {label}{required && <span className="requiredRight">*</span>}
                    </FormLabel>
                </Col>
                <Col xs={12} lg={inModal ? 8 : 8}>
                    <InputFile 
                        onChange={onChange} 
                        error={error} 
                        accept={accept} 
                        defaultFileName={defaultFileName} 
                        readOnly={readOnly} 
                    />
                    <Form.Control.Feedback type="invalid" className={error ? "d-block" : "d-none"}>
                        {error}
                    </Form.Control.Feedback>
                </Col>
            </Row>
        </Form.Group>
    );

}

export default InputFileLabel;