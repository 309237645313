import {ADD_TOAST_ALERT} from './type';

const initialStateAlert = {
    id: 0,
    toasts: []
}

const alertReducer = (state = initialStateAlert, action) => {
    switch (action.type) {
        case ADD_TOAST_ALERT:
            const newToast = {
                id: state.id,
                title: action.title,
                message: action.message,
                className: action.className
            };
            return {
                id: state.id + 1,
                toasts: [...state.toasts, newToast]
            };
        default:
            return state;
    }
}

export default alertReducer;