import React from 'react';

const HeaderTextLabel = ({className, label, isMain, children}) => {
    return (
        <div className={`header-text-label ${className}`}>
            <div className={`${isMain ? "over-title" : "label"} ps-2`}>{label ?? '\u00A0'}</div>
            <div className={isMain ? "title" : "text"}>{children}</div>
        </div>
    );
}

export default HeaderTextLabel;