import React, {useState} from 'react';
import TagsInput from 'react-tagsinput';
import './tag.scss';
import {Form} from "react-bootstrap-v5";
import {InputMaxLength} from "../../utils/enums/input-max-length";

const TagsInputCustom = ({id, className, placeholder, label, tags, onTagsChange}) => {
    const [inputValue, setInputValue] = useState('');

    const handleChange = (tags) => {
        onTagsChange(tags);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter' && inputValue.trim() !== '' && !tags.some(tag => tag.toLowerCase() === inputValue.trim().toLowerCase())) {
            const newTags = [...tags, inputValue.trim()];
            onTagsChange(newTags);
            setInputValue('');
        }
    };

    return (
        <Form.Group controlId={id} className={`input-label row align-items-center ${className}`}>
            <Form.Label className="col col-4 text-end mb-0">{label}</Form.Label>
            <div className="col col-8">
                <input
                    type="text"
                    className={"form-control"}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    placeholder={placeholder}
                    maxLength={InputMaxLength.FIFTY_CHARACTERS_TEXT}
                />
                {tags.length > 0 &&
                    <TagsInput value={tags} onChange={handleChange}/>
                }
            </div>
        </Form.Group>
    );
};

export default TagsInputCustom;
