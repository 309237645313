import React from 'react';
import './input-label.scss'
import {Form} from "react-bootstrap-v5";
import {InputMaxLength} from "../../utils/enums/input-max-length";

const TextareaLabel = ({id, label, placeholder, required, value, onChange, error, className, maxLength = InputMaxLength.STANDARD_TEXT_AREA}) => {
    return (
        <Form.Group controlId={id} className={`textarea-label row align-items-center ${className}`}>
            <Form.Label className="col col-4 text-end mb-0">{label}{!!required ? <span className="required">*</span> : ""}</Form.Label>
            <div className="col col-8">
                <Form.Control as="textarea" rows={3} placeholder={placeholder ?? label} value={value} onChange={onChange} isInvalid={!!error} maxLength={maxLength}/>
                <Form.Control.Feedback type='invalid'>
                    {error}
                </Form.Control.Feedback>
            </div>
        </Form.Group>
    );
}

export default TextareaLabel;