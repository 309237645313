import React, {useEffect, useRef, useState} from "react";
import {connect} from 'react-redux';
import {Alert, Button, Form, Spinner} from 'react-bootstrap-v5';
import {authActions} from "../../redux/auth/actions-auth";
import {alertActions} from "../../redux/alert/actions-alert";
import T from 'i18n-react';
import {Link} from "react-router-dom";
import {Routes} from "../../router/router-constants";
import Validator from "../../utils/validators/validator";
import PageFormTemplate from "../../components/template-pages/page-form-template";
import {authService} from "../../services/auth-service";
import CurrentUserEntity from "../../models/entities/current-user-entity";
import {InputMaxLength} from "../../utils/enums/input-max-length";
import I18nController from "../../utils/i18n/i18n-controller";
import './login-page.scss';

const LoginPage = ({authData, authRequest, authSuccess, authError}) => {
    const ref = useRef(null);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [errorEmail, setErrorEmail] = useState("");
    const [errorPassword, setErrorPassword] = useState("");

    let hasErrors = false;

    const resetErrors = () => {
        setErrorEmail('');
        setErrorPassword('');
        hasErrors = false;
    }

    useEffect(() => {
        ref.current.focus();
    }, []);


    const validate = () => {
        if (Validator.isNullOrEmpty(email)) {
            setErrorEmail(T.translate('form.validation.required').toString());
            hasErrors = true;
        }
        if (Validator.isNotValidEmail(email)) {
            setErrorEmail(T.translate('form.validation.invalidEmail').toString());
            hasErrors = true;
        }
        if (Validator.isNullOrEmpty(password)) {
            setErrorPassword(T.translate('form.validation.required').toString());
            hasErrors = true;
        }
    }

    const login = async (username, password) => {
        authRequest();
        try {
            const userData = await authService.login(username, password);
            const user = new CurrentUserEntity(userData);
            authSuccess(user);
            I18nController.getInstance().changeLang(user.language);
        } catch (error) {
            authError(error.message);
        }
    }

    const handleLoginSubmit = e => {
        e.preventDefault();

        resetErrors();
        validate();

        if (!hasErrors) {
            login(email, password);
        }
    }

    return (
        <React.Fragment>
            <PageFormTemplate onSubmit={handleLoginSubmit} pageTitle={T.translate('pageTitle.login')} className="login-page-form">
                <Alert variant="danger" hidden={!authData.error}>
                    <Alert.Heading>{T.translate('alert.errorTitle')}</Alert.Heading>
                    {authData.error}
                </Alert>
                <Form.Group controlId="email" className="form-floating login-form mb-3">
                    <Form.Control ref={ref} type='text' placeholder="test@test.com" value={email} onChange={e => setEmail(e.target.value)} isInvalid={!!errorEmail} maxLength={InputMaxLength.STANDARD_TEXT}/>
                    <Form.Label>{T.translate("form.label.username")}</Form.Label>
                    <Form.Control.Feedback type='invalid'>
                        {errorEmail}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="password" className="form-floating login-form mb-3">
                    <Form.Control type='password' placeholder="xxx" value={password} onChange={e => setPassword(e.target.value)} isInvalid={!!errorPassword} maxLength={InputMaxLength.STANDARD_TEXT}/>
                    <Form.Label>{T.translate("form.label.password")}</Form.Label>
                    <Form.Control.Feedback type='invalid'>
                        {errorPassword}
                    </Form.Control.Feedback>
                </Form.Group>
                <Button variant="primary" type="submit" className="login-form w-100 p-2 my-2" disabled={authData.isLoading}>
                    {authData.isLoading ? <Spinner as="span" size="sm" animation="border"/> : ""}
                    {T.translate("auth.btnLogin")}
                </Button>
                <Link to={Routes.CHANGE_PASSWORD_REQUEST_PAGE} className="d-block mt-1">{T.translate("auth.linkPasswordForgotten")}</Link>
            </PageFormTemplate>
            <footer className="app-version position-absolute bottom-0 w-100 text-center">
                {T.translate('app.version', {version: process.env.REACT_APP_VERSION})}
            </footer>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        authData: state.authReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        authRequest: () => dispatch(authActions.authRequest()),
        authSuccess: user => dispatch(authActions.authSuccess(user)),
        authError: error => dispatch(authActions.authError(error)),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);