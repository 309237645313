import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import PageFormTemplate from "../../components/template-pages/page-form-template";
import {Alert, Button, ButtonGroup, Form, Spinner} from "react-bootstrap-v5";
import T from "i18n-react";
import Validator from "../../utils/validators/validator";
import {authService} from "../../services/auth-service";
import {RequestState} from "../../utils/enums/request-state";
import Recaptcha from "react-grecaptcha";
import {InputMaxLength} from "../../utils/enums/input-max-length";

const ChangePasswordRequestPage = () => {
    const history = useHistory();

    const [email, setEmail] = useState("");
    const [errorEmail, setErrorEmail] = useState("");
    const [captcha, setCaptcha] = useState({
        captchaChecked: false,
        token: ""
    });

    const [requestState, setRequestState] = useState({
        state: RequestState.INITIAL,
        message: ''
    });

    const resetErrors = () => {
        setErrorEmail('');
        setRequestState({
            state: RequestState.INITIAL,
            message: ''
        });
    }

    const validate = () => {
        if (Validator.isNullOrEmpty(email)) {
            setErrorEmail(T.translate('form.validation.required').toString());
            return false;
        }
        if (Validator.isNotValidEmail(email)) {
            setErrorEmail(T.translate('form.validation.invalidEmail').toString());
            return false;
        }
        return true;
    }

    const handleSubmit = async e => {
        e.preventDefault();
        resetErrors();

        if (validate()) {
            setRequestState({
                state: RequestState.LOADING,
                message: ''
            });
            try {
                await authService.changePasswordRequest(email, captcha.token);
                setRequestState({
                    state: RequestState.SUCCESS,
                    message: T.translate('changePassword.mailSent').toString()
                });
            } catch (error) {
                setRequestState({
                    state: RequestState.ERROR,
                    message: error.message
                });
            } finally {
                setCaptcha({captchaChecked: false, token: ""})
                window.grecaptcha.reset()
            }
        }
    }

    const callBackCaptcha = response => {
        setCaptcha({token: response, captchaChecked: true})
    }

    const expiredCallBackCaptcha = () => {
        setCaptcha({captchaChecked: false, token: ""})
    }

    return (
        <PageFormTemplate onSubmit={handleSubmit} pageTitle={T.translate('pageTitle.changePasswordRequest')}>
            <h1 className="mb-3">{T.translate('auth.titleResetPassword')}</h1>
            <Alert variant={requestState.state === RequestState.ERROR ? "danger" : "success"}
                   hidden={requestState.state !== RequestState.ERROR && requestState.state !== RequestState.SUCCESS}>
                <Alert.Heading>
                    {T.translate(requestState.state === RequestState.ERROR ? "alert.errorTitle" : "alert.successTitle")}
                </Alert.Heading>
                {requestState.message}
            </Alert>
            <Form.Group controlId="email" className="form-floating mt-4 mb-3">
                <Form.Control type='text' placeholder="xxx" value={email} onChange={e => setEmail(e.target.value)} isInvalid={!!errorEmail} maxLength={InputMaxLength.STANDARD_TEXT}/>
                <Form.Label>{T.translate("form.label.email")}</Form.Label>
                <Form.Control.Feedback type='invalid'>
                    {errorEmail}
                </Form.Control.Feedback>
            </Form.Group>
            <Recaptcha sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_KEY} callback={callBackCaptcha}
                       expiredCallback={expiredCallBackCaptcha}/>
            <ButtonGroup className="d-flex">
                <Button variant="secondary" type="button" className="p-2 my-2" onClick={e => history.goBack()}
                        disabled={requestState.state === RequestState.LOADING}>
                    {T.translate("form.button.cancel")}
                </Button>
                <Button id={"btn-submit"} variant="primary" type="submit" className="p-2 my-2"
                        disabled={requestState.state === RequestState.LOADING || !captcha.captchaChecked}>
                    {requestState.state === RequestState.LOADING ? <Spinner as="span" size="sm" animation="border"/> : ""}
                    {T.translate("form.button.confirm")}
                </Button>
            </ButtonGroup>
        </PageFormTemplate>

    );
}

export default ChangePasswordRequestPage;