import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card, Col, Spinner, Image, Form, Row} from "react-bootstrap-v5";
import {UserRole} from "../../../utils/enums/user-role";
import Validator from "../../../utils/validators/validator";
import {productsImportService} from "../../../services/products-import-service";
import iconWarningGreen from "../../../assets/images/icons/info.svg";
import iconWarningRed from "../../../assets/images/icons/info-red.svg";
import T from "i18n-react";
import {connect} from "react-redux";
import InputDateLabel from "../../../components/input-label/input-date-label";
import {confirmDialogActions} from "../../../redux/confirm-dialog/actions-confirm-dialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CardDeleteProducts = ({className, currentUser, showConfirmDialog}) => {

    const companyInitialState = {id: currentUser?.role === UserRole.SUPER_ADMINISTRATOR ? '' : currentUser?.companyId, name: ''};
    const [isLoading, setLoading] = useState(false);
    const [iconVariant, setIconVariant] = useState('');
    const [resultMessage, setResultMessage] = useState('');
    const [dateRange, setDateRange] = useState({start: '', end: ''});
    const [timeRange, setTimeRange] = useState({start: '', end: ''});
    const [company, setCompany] = useState(companyInitialState);
    const [inputErrors, setInputErrors] = useState({compagny: '', dateRange: '', timeRange: ''});

    const resetForm = useCallback(
        () => {
            setCompany(companyInitialState);
            setDateRange({start: '', end: ''});
            setTimeRange({start: '', end: ''});
        }, [],
    );

    useEffect(() => {
        resetForm();
    }, [currentUser])


    const resetErrors = () => {
        setInputErrors({compagny: '', dateRange: '', timeRange: ''});
        setResultMessage('');
    }

    const formatDate = (date, time)=>{
        const [year, month, day] = date.split('-').map(Number);
        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        const dateTimeISO = `${formattedDate} ${time}:00`;
        return dateTimeISO;
    }

    const validate =  () => {
        const companyError = Validator.validateRequired(company.id);
        const dateRangeError = Validator.validateDateRangeWithStartAndEndNotNull(dateRange.start, dateRange.end);
        const timeRangeError = Validator.validateTimeRangeWithBothStartAndEnd(timeRange.start, timeRange.end, T.translate('i18n.format.timeHourMinute').toString());
        const isValid = !dateRangeError && !timeRangeError && !companyError;

        setInputErrors({
            company: T.translate(companyError).toString(),
            dateRange: T.translate(dateRangeError).toString(),
            timeRange: T.translate(timeRangeError).toString(),
        })

        return isValid;
    }
    const submitForm = () => {
        resetErrors();
        if (validate()) {
            var startDate = formatDate(dateRange.start, timeRange.start);
            var endDate = formatDate(dateRange.end, timeRange.end);
            deleteProducts(startDate, endDate)
        }
    }

    const handleDeleteImportedProducts = async (e) =>{
        e.stopPropagation();

        showConfirmDialog(
            T.translate('dialog.title.confirmDelete'),
            T.translate('dialog.confirmDeleteImportedProducts'),
            () => {},
            () => submitForm())
    }

    const deleteProducts = async (startDate, endDate) => {
        setLoading(true);
        try {
            const data = await productsImportService.deleteProductsFromImport(startDate, endDate);
            setResultMessage(data.message);
            setIconVariant("ok");
            setLoading(false);
            
        } catch (error) {
            setLoading(false);
            setResultMessage(error.message);
            setIconVariant("danger");
        }
        finally{
            setLoading(false);
        }
    }

    return (
        <Col sm={12} lg={8}>
            <Card className={`card-indicators h-100 ${className}`}>
                <Card.Header className="d-flex justify-content-between align-items-end pb-0">
                    <Card.Title>{T.translate(`imports.products.deleteImportedProductsTitle`)}</Card.Title>
                </Card.Header>
                
                <Card.Body>
                    <Col xs={12} lg={12}>
                        <Row className="align-items-center" lg={12} xs={12} >
                            <Col xs={12} lg={6} style={{width: 'auto'}}>
                                <InputDateLabel 
                                    label={T.translate('form.label.dateFrom')}
                                    error={inputErrors.dateRange} 
                                    value={dateRange.start}
                                    onChange={date => setDateRange({...dateRange, start: date})}
                                    indented={false} 
                                />
                            </Col>
                            <Col xs={12} lg={6} style={{width: 'auto'}}>
                                <Form.Group controlId="formStartTime">
                                    <Form.Control
                                        type="text"
                                        placeholder={T.translate('i18n.format.timeHourMinute')}
                                        value={timeRange.start}
                                        onChange={e => setTimeRange({ ...timeRange, start: e.target.value })}
                                        isInvalid={!!inputErrors.timeRange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {inputErrors.timeRange}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} lg={12} className="mt-3" >
                        <Row className="align-items-center input-dateTo-align" lg={12} xs={12}  >
                            <Col xs={12} lg={6} style={{width: 'auto'}}>
                                <InputDateLabel 
                                    className='ml-5'
                                    label={T.translate('form.label.dateTo')}
                                    error={inputErrors.dateRange} 
                                    value={dateRange.end}
                                    onChange={date => setDateRange({...dateRange, end: date})}
                                    indented={false} 
                                />
                            </Col>
                            <Col xs={12} lg={6} style={{width: 'auto'}}>
                                <Form.Group controlId="formEndTime">
                                    <Form.Control
                                        type="text"
                                        placeholder={T.translate('i18n.format.timeHourMinute')}
                                        value={timeRange.end}
                                        onChange={e => setTimeRange({ ...timeRange, end: e.target.value })}
                                        isInvalid={!!inputErrors.timeRange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {inputErrors.timeRange}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} lg={6} className='mt-4'>
                        <div >
                            {T.translate(`imports.products.deleteImportedProductsNote`)}
                        </div>
                    </Col>
                    <Col xs={12} lg={4} className="mt-3">
                        <Button 
                            size="sm" 
                            variant="primary" 
                            onClick={e => handleDeleteImportedProducts(e)}
                            className="mb-3 mt-2 btn-import btn-danger"
                            disabled={isLoading}
                        >
                            {isLoading ? <Spinner as="span" size="sm" animation="border"/> : ""}
                            {T.translate('form.button.delete')}
                            
                            <FontAwesomeIcon icon="trash" className='btn-icon'/>
                        </Button>
                    </Col>

                    {!isLoading && resultMessage ? 
                        <Col xs={12} lg={12} className='d-flex flex-auto resultContainer'>
                            <Image src={iconVariant === "danger" ? iconWarningRed : iconWarningGreen} />
                            <div className={iconVariant === "danger" ? "error" : "infos"}>{resultMessage}</div>
                        </Col> 
                        : ""
                    }
                </Card.Body>
            </Card>

        </Col>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardDeleteProducts);