import React, { useEffect, useState} from 'react';
import CustomModal from "../../../../components/custom-modal/custom-modal";
import {connect} from "react-redux";
import T from 'i18n-react'
import {Alert, Tabs, Tab, Form, Row,  Col} from "react-bootstrap-v5";
import {confirmDialogActions} from "../../../../redux/confirm-dialog/actions-confirm-dialog";
import {alertActions} from "../../../../redux/alert/actions-alert";
import {analyseActions} from "../../../../redux/analyse/actions-analyse";
import {analyseService} from "../../../../services/analyse-service";
import AnalyseEntity from "../../../../models/entities/analyse-entity";
import AddAnalysisProductsTab from "./add-analysis-products-tab"
import CurrentAnalysisProductsTab from './current-analysis-products-tab';
const LinkAnalysisModal = ({analyseReducer, hideLinkModal}) => {

    const [activeTab, setActiveTab] = useState('currentProducts'); // État pour stocker l'onglet actif

    const [apiError, setApiError] = useState('');

    const [analyseEntity, setAnalyseEntity] = useState({});
    
    //Etat pour signaler le refresh des tableaux en cas d'appel au back réussi
    const [dataFromCurrentAnalysisProductsTab, setDataFromCurrentAnalysisProductsTab] = useState('');
    const [dataFromAddAnalysisProductsTab, setDataFromAddAnalysisProductsTab] = useState('');


    useEffect(() => {
        const getAnalyse = async () => {
            try {
                const analyseData = await analyseService.getAnalyse(analyseReducer.analyseId)
                const analyseFetched = new AnalyseEntity(analyseData);
                setAnalyseEntity(analyseFetched);            
            } catch (error) {
                setApiError(error.message);
            }
        }
        if(analyseReducer.analyseId){
            getAnalyse()
        }
    }, [analyseReducer.showLinkModal, analyseReducer.analyseId]);

    const hideModal = () => {
        hideLinkModal();
    }

    const handleActiveTab = (selectedTab) => {
        setActiveTab(selectedTab)
    }

    return (
        <CustomModal show={analyseReducer.showLinkModal} onHide={hideModal}  title={T.translate('analyse.title.linkAnalyse')}>
            <Form>
                <Alert variant={"danger"} hidden={!apiError}>
                    <Alert.Heading>
                        {T.translate("alert.errorTitle")}
                    </Alert.Heading>
                    {apiError}
                </Alert>
                <Row>
                </Row>
            </Form>

            <Row className="mb-3"> 
                <Col>{T.translate('analyse.analysisName', {analysisName : analyseEntity.name})}</Col>
                <Col>{T.translate('analyse.methodName',  {methodName : analyseEntity.method})}</Col> 
            </Row>
            <Tabs id="controls-tab" className="pt-2 px-4" defaultActiveKey="currentProducts" 
                onSelect={handleActiveTab} 
                mountOnEnter={true} unmountOnExit={false}>
                    <Tab eventKey="currentProducts" title={T.translate('analyse.tabs.currentProducts')}>
                        <Tab.Content>
                            <CurrentAnalysisProductsTab 
                                sendToAddAnalysisProductsTab={setDataFromCurrentAnalysisProductsTab} 
                                dataFromAddAnalysisProductsTab={dataFromAddAnalysisProductsTab} />
                        </Tab.Content>
                    </Tab>
                    <Tab eventKey="addProducts" title={T.translate('analyse.tabs.addProducts')} tabClassName="large">
                        <Tab.Content>
                            <AddAnalysisProductsTab 
                                sendToCurrentAnalysisProductsTab={setDataFromAddAnalysisProductsTab} 
                                dataFromCurrentAnalysisProductsTab={dataFromCurrentAnalysisProductsTab} />
                        </Tab.Content>
                    </Tab>
            </Tabs>
        </CustomModal>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        analyseReducer: state.analyseReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideLinkModal: () => dispatch(analyseActions.hideLinkModal()),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkAnalysisModal);