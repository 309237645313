import React, {useState} from 'react';
import {Badge, Button, Card, Tab, Tabs} from "react-bootstrap-v5";
import T from 'i18n-react'
import TableLaboratoryAnalysesList from "./table-laboratory-analyses-list";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {laboratoryService} from "../../../services/laboratory-service";
import {alertActions} from "../../../redux/alert/actions-alert";
import {connect} from "react-redux";
import {laboratoryActions} from "../../../redux/laboratory/actions-laboratory";
import {analyseService} from "../../../services/analyse-service";
import LaboratoryAnalyseDto from "../../../models/dto/laboratory-analyse-dto";
import PaneAddAnalysis from "../../../components/pane-add-analysis/pane-add-analysis";
import TableLaboratoryAnalysesInProgress from "./table-laboratory-analyses-in-progress";
import {StatusLotClassname} from "../../../utils/enums/status-lot";
import ButtonMoreOptions from "../../../components/buttons/button-more-options";
import {ping} from "../../../services/api/api";
import {UserRole} from "../../../utils/enums/user-role";

const CardLaboratoryAnalyses = ({currentUserRole, className, laboratoryId, addedAnalysisToLaboratory, showFormatModal, showToast, nbOfAnalysisInProgress}) => {

    const [showAddAnalysisPane, setShowAddAnalysisPane] = useState(false);

    const handleHidePane = () => {
        setShowAddAnalysisPane(false);
    }

    const handleAddAnalysis = async (analysisList) => {
        const laboratoryAnalysisList = analysisList.map(analyse => new LaboratoryAnalyseDto(analyse));
        await laboratoryService.addAnalysisToLaboratory(laboratoryId, laboratoryAnalysisList);
        addedAnalysisToLaboratory();
        showToast(T.translate('alert.successTitle'), T.translate('laboratory.successAddAnalysis', {count: analysisList.length}), ToastClass.SUCCESS);
    }

    const handleDownloadNewAnalysisExport = async () => {
        const linkRef = document.createElement('a');
        linkRef.download = 'download.csv';
        linkRef.href = await laboratoryService.getAnalysisExport(laboratoryId);
        // ici on ping pour éventuellement forcer axios à appeler le refresh token et éviter sinon que le download envoie un 401 car c'est un appel backend direct
        await ping();
        document.body.appendChild(linkRef);
        linkRef.click();
        document.body.removeChild(linkRef);
    }

    const onFetchAnalysis = async (searchText) => {
        const data = await analyseService.getAllWithoutLaboratory(laboratoryId, 1, 50, searchText);
        return data.content;
    }

    let moreOptions = [
        {
            title: (
                <div>
                    <FontAwesomeIcon icon="file" className="me-2"/>
                    {T.translate('product.button.addFormatImport')}
                </div>
            ),
            onClick: () => showFormatModal(laboratoryId),
        },
        {
            title: (
                <div>
                    <FontAwesomeIcon icon="plus-circle" className="me-2"/>
                    {T.translate('product.button.addAnalysis')}
                </div>
            ),
            onClick: () => setShowAddAnalysisPane(true),
        },
    ];


    return (
        <React.Fragment>
            <Card className={`card-laboratory-analyses overflow-hidden ${className}`}>
                <Card.Body className="pt-4 position-relative">
                    <div className="btn-right-container">
                        {(currentUserRole === UserRole.SUPER_ADMINISTRATOR || currentUserRole === UserRole.COMPANY_ADMINISTRATOR || currentUserRole === UserRole.SUPERVISOR) &&
                            <Button variant="success" className="px-4 mx-3" onClick={handleDownloadNewAnalysisExport}>
                                {T.translate('product.button.newAnalysisExport')}
                            </Button>}
                        <ButtonMoreOptions title={<FontAwesomeIcon icon="ellipsis-v"/>} buttonOptions={moreOptions}/>
                    </div>
                    <Tabs id="analyses-tab" className="pt-2 px-4" defaultActiveKey="catalog">
                        <Tab eventKey="catalog" title={T.translate('laboratory.tabs.catalog')}>
                            <Tab.Content>
                                <TableLaboratoryAnalysesList/>
                            </Tab.Content>
                        </Tab>
                        <Tab eventKey="analysesInProgress" title={
                            <React.Fragment>
                                <Badge className={`d-inline me-2 ${StatusLotClassname.IN_PROGRESS}`} pill={true}>{nbOfAnalysisInProgress}</Badge>
                                {T.translate('laboratory.tabs.analysesInProgress')}
                            </React.Fragment>
                        }>
                            <Tab.Content>
                                <TableLaboratoryAnalysesInProgress/>
                            </Tab.Content>
                        </Tab>
                    </Tabs>
                </Card.Body>
            </Card>
            <PaneAddAnalysis show={showAddAnalysisPane} onHide={handleHidePane} useDetails={true} allowMultipleSelection={false}
                             onFetchAnalysis={onFetchAnalysis} onSubmit={handleAddAnalysis}/>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        currentUserRole: state.authReducer.currentUser.role,
        nbOfAnalysisInProgress: state.laboratoryReducer.nbOfAnalysisInProgress
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        addedAnalysisToLaboratory: () => dispatch(laboratoryActions.addAnalysisToLaboratory()),
        showFormatModal: (laboratoryId) => dispatch(laboratoryActions.showFormatModal(laboratoryId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardLaboratoryAnalyses);