import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import T from 'i18n-react'
import {Button, Row, Col} from "react-bootstrap-v5";
import {confirmDialogActions} from "../../../redux/confirm-dialog/actions-confirm-dialog";
import {alertActions} from "../../../redux/alert/actions-alert";
import {analyseActions} from "../../../redux/analyse/actions-analyse";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InputLabel from "../../../components/input-label/input-label";
import CheckLabel from "../../../components/input-label/check-label";
import SelectLabel from "../../../components/input-label/select-label";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {i18nService} from "../../../services/i18n-service";
import { productService } from '../../../services/product-service';


const TableAnalysisProduct = ({ analyseReducer, analysis, parameters, selectedProducts, showConfirmDialog, showToast, onProgress, isCreation = false }) => {
    const [globalSpecifications, setGlobalSpecifications] = useState('');
    const [globalUnit, setGlobalUnit] = useState('');
    const [globalSource, setGlobalSource] = useState(''); 
    const [globalPeriodicity, setGlobalPeriodicity] = useState('');
    const [globalCoaSpecs, setGlobalCoaSpecs] = useState(false);

    const [parametersData, setParametersData] = useState([]);    
    const [parametersInput, setParametersInput] = useState([]);    
    const [periodicities, setPeriodicities] = useState([]);

    const [showEditParamForm, setShowEditParamForm] = useState(true);

    useEffect(() => {
        if (parameters && parameters.length > 0) {
            setParametersData(parameters);
            initializeParameterInputs(parameters);
        }
    }, [parameters]);

    useEffect(() => {
        const getPeriodicities = async () => {
            try {
                const availablePeriodicities = await i18nService.getAllPeriodicities();
                setPeriodicities(availablePeriodicities);
                setGlobalPeriodicity(availablePeriodicities[0]?.periodicityCode || '');
            } catch (e) {
                setPeriodicities([]);
            }
        }
        getPeriodicities();
        setGlobalSource(analysis.laboratoryList?.[0]?.id || '');
    }, [analysis]);

    const initializeParameterInputs = (parameters) => {
        const initialInputs = parameters.map(param => ({
            name: param.parameterName,
            specs: param.specification || '',
            checked: false
        }));
        setParametersInput(initialInputs);
    }

    const handleParamChange = (index, field, value) => {
        setParametersInput(prevInputs => {
            const updatedInputs = [...prevInputs];
            updatedInputs[index][field] = value;
            return updatedInputs;
        });
    };

    const handleUpdateAnalysisFromProducts = async (e) => {
        e.stopPropagation();
        showConfirmDialog(
            T.translate('dialog.title.confirmDelete'),
            T.translate('dialog.confirmUpdateProductsAnalysis', {lotAnalysisName: analysis.name, selectedProductNumber: selectedProducts.length}),
            () => {
            },
            () => updateAnalysisForProducts(selectedProducts))
    }

    const updateAnalysisForProducts = async () =>{
        try {
            const analysisId = analyseReducer.analyseId;
            const data =
            {
                analyseId: analysisId,
                productsId: selectedProducts,
                specs: globalSpecifications,
                unit: globalUnit,
                source: globalSource,
                periodicity: globalPeriodicity,
                coaSpecs: globalCoaSpecs,
                parameters: parametersInput,
                isCreation: isCreation
            };
            await productService.editProductsAnalysis(analysisId, data);
            if (onProgress) {
                onProgress();
            }
            showToast(T.translate('alert.successTitle'), T.translate('lot.successEditDataProductsAnalysis'), ToastClass.SUCCESS);
        
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const periodicityOptions = () => 
        periodicities.map(periodicity => (
        <option key={periodicity.periodicityCode} value={periodicity.periodicityCode}>
                {periodicity.displayName}
        </option>
    ))

    const laboratoryOptions = () => {
        const specialLaboratoryOptions = [
            { id: "SPECIAL_LABORATORY_SUPPLIER_DATA", name: T.translate('product.label.supplierData') },
            { id: "SPECIAL_LABORATORY_PERIODIC_PLAN", name: T.translate('product.label.periodicPlan') },
            { id: "SPECIAL_LABORATORY_RAW_MATERIAL", name: T.translate('product.label.rawMaterial') },
            { id: "SPECIAL_LABORATORY_COMPUTED_VALUE", name: T.translate('product.label.computedValue') },
            { id: "SPECIAL_LABORATORY_CLIENT_DATA", name: T.translate('product.label.clientData') }
        ];

        const laboratoryListOptions = (analysis && analysis.laboratoryList.length > 0) 
            ? analysis.laboratoryList.map(laboratory => ({ id: laboratory.id, name: laboratory.name }))
            : [];

        const sources = [...laboratoryListOptions, ...specialLaboratoryOptions];
        return sources.map(source => (
            <option key={`source-option-${source.id}`} id={`source-option-${source.id}`} value={source.id}>
                {source.name}
            </option>
        ));
    };

    const handleGlobalSpecificationsChange=(e)=>{
        setGlobalSpecifications(e.target.value)
    }

    const handleGlobalUnitChange=(e)=>{
        setGlobalUnit(e.target.value)
    }

    const handleGlobalSourceChange=(e)=>{
        setGlobalSource(e.target.value)
    }

    const handleGlobalPeriodicityChange=(e)=>{
        setGlobalPeriodicity(e.target.value)
    }
    const handleGlobalCoaSpecsChange=(e)=>{
        setGlobalCoaSpecs(e.target.checked)
    }
    return (
        <Col className="bordered-container ">
            <Row className="mt-3 mb-3" >
                <Col md={4}>
                    <InputLabel 
                        id="globalSpecification" 
                        type="text" 
                        label={T.translate('form.label.specification')}
                        value={globalSpecifications}
                        onChange={handleGlobalSpecificationsChange}
                    />
                </Col>
                <Col md={4}>
                    <InputLabel 
                        id="globalUnit" 
                        type="text" 
                        label={T.translate('form.label.unit')} 
                        value={globalUnit}
                        onChange={handleGlobalUnitChange}
                    />
                </Col>
                <Col md={4}>
                    <SelectLabel 
                    id="globalSource" 
                    defaultValue={analysis.laboratoryList?.[0]?.id || ''} 
                    label={T.translate('form.label.source')}
                    onChange={handleGlobalSourceChange}
                    options={laboratoryOptions()}
                    />
                </Col>
                <Col md={4} className="mt-4 mb-4">
                    <SelectLabel 
                        id="globalPeriodicty" 
                        defaultValue={periodicities[0]}
                        label={T.translate('form.label.periodicity')}
                        onChange={handleGlobalPeriodicityChange}
                        options={periodicityOptions()}/>
                </Col>
                <Col md={4} className="mt-4 mb-4">
                    <CheckLabel 
                        id="globalCoaSpecs" 
                        label={T.translate('form.label.coaSpecs')}
                        checked={globalCoaSpecs}
                        onChange={handleGlobalCoaSpecsChange}
                    />
                </Col>
            </Row>

            <Row >
                <div className="mb-2">
                    <FontAwesomeIcon
                    className={"me-3"}
                    icon={showEditParamForm ? "chevron-up" : "chevron-down"}
                    onClick={() => setShowEditParamForm(prev => !prev)}
                    />
                    {T.translate('form.label.parameters')}
                </div>

                {showEditParamForm && 
                <div>
                    <Row className="mt-4 mb-4">  
                        {parametersData && parametersData.map((parameter, index) => (
                            <Row key={index} className="mb-3 d-flex align-items-center">
                                <Col>
                                    {parameter.parameterName}
                                </Col>
                                <Col>
                                    <InputLabel 
                                        id={`specification-${index}`} 
                                        type="text" 
                                        label={T.translate('form.label.specification')}
                                        value={parameter.specification} 
                                        onChange={e => handleParamChange(index, 'specs', e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <InputLabel 
                                        id={`unit-${index}`} 
                                        type="text" 
                                        label={T.translate('form.label.unit')} 
                                        value={globalUnit} 
                                        onChange={e => handleParamChange(index, 'unit', e.target.value)}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                        ))}
                    </Row>
                    <Row className="d-flex justify-content-end">
                        <Col xs="auto">
                            {T.translate('analyse.selectedProductNumberForProductAnalysis', {selectedProductNumber : selectedProducts.length})}
                        </Col>
                    </Row>  
                    <Row className="d-flex justify-content-end">
                        <Col xs="auto">
                            <Button variant="success" className="px-4 mb-2 mb-sm-0" size="sm" onClick={handleUpdateAnalysisFromProducts}>
                                {T.translate('form.button.edit')}
                            </Button>
                        </Col>
                    </Row>
                    </div>
                }
            </Row>
        </Col>
    );
};

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        analyseReducer: state.analyseReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideLinkModal: () => dispatch(analyseActions.hideLinkModal()),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableAnalysisProduct);