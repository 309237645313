import React, {useCallback, useMemo} from 'react';
import {Button, Card, OverlayTrigger, Tooltip} from "react-bootstrap-v5";
import {companyService} from "../../../services/company-service";
import RemoteTable from "../../../components/table/remote-table";
import StatusComponent from "../../../components/status-component/status-component";
import T from 'i18n-react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {alertActions} from "../../../redux/alert/actions-alert";
import {connect} from "react-redux";
import {companyActions} from "../../../redux/company/actions-company";
import {confirmDialogActions} from "../../../redux/confirm-dialog/actions-confirm-dialog";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import CompanyEntity from "../../../models/entities/company-entity";
import {StatusActiveInactive, StatusActiveInactiveClassname} from "../../../utils/enums/status-active-inactive";
import {copyTextToClipboard} from "../../../utils/functions/copy-to-clipboard";

const TableCompanyList = ({className, searchText, showToast, showEditCompany, companyDeleted, showConfirmDialog, companyReducer}) => {

    const columns = [
        {
            dataField: T.translate('table.fields.name').toString(),
            text: T.translate('table.columns.company.name').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.company.contactAddress').toString(),
            text: T.translate('table.columns.company.contactAddress').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.company.contactPhone').toString(),
            text: T.translate('table.columns.company.contactPhone').toString()
        },
        {
            dataField: T.translate('table.fields.company.deliveryAccount').toString(),
            text: T.translate('table.columns.company.deliveryAccount').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.status').toString(),
            text: T.translate('table.columns.status').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.actions').toString(),
            text: T.translate('table.columns.actions').toString()
        }
    ];

    const reload = useMemo(() => companyReducer.progress, [companyReducer.progress]);

    const onFetchData = useCallback(async (page, size, sortedOrder, sortedField, searchText) => {
        const deleteCompany = async company => {
            try {
                await companyService.deleteCompany(company.companyId);
                companyDeleted();
                showToast(T.translate('alert.successTitle'), T.translate('company.successDeleteCompany', {companyName: company.name}), ToastClass.SUCCESS);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleDeleteCompany = async (e, company) => {
            e.stopPropagation();
            showConfirmDialog(
                T.translate('dialog.title.confirmDelete'),
                T.translate('dialog.confirmDeleteCompany', {companyName: company.name}),
                () => {
                },
                () => deleteCompany(company))
        }

        const handleEditCompany = (e, companyData) => {
            e.stopPropagation();
            const companyEntity = new CompanyEntity(companyData);
            showEditCompany(companyEntity);
        }

        const handleCopyCompanyIdToClipboard = async (e, text) => {
            e.stopPropagation();
            await copyTextToClipboard(text);
            showToast(T.translate('alert.successTitle'), T.translate('company.successIdCopied'), ToastClass.SUCCESS);
        }

        const completeElements = allElements => {
            return allElements.map(element => {
                return {
                    ...element,
                    id: element.companyId,
                    name: (
                        <OverlayTrigger trigger={['hover', 'focus']} key={`tooltip-trigger-${element.companyId}`}
                                        overlay={<Tooltip id={`tooltip-${element.companyId}`}>{T.translate('company.clickToCopyId')}</Tooltip>}>
                            <div className="cursor-pointer" onClick={e => handleCopyCompanyIdToClipboard(e, element.companyId)}>{element.name}</div>
                        </OverlayTrigger>
                    ),
                    activated: <StatusComponent type={StatusActiveInactiveClassname[element.activated ? StatusActiveInactive.ACTIVE : StatusActiveInactive.INACTIVE]}/>,
                    actions: (
                        <div className="actions">
                            <Button size="sm" className="edit" onClick={e => handleEditCompany(e, element)}>
                                <FontAwesomeIcon icon="edit"/>
                            </Button>
                            <Button size="sm" className={`delete ms-1 ${element.deactivated ? "d-none" : ""}`}
                                    onClick={e => handleDeleteCompany(e, element)}>
                                <FontAwesomeIcon icon="times"/>
                            </Button>
                        </div>
                    )
                }
            });
        }

        const data = await companyService.getAll(page, size, sortedOrder, sortedField, searchText);
        const elements = completeElements(data.content)
        return {
            ...data,
            elements: elements
        };
    }, [showEditCompany, showToast, showConfirmDialog, companyDeleted])

    return (
        <Card className={className}>
            <RemoteTable id="table-company-list"
                         defaultSortedField={T.translate('table.fields.name')}
                         columns={columns} onFetchData={onFetchData} reload={reload} searchText={searchText}/>
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        companyReducer: state.companyReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        showEditCompany: company => dispatch(companyActions.companyEdit(company)),
        companyDeleted: () => dispatch(companyActions.companyDeleted()),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableCompanyList);
