import {AUTH_ERROR, AUTH_LOGOUT, AUTH_REQUEST, AUTH_SUCCESS, AUTH_UPDATE_CURRENT_USER} from "./type";

const authRequest = () => {
    return {
        type: AUTH_REQUEST
    }
}

const authSuccess = user => {
    return {
        type: AUTH_SUCCESS,
        payload: user
    }
}

const authError = error => {
    return {
        type: AUTH_ERROR,
        payload: error
    }
}

const authLogout = () => {
    return {
        type: AUTH_LOGOUT
    };
}

const authUpdateCurrentUser = currentUser => {
    return {
        type: AUTH_UPDATE_CURRENT_USER,
        payload: currentUser
    }
}

export const authActions = {
    authRequest,
    authSuccess,
    authError,
    authLogout,
    authUpdateCurrentUser
};