import React, {useCallback} from 'react';
import {connect} from "react-redux";
import T from 'i18n-react'
import {UserRole} from "../../../utils/enums/user-role";
import StatusComponent from "../../../components/status-component/status-component";
import {Button} from "react-bootstrap-v5";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {alertActions} from "../../../redux/alert/actions-alert";
import {confirmDialogActions} from "../../../redux/confirm-dialog/actions-confirm-dialog";
import {useParams} from "react-router-dom";
import {analyseService} from "../../../services/analyse-service";
import {analyseActions} from "../../../redux/analyse/actions-analyse";
import RemoteTable from "../../../components/table/remote-table";
import {StatusPrimarySecondary, StatusPrimarySecondaryClassname} from "../../../utils/enums/status-primary-secondary";

const TableLaboratoryAnalysesList = ({className, showToast, currentUser, showConfirmDialog, progress, showEditAnalyseModal, analysisLaboratoryDeleted}) => {

    const {id} = useParams();

    let columns = [
        {
            dataField: T.translate('table.fields.analyse.category').toString(),
            text: '', // On ne veut pas que le mot catégorie s'affiche, c'est une colonne fantôme
            sort: false
        },
        {
            dataField: T.translate('table.fields.analyse.analyseName').toString(),
            text: T.translate('table.columns.analyseName').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.method').toString(),
            text: T.translate('table.columns.method').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.technique').toString(),
            text: T.translate('table.columns.technique').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.unit').toString(),
            text: T.translate('table.columns.unit').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.status').toString(),
            text: T.translate('table.columns.status').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.price').toString(),
            text: `${T.translate('table.columns.price')} ${!!currentUser.currencySymbol ? '(' + currentUser.currencySymbol + ')' : ''}`,
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.delay').toString(),
            text: T.translate('table.columns.delayInDays').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.quantity').toString(),
            text: T.translate('table.columns.quantityInGram').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.actions').toString(),
            text: T.translate('table.columns.actions').toString(),
            sort: false
        },
    ];

    const onFetchData = useCallback(async (_, __, sortedOrder, sortedField, searchText) => {

        const handleEditAnalysis = (e, analysis) => {
            showEditAnalyseModal(analysis.analysisId)
        }

        const deleteAnalysis = async analysis => {
            try {
                await analyseService.deleteAnalysisLaboratory(analysis.analysisId, analysis.analysisLaboratoryDetail?.laboratory?.laboratoryId);
                analysisLaboratoryDeleted()
                showToast(T.translate('alert.successTitle'), T.translate('analyse.successDeleteAnalyseLaboratory', {
                    analysisName: analysis.analysisInfo?.name,
                    laboratoryName: analysis.analysisLaboratoryDetail?.laboratory?.name
                }), ToastClass.SUCCESS);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleDeleteAnalysis = async (e, analysis) => {
            e.stopPropagation();
            showConfirmDialog(
                T.translate('dialog.title.confirmDelete'),
                T.translate('dialog.confirmDeleteAnalysisLaboratory', {
                    analysisName: analysis.analysisInfo?.name,
                    laboratoryName: analysis.analysisLaboratoryDetail?.laboratory?.name
                }),
                () => {
                },
                () => deleteAnalysis(analysis))
        }

        const completeElements = allElements => {
            return allElements.map(element => {
                return {
                    ...element,
                    id: element.analysisId,
                    status: <StatusComponent
                        type={StatusPrimarySecondaryClassname[element.analysisLaboratoryDetail?.mainLaboratory ? StatusPrimarySecondary.PRIMARY : StatusPrimarySecondary.SECONDARY]}/>,
                    actions: (
                        <div className="actions">
                            <Button size="sm"
                                    className={`edit ${currentUser.role === UserRole.COMPANY_ADMINISTRATOR || currentUser.role === UserRole.SUPER_ADMINISTRATOR || currentUser.role === UserRole.SUPERVISOR || currentUser.role === UserRole.CONTRIBUTOR ? "" : "d-none"}`}
                                    onClick={e => handleEditAnalysis(e, element)}>
                                <FontAwesomeIcon icon="edit"/>
                            </Button>
                            <Button size="sm"
                                    className={`delete ms-1 ${(currentUser.role === UserRole.COMPANY_ADMINISTRATOR || currentUser.role === UserRole.SUPER_ADMINISTRATOR || currentUser.role === UserRole.SUPERVISOR || currentUser.role === UserRole.CONTRIBUTOR) && (!element?.analysisLaboratoryDetail.mainLaboratory) ? "" : "d-none"}`}
                                    onClick={e => handleDeleteAnalysis(e, element)}>
                                <FontAwesomeIcon icon="times"/>
                            </Button>
                        </div>
                    )
                }
            })
        }
        if (sortedField === 'status') {
            sortedField = "analysisLaboratoryDetail.mainLaboratory"
        }
        // laisser ici 10000, c'est un tableau qui doit afficher toutes les analyses sans pagination
        const data = await analyseService.getAnalysisByLaboratoryId(id, 1, 10000, sortedOrder, sortedField, searchText);
        const elements = completeElements(data.content);
        return {
            ...data,
            elements: elements
        };
    }, [showToast, showConfirmDialog, currentUser, id, showEditAnalyseModal, analysisLaboratoryDeleted])

    return (
        <RemoteTable id="table-laboratory-analyses-list"
                     defaultSortedField={T.translate('table.fields.analyse.analyseName')}
                     className={className} columns={columns} onFetchData={onFetchData} reload={progress}
                     groupByName={T.translate('table.fields.analyse.category').toString()} useSearchField={false} useSizeSelect={false}
                     groupById={T.translate('table.fields.analyse.categoryId')} usePagination={false}/>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        progress: state.analyseReducer.progress + state.laboratoryReducer.progress
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm)),
        showEditAnalyseModal: (analysisId) => dispatch(analyseActions.analyseEdit(analysisId)),
        analysisLaboratoryDeleted: () => dispatch(analyseActions.analyseLaboratoryDeleted())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableLaboratoryAnalysesList);