import React, {useEffect, useState} from 'react';
import {Card} from "react-bootstrap-v5";
import OverlayLoadingOrError from "../../../components/overlay-component/overlay-loading-or-error";
import {lotService} from "../../../services/lot-service";
import T from "i18n-react";
import BarChart from "../../../components/charts/bar-chart";
import {connect} from "react-redux";
import I18NController from "../../../utils/i18n/i18n-controller";

const CardIndicatorsLotsClosedControlPrice = ({className, currentUser}) => {
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;

    const [state, setState] = useState({isLoading: false, error: ''});

    const [currentYearPrice, setCurrentYearPrice] = useState(0);
    const [lastYearPrice, setLastYearPrice] = useState(0);

    useEffect(() => {
        const getIndicators = async () => {
            try {
                setState({isLoading: true, error: ''});
                const indicators = await lotService.indicatorsLotsClosedControlPrice();
                setCurrentYearPrice(indicators.currentYear.sumOfControlPrice);
                setLastYearPrice(indicators.lastYear.sumOfControlPrice);
                setState({isLoading: false, error: ''});
            } catch (error) {
                setState({isLoading: false, error: error.message})
            }
        }

        getIndicators();
    }, []);

    const formatPrice = price => {
        return I18NController.getInstance().formatPrice(price, currentUser.currency, currentUser.language);
    }

    return (
        <Card className={`card-indicators h-100 ${className}`}>
            <Card.Header className="d-flex justify-content-between align-items-end pb-0">
                <Card.Title>{T.translate(`indicators.lotsClosedControlPrice`)}</Card.Title>
            </Card.Header>
            <Card.Body>
                <div className="w-50 mx-auto pb-2 px-3">
                    <BarChart
                        dataset={[
                            {x: lastYear, y: lastYearPrice},
                            {x: currentYear, y: currentYearPrice},
                        ]}
                        labelFormat={formatPrice}
                    />
                </div>
                <OverlayLoadingOrError isLoading={state.isLoading} error={state.error} onCloseError={() => setState({isLoading: false, error: ''})}/>
            </Card.Body>
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser
    }
}

export default connect(mapStateToProps)(CardIndicatorsLotsClosedControlPrice);