import axios from 'axios';
import T from 'i18n-react'
import store from "../../redux/store";
import {authActions} from "../../redux/auth/actions-auth";
import {alertActions} from "../../redux/alert/actions-alert";
import {ToastClass} from "../../components/toast-alert/toast-alert-class";
import {Routes} from "../../router/router-constants";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const getBackendUrlFromHostname = location => {
    if (location.hostname === "localhost") return process.env.REACT_APP_BACKEND_LOCALHOST_URL;

    const rctDomaine = 'ciklab.coaxys.com';
    if (location.host.includes(rctDomaine)) {
        const hostSplitted = location.host.split(rctDomaine);
        return `${location.protocol}//${hostSplitted[0]}backend-${rctDomaine}`;
    }

    const hostnameSplitted = location.origin.split('://');
    const constructedHostname = hostnameSplitted[1].replace("www.", "");
    return `${hostnameSplitted[0]}://${process.env.REACT_APP_BACKEND_PREPEND_URL}${constructedHostname}`;

}

const backendUrl = getBackendUrlFromHostname(window.location);

const publicAPI = axios.create({
    baseURL: backendUrl
});

const privateAPI = axios.create({
    baseURL: backendUrl,
    withCredentials: true
});

let call = {};
const onceGet = async (request = '', requestType = 'unnamed', data = {}) => {
    if (call[requestType]) {
        call[requestType].cancel(T.translate('error.http.cancel'));
    }
    call[requestType] = axios.CancelToken.source();

    return privateAPI.get(request, {
        ...data,
        cancelToken: call[requestType].token
    });
}

// Faire appel à cette fonction pour rafraichir le token avant un click sur un href pointant sur le back directement
const ping = () => privateAPI.get('/application/ping');

// Function that will be called to refresh authorization
const refreshAuthLogic = () => privateAPI.post('/login/refresh-token', null, {skipAuthRefresh: true})

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(privateAPI, refreshAuthLogic);

const errorHandler = error => {
    if (axios.isCancel(error)) {
        error.response = {status: "cancel"};
    } else if (!error.response) {
        error.response = {status: "network"};
        error.message = T.translate("error.http.network")
    } else if (error.response.status === 401) {
        const pathName = window.location.pathname;
        store.dispatch(authActions.authLogout());
        if (pathName !== Routes.LOGIN_PAGE) {
            store.dispatch(alertActions.addToast(T.translate('alert.errorTitle'), T.translate('error.http.401'), ToastClass.ERROR))
        }
    }
    throw error;
}

publicAPI.interceptors.response.use(response => response, errorHandler);
privateAPI.interceptors.response.use(response => response, errorHandler);

export {
    publicAPI,
    privateAPI,
    backendUrl,
    onceGet,
    ping
};
