import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from "react-bootstrap-v5";
import T from "i18n-react";
import IndicatorValue from "../../../components/card-indicator/indicator-value";
import IndicatorPieChart from "../../../components/card-indicator/indicator-pie-chart";
import IndicatorBarChart from "../../../components/card-indicator/indicator-bar-chart";
import I18NController from "../../../utils/i18n/i18n-controller";
import {connect} from "react-redux";
import OverlayLoadingOrError from "../../../components/overlay-component/overlay-loading-or-error";
import {productService} from "../../../services/product-service";

const CardProductIndicators = ({productId, className, currentUser}) => {
    const [state, setState] = useState({isLoading: false, error: ''});
    const [indicatorLotsClosed, setIndicatorLotsClosed] = useState({});
    const [indicatorCompliance, setIndicatorCompliance] = useState({});
    const [indicatorCost, setIndicatorCost] = useState({});

    useEffect(() => {
        const getIndicators = async () => {
            try {
                setState({isLoading: true, error: ''});
                const indicatorNb = await productService.indicatorsLotsClosedForProduct(productId);
                const indicatorCompl = await productService.indicatorsLotsClosedComplianceForProduct(productId);
                const indicatorPrice = await productService.indicatorsLotsClosedControlPriceForProduct(productId);
                setIndicatorLotsClosed({currentYear: indicatorNb.currentYear.totalNumberOfClosedLots, lastYear: indicatorNb.lastYear.totalNumberOfClosedLots});
                setIndicatorCompliance({currentYear: indicatorCompl.currentYear.percentageOfCompliantLots, lastYear: indicatorCompl.lastYear.percentageOfCompliantLots});
                setIndicatorCost({currentYear: indicatorPrice.currentYear.sumOfControlPrice, lastYear: indicatorPrice.lastYear.sumOfControlPrice});
                setState({isLoading: false, error: ''});
            } catch (error) {
                setState({isLoading: false, error: error.message})
            }
        }

        getIndicators();
    }, [productId]);


    const formatPrice = price => I18NController.getInstance().formatPrice(price, currentUser.currency, currentUser.language);

    return (
        <Card className={`card-product-indicators ${className}`}>
            <Card.Header className="d-flex justify-content-between align-items-center">
                <Card.Title className="m-0">{T.translate('title.indicators')}</Card.Title>
            </Card.Header>
            <Card.Body>
                <Row className="m-0 px-2 gx-4 h-100 justify-content-center">
                    <Col className="mb-4">
                        <IndicatorValue title={T.translate('indicators.lotsClosed')} currentYear={indicatorLotsClosed.currentYear} lastYear={indicatorLotsClosed.lastYear}/>
                    </Col>
                    <Col className="mb-4">
                        <IndicatorPieChart title={T.translate('indicators.compliance')} currentYear={indicatorCompliance.currentYear} lastYear={indicatorCompliance.lastYear}/>
                    </Col>
                    <Col className="mb-4">
                        <IndicatorBarChart title={T.translate('indicators.globalCost')} currentYear={indicatorCost.currentYear} lastYear={indicatorCost.lastYear} labelFormat={formatPrice}/>
                    </Col>
                </Row>
            </Card.Body>
            <OverlayLoadingOrError isLoading={state.isLoading} error={state.error}/>
        </Card>
    );
};

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser
    }
}

export default connect(mapStateToProps)(CardProductIndicators);
