import React from 'react';
import T from 'i18n-react'
import './status-products-page-component.scss'
import {OverlayTrigger, Tooltip} from "react-bootstrap-v5";

const StatusProductsPageComponent = ({type}) => {
    const tooltip = (
        <Tooltip id={`tooltip-sub-status-component-${type}`} className="tooltip-sub-status-component d-flex align-items-center">
            {T.translate('status.' + type).toUpperCase()}
        </Tooltip>
    );

    return (
        <OverlayTrigger trigger={['hover', 'focus']} key={`tooltip-trigger-sub-status-component-${type}`} placement="right"
                        overlay={tooltip}>
            <div className={`circle ${type}`}></div>
        </OverlayTrigger>
    );
}

export default StatusProductsPageComponent;