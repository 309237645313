import React, {useRef, useState} from 'react';
import './user-infos.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {connect} from "react-redux";
import {Button, Nav, NavItem, Spinner} from "react-bootstrap-v5";
import {Routes} from "../../../../router/router-constants";
import {authActions} from "../../../../redux/auth/actions-auth";
import T from 'i18n-react';
import NavUserItem from "./nav-user-item";
import {UserRole, UserRoleMessageKey} from "../../../../utils/enums/user-role";
import ButtonClose from "../../../buttons/button-close";
import {authService} from "../../../../services/auth-service";
import {ToastClass} from "../../../toast-alert/toast-alert-class";
import {alertActions} from "../../../../redux/alert/actions-alert";
import {auditTrailService} from "../../../../services/audit-trail-service";
import AuditTrailDto from "../../../../models/dto/audit-trail-dto";
import {ping} from "../../../../services/api/api";

const NavUser = ({currentUser, logout, showToast}) => {

    const linkRef = useRef();

    const [isLoading, setLoading] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState(false);

    const handleLogout = async () => {
        setLoading(true);
        try {
            await authService.logout();
            setLoading(false);
            logout();
        } catch (error) {
            setLoading(false);
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleDownloadAuditTrail = async () => {
        const auditData = new AuditTrailDto({companyId: currentUser.companyId});
        linkRef.current.href = auditTrailService.getExportCsvUrlByCompany(auditData);
        // ici on ping pour éventuellement forcer axios à appeler le refresh token et éviter sinon que le download envoie un 401 car c'est un appel backend direct
        await ping();
        linkRef.current.click();
    }

    const navMyProfile = (
        <NavUserItem title={T.translate('menu.myProfile')} subtitle={T.translate('menu.myProfileDescription')} icon="user"
                     route={Routes.PROFILE_PAGE} onNavigateToRoute={() => setShowUserMenu(false)}/>
    );

    const navMyCompany = (
        <NavUserItem title={T.translate('menu.myCompany')} subtitle={T.translate('menu.myCompanyDescription')} icon="building"
                     route={Routes.MY_COMPANY_PAGE} onNavigateToRoute={() => setShowUserMenu(false)}/>
    );

    const navMyUsers = (
        <NavUserItem title={T.translate('menu.myUsers')} subtitle={T.translate('menu.myUsersDescription')} icon="user-friends"
                     route={Routes.USERS_PAGE} onNavigateToRoute={() => setShowUserMenu(false)}/>
    );

    const navCompanies = (
        <NavUserItem title={T.translate('menu.companies')} subtitle={T.translate('menu.companiesDescription')} icon="building"
                     route={Routes.COMPANIES_PAGE} onNavigateToRoute={() => setShowUserMenu(false)}/>
    );

    const navUsers = (
        <NavUserItem title={T.translate('menu.users')} subtitle={T.translate('menu.usersDescription')} icon="user-friends"
                     route={Routes.USERS_PAGE} onNavigateToRoute={() => setShowUserMenu(false)}/>
    );

    const navAuditTrail = (
        <React.Fragment>
            <a ref={linkRef} href="#0" hidden={true} download>download</a>
            <div className="nav-link d-flex w-100 align-items-center" onClick={handleDownloadAuditTrail}>
                <div className="icon me-2"><FontAwesomeIcon icon="user-circle"/></div>
                <div>
                    <div className="title">{T.translate('menu.auditTrail')}</div>
                    <div className="description">{T.translate('menu.auditTrailDescription')}</div>
                </div>
            </div>
        </React.Fragment>
    );

    const navImportProducts = (
        <NavUserItem title={T.translate('menu.imports')} subtitle={T.translate('menu.importsDescription')} icon="file-import"
                    route={Routes.IMPORTS_PAGE} onNavigateToRoute={() => setShowUserMenu(false)}/>
    );


    return (
        <Nav id="nav-user">
            <NavItem className="d-inline-block cursor-pointer px-4 pe-lg-2 pe-xl-4" onClick={() => setShowUserMenu(true)}>
                <div className="d-inline-block align-middle px-3">{currentUser.firstname} {currentUser.lastname}</div>
            </NavItem>
            <div hidden={!showUserMenu} className="overlay w-100 h-100 position-fixed top-0 end-0" onClick={() => setShowUserMenu(false)}>
                <div id="menu-profile" hidden={!showUserMenu} className="position-absolute top-0 end-0 bg-white h-100 p-4"
                     onClick={e => e.stopPropagation()}>
                    <ButtonClose onClick={() => setShowUserMenu(false)}/>
                    <div className="title ps-2">{T.translate('menu.profile')}</div>
                    <div className="pb-2 px-2 mb-4">
                        <div>
                            <div className="user-name">{currentUser.firstname} {currentUser.lastname}</div>
                            <div className="user-role">{T.translate(UserRoleMessageKey[currentUser.role])}</div>
                            <div className="user-email mb-4">{currentUser.email}</div>
                            <Button className="btn-icon d-flex p-0 w-100" onClick={handleLogout} disabled={isLoading}>
                                <div className="icon">
                                    {isLoading ? <div className="spinner"><Spinner animation="border" size="sm"/></div> : <FontAwesomeIcon icon="power-off"/>}
                                </div>
                                <div className="px-3 align-self-center">{T.translate('menu.logout')}</div>
                            </Button>
                        </div>
                    </div>
                    <hr className="mx-2"/>
                    <Nav className="d-block">
                        {currentUser.role === UserRole.SUPER_ADMINISTRATOR && (
                            <React.Fragment>
                                {navMyProfile}
                                {navCompanies}
                                {navUsers}
                            </React.Fragment>
                        )}
                        {currentUser.role === UserRole.COMPANY_ADMINISTRATOR && (
                            <React.Fragment>
                                {navMyProfile}
                                {navMyCompany}
                                {navMyUsers}
                            </React.Fragment>
                        )}
                        {(currentUser.role === UserRole.SUPERVISOR || currentUser.role === UserRole.CONTRIBUTOR || currentUser.role === UserRole.VISITOR || currentUser.role === UserRole.READER) && (
                            <React.Fragment>
                                {navMyProfile}
                            </React.Fragment>
                        )}
                    </Nav>
                    {(currentUser.role === UserRole.COMPANY_ADMINISTRATOR || currentUser.role === UserRole.SUPERVISOR) && (
                        <React.Fragment>
                            <hr className="mx-2"/>
                            <Nav className="d-block">
                                {navAuditTrail}
                            </Nav>
                        </React.Fragment>
                    )}
                    {(currentUser.role === UserRole.COMPANY_ADMINISTRATOR) && (
                         <React.Fragment>
                            <Nav className="d-block">
                                {navImportProducts}
                            </Nav>
                         </React.Fragment>
                    )}
                    <div className="app-version position-absolute bottom-0 start-0 end-0 text-center">
                        {T.translate('app.version', {version: process.env.REACT_APP_VERSION})}
                    </div>
                </div>
            </div>
        </Nav>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(authActions.authLogout()),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavUser);