import {connect} from "react-redux";
import {alertActions} from "../../../redux/alert/actions-alert";
import {laboratoryActions} from "../../../redux/laboratory/actions-laboratory";
import T from "i18n-react";
import React, {useCallback} from "react";
import {analyseService} from "../../../services/analyse-service";
import {Button, Card} from "react-bootstrap-v5";
import RemoteTable from "../../../components/table/remote-table";
import Moment from "react-moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Routes} from "../../../router/router-constants";
import {useHistory} from "react-router-dom";

const TableAnalysesListInProgress = ({className, searchText, progress, setAnalysisInProgressNumber}) => {
    const history = useHistory();

    let columns = [
        {
            dataField: T.translate('table.fields.analyse.inProgress.orderDate').toString(),
            text: T.translate('table.columns.lot.orderDate').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.inProgress.analysisName').toString(),
            text: T.translate('table.columns.analyse.name').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.inProgress.analysisMethod').toString(),
            text: T.translate('table.columns.method').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.inProgress.laboratoryName').toString(),
            text: T.translate('table.columns.analyse.laboratory').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.inProgress.reference').toString(),
            text: T.translate('table.columns.reference').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.lot.orderNumber').toString(),
            text: T.translate('table.columns.lot.orderNumber').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.inProgress.productName').toString(),
            text: T.translate('table.columns.lot.productName').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.inProgress.articleCode').toString(),
            text: T.translate('table.columns.product.articleCode').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.inProgress.lotNumber').toString(),
            text: T.translate('table.columns.lot.lotNumber').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.specifications').toString(),
            text: T.translate('table.columns.specifications').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.unit').toString(),
            text: T.translate('table.columns.unit').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.dueDate').toString(),
            text: T.translate('table.columns.shortDueDate').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.inProgress.endControlDate').toString(),
            text: T.translate('table.columns.lot.endControlDate').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.inProgress.dluo').toString(),
            text: T.translate('table.columns.lot.dluo').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.actions').toString(),
            text: T.translate('table.columns.actions').toString(),
            sort: false
        },
    ];


    const onFetchData = useCallback(async (page, size, sortedOrder, sortedField, searchText) => {

        const handleEditAnalysis = (lotId) => {
            history.push(`${Routes.LOTS_PAGE}/${lotId}`);
        }
        const completeElements = allElements => {
            return allElements.map(element => {
                return {
                    ...element,
                    id: element.lotAnalysisId + '-' + element.lot.lotId,
                    orderDate: !!element.orderDate ? <Moment>{element.orderDate}</Moment> : "-",
                    dueDate: !!element.dueDate ? <Moment>{element.dueDate}</Moment> : "-",
                    lot: {
                        ...element.lot,
                        endControlDate: !element.lot.endControlDate ? "-" : <Moment>{element.lot.endControlDate}</Moment>,
                        dluo: !!element.lot.dluo ? <Moment>{element.lot.dluo}</Moment> : "-"
                    },
                    actions: (
                        <div className="actions">
                            <Button size="sm" className="edit" onClick={() => handleEditAnalysis(element.lot.lotId)}>
                                <FontAwesomeIcon icon="edit"/>
                            </Button>
                        </div>
                    )
                }
            })
        }
        const data = await analyseService.getAllInProgress(page, size, sortedOrder, sortedField, searchText);

        setAnalysisInProgressNumber(data.totalElements);

        const elements = completeElements(data.content);
        return {
            ...data,
            elements: elements
        };
    }, [setAnalysisInProgressNumber, history])
    return (
        <Card className={className}>
            <RemoteTable id="table-analysis-list-in-progress"
                         defaultSortedField={T.translate('table.fields.analyse.inProgress.lotNumber')}
                         columns={columns} onFetchData={onFetchData} reload={progress} searchText={searchText}/>
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        progress: state.analyseReducer.progress
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        setAnalysisInProgressNumber: (nbOfAnalysisInProgress) => dispatch(laboratoryActions.setAnalysisInProgressNumber(nbOfAnalysisInProgress))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableAnalysesListInProgress);
