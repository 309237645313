import React from 'react';
import T from 'i18n-react'
import CardTabsInfos from "../../../components/card-infos/card-tabs-infos";
import EditableText from "../../../components/editable-text/editable-text";
import {connect} from "react-redux";
import {UserRole} from "../../../utils/enums/user-role";
import Moment from "react-moment";
import {StatusLot} from "../../../utils/enums/status-lot";
import CardTabs from "../../../components/card-infos/card-tabs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EditableTextArea from "../../../components/editable-text/editable-textarea";
import DateInput from "../../../components/input-label/date-input";

const CardLotInfosAndComments = ({className, lot, currentUser, onEditValue, onEditComment}) => {

    const handleSupplierLotNumberChange = async supplierLotNumber => {
        onEditValue("supplierLotNumber", {supplierLotNumber}, supplierLotNumber);
    }

    const handleSupplierChange = async supplier => {
        onEditValue("supplier", {supplier}, supplier);
    }

    const handleManufacturingDateChange = async date => {
        const manufacturingDate = date ?? '';
        onEditValue("manufacturingDate", {manufacturingDate}, manufacturingDate);
    }

    const handleDluoChange = async date => {
        const dluo = date ?? '';
        onEditValue("dluo", {dluo}, dluo);
    }

    const canEdit = currentUser.role !== UserRole.VISITOR && lot.status !== StatusLot.CLOSED && (currentUser.role !== UserRole.CONTRIBUTOR || lot.status !== StatusLot.TO_CLOSE);

    const dataInfos = [
        {
            label: T.translate('table.columns.product.articleCode'),
            component: <div>{lot.articleCode}</div>
        },
        {
            label: T.translate('table.columns.category'),
            component: <div>{lot.category}</div>
        },
        {
            label: T.translate('table.columns.supplier'),
            component: !canEdit ? <div>{lot.supplier}</div> :
                <EditableText id="lotSupplier" defaultValue={lot.supplier} onSubmit={handleSupplierChange} shouldSubmitOnBlur/>
        },
        {
            label: T.translate('table.columns.lot.supplierLotNumber'),
            component: !canEdit ? <div>{lot.supplierLotNumber}</div> :
                <EditableText id="lotSupplierLotNumber" defaultValue={lot.supplierLotNumber} onSubmit={handleSupplierLotNumberChange} shouldSubmitOnBlur/>
        },
        {
            label: T.translate('table.columns.lot.creationDate'),
            component: !!lot.creationDate && <div><Moment>{lot.creationDate}</Moment></div>
        },
        {
            label: T.translate('table.columns.manufacturingDate'),
            component: canEdit ? <DateInput className="editable-date" id="lotManufacturingDate" defaultValue={lot.manufacturingDate} onChange={handleManufacturingDateChange}/> :
                !!lot.manufacturingDate && <div><Moment>{lot.manufacturingDate}</Moment></div>
        },
        {
            label: (
                <div className={!!lot.lifeDuration ? "extended-label" : ""}>
                    {T.translate('table.columns.dluo')}
                    <div className={`small-text ${!!lot.lifeDuration ? "" : "d-none"}`}>{lot.lifeDuration}</div>
                </div>
            ),
            component: canEdit ? <DateInput className="editable-date" id="dluo" defaultValue={lot.dluo} onChange={handleDluoChange}/> :
                !!lot.dluo && <div><Moment>{lot.dluo}</Moment></div>
        }
    ];

    return (
        <CardTabs className={className} tabs={[
            {
                key: "infos",
                title: T.translate('title.cardInfos'),
                content: <CardTabsInfos data={dataInfos}/>,
            },
            {
                key: "comments",
                title: (
                    <div>
                        {T.translate('title.comments')}
                        {(!!lot.comment || !!lot.productComment) && <FontAwesomeIcon icon="comment" className="ms-2"/>}
                    </div>
                ),
                content: (
                    <div className="pt-3">
                        <div className="title ps-4 ms-2">{T.translate('lot.label.productComment')}</div>
                        <div className="enable-line-break ps-4 ms-2">{!!lot.productComment ? lot.productComment : "-"}</div>
                        <div className="title ps-4 ms-2 mt-2">{T.translate('lot.label.lotComment')}</div>
                        {!canEdit ?
                            <div className="enable-line-break ps-4 ms-2">{!!lot.comment ? lot.comment : "-"}</div> :
                            <EditableTextArea id="lotComment" className="p-3" defaultValue={lot.comment} onSubmit={onEditComment} shouldSubmitOnBlur/>
                        }
                        {lot.status === StatusLot.CLOSED && (
                            <React.Fragment>
                                <div className="title ps-4 ms-2 mt-2">{T.translate('form.label.commentCoA')}</div>
                                <div className="enable-line-break ps-4 ms-2">{!!lot.closingReasonComment ? lot.closingReasonComment : "-"}</div>
                            </React.Fragment>
                        )}
                    </div>
                ),
            },
        ]}/>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser
    }
}

export default connect(mapStateToProps)(CardLotInfosAndComments);