import React, {useState} from "react";
import Header from '../../components/header/header'
import PageWithMenuTemplate from "../../components/template-pages/page-with-menu-template";
import T from 'i18n-react'
import {Button} from "react-bootstrap-v5";
import TableCompanyList from "./components/table-company-list";
import {connect} from "react-redux";
import {companyActions} from "../../redux/company/actions-company";
import HeaderTextLabel from "../../components/header/header-text-label";
import SearchInput from "../../components/input-label/search-input";

const CompaniesPage = ({showCreateCompanyModal}) => {
    const [searchText, setSearchText] = useState('');

    const handleSearchChange = e => {
        const text = e.target.value;
        if (text.length === 0 || text.length >= 3) {
            setSearchText(text);
        }
    }

    const createCompanyButton = (
        <Button variant="success" className="px-4" onClick={() => showCreateCompanyModal()}>{T.translate('company.button.createCompany')}</Button>
    );

    return (
        <PageWithMenuTemplate pageTitle={T.translate('pageTitle.companies')}>
            <Header>
                <HeaderTextLabel className="me-4" isMain={true}>
                    <h1 className="ps-1">{T.translate('menu.companies')}</h1>
                </HeaderTextLabel>
            </Header>
            <Header button={createCompanyButton}>
                <div className="search-container">
                    <SearchInput onChange={handleSearchChange}/>
                </div>
            </Header>
            <TableCompanyList className="mx-3" searchText={searchText}/>
        </PageWithMenuTemplate>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        showCreateCompanyModal: () => dispatch(companyActions.companyCreate())
    }
}

export default connect(null, mapDispatchToProps)(CompaniesPage);
