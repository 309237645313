export default class CompanyDto {
    constructor(companyData) {
        this.name = companyData?.name ?? '';
        this.companyContactDetails = {
            postalAddress: companyData?.contactAddress,
            phoneNumber: companyData?.contactPhone,
            email: companyData?.contactEmail ?? ''
        };
        this.billingContactDetails = {
            email: companyData?.billingContactEmail,
            firstname: companyData?.billingContactFirstname,
            lastname: companyData?.billingContactLastname,
            phoneNumber: companyData?.billingContactPhone
        };
        this.reportContactDetails = {
            email: companyData?.reportContactEmail,
            firstname: companyData?.reportContactFirstname,
            lastname: companyData?.reportContactLastname,
            phoneNumber: companyData?.reportContactPhone
        };
        this.website = companyData?.website;
        this.deliveryAccountNumber = companyData?.deliveryAccountNumber;
        this.legalInformationsDetails = {
            socialName: companyData?.socialName ?? '',
            rcsNumber: companyData?.rcsNumber ?? '',
            legalForm: companyData?.legalForm ?? '',
            socialCapital: companyData?.socialCapital ?? '',
            vatNumber: companyData?.vatNumber ?? ''
        };
        this.currencyCode = companyData?.currency;
        this.lotUnicity = companyData?.lotUnicity;
        this.customTemplates = companyData?.customTemplates;
    }
}