import React, {useState} from 'react';
import {Button, Card, Image, Tab, Tabs} from "react-bootstrap-v5";
import T from "i18n-react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {UserRole} from "../../../utils/enums/user-role";
import {connect} from "react-redux";
import {lotService} from "../../../services/lot-service";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {alertActions} from "../../../redux/alert/actions-alert";
import TableControlsLotList from "./table-controls-lot-list";
import {lotActions} from "../../../redux/lot/actions-lot";
import {analyseService} from "../../../services/analyse-service";
import {SortDirection} from "../../../utils/enums/sort-direction";
import PaneAddAnalysis from "../../../components/pane-add-analysis/pane-add-analysis";
import TableAdditionalInformationsList from "../../../components/additional-informations/table-additional-information-list";
import ButtonMoreOptions from "../../../components/buttons/button-more-options";
import {StatusLot} from "../../../utils/enums/status-lot";
import transferIcon from "../../../assets/images/icons/transfer.svg";
import addIcon from "../../../assets/images/icons/add-circled-outline.svg";
import TransferResultsModal from "./transfer-results-modal";
import CancelTransferResultsModal from "./cancel-transfer-results-modal";

const CardLotControlPlan = ({
                                className,
                                companyId,
                                lotId,
                                onGenerateOrders,
                                onCanceledTransfer,
                                currentUser,
                                showToast,
                                addedAnalysisToLot,
                                lotEntity,
                                selectedAnalysisNumber,
                                reload
                            }) => {

    const [showAddAnalysisPane, setShowAddAnalysisPane] = useState(false);
    const [showTransferResultsModal, setShowTransferResultsModal] = useState(false);
    const [showCancelTransferResultsModal, setShowCancelTransferResultsModal] = useState(false);
    const [showAdditionalInformationModal, setShowAdditionalInformationModal] = useState(false);
    const [isCreationMode, setIsCreationMode] = useState(false);
    const [additionalInformationToEdit, setAdditionalInformationToEdit] = useState(null);
    const [selectedTab, setSelectedTab] = useState('controls');

    const isClosedStatusLot = lotEntity.status === StatusLot.CLOSED;
    const canEdit = currentUser.role === UserRole.SUPER_ADMINISTRATOR || currentUser.role === UserRole.COMPANY_ADMINISTRATOR || currentUser.role === UserRole.SUPERVISOR;

    const handleChangeTab = tab => {
        setSelectedTab(tab);
    }

    const handleAddAnalysis = async (analysisList) => {
        const analysisIdList = analysisList.map(analyse => analyse.analysisId);
        await lotService.addAnalysisToLot(lotId, analysisIdList);
        addedAnalysisToLot();
        showToast(T.translate('alert.successTitle'), T.translate('lot.successAddAnalysis', {count: analysisIdList.length}), ToastClass.SUCCESS);
    }

    const onFetchAnalysis = async (searchText) => {
        const data = await analyseService.getAll(1, 50, SortDirection.ASC.toUpperCase(), 'analysisInfo.name', searchText, companyId, false);
        return data.content;
    }

    const onCreateAdditionalInformationModal = () => {
        setIsCreationMode(true);
        setAdditionalInformationToEdit(null);
        setShowAdditionalInformationModal(true);
    }

    const onEditAdditionalInformation = additionalInformation => {
        setIsCreationMode(false);
        setAdditionalInformationToEdit(additionalInformation);
        setShowAdditionalInformationModal(true);
    }

    const onHideAdditionalInformationModal = () => {
        setAdditionalInformationToEdit(null);
        setShowAdditionalInformationModal(false);
    }

    const handleShowAddAnalysisPane = () => setShowAddAnalysisPane(true);
    const handleHidePane = () => setShowAddAnalysisPane(false);

    const handleShowTransferResultsModal = () => setShowTransferResultsModal(true);
    const handleHideTransferResultsModal = () => setShowTransferResultsModal(false);

    const handleShowCancelTransferResultsModal = () => setShowCancelTransferResultsModal(true);
    const handleHideCancelTransferResultsModal = (success) => {
        setShowCancelTransferResultsModal(false);
        if (success) {
            onCanceledTransfer();
        }
        onCanceledTransfer();
        
    }

    let moreOptions = [];

    if (lotEntity.status !== StatusLot.CLOSED && (lotEntity.status !== StatusLot.TO_CLOSE || currentUser.role !== UserRole.CONTRIBUTOR)) {
        moreOptions = [
            ...moreOptions,
            {
                title: (
                    <div>
                        <Image src={addIcon} className="me-3" width={18}/>
                        {T.translate('product.button.addAnalysis')}
                    </div>
                ),
                onClick: handleShowAddAnalysisPane,
            },
        ];
    }

    if (canEdit || currentUser.role === UserRole.CONTRIBUTOR) {
        moreOptions = [
            ...moreOptions,
            {
                title: (
                    <div>
                        <Image src={transferIcon} className="me-3" width={18}/>
                        {T.translate('lot.button.transferResults')}
                    </div>
                ),
                onClick: handleShowTransferResultsModal,
            },
        ];
    }

    if ((canEdit || currentUser.role === UserRole.CONTRIBUTOR) && !!lotEntity.sourceLots && lotEntity.sourceLots.length > 0) {
        moreOptions = [
            ...moreOptions,
            {
                title: (
                    <div>
                        <Image src={addIcon} className="me-3 rotate-45" width={18}/>
                        {T.translate('lot.button.cancelLinkAndTransfer')}
                    </div>
                ),
                onClick: handleShowCancelTransferResultsModal,
            },
        ];
    }

    return (
        <React.Fragment>
            <Card className={`card-control-plan ${className}`}>
                <Card.Body className="pt-4 position-relative">
                    {selectedTab === 'controls' && currentUser.role !== UserRole.VISITOR && (
                        <div className="btn-right-container">
                            <Button variant="primary" className="me-3" onClick={onGenerateOrders}
                                    disabled={selectedAnalysisNumber === 0 || lotEntity.status === StatusLot.TO_CLOSE || lotEntity.status === StatusLot.CLOSED}>
                                {T.translate('lot.button.generateOrders')}
                            </Button>
                            <ButtonMoreOptions title={<FontAwesomeIcon icon="ellipsis-v"/>} buttonOptions={moreOptions}/>
                        </div>)}
                    {selectedTab === 'additionalInformations' && canEdit && !isClosedStatusLot && (<div className="btn-right-container">
                        <Button variant="success" className="px-4 mb-2 mb-sm-0" onClick={onCreateAdditionalInformationModal}>
                            <FontAwesomeIcon icon="plus-circle" className="me-2"/>
                            {T.translate('product.button.addAdditionalInformation')}
                        </Button>
                    </div>)}
                    <Tabs id="controls-tab" className="pt-2 px-4" defaultActiveKey="controls" onSelect={handleChangeTab} mountOnEnter={true}
                          unmountOnExit={false}>
                        <Tab eventKey="controls" title={T.translate('title.controls')}>
                            <Tab.Content>
                                {Object.keys(lotEntity).length !== 0 && <TableControlsLotList lotEntity={lotEntity}/>}
                            </Tab.Content>
                        </Tab>
                        <Tab eventKey="additionalInformations" title={T.translate('title.additionalInformations')} tabClassName="large">
                            <Tab.Content>
                                <TableAdditionalInformationsList isLot={true}
                                                                 showAdditionalInformationsModal={showAdditionalInformationModal}
                                                                 onHideAdditionalInformationsModal={onHideAdditionalInformationModal}
                                                                 onEditAdditionalInformation={onEditAdditionalInformation}
                                                                 isCreationMode={isCreationMode}
                                                                 additionalInformationToEdit={additionalInformationToEdit}
                                                                 isModificationInProgress={!isClosedStatusLot}
                                                                 />
                               
                            </Tab.Content>
                        </Tab>
                    </Tabs>
                </Card.Body>
            </Card>
            <PaneAddAnalysis show={showAddAnalysisPane} onHide={handleHidePane} onSubmit={handleAddAnalysis} companyId={companyId}
                             useDetails={false} allowMultipleSelection={true} onFetchAnalysis={onFetchAnalysis}/>
            <TransferResultsModal show={showTransferResultsModal} onHide={handleHideTransferResultsModal} lotSource={lotEntity}/>
            <CancelTransferResultsModal show={showCancelTransferResultsModal} onHide={handleHideCancelTransferResultsModal} lot={lotEntity}/>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser, 
        selectedAnalysisNumber: state.lotReducer.selectedAnalysisNumber
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        addedAnalysisToLot: () => dispatch(lotActions.addAnalysisToLot()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardLotControlPlan);
