import React, {useCallback, useEffect, useState} from 'react';
import T from "i18n-react";
import {connect} from "react-redux";
import {Alert, Button, Spinner, Table} from "react-bootstrap-v5";
import {useDrop} from "react-dnd";
import PaneSelectedAnalyseElement from "./pane-selected-analyse-element";
import PaneAnalyseElement from "./pane-analyse-element";
import {ToastClass} from "../toast-alert/toast-alert-class";
import {alertActions} from "../../redux/alert/actions-alert";
import ButtonClose from "../buttons/button-close";
import SearchInput from "../input-label/search-input";
import './pane-add-analysis.scss'
import {debounceFunc} from "../../utils/functions/debounce-function";

const DND_ITEM_TYPE = 'pane-element';

const PaneAddAnalysis = ({show, onFetchAnalysis, onHide, onSubmit, useDetails = false, allowMultipleSelection = true, showToast}) => {

    const [isLoading, setLoading] = useState({fetchData: false, submitData: false});
    const [apiError, setApiError] = useState('');
    const [inputError, setInputError] = useState('');
    const [analysisList, setAnalysisList] = useState([]);
    const [selectedAnalysisList, setSelectedAnalysisList] = useState([]);

    const getAnalysis = useCallback(async (searchText) => {
        const completeElements = allElements => {
            if (!useDetails) return allElements;
            return allElements.map(element => {
                return {
                    ...element,
                    price: 0,
                    delayInDays: 0,
                    quantityInGram: 0,
                    reference: ""
                }
            });
        }

        const callApi = async () => {
            try {
                let data = await onFetchAnalysis(searchText);
                setAnalysisList(completeElements(data));
                setLoading(prevState => {
                    return {...prevState, fetchData: false}
                });
            } catch (error) {
                if (error.response.status === "cancel") return;
                setApiError(error.message);
                setLoading(prevState => {
                    return {...prevState, fetchData: false}
                });
            }
        }

        const optimizedCallApi = debounceFunc(callApi, 'pane-add-analysis');

        setLoading(prevState => {
            return {...prevState, fetchData: true}
        });
        optimizedCallApi();
    }, [onFetchAnalysis, useDetails])

    useEffect(() => {
        if (show) {
            getAnalysis('');
        }
    }, [show, getAnalysis]);

    const handleSearchChange = e => {
        const searchText = e.target.value;
        if (searchText.length === 0 || searchText.length >= 3) {
            getAnalysis(searchText);
        }
    }

    const handleCancelSelectedAnalysis = () => {
        setLoading({fetchData: false, submitData: false});
        setApiError('');
        setInputError('');
        setSelectedAnalysisList([]);
        onHide();
    }

    const handleSubmitSelectedAnalysis = async () => {
        setInputError('');
        if (selectedAnalysisList.length === 0) {
            setInputError(T.translate('error.product.addAnalysis').toString());
            return;
        }
        try {
            setLoading({...isLoading, submitData: true});
            await onSubmit(selectedAnalysisList);
            setSelectedAnalysisList([]);
            onHide();
            setLoading({...isLoading, submitData: false});
        } catch (error) {
            setLoading({...isLoading, submitData: false});
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const [{hovering}, dropRef] = useDrop(() => ({
        accept: DND_ITEM_TYPE,
        drop: ({analyse}, monitor) => {
            setSelectedAnalysisList(prevState => [...prevState, analyse]);
            if (!allowMultipleSelection) {
                setAnalysisList(prevState => {
                    let newList = [...prevState];
                    const index = newList.indexOf(analyse);
                    if (index >= 0) {
                        newList.splice(index, 1);
                    }
                    return newList;
                })
            }
        },
        collect: (monitor) => ({
            hovering: monitor.isOver() ? "hovering" : ""
        })
    }));

    const handleUnselectItem = (index, analyse) => {
        const newSelectedAnalysisList = [...selectedAnalysisList];
        newSelectedAnalysisList.splice(index, 1);
        setSelectedAnalysisList(newSelectedAnalysisList);
        if (!allowMultipleSelection) {
            setAnalysisList(prevState => [...prevState, analyse]);
        }
    }

    const handleAnalyseChanged = (index, newAnalyse) => {
        const newSelectedAnalysisList = [...selectedAnalysisList];
        newSelectedAnalysisList[index] = newAnalyse;
        setSelectedAnalysisList(newSelectedAnalysisList);
    }

    return (
        <div hidden={!show} className="overlay w-100 h-100 position-fixed top-0 end-0">
            <div id="pane-analysis" hidden={!show} className="bg-white p-2 pt-4 p-lg-4"
                 onClick={e => e.stopPropagation()}>
                <ButtonClose onClick={onHide} className="position-absolute"/>
                <div className="title">{T.translate('product.title.addAnalysis')}</div>
                <SearchInput className="mt-2" onChange={handleSearchChange}/>
                <div className="mt-4 text-center">
                    {isLoading.fetchData && <Spinner animation="border"/>}
                    <Alert variant="danger" hidden={!apiError}>
                        <Alert.Heading>
                            {T.translate("alert.errorTitle")}
                        </Alert.Heading>
                        {apiError}
                    </Alert>
                    {analysisList.length === 0 ? <div>{T.translate('table.empty')}</div> : (
                        <Table>
                            <tbody>
                            {analysisList.map(analyse => (
                                <PaneAnalyseElement analyse={analyse} dndItemType={DND_ITEM_TYPE}/>
                            ))}
                            </tbody>
                        </Table>
                    )}
                </div>
            </div>
            <div id="drop-section" ref={dropRef} className={`d-flex flex-column p-2 p-lg-3 ${hovering} ${!!inputError ? "error" : ""}`}>
                <div className="drop-area d-flex flex-column">
                    <div className="title m-2">{T.translate('product.title.slideAnalysisHere')}</div>
                    <div className="dropped-elements p-2">
                        {selectedAnalysisList.map((analyse, index) => (
                            <PaneSelectedAnalyseElement analyse={analyse} useDetails={useDetails}
                                                        onAnalyseChanged={newAnalyse => handleAnalyseChanged(index, newAnalyse)}
                                                        onUnselectItem={() => handleUnselectItem(index, analyse)}/>
                        ))}
                    </div>
                </div>
                <div className="d-lg-flex justify-content-between mt-2">
                    <div className="invalid-feedback d-block">{inputError}</div>
                    <div className="actions text-end flex-shrink-0 mt-2 mt-lg-0">
                        <div className="count-dropped-elements d-lg-inline-block mb-2 mb-lg-0 mx-lg-3">
                            {T.translate('product.countDroppedAnalysis', {count: selectedAnalysisList.length})}
                        </div>
                        <Button variant="secondary me-2" onClick={handleCancelSelectedAnalysis}>
                            {T.translate('form.button.cancel')}
                        </Button>
                        <Button variant="success" onClick={handleSubmitSelectedAnalysis}>
                            {isLoading.submitData && <Spinner as="span" size="sm" animation="border" className="me-2"/>}
                            {T.translate('form.button.confirm')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaneAddAnalysis);
