import React, {useEffect, useRef, useState} from 'react';
import {Form, FormControl} from "react-bootstrap-v5";
import './editable-text.scss'
import {InputMaxLength} from "../../utils/enums/input-max-length";
import T from "i18n-react";

const EditableText = ({
                          className = '',
                          id,
                          type = 'text',
                          adjustWidthWithText = false,
                          defaultValue,
                          editionMode,
                          onSubmit,
                          onCancel,
                          onFocus,
                          onCancelConfirmDialog,
                          shouldSubmitOnBlur,
                          error,
                          reload,
                          placeholder = "",
                          maxLength = InputMaxLength.STANDARD_TEXT,
                          forceUpdate
                      }) => {

    const spanRef = useRef(null);

    const [width, setWidth] = useState(0);
    const [editing, setEditing] = useState(!!editionMode ? editionMode : false);
    const [currentValue, setCurrentValue] = useState(defaultValue);

    useEffect(() => setCurrentValue(defaultValue), [defaultValue, reload]);
    useEffect(() => {
        let newWidth = spanRef.current?.getBoundingClientRect().width + 4;
        if (type === "date") {
            newWidth += 60;
        }
        setWidth(newWidth);
    }, [currentValue, type, editing]);

    useEffect(() => {
        if (!!onCancelConfirmDialog) {
            setCurrentValue(defaultValue);
            setEditing(false);
        }
    }, [onCancelConfirmDialog, defaultValue]);

    useEffect(() => {
        if (forceUpdate) {
            setCurrentValue(defaultValue);
        }
    }, [forceUpdate, defaultValue]);

    useEffect(() => {
        if (editionMode !== undefined && editionMode !== null && editing !== editionMode) {
            setEditing(editionMode);
        }
    }, [editionMode, editing])

    const handleClick = e => {
        setEditing(true);
        e.stopPropagation();
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            if (!!onFocus) {
                onFocus(event, currentValue);
                setEditing(false);
            } else {
                handleSubmit(event);
            }
        } else if (event.key === 'Escape') {
            handleCancel(event);
        } else if (event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            if (!!onFocus) {
                onFocus(event, currentValue);
                setEditing(false);
            }
        }
    }

    const handleSubmit = event => {
        event.stopPropagation();
        if (currentValue !== defaultValue) {
            onSubmit(currentValue)
        }
        setEditing(false);
    }

    const handleCancel = event => {
        event.stopPropagation();
        setCurrentValue(defaultValue);
        setEditing(false);
        if (!!onCancel) {
            onCancel();
        }
        document.activeElement.blur();
    }

    const handleBlur = event => {
        if (!!shouldSubmitOnBlur) {
            if (!!onFocus) {
                onFocus(event, currentValue);
                setEditing(false);
            } else {
                handleSubmit(event);
            }
        }
    }

    return (
        <div className={`editable-text ${type === "date" ? "date" : ""} ${editing ? "editing" : ""}`} onClick={handleClick} style={{
            width: adjustWidthWithText ? `${width}px` : "auto"
        }}>
            <Form.Group controlId={id} className="position-relative">
                <div ref={spanRef} className="hidden-ref">{type === "date" ? T.translate('i18n.format.date') : currentValue}</div>
                <FormControl className={`${className} ${type === "date" && !currentValue ? "empty-date" : ""}`}
                             type={type} placeholder={placeholder} value={currentValue} onChange={e => setCurrentValue(e.target.value)}
                             onKeyDown={handleKeyPress} isInvalid={!!error} maxLength={maxLength} onBlur={handleBlur}/>
            </Form.Group>
            <FormControl.Feedback type="invalid" className={!!error ? "d-block" : "d-none"}>{error}</FormControl.Feedback>
        </div>
    );
}

export default EditableText;