export default class LaboratoryDto {
    constructor(laboratoryData) {
        this.companyId = laboratoryData?.company?.id ?? ''
        this.name = laboratoryData?.name ?? ''
        this.address = laboratoryData?.address ?? ''
        this.phoneNumber = laboratoryData?.phone ?? ''
        this.contact = laboratoryData?.contact ?? ''
        this.functionName = laboratoryData?.role ?? ''
        this.email = laboratoryData?.email ?? ''
        this.customerNumber = laboratoryData?.customerNumber ?? ''
    }
}