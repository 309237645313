import React, {useCallback, useEffect, useRef, useState} from 'react';
import './card-indicator.scss'
import PieChart from "../charts/pie-chart";
import T from "i18n-react";

const IndicatorPieChart = ({title, currentYear, lastYear}) => {
    const lastYearDate = new Date().getFullYear() - 1;

    const ref = useRef(null);
    const [pieChartSize, setPieChartSize] = useState();

    const handleResize = useCallback(() => {
        setPieChartSize(ref.current.offsetWidth / 2);
    }, [ref]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize]);

    useEffect(() => {
        handleResize();
    }, [handleResize]);

    return (
        <div className="indicator indicator-pie-chart p-3 h-100 d-flex flex-column justify-content-between" ref={ref}>
            <div className="title mb-3">{title}</div>
            <PieChart data={currentYear} size={pieChartSize}/>
            <div className="lastYear mt-2">{lastYearDate}: <span>{T.translate('indicators.percent', {nb: lastYear})}</span></div>
        </div>
    );
};

export default IndicatorPieChart;
