export const StatusLot = {
    NEW: "NEW",
    IN_PROGRESS: "IN_PROGRESS",
    TO_CLOSE: "TO_CLOSE",
    CLOSED: "CLOSED"
}

export const StatusLotClassname = {
    NEW: "new",
    IN_PROGRESS: "inProgress",
    TO_CLOSE: "validated",
    CLOSED: "closed"
}