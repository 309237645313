import React, {useCallback, useEffect, useState} from 'react';
import CustomModal from "../../../components/custom-modal/custom-modal";
import {connect} from "react-redux";
import {documentActions} from "../../../redux/document/actions-document";
import T from 'i18n-react'
import {Alert, Button, Col, Form, Row, Spinner} from "react-bootstrap-v5";
import InputLabel from "../../../components/input-label/input-label";
import TextareaLabel from "../../../components/input-label/textarea-label";
import Validator from "../../../utils/validators/validator";
import {FileType} from "../../../components/input-label/file-type";
import InputFileLabel from "../../../components/input-label/input-file-label";
import {documentService} from "../../../services/document-service";
import {alertActions} from "../../../redux/alert/actions-alert";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import ReadonlyLabel from "../../../components/input-label/readonly-label";
import Moment from "react-moment";
import {InputMaxLength} from "../../../utils/enums/input-max-length";

const CreateOrEditDocumentModal = ({documentReducer, hideCreateOrEditDocumentModal, showToast}) => {

    const [isLoading, setLoading] = useState(false);
    const [apiError, setApiError] = useState('');

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);

    const [inputErrors, setInputErrors] = useState({name: '', file: ''});

    const resetErrors = () => {
        setInputErrors({name: '', file: ''});
        setApiError('');
    }

    const resetForm = useCallback(
        () => {
            setName(documentReducer.document?.name ?? '');
            setDescription(documentReducer.document?.description ?? '');
            setFile(null);
        }, [documentReducer.document],
    );


    useEffect(() => resetForm(), [resetForm, documentReducer.document]);

    const validate = () => {
        const nameError = Validator.validateRequired(name);
        const fileError = documentReducer.isCreationMode ? Validator.validateRequiredPDFFile(file) : '';
        const isValid = !nameError && !fileError;
        setInputErrors({
            name: T.translate(nameError).toString(),
            file: T.translate(fileError).toString()
        })
        return isValid;
    }

    const addDocument = async (documentData) => {
        setLoading(true);
        try {
            await documentService.createDocument(documentData)
            setLoading(false);
            hideModal();
            showToast(T.translate('alert.successTitle'), T.translate('document.successAddDocument', {documentName: name}), ToastClass.SUCCESS)
        } catch (error) {
            setApiError(error.message)
            setLoading(false)
        }
    }

    const editDocument = async (id, documentData) => {
        setLoading(true);
        try {
            await documentService.editDocument(id, documentData)
            setLoading(false);
            hideModal();
            showToast(T.translate('alert.successTitle'), T.translate('document.successEditDocument', {documentName: name}), ToastClass.SUCCESS)
        } catch (error) {
            setApiError(error.message)
            setLoading(false)
        }
    }

    const submitForm = e => {
        e.preventDefault();

        resetErrors();
        if (validate()) {
            if (documentReducer.isCreationMode) {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('documentType', documentReducer.documentType);
                formData.append('name', name);
                formData.append('companyId', documentReducer.companyId);
                formData.append('entityId', documentReducer.entityId);
                formData.append('description', description);
                addDocument(formData);
            } else {
                const id = documentReducer.document?.documentId;
                const documentData = {name, description};
                editDocument(id, documentData)
            }
        }
    }

    const hideModal = () => {
        resetErrors();
        resetForm();
        hideCreateOrEditDocumentModal();
    }

    const footer = (
        <React.Fragment>
            <Button variant="outline-secondary" onClick={hideModal} className="px-3 py-1">
                {T.translate('form.button.cancel')}
            </Button>
            <Button variant="primary" onClick={submitForm} className="px-3 py-1" disabled={isLoading}>
                {isLoading ? <Spinner as="span" size="sm" animation="border"/> : ""}
                {T.translate('form.button.confirm')}
            </Button>
        </React.Fragment>
    );

    return (

        <CustomModal show={documentReducer.showCreateOrEditModal} onHide={hideModal} footer={footer} title={T.translate('document.title.addDocument')}>
            <Form>
                <Alert variant={"danger"} hidden={!apiError}>
                    <Alert.Heading>
                        {T.translate("alert.errorTitle")}
                    </Alert.Heading>
                    {apiError}
                </Alert>
                <Row>
                    <Col sm={12} lg={8} className="offset-lg-1">
                        <InputFileLabel id="file" className="mb-4" label={T.translate('form.label.file')} required={true}
                                        onChange={val => setFile(val)} error={inputErrors.file} accept={FileType.PDF}
                                        defaultFileName={documentReducer.document?.originalFileName} readOnly={!documentReducer.isCreationMode}/>

                        {
                            !documentReducer.isCreationMode &&
                            <ReadonlyLabel className="mb-4" label={T.translate('form.label.createdDate')}
                                           value={!!documentReducer.document?.createdDate ?
                                               <Moment format={T.translate('i18n.format.dateWithFullMonth').toString()}>{documentReducer.document.createdDate}</Moment> : "-"}/>
                        }

                        <InputLabel id="name" className="mb-4" type="text" label={T.translate('form.label.document.name')} required={true}
                                    value={name} onChange={e => setName(e.target.value)} error={inputErrors.name}/>

                        <TextareaLabel id="description" className="mb-4" label={T.translate('form.label.description')}
                                       value={description} onChange={e => setDescription(e.target.value)} maxLength={InputMaxLength.DESCRIPTION}/>
                    </Col>
                </Row>
            </Form>
        </CustomModal>
    );
}

const mapStateToProps = state => {
    return {
        documentReducer: state.documentReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideCreateOrEditDocumentModal: () => dispatch(documentActions.hideCreateOrEditDocumentModal()),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrEditDocumentModal);