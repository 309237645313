import {privateAPI} from "./api/api";
import {ApiErrorHandler} from "./api/api-error";

const BASE_URL = '/products-import/';
const config = {
    headers: {
        'accept': 'application/json',
        'content-type': 'multipart/form-data'
    }
}

const importProducts = async (formData) => {
    try {
        const response = await privateAPI.post(`${BASE_URL}import`, formData, config);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const deleteProductsFromImport = async (startDate, endDate) => {
    try {
        const response = await privateAPI.delete(`${BASE_URL}delete`, {
            params: {
                startDate,
                endDate
            }
        });

        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

export const productsImportService = {
    importProducts,
    deleteProductsFromImport

};
