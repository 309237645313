import T from 'i18n-react';

export class ApiErrorHandler {
    static getErrorMessage(error) {
        const statusCode = error.response.status;
        const classException = error.response?.data?.exceptionClass;
        const entityIdField = error.response?.data?.entityIdField;
        if (!!statusCode) {
            if (statusCode === 422 && !!classException) {
                return this.getErrorMessageFromStatus422(error, classException, entityIdField)
            }
            const messageKey = `error.http.${statusCode}`;
            const message = T.translate(messageKey).toString();
            if (message !== messageKey) {
                return message;
            }
        }
        return T.translate('error.unknown').toString();
    }

    static getErrorMessageFromStatus422(error, classException, entityIdField) {
        if (!!classException) {
            if (classException === "ObjectAlreadyExistsException" && !!entityIdField && entityIdField.includes("email")) {
                return T.translate('error.alreadyExists');
            }
            if (classException === "InvalidPhoneNumberException") {
                return T.translate('error.class.InvalidPhoneNumberException', {correctExample: error.response.data.correctExample});
            }
            if (classException === "IncorrectPasswordException") {
                error.response.data.entityIdField = "password";
            }
            if (classException === "IncompleteTitleMappingException") {
                if (error.response.data.entityIdField) {
                    return T.translate('error.class.IncompleteTitleMappingException', {column: error.response.data.entityIdField});
                } else {
                    return T.translate('error.class.WrongFormat');
                }
            }
            if (classException === "UnknownTitleMappingException") {
                return T.translate('error.class.UnknownTitleMappingException', {
                    labColumn: error.response.data.entityIdValue,
                    ciklabColumn: error.response.data.entityIdField
                });

            }
            const messageKey = `error.class.${classException}`;
            const message = T.translate(messageKey);
            if (message !== messageKey) return message;
        }
        return T.translate("error.http.422");
    }
}