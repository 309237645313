import React from 'react'
import {useDropzone} from 'react-dropzone'
import OverlayLoadingOrError from "../overlay-component/overlay-loading-or-error";
import T from "i18n-react";
import './custom-drop-zone.scss'

const CustomDropZone = ({acceptedTypes, children, onDrop, isLoading, error, onCloseError}) => {

    const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} = useDropzone({
        noClick: true,
        onDrop,
        accept: acceptedTypes
    })

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()}/>
            <div id="document-drop-section" className={`d-flex flex-column p-2 p-lg-3`}>
                <div
                    className={`drop-area d-flex flex-column ${isDragActive ? 'visible' : ''} ${isDragAccept ? 'accepted' : ''} ${isDragReject ? 'rejected' : ''}`}>
                    <div className={`title m-2`}>
                        {!isDragActive ?
                            T.translate('document.dropZone').toString()
                            : T.translate('document.dropZoneOnDrag').toString()}
                    </div>
                    {children}
                </div>
            </div>
            <OverlayLoadingOrError isLoading={isLoading} error={error} onCloseError={onCloseError}/>
        </div>
    )
}


export default CustomDropZone