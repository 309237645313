import React from 'react';
import './card-indicator.scss'
import BarChart from "../charts/bar-chart";

const IndicatorBarChart = ({title, currentYear, lastYear, labelFormat}) => {
    const currentYearDate = new Date().getFullYear();
    const lastYearDate = currentYearDate - 1;

    return (
        <div className="indicator indicator-bar-chart p-3 h-100 d-flex flex-column justify-content-between">
            <div className="title mb-3">{title}</div>
            <div className="px-3">
                <BarChart
                    dataset={[
                        {x: lastYearDate, y: lastYear},
                        {x: currentYearDate, y: currentYear},
                    ]}
                    labelFormat={labelFormat}/>
            </div>
            <div></div>
        </div>
    );
};

export default IndicatorBarChart;
