export const UserRole = {
    SUPER_ADMINISTRATOR: "SUPER_ADMINISTRATOR",
    COMPANY_ADMINISTRATOR: "COMPANY_ADMINISTRATOR",
    SUPERVISOR: "SUPERVISOR",
    CONTRIBUTOR: "CONTRIBUTOR",
    VISITOR: "VISITOR",
    READER: "READER",
    API_CONSUMER: "API_CONSUMER"
}

export const UserRoleMessageKey = {
    SUPER_ADMINISTRATOR: "userRole.superAdministrator",
    COMPANY_ADMINISTRATOR: "userRole.companyAdministrator",
    SUPERVISOR: "userRole.supervisor",
    CONTRIBUTOR: "userRole.contributor",
    VISITOR: "userRole.visitor",
    READER: "userRole.reader",
    API_CONSUMER: "userRole.apiConsumer"
}
