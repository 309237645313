import React from 'react';
import {VictoryAxis, VictoryBar, VictoryChart, VictoryLabel} from "victory";
import variables from '../../assets/styles/variables.scss';

const BarChart = ({dataset, labelFormat}) => {
    const currentYear = new Date().getFullYear();
    const colors = [variables.primary, variables.purpleGrey];

    return (
        <VictoryChart
            width={200}
            height={200}>
            <VictoryBar
                width={200}
                height={200}
                data={dataset}
                domain={{x: [currentYear - 1, currentYear]}}
                cornerRadius={{top: 5, bottom: 5}}
                barWidth={35}
                labels={({datum}) => labelFormat(Math.round(datum.y))}
                labelComponent={<VictoryLabel dy={-10}/>}
                style={{
                    data: {fill: ({datum}) => colors[(currentYear - datum.x) % colors.length]},
                    labels: {fill: ({datum}) => colors[(currentYear - datum.x) % colors.length], fontFamily: variables.fontFamily, fontWeight: "bold", fontSize: 20},
                }}
            />
            <VictoryAxis
                tickFormat={dataset.map(item => item.x)}
                tickLabelComponent={<VictoryLabel dy={10}/>}
                style={{
                    axis: {stroke: "transparent"},
                    tickLabels: {fill: variables.dark, fontFamily: variables.fontFamily},
                }}
            />
        </VictoryChart>
    );
};

export default BarChart;
