import React, {useEffect, useState} from 'react';
import {Form, FormControl} from "react-bootstrap-v5";
import './editable-text.scss'
import {InputMaxLength} from "../../utils/enums/input-max-length";

const EditableTextArea = ({
                              id,
                              className,
                              rows = 3,
                              defaultValue,
                              onSubmit,
                              onCancel,
                              shouldSubmitOnBlur,
                              error,
                              maxLength = InputMaxLength.STANDARD_TEXT_AREA
                          }) => {

    const [editing, setEditing] = useState(false);
    const [currentValue, setCurrentValue] = useState(defaultValue);

    useEffect(() => setCurrentValue(defaultValue), [defaultValue]);

    const handleClick = () => {
        setEditing(true);
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter' && event.shiftKey) return;
        if (event.key === 'Enter') {
            event.preventDefault();
            if (!!shouldSubmitOnBlur) {
                document.activeElement.blur();
            } else {
                handleSubmit(event);
            }
            return;
        }
        if (event.key === 'Escape') {
            handleCancel(event);
        }
    }

    const handleSubmit = event => {
        event.stopPropagation();
        if (currentValue !== defaultValue) {
            onSubmit(currentValue)
        }
        setEditing(false);
        document.activeElement.blur();
    }

    const handleCancel = event => {
        event.stopPropagation();
        setCurrentValue(defaultValue);
        setEditing(false);
        if (!!onCancel) {
            onCancel();
        }
        document.activeElement.blur();
    }

    const handleBlur = (event) => {
        if (!!shouldSubmitOnBlur) {
            handleSubmit(event);
        }
    }

    return (
        <div className={`editable-textarea ${editing ? "editing" : ""} ${className}`} onClick={handleClick}>
            <Form.Group controlId={id} className="position-relative">
                <FormControl as="textarea" rows={rows} placeholder="" value={currentValue} onChange={e => setCurrentValue(e.target.value)}
                             onKeyDown={handleKeyPress} isInvalid={!!error} maxLength={maxLength} onBlur={handleBlur}/>
            </Form.Group>
            <FormControl.Feedback type="invalid" className={!!error ? "d-block" : "d-none"}>{error}</FormControl.Feedback>
        </div>
    );
}

export default EditableTextArea;