import React, {useCallback} from 'react';
import {Button, Card} from "react-bootstrap-v5";
import RemoteTable from "../../../components/table/remote-table";
import StatusComponent from "../../../components/status-component/status-component";
import T from 'i18n-react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {alertActions} from "../../../redux/alert/actions-alert";
import {connect} from "react-redux";
import {confirmDialogActions} from "../../../redux/confirm-dialog/actions-confirm-dialog";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {userActions} from "../../../redux/user/actions-user";
import {UserRole, UserRoleMessageKey} from "../../../utils/enums/user-role";
import {userService} from "../../../services/user-service";
import {StatusActiveInactive, StatusActiveInactiveClassname} from "../../../utils/enums/status-active-inactive";

const TableUserList = ({className, searchText, showToast, currentUser, showEditUser, userDeleted, showConfirmDialog, progress}) => {

    let columns = [
        {
            dataField: T.translate('table.fields.lastname').toString(),
            text: T.translate('table.columns.lastname').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.firstname').toString(),
            text: T.translate('table.columns.firstname').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.role').toString(),
            text: T.translate('table.columns.role').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.email').toString(),
            text: T.translate('table.columns.email').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.status').toString(),
            text: T.translate('table.columns.status').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.actions').toString(),
            text: T.translate('table.columns.actions').toString()
        }
    ];

    if (currentUser.role === UserRole.SUPER_ADMINISTRATOR) {
        columns = [
            {
                dataField: T.translate('table.fields.user.companyName').toString(),
                text: T.translate('table.columns.company').toString(),
                sort: true
            },
            ...columns
        ];
    }

    const onFetchData = useCallback(async (page, size, sortedOrder, sortedField, searchText) => {
        const deleteUser = async user => {
            try {
                await userService.deleteUser(user.email);
                userDeleted();
                showToast(T.translate('alert.successTitle'), T.translate('user.successDeleteUser', {userName: `${user.firstname} ${user.lastname}`}), ToastClass.SUCCESS);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleDeleteUser = async (e, user) => {
            e.stopPropagation();
            showConfirmDialog(
                T.translate('dialog.title.confirmDelete'),
                T.translate('dialog.confirmDeleteUser', {userName: `${user.firstname} ${user.lastname}`}),
                () => {
                },
                () => deleteUser(user))
        }

        const handleEditUser = (e, user) => {
            e.stopPropagation();
            showEditUser(user);
        }

        const completeElements = allElements => {
            return allElements.map(user => {
                return {
                    ...user,
                    id: user.email,
                    role: T.translate(UserRoleMessageKey[user.role]),
                    activated: <StatusComponent type={StatusActiveInactiveClassname[user.activated ? StatusActiveInactive.ACTIVE : StatusActiveInactive.INACTIVE]}/>,
                    actions: user.email === currentUser.email ? "" : (
                        <div className="actions">
                            <Button size="sm" className="edit" onClick={e => handleEditUser(e, user)}>
                                <FontAwesomeIcon icon="edit"/>
                            </Button>
                            <Button size="sm"
                                    className={`delete ms-1 ${user.role !== UserRole.COMPANY_ADMINISTRATOR ? "" : "d-none"}`}
                                    onClick={e => handleDeleteUser(e, user)}>
                                <FontAwesomeIcon icon="times"/>
                            </Button>
                        </div>
                    )
                }
            })
        }

        const data = await userService.getAll(page, size, sortedOrder, sortedField, searchText);
        const elements = completeElements(data.content)
        return {
            ...data,
            elements: elements
        };
    }, [showEditUser, showToast, showConfirmDialog, userDeleted, currentUser])

    return (
        <Card className={className}>
            <RemoteTable id="table-user-list"
                         defaultSortedField={T.translate('table.fields.lastname')}
                         columns={columns} onFetchData={onFetchData} reload={progress} searchText={searchText}/>
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        progress: state.userReducer.progress
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        showEditUser: user => dispatch(userActions.userEdit(user)),
        userDeleted: () => dispatch(userActions.userDeleted()),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableUserList);
