import {ANALYSE_HISTORY_HIDE_HISTORY_MODAL, ANALYSE_HISTORY_SHOW_HISTORY_MODAL} from "./type";

const showAnalyseHistoryModal = (analyseId, parameterName, productId, lotId) => {
    return {
        type: ANALYSE_HISTORY_SHOW_HISTORY_MODAL,
        analyseId,
        parameterName,
        productId,
        lotId
    }
}

const hideAnalyseHistoryModal = () => {
    return {
        type: ANALYSE_HISTORY_HIDE_HISTORY_MODAL
    }
}

export const analyseHistoryActions = {
    showAnalyseHistoryModal,
    hideAnalyseHistoryModal
};