import React, { useCallback, useEffect, useState } from 'react';
import { connect } from "react-redux";
import T from 'i18n-react'
import { Alert, Row, Col, FormCheck } from "react-bootstrap-v5";
import { confirmDialogActions } from "../../../../redux/confirm-dialog/actions-confirm-dialog";
import { alertActions } from "../../../../redux/alert/actions-alert";
import { analyseActions } from "../../../../redux/analyse/actions-analyse";
import RemoteTable from "../../../../components/table/remote-table";
import { analyseService } from "../../../../services/analyse-service";
import { UserRole } from "../../../../utils/enums/user-role";
import { SortDirection } from "../../../../utils/enums/sort-direction";
import TableAnalysisProduct from "../table-analysis-product";
import AnalyseEntity from "../../../../models/entities/analyse-entity";
import LinkAnalysisSearchInput from "../link-analysis/link-analysis-search-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddAnalysisProductsTab = ({ analyseReducer, currentUser, showConfirmDialog, sendToCurrentAnalysisProductsTab, dataFromCurrentAnalysisProductsTab }) => {
    const [progress, setProgress] = useState(0);
    const [apiError, setApiError] = useState('');
    const [isSelectedProduct, setIsSelectedProduct] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [analyseEntity, setAnalyseEntity] = useState({});
    const [parameters, setParameters] = useState([]);
    const [searchText, setSearchText] = useState({
        category: '',
        productName: '',
        productNumber: '',
        productSupplier: ''
    });

    const canEditCheckbox = currentUser.role !== UserRole.VISITOR && currentUser.role !== UserRole.CONTRIBUTOR;


    const incrementProgress = () => {
        setProgress(prevState => prevState + 1);
        sendToCurrentAnalysisProductsTab(true);
        setSelectedProducts([]);
    };

    useEffect(() => {
        const getAnalyse = async () => {
            try {
                const analyseData = await analyseService.getAnalyse(analyseReducer.analyseId)
                const analyseFetched = new AnalyseEntity(analyseData);
                setAnalyseEntity(analyseFetched);
            } catch (error) {
                setApiError(error.message);
            }
        }
        if (!analyseReducer.showLinkModal) {
            setSelectedProducts([]);
        }
        if (analyseReducer.analyseId) {
            getAnalyse()
        }
    }, [analyseReducer.showLinkModal, analyseReducer.analyseId]);

    useEffect(() => {
        setParameters(analyseEntity.laboratoryParameterNames);
    }, [analyseEntity]);

    useEffect(() => {
        setSelectedProducts([]);
        setIsSelectedProduct(false);
    }, [dataFromCurrentAnalysisProductsTab]);

    useEffect(() => {
        if (selectedProducts.length === 0) {
            setIsSelectedProduct(false);
        } else {
            setIsSelectedProduct(true);
        }
    }, [selectedProducts]);

    
    let columns = [
        {
            dataField: T.translate('table.fields.analyse.analyseName').toString(),
            text: '',
            sort: false,
        },
        {
            dataField: T.translate('table.fields.category').toString(),
            text: T.translate('table.columns.category'),
            sort: true,
        },
        {
            dataField: T.translate('table.fields.product.name').toString(),
            text: T.translate('table.columns.product.name'),
            sort: true,
        },
        {
            dataField: T.translate('table.fields.product.articleCode'),
            text: T.translate('table.columns.product.articleCode'),
            sort: true,
        },
        {
            dataField: T.translate('table.fields.product.supplier'),
            text: T.translate('table.columns.product.supplier'),
            sort: true,
        }
    ];

    

    const groupCheckbox = (leafValues) => {
        let checked = true;
        for (let leafValue of leafValues) {
            if (leafValue && !leafValue.props.checked) {
                checked = false
            }
        }
        return canEditCheckbox && (<FormCheck onChange={e => handleGroupCheck(leafValues, e)} checked={checked} />)
    }

    if (canEditCheckbox) {
        columns = [
            {
                dataField: T.translate('table.fields.checkbox').toString(),
                aggregate: groupCheckbox,
                text: '',
                sort: false
            },
            ...columns
        ];
    }
    
    const handleGroupCheck = useCallback(async (checkBoxCells, e) => {
        const checkedCertificate = !!e.target.checked
        const productIds = []; 
        let atLeastOneControlEdited = false;
        for (let checkBoxCell of checkBoxCells) {
            if (checkBoxCell) {
                const productId = checkBoxCell.props.className.split(' ').find(d => d.includes("product-id")).split(':')[1];
                if(!selectedProducts.includes(productId) && !productIds.includes(productId)){
                    productIds.push(productId); 
                    atLeastOneControlEdited = true;
                }
            }
        }

        if (atLeastOneControlEdited && checkedCertificate) {
            setSelectedProducts(prevSelectedProducts => [
                ...new Set([...prevSelectedProducts, ...productIds])
            ]);
        } else if (atLeastOneControlEdited && !checkedCertificate) {
            setSelectedProducts(prevSelectedProducts => 
                prevSelectedProducts.filter(productId => !productIds.includes(productId))
            );
        }
    }, []);

        
    
    const completeElement = useCallback((element, elementIndex) =>{

        const checkIfSelected = (id) => {
            return selectedProducts.includes(id);
        }
        
        const handleCheckBoxProductClick = (e, productId) => {
            e.stopPropagation();
            const checkedProduct = e.target.checked;
            setSelectedProducts((prevSelectedProducts) => {
                if (checkedProduct) {
                    if (!prevSelectedProducts.includes(productId)) {
                        return [...prevSelectedProducts, productId];
                    }
                } else {
                    return prevSelectedProducts.filter(id => id !== productId);
                }
                return prevSelectedProducts;
            });
        };

        return {
            ...element,
            id: element.productId,
            analysisName: analyseEntity.name,
            checkbox:
                <FormCheck
                    className={`text-center product-id:${element.productId}`}
                    onChange={e => handleCheckBoxProductClick(e, element.productId, element.productName)}
                    checked={checkIfSelected(element.productId)}
                />,
            category: element.category,
            productName: element.name,
            articleCode: element.articleCode,
            supplier: element.supplier,
        
        }
    }, [currentUser, analyseReducer, selectedProducts, showConfirmDialog, analyseEntity, progress, dataFromCurrentAnalysisProductsTab, searchText])

    const onFetchData = useCallback(async (page, size, sortedOrder, sortedField) => {
        const data = await analyseService.getUnrelatedProductsToAnalysis(analyseReducer.analyseId, page, size, sortedOrder, sortedField, searchText);
        const elements = data.content.map((element, elementIndex) => completeElement(element, elementIndex));
        return {
            ...data,
            elements: elements
        };
    }, [completeElement])

    const handleSearchChange = e => {
        const filters = e;
        if ((filters.category.length >= 3 || filters.category.length === 0) &&
            (filters.productName.length >= 3 || filters.productName.length === 0) &&
            (filters.productNumber.length >= 3 || filters.productNumber.length === 0) &&
            (filters.productSupplier.length >= 3 || filters.productSupplier.length === 0)
        ) {
            setSearchText(filters);
        }
    }
    
    return (
        <>
            <Alert variant="danger" hidden={!apiError}>
                <Alert.Heading>
                    {T.translate("alert.errorTitle")}
                </Alert.Heading>
                {apiError}
            </Alert>
            {!isSelectedProduct ? (
                <Row className="ms-3">{T.translate('analyse.addProductAnalysisDescription')}</Row>
            ) : (
                <Row className="mt-4">
                    <Col>
                        <TableAnalysisProduct parameters={parameters} selectedProducts={selectedProducts} analysis={analyseEntity} onProgress={incrementProgress} isCreation={true} />
                    </Col>
                </Row>
            )}
            <div className="search-container-modal mt-4 ms-3">
                <LinkAnalysisSearchInput onChange={handleSearchChange} />
            </div>

            <RemoteTable id="table-unrelated-product-list"
                defaultSortedField={'name'}
                defaultSortedOrder={SortDirection.DESC}
                columns={columns}
                onFetchData={onFetchData}
                leftGroupNameColumnId={T.translate('table.fields.product.checkbox')}
                searchText={searchText}
                tableSize="20"
                groupByName={T.translate('table.fields.analyse.analyseName').toString()}
                completeElement={completeElement}
                checkAll={true}
                />
        </>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        analyseReducer: state.analyseReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideLinkModal: () => dispatch(analyseActions.hideLinkModal()),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAnalysisProductsTab);