import React from 'react';
import {Form} from "react-bootstrap-v5";
import './input-label.scss'
import DateInput from "./date-input";

const InputDateLabel = ({id, label, required, value, onChange, error, className, indented = true}) => {
    return (
        <Form.Group
            controlId={id}
            className={`input-date-label${indented ? '' : '-unindented'} ${indented ? 'row' : 'd-flex'} align-items-center ${className}`}
        >
            <Form.Label
                className={`${indented ? 'col col-4 text-end' : 'me-2'} mb-0`}
            >
                {label}
                {required && <span className="required">*</span>}
            </Form.Label>
            <div className={indented ? "col col-8" : ""}>
                <DateInput value={value} onChange={onChange} error={error}/>
            </div>
        </Form.Group>
    );
}

export default InputDateLabel;

