import React, {useState} from "react";
import Header from '../../components/header/header'
import PageWithMenuTemplate from "../../components/template-pages/page-with-menu-template";
import T from "i18n-react";
import {Button} from "react-bootstrap-v5";
import {connect} from "react-redux";
import {userActions} from "../../redux/user/actions-user";
import CreateOrEditUserModal from "./components/create-or-edit-user-modal";
import TableUserList from "./components/table-user-list";
import {UserRole} from "../../utils/enums/user-role";
import HeaderTextLabel from "../../components/header/header-text-label";
import SearchInput from "../../components/input-label/search-input";

const UsersPage = ({showCreateUserModal, currentUser}) => {
    const [searchText, setSearchText] = useState('');

    const handleSearchChange = e => {
        const text = e.target.value;
        if (text.length === 0 || text.length >= 3) {
            setSearchText(text);
        }
    }

    const createUserButton = (
        <Button variant="success" className="px-4" onClick={() => showCreateUserModal()}>{T.translate('user.button.createUser')}</Button>
    );

    return (
        <React.Fragment>
            <PageWithMenuTemplate pageTitle={T.translate(currentUser.role === UserRole.SUPER_ADMINISTRATOR ? 'pageTitle.users' : 'pageTitle.myUsers')}>
                <Header>
                    <HeaderTextLabel className="me-4" isMain={true}>
                        <h1 className="ps-1">{T.translate(currentUser.role === UserRole.SUPER_ADMINISTRATOR ? 'menu.users' : 'menu.myUsers')}</h1>
                    </HeaderTextLabel>
                </Header>
                <Header button={createUserButton}>
                    <div className="search-container">
                        <SearchInput onChange={handleSearchChange}/>
                    </div>
                </Header>
                <TableUserList className="mx-3" searchText={searchText}/>
            </PageWithMenuTemplate>
            <CreateOrEditUserModal/>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showCreateUserModal: () => dispatch(userActions.userCreate())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
