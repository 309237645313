import {ApiErrorHandler} from "./api/api-error";
import {backendUrl, privateAPI} from "./api/api";

const BASE_URL = '/laboratories/';

const getAll = async (page = 1, size = 10, sortDirection = 'ASC', sortFieldName = 'name', searchText = '', companyId = null) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}?page=${page}&size=${size}&sortDirection=${!!sortDirection ? sortDirection : 'ASC'}&sortFieldName=${!!sortFieldName ? sortFieldName : 'name'}&searchText=${searchText}${!!companyId ? "&companyId=" + companyId : ""}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getLaboratoryById = async (id) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}${id}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const createLaboratory = async (laboratory) => {
    try {
        const response = await privateAPI.post(BASE_URL, laboratory);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const deleteLaboratory = async (id) => {
    try {
        return await privateAPI.delete(`${BASE_URL}${id}`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editLaboratory = async (id, data) => {
    try {
        return await privateAPI.patch(`${BASE_URL}${id}`, data);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editLaboratoryComment = async (id, comment) => {
    try {
        return await privateAPI.put(`${BASE_URL}${id}/comment`, comment, {headers: {'content-type': 'text/plain'}});
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const addAnalysisToLaboratory = async (laboratoryId, analysisList) => {
    try {
        return await privateAPI.post(`${BASE_URL}${laboratoryId}/analyses`, analysisList);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getAnalysisInProgressForLaboratory = async (laboratoryId, sortDirection, sortFieldName) => {
    try {
        const response = await privateAPI.get(`/lots/analysis/in-progress/laboratory/${laboratoryId}?sortDirection=${!!sortDirection ? sortDirection : 'ASC'}&sortFieldName=${!!sortFieldName ? sortFieldName : 'lot.lotNumber'}`)
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const indicatorsLotsClosedOrderedAnalysesForLaboratory = async (laboratoryId) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}${laboratoryId}/indicators/closed-lots-ordered-analyses`)
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const indicatorsLotsClosedControlPriceForLaboratory = async (laboratoryId) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}${laboratoryId}/indicators/closed-lots-control-price`)
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getAnalysisExport = async (analyseId) => {
    return `${backendUrl}${BASE_URL}${analyseId}/analyses-ordered-by-laboratories/csv`;
}

export const laboratoryService = {
    getAll,
    getLaboratoryById,
    createLaboratory,
    deleteLaboratory,
    editLaboratory,
    editLaboratoryComment,
    addAnalysisToLaboratory,
    getAnalysisInProgressForLaboratory,
    indicatorsLotsClosedOrderedAnalysesForLaboratory,
    indicatorsLotsClosedControlPriceForLaboratory,
    getAnalysisExport,
};
