import React, {useCallback, useEffect, useState} from 'react';
import RemoteTable from "../../../components/table/remote-table";
import {connect} from "react-redux";
import T from 'i18n-react'
import {UserRole} from "../../../utils/enums/user-role";
import {Button, FormCheck, OverlayTrigger, Tooltip} from "react-bootstrap-v5";
import EditableText from "../../../components/editable-text/editable-text";
import EditableSelect from "../../../components/editable-text/editable-select";
import SelectLabel from "../../../components/input-label/select-label";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CUSTOMIZED_EMPTY_NEXT_MONITORING_DATE, productService} from "../../../services/product-service";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {alertActions} from "../../../redux/alert/actions-alert";
import {productActions} from "../../../redux/product/actions-product";
import {confirmDialogActions} from "../../../redux/confirm-dialog/actions-confirm-dialog";
import {analyseHistoryActions} from "../../../redux/analyse-history/actions-analyse-history";
import {ProductStatus} from "../../../utils/enums/product-status";
import {useParams} from "react-router-dom";
import {i18nService} from "../../../services/i18n-service";
import NextMonitoringDateInputComponent from "../../../components/next-monitoring-date-component/next-monitoring-date-input-component";
import Moment from "react-moment";

const TableControlsProductList = ({
                                      product,
                                      className,
                                      showToast,
                                      currentUser,
                                      showConfirmDialog,
                                      progress,
                                      productControlEdited,
                                      productControlDeleted,
                                      checkCheckBox,
                                      updateSelectedAnalysisNumber,
                                      incrementSelectedAnalysisNumber,
                                      showAnalyseHistoryModal
                                  }) => {
    const {id} = useParams();
    const [periodicities, setPeriodicities] = useState([]);
    const [responseData, setResponseData] = useState()

    const canEdit = currentUser.role === UserRole.SUPER_ADMINISTRATOR || currentUser.role === UserRole.COMPANY_ADMINISTRATOR || currentUser.role === UserRole.SUPERVISOR;

    const isModificationInProgress = product.status === ProductStatus.MODIFICATION_IN_PROGRESS;

    let columns = [
        {
            dataField: T.translate('table.fields.product.category').toString(),
            text: '', // On ne veut pas que le mot catégorie s'affiche, c'est une colonne fantôme
        },
        {
            dataField: T.translate('table.fields.product.analysisName').toString(),
            text: T.translate('table.columns.product.analysisName').toString(),
            targetForChevron: true,
        },
        {
            dataField: T.translate('table.fields.product.analysisMethod').toString(),
            text: T.translate('table.columns.method').toString(),
        },
        {
            dataField: T.translate('table.fields.specifications').toString(),
            text: T.translate('table.columns.specifications').toString(),
        },
        {
            dataField: T.translate('table.fields.product.unit').toString(),
            text: T.translate('table.columns.unit').toString(),
            padding: "ps-3",
        },
        {
            dataField: T.translate('table.fields.source').toString(),
            text: T.translate('table.columns.analyse.source').toString(),
        },
        
        {
            dataField: T.translate('table.fields.product.price').toString(),
            text: `${T.translate('table.columns.price')} ${!!currentUser.currencySymbol ? '(' + currentUser.currencySymbol + ')' : ''}`,
        },
        {
            dataField: T.translate('table.fields.product.delay').toString(),
            text: T.translate('table.columns.delayInDays').toString(),
        },
        {
            dataField: T.translate('table.fields.product.quantity').toString(),
            text: T.translate('table.columns.quantityInGram').toString(),
        },
        {
            dataField: T.translate('table.fields.periodicity').toString(),
            text: T.translate('table.columns.periodicity').toString(),
        },
        {
            dataField: T.translate('table.fields.nextMonitoringDate').toString(),
            text: T.translate('table.columns.nextMonitoringDate').toString(),
        },
        {
            dataField: T.translate('table.fields.actions').toString(),
            text: T.translate('table.columns.actions').toString(),
        },
    ];

    const groupCheckbox = (leafValues) => {
        let checked = true
        for (let leafValue of leafValues) {
            if (leafValue && !leafValue.props.checked) {
                checked = false
            }
        }
        return canEdit && (
            <FormCheck onChange={e => handleGroupCheck(leafValues, e)} checked={checked} disabled={!isModificationInProgress}/>)
    }

    if (canEdit) {
        columns = [
            {
                dataField: T.translate('table.fields.checkbox').toString(),
                aggregate: groupCheckbox,
                text: <FontAwesomeIcon icon="eye"/>,
                sort: true
            },
            ...columns,
        ];
    }

    useEffect(() => {
        const getPeriodicities = async () => {
            try {
                const availablePeriodicities = await i18nService.getAllPeriodicities();
                setPeriodicities(availablePeriodicities);
            } catch (e) {
                setPeriodicities([]);
            }
        }

        getPeriodicities();
    }, []);

    function getHistoryButton(handleShowHistory, elementId) {
        return (<Button size="sm" className="ms-1" onClick={e => handleShowHistory(e, elementId)}>
            <FontAwesomeIcon icon="chart-bar"/>
        </Button>);
    }

    // FIXME retirer elementIndex et utiliser une autre variable pour nommer les id du html, par exemple lotAnalysisId semblerait convenir
    const completeElement = useCallback((element, elementIndex) => {
        const editControl = async (controlId, controlPatch) => {
            const response = await productService.editControl(id, controlId, controlPatch);
            setResponseData(response.data)
            return response

        }

        const deleteControl = async control => {
            try {
                await productService.deleteControl(id, control.productAnalysisId);
                productControlDeleted(control.checked);
                showToast(T.translate('alert.successTitle'), T.translate('product.successDeleteControl'), ToastClass.SUCCESS);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleDeleteControl = async (e, control) => {
            e.stopPropagation();
            showConfirmDialog(
                T.translate('dialog.title.confirmDelete'),
                T.translate('dialog.confirmDeleteProductAnalysis'),
                () => {
                },
                () => deleteControl(control))
        }

        const handleSpecsChange = async (controlId, specs, event) => {
            try {
                await editControl(controlId, {specs})
                showToast(T.translate('alert.successTitle'), T.translate('product.successEditSpecs'), ToastClass.SUCCESS);
                handleFocusEvent(event);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleUnitChange = async (controlId, unit, event) => {
            try {
                await editControl(controlId, {unit})
                showToast(T.translate('alert.successTitle'), T.translate('product.successEditUnit'), ToastClass.SUCCESS);
                handleFocusEvent(event);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handlePeriodicityChange = async (controlId, e) => {
            const periodicity = e.target.value;
            try {
                await editControl(controlId, {periodicity})
                showToast(T.translate('alert.successTitle'), T.translate('product.successEditPeriodicity'), ToastClass.SUCCESS);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleSourceChange = async (controlId, val) => {
            try {
                await editControl(controlId, {source: val.id});
                showToast(T.translate('alert.successTitle'), T.translate('product.successEditSource'), ToastClass.SUCCESS);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const periodicityOptions = (defaultPeriodicity) => periodicities.map(periodicity => (
            <option key={periodicity.periodicityCode} value={periodicity.periodicityCode}
                    selected={periodicity.periodicityCode === defaultPeriodicity.periodicityCode}>
                {periodicity.displayName}
            </option>
        ));

        const handleCheckBoxClick = async (controlId, e) => {
            const checked = !!e.target.checked
            try {
                const response = await editControl(controlId, {checked})
                checkCheckBox(response.data.controlPlanMustBeRegenerated)
                incrementSelectedAnalysisNumber(checked)
                if (checked) {
                    showToast(T.translate('alert.successTitle'), T.translate('product.successCheck'), ToastClass.SUCCESS);
                } else {
                    showToast(T.translate('alert.successTitle'), T.translate('product.successUnCheck'), ToastClass.SUCCESS);
                }
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleNextMonitoringDate = async (controlId, val) => {
            const nextMonitoringDate = val === null ? CUSTOMIZED_EMPTY_NEXT_MONITORING_DATE : val;

            try {
                const response = await productService.editNextMonitoringDate(id, controlId, nextMonitoringDate);
                setResponseData(response.data)
                showToast(T.translate('alert.successTitle'), T.translate('product.successEditPeriodicity'), ToastClass.SUCCESS);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleFocusEvent = (event) => {
            if (event !== undefined) {
                if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
                    handleFocusPreviousNext(event);
                } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                    handleFocusUpDown(event)
                } else if (event.key === 'Enter') {
                    handleFocusEnter(event);
                } else if (event.type === 'blur') {
                    handleFocusBlur(event);
                }
            }
        }

        const handleFocusBlur = (event) => {
            if (event.relatedTarget !== null) {
                if (event.relatedTarget.matches('input[type="text"]')) {
                    let inputToFocus = document.getElementById(event.relatedTarget.id);
                    inputToFocus.focus();
                }
            }
        }

        const handleFocusEnter = (event) => {
            let input = document.getElementById(event.target.id);
            let elementName = event.target.id.split('-')[0];

            let table = input.parentElement.parentElement.parentElement.parentElement.parentElement;
            let td = input.parentElement.parentElement.parentElement;

            if (elementName === "result") {
                let inputResult = table.querySelectorAll(('*[id^="' + elementName + '"]'));
                for (let i = 0; i < inputResult.length; i++) {
                    if (inputResult[i].parentElement.parentElement.parentElement === td) {
                        if (inputResult.length - 1 !== i) {
                            i++;
                        }
                        inputResult[i].focus();
                        break;
                    }
                }
            } else {
                input.focus()
            }
        }

        const handleFocusPreviousNext = (event) => {
            let input = document.getElementById(event.target.id);
            let td = input.parentNode.parentNode.parentNode;
            let row = td.parentNode;
            let inputs = row.querySelectorAll('input[type="text"]');

            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i] === input) {
                    if (event.key === "ArrowRight") {
                        if (inputs.length - 1 !== i) {
                            i++;
                        }
                        inputs[i++].focus();
                    } else {
                        i === 0 ? input.focus() : inputs[i - 1].focus();
                    }
                }
            }
        }

        const handleFocusUpDown = (event) => {
            let input = document.getElementById(event.target.id);
            let elementName = event.target.id.split('-')[0];
            let table = input.parentElement.parentElement.parentElement.parentElement.parentElement;
            let inputs = table.querySelectorAll(('*[id^="' + elementName + '"]'));

            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i] === input) {
                    if (event.key === "ArrowDown") {
                        if (inputs.length - 1 !== i) {
                            i++;
                        }
                        inputs[i++].focus();
                    } else {
                        i === 0 ? input.focus() : inputs[i - 1].focus();
                    }
                    break;
                }
            }
        }

        const onFocus = (event, newVal, currentVal, element) => {
            if (newVal !== currentVal) {
                const idElement = event.target.id;
                if (idElement.includes("unit")) {
                    handleUnitChangeWithEvent(event, newVal, element)
                } else if (idElement.includes("specs")) {
                    handleSpecsChangeWithEvent(event, newVal, element)
                }
            } else {
                handleFocusEvent(event);
            }
        }

        const handleSpecsChangeWithEvent = (event, val, element) => {
            handleSpecsChange(element, val, event);
        }

        const handleUnitChangeWithEvent = (event, val, element) => {
            handleUnitChange(element, val, event);
        }

        const handleShowAnalyseHistory = (event, productAnalysisId) => {
            showAnalyseHistoryModal(productAnalysisId, '', id);
        }

        // Mettre en forme les subRows
        const completeElementForSubRow = (parent, parameter, parameterIndex) => {
            const handleSpecsParameterChange = async (controlId, name, specs, event) => {
                try {
                    const parameter = {name, specs}
                    await editControl(controlId, {parameter})
                    showToast(T.translate('alert.successTitle'), T.translate('product.successEditSpecsParameter'), ToastClass.SUCCESS);
                    handleFocusEvent(event);
                } catch (error) {
                    showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
                }
            }
            const handleCheckBoxParameterClick = async (e, controlId, name) => {
                const checked = !!e.target.checked
                const parameter = {name, checked}
                try {
                    const response = await editControl(controlId, {parameter})
                    checkCheckBox(response.data.controlPlanMustBeRegenerated)
                    if (checked) {
                        showToast(T.translate('alert.successTitle'), T.translate('product.successCheckParameter'), ToastClass.SUCCESS);
                    } else {
                        showToast(T.translate('alert.successTitle'), T.translate('product.successUnCheckParameter'), ToastClass.SUCCESS);
                    }
                } catch (error) {
                    showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
                }
            }

            const handleShowParameterHistory = (event, parameterName) => {
                showAnalyseHistoryModal(parent.productAnalysisId, parameterName, id);
            }

            const onParameterFocus = (event, newVal, currentVal, controlId, parameterName) => {
                if (newVal !== currentVal) {
                    const idElement = event.target.id;
                    if (idElement.includes("specs")) {
                        handleSpecsParameterChange(controlId, parameterName, newVal, event)
                    }
                } else {
                    handleFocusEvent(event);
                }
            }

            return {
                ...parent,
                // Elements affichés dans la tableau
                disableDrag: true,
                checked: parameter.checkbox,
                checkbox: canEdit && !parent.analysis.archived && (
                    <FormCheck className={`text-center product-analysis-id:${parent.productAnalysisId}`}
                        onChange={e => handleCheckBoxParameterClick(e, parent.productAnalysisId, parameter.name)}
                        checked={parameter.checked} disabled={!isModificationInProgress}/>
                ),
                analysis: {
                    ...parent.analysis,
                    analysisInfo: {
                        ...parent.analysis.analysisInfo,
                        name: parameter.name
                    },
                },
                specs: (canEdit && isModificationInProgress && (!parent.analysis.archived)) ?
                    <EditableText id={`specs-${elementIndex}-${parameterIndex}`} defaultValue={parameter.specs}
                        onFocus={(e, val) => onParameterFocus(e, val, parameter.specs, parent.productAnalysisId, parameter.name)}
                        onSubmit={val => handleSpecsParameterChange(parent.productAnalysisId, parameter.name, val)} shouldSubmitOnBlur/>
                    : parameter.specs,
                mainLaboratory: {
                    ...parent.mainLaboratory,
                    laboratory: {
                        ...parent.mainLaboratory.laboratory,
                        name: ''
                    },
                    delayInDays: '',
                    quantityInGram: '',
                    price: ''
                },
                source: '',
                periodicity: '',
                nextMonitoringDate: '',
                actions: (<div className="actions">{getHistoryButton(handleShowParameterHistory, parameter.name)}</div>),
                parameters: undefined,
                isParameter: true,
                parameterName: parameter.name
            }
        }

        const getNextMonitoringDateComponent = () => {
            if (!element.periodicity.convertibleInDays) {
                return '';
            }

            return (canEdit && !element.analysis.archived) ?
                <NextMonitoringDateInputComponent value={element.nextMonitoringDate}
                    onChange={val => handleNextMonitoringDate(element.productAnalysisId, val)}/>
                : <Moment format={T.translate('i18n.format.date').toString()}>{element.nextMonitoringDate}</Moment>;
        }

        const sources = element.analysis.laboratoryDetails.map(labo => {
            return {id: labo.laboratory.laboratoryId, name: labo.laboratory.name};
        });
        sources.push({id: "SPECIAL_LABORATORY_SUPPLIER_DATA", name: T.translate('product.label.supplierData')});
        sources.push({id: "SPECIAL_LABORATORY_PERIODIC_PLAN", name: T.translate('product.label.periodicPlan')})
        sources.push({id: "SPECIAL_LABORATORY_RAW_MATERIAL", name: T.translate('product.label.rawMaterial')})
        sources.push({id: "SPECIAL_LABORATORY_COMPUTED_VALUE", name: T.translate('product.label.computedValue')})
        sources.push({id: "SPECIAL_LABORATORY_CLIENT_DATA", name: T.translate('product.label.clientData')})
        const currentSource = sources.find(source => source.id === element.sourceId);
        
        const sourceElement = () => { 
            return canEdit && isModificationInProgress ? (
                <EditableSelect id={`source-${elementIndex}`} className="source"
                                onChange={e => handleSourceChange(element.productAnalysisId, sources[e.target.selectedIndex])}
                                options={sources.map(source => (
                                    <option key={`source-option-${source.id}`} id={`source-option-${source.id}`} value={source}
                                            selected={source.id === currentSource.id}>
                                        {source.name}
                                    </option>
                                ))}/>
                            ) : (
                                <div className="px-2 mx-1">{currentSource.name}</div>
                            );
        }

        return {
            ...element,
            id: element.productAnalysisId,
            displayOrder: element.displayOrder,
            color: element.analysis.archived ? "archived" : "",
            disableDrag: !canEdit || element.analysis.archived,
            checkbox: canEdit && !element.analysis.archived && (
                <FormCheck className={`product-analysis-id:${element.productAnalysisId}`}
                    onChange={e => handleCheckBoxClick(element.productAnalysisId, e)}
                    checked={element.checked} disabled={!isModificationInProgress}/>
            ),
            analysis: {
                ...element.analysis,
                analysisInfo: {
                    ...element.analysis.analysisInfo,
                    name: !element.analysis.archived ? element.analysis.analysisInfo.name : (
                        <div className="position-relative ps-4">
                            <div className="archived-icon position-absolute start-0">
                                <OverlayTrigger trigger={['hover', 'focus']} key={`tooltip-trigger-${elementIndex}`} placement="top"
                                                overlay={<Tooltip id={`tooltip-${elementIndex}`}>{T.translate('product.archivedAnalyse')}</Tooltip>}>
                                    <FontAwesomeIcon icon="archive"/>
                                </OverlayTrigger>
                            </div>
                            <div className="text-nowrap">{element.analysis.analysisInfo.name}</div>
                        </div>
                    )
                }
            },
            source: sourceElement(),
            specs: (canEdit && isModificationInProgress && (!element.analysis.archived)) ?
                <EditableText id={`specs-${elementIndex}`} defaultValue={element.specs} onSubmit={val => handleSpecsChange(element.productAnalysisId, val)}
                              onFocus={(e, val) => onFocus(e, val, element.specs, element.productAnalysisId)} shouldSubmitOnBlur/>
                : element.specs,
            unit: (canEdit && isModificationInProgress && (!element.analysis.archived)) ?
                <EditableText id={`unit-${elementIndex}`} defaultValue={element.unit} onSubmit={val => handleUnitChange(element.productAnalysisId, val)}
                              onFocus={(e, val) => onFocus(e, val, element.unit, element.productAnalysisId)}
                              shouldSubmitOnBlur/>
                : element.unit,
            periodicity: (canEdit && isModificationInProgress && (!element.analysis.archived)) ?
                <SelectLabel id={`periodicity-${elementIndex}`} defaultValue={element.periodicity.periodicityCode}
                             onChange={e => handlePeriodicityChange(element.productAnalysisId, e)}
                             options={periodicityOptions(element.periodicity)}/>
                : element.periodicity.displayName,
            nextMonitoringDate: getNextMonitoringDateComponent(),
            actions: (
                <div className="actions">
                    {getHistoryButton(handleShowAnalyseHistory, element.productAnalysisId)}
                    {canEdit && isModificationInProgress && !element.isChild &&
                        <Button size="sm" className="delete" onClick={e => handleDeleteControl(e, element)}>
                            <FontAwesomeIcon icon="times"/>
                        </Button>}
                </div>
            ),
            subRows: !element.analysis.archived && element.parameters.length > 0 ?
                element.parameters.map((parameter, parameterIndex) => completeElementForSubRow(element, parameter, parameterIndex)) : []
        }
    }, [canEdit, checkCheckBox, id, isModificationInProgress, incrementSelectedAnalysisNumber, productControlDeleted, showConfirmDialog, showToast, showAnalyseHistoryModal, periodicities])

    const handleGroupCheck = useCallback(async (checkBoxCells, e) => {
        const checked = !!e.target.checked
        try {
            let atLeastOneControlEdited = false;
            for (let checkBoxCell of checkBoxCells) {
                if (checkBoxCell) {
                    const productAnalysisId = checkBoxCell.props.className.split(' ').find(d => d.includes("product-analysis-id")).split(':')[1]
                    const response = await productService.editControl(id, productAnalysisId, {checked})
                    checkCheckBox(response.data.controlPlanMustBeRegenerated)
                    incrementSelectedAnalysisNumber(checked)
                    setResponseData(response.data)
                    atLeastOneControlEdited = true;
                }
            }
            if (atLeastOneControlEdited) {
                if (checked) {
                    showToast(T.translate('alert.successTitle'), T.translate('product.successCategoryCheck'), ToastClass.SUCCESS);
                } else {
                    showToast(T.translate('alert.successTitle'), T.translate('product.successCategoryUnCheck'), ToastClass.SUCCESS);
                }
            }
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }, [checkCheckBox, id, showToast, incrementSelectedAnalysisNumber])

    const handleCategoryChange = async (controlId, destinationCategoryId) => {
        try {
            const response = await productService.editControl(id, controlId, {"analysisCategoryId": destinationCategoryId});
            setResponseData(response.data)
            showToast(T.translate('alert.successTitle'), T.translate('product.successEditControlCategory'), ToastClass.SUCCESS);
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleCategoryReorder = async (controlId, destinationDisplayOrder) => {
        try {
            await productService.editControl(id, controlId, {"displayOrder": destinationDisplayOrder});
            productControlEdited();
            showToast(T.translate('alert.successTitle'), T.translate('product.successEditControlCategory'), ToastClass.SUCCESS);
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const onFetchData = useCallback(async (page, size, sortedOrder, sortedField) => {

        if (!product.id) return;

        if (sortedField === T.translate('table.fields.checkbox').toString()) {
            sortedField = "checked"
        }

        const data = await productService.getControlsByProductId(id, sortedOrder, sortedField);
        // il faut d'abord trier les données puis appliquer completeElement

        // sinon suite à une modification avec un call api, alors on applique completeElement mais au nouvel ordre après le tri
        // et cela crée des décalages avec le tableau d'origine qui lui se base sur les elementIndex avant le tri
        const elements = data
            .sort((a, b) => {
                if (a.analysisCategory.name < b.analysisCategory.name) return -1;
                if (a.analysisCategory.name > b.analysisCategory.name) return 1;
                return a.displayOrder - b.displayOrder;
            })
            .map((element, elementIndex) => completeElement(element, elementIndex));

        const nbCheckedAnalysis = elements.map(element => !element.archived && element.checked ? 1 : 0).reduce((a, b) => a + b, 0);
        updateSelectedAnalysisNumber(nbCheckedAnalysis);

        return {
            ...data,
            elements: elements
        };
    }, [id, updateSelectedAnalysisNumber, completeElement, product])

    return (
        <div>
            <RemoteTable id="table-controls-product-list" isOverflowYHidden={false} tableMinHeight={'400px'}
                         defaultSortedField={T.translate('table.fields.product.analysisName')}
                         className={className} columns={columns} reload={progress} useSearchField={false} useSizeSelect={false} onFetchData={onFetchData}
                         groupByName={T.translate('table.fields.product.category').toString()} groupById={T.translate('table.fields.product.categoryId')}
                         useDragRow={true} handleGroupChange={handleCategoryChange} usePagination={false} responseData={responseData}
                         indexName={T.translate('table.fields.product.productAnalysisId')}
                         completeElement={completeElement} leftGroupNameColumnId={T.translate('table.fields.product.checkbox')}
                         handleGroupReorder={handleCategoryReorder}/>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        progress: state.productReducer.progress
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        productControlEdited: () => dispatch(productActions.editProductControl()),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm)),
        productControlDeleted: (checked) => dispatch(productActions.deleteProductControl(checked)),
        checkCheckBox: (showWarning) => dispatch(productActions.checkCheckBox(showWarning)),
        updateSelectedAnalysisNumber: (nbAnalysis) => dispatch(productActions.updateSelectedAnalysisNumber(nbAnalysis)),
        incrementSelectedAnalysisNumber: (checked) => dispatch(productActions.incrementSelectedAnalysisNumber(checked)),
        showAnalyseHistoryModal: (analyseId, parameterName, productId) => dispatch(analyseHistoryActions.showAnalyseHistoryModal(analyseId, parameterName, productId, ''))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableControlsProductList);
