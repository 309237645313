import React, {useState} from 'react';
import './input-label.scss'
import Autosuggest from 'react-autosuggest';
import {debounceFunc} from "../../utils/functions/debounce-function";

const Autocomplete = ({id, className, placeholder, value, onChange, onSuggestionsFetch, onSelect, disabled, error}) => {

    const [suggestions, setSuggestions] = useState([]);

    const onSuggestionsFetchRequested = async ({value}) => {
        if (disabled) {
            setSuggestions([]);
            return;
        }
        setSuggestions(await onSuggestionsFetch(value))
    };

    const optimizedOnSuggestionsFetchRequested = debounceFunc(onSuggestionsFetchRequested, `autocomplete-${id}`);

    const onSuggestionsClearRequested = () => setSuggestions([]);

    const onSuggestionSelected = (event, {suggestion}) => onSelect(suggestion)

    const getSuggestionValue = suggestion => suggestion.id;

    const renderSuggestion = suggestion => (
        <div key={suggestion.id}>
            {suggestion.name}
        </div>
    );

    return (
        <div className={`autocomplete text-start ${className} ${!!error ? "is-invalid" : ""}`}>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={optimizedOnSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={onSuggestionSelected}
                inputProps={{
                    id,
                    className: `form-control ${!!error ? "is-invalid" : ""}`,
                    value,
                    onChange: disabled ? () => {
                    } : onChange,
                    placeholder: placeholder
                }}
            />
        </div>
    );
}

export default Autocomplete;

