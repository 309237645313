import React from 'react';
import iconCheckAll from "../../assets/images/icons/check-all.svg";
import iconCheckedAll from "../../assets/images/icons/checked-all.svg";
import iconCheckSingle from "../../assets/images/icons/check-single.svg";
import iconCheckedSingle from "../../assets/images/icons/checked-single.svg";
import {Image} from "react-bootstrap-v5";
import './app-checkbox.scss'

const AppCheckbox = ({checked, onChange, disabled = false, multiple = false}) => {
    const iconChecked = multiple ? iconCheckedAll : iconCheckedSingle;
    const iconUnchecked = multiple ? iconCheckAll : iconCheckSingle;

    const handleChange = () => onChange(!checked);

    return (
        <Image className={`app-checkbox ${disabled && "disabled opacity-75"}`}
               src={checked ? iconChecked : iconUnchecked}
               onClick={disabled ? null : handleChange}/>
    );
};

export default AppCheckbox;
