import {privateAPI} from "./api/api";
import {ApiErrorHandler} from "./api/api-error";

const BASE_URL = '/analysis-categories/'

const getAll = async (page = 1, size = 10, sortDirection = 'ASC', sortFieldName = 'name', searchText = '', companyId = null) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}?page=${page}&size=${size}&sortDirection=${!!sortDirection ? sortDirection : 'ASC'}&sortFieldName=${!!sortFieldName ? sortFieldName : 'name'}&searchText=${searchText}${!!companyId ? "&companyId=" + companyId : ""}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const createCategory = async (data) => {
    try {
        return await privateAPI.post(BASE_URL, data);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editCategory = async (id, data) => {
    try {
        return await privateAPI.put(`${BASE_URL}${id}`, data);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const deleteCategory = async (id) => {
    try {
        return await privateAPI.delete(`${BASE_URL}${id}`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

export const analyseCategoryService = {
    getAll,
    createCategory,
    editCategory,
    deleteCategory
}