import React from 'react';
import './card-indicator.scss'
import BubbleChart from "../charts/bubble-chart";

const IndicatorBubbleChart = ({title, currentYear, lastYear}) => {
    const currentYearDate = new Date().getFullYear();
    const lastYearDate = currentYearDate - 1;

    return (
        <div className="indicator indicator-bar-chart p-3 h-100 d-flex flex-column justify-content-between">
            <div className="title mb-3">{title}</div>
            <div className="px-3">
                <BubbleChart
                    dataset={[
                        {x: lastYearDate, y: lastYear},
                        {x: currentYearDate, y: currentYear},
                    ]}/>
            </div>
            <div></div>
        </div>
    );
};

export default IndicatorBubbleChart;
