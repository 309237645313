import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckLabel from "../../components/input-label/check-label";
import { ToastClass } from "../../components/toast-alert/toast-alert-class";
import { StatusLot } from "../../utils/enums/status-lot";
import { Button, Table } from "react-bootstrap-v5";
import { ping } from "../../services/api/api";
import { documentService } from "../../services/document-service";
import T from "i18n-react";
import { confirmDialogActions } from "../../redux/confirm-dialog/actions-confirm-dialog";
import { alertActions } from "../../redux/alert/actions-alert";
import { connect } from "react-redux";
import { lotService } from "../../services/lot-service";
import { lotActions } from "../../redux/lot/actions-lot";

const RawMaterialTable = ({ row, showConfirmDialog, showToast, lotTransferResult }) => {


    const isClosedStatusLot = row.original.lot.status === StatusLot.CLOSED;
    const downloadDocRef = useRef();

    const downloadDocument = async (fileUri) => {
        downloadDocRef.current.href = fileUri;
        // ici on ping pour éventuellement forcer axios à appeler le refresh token et éviter sinon que le download envoie un 401 car c'est un appel backend direct
        await ping();
        downloadDocRef.current.click();
    }

    const transferResult = async rawMaterial => {
        try {
            const sourceLotId = rawMaterial.lotId;
            const destinationLotId = row.original.lot.lotId;
            const sourceLotAnalysisId = rawMaterial.lotAnalysisId;
            const destinationLotAnalysisId = row.original.lotAnalysisId;
            const analysisList = [{
                sourceLotAnalysisId: sourceLotAnalysisId,
                destinationLotAnalysisId: destinationLotAnalysisId,
            }];
            await lotService.transferResults(sourceLotId, destinationLotId, analysisList);
            lotTransferResult(); //Permet le refresh du composant
            showToast(T.translate('alert.successTitle'), T.translate('lot.successTransferResults'), ToastClass.SUCCESS);
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }
    const handleTransferResult = async (e, rawMaterial) => {
        e.stopPropagation();
        showConfirmDialog(
            T.translate('dialog.title.confirmEdit'),
            T.translate('dialog.confirmTransferResultFromRawMaterial', { lotAnalysisName: row.original.analysisName, lotNumber: rawMaterial.lotNumber, articleCode: rawMaterial.product.name, supplier: rawMaterial.product.supplier }),
            () => {
            },
            () => transferResult(rawMaterial))
    }

    const handleCheckBoxRawMaterialClick = async (e, rawMaterial) => {
        const checkedCertificate = !!e.target.checked;
        rawMaterial.checkedCertificate = !!e.target.checked;
        const rawMaterialLot = rawMaterial
        const lotId = row.original.lot.lotId;
        const lotAnalysisId = row.original.lotAnalysisId;
        try {
            await editControl(lotId, lotAnalysisId, { rawMaterialLot });
            showToast(T.translate('alert.successTitle'), T.translate(checkedCertificate ? 'lot.successCertificateCheckRawMaterial' : 'lot.successCertificateUncheckRawMaterial'), ToastClass.SUCCESS);
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const editControl = async (lotId, controlId, controlPatch) => {
        await lotService.editControl(lotId, controlId, controlPatch);
        lotTransferResult(); //Permet le refresh du composant
    }

    return <React.Fragment>
        <td colSpan="18">
            <div className="rawMaterial">
                <div className="title">{T.translate('lot.label.rawMaterials')}</div>
                <Table striped bordered size="sm" className="table">
                    <thead>
                        <tr>
                            <th>{!isClosedStatusLot && <FontAwesomeIcon icon="eye" className="icon"/>}</th>
                            <th>{T.translate('table.columns.rawMaterial.reference')}</th>
                            <th>{T.translate('table.columns.rawMaterial.product')}</th>
                            <th>{T.translate('table.columns.rawMaterial.lotNumber')}</th>
                            <th>{T.translate('table.columns.rawMaterial.result')}</th>
                            <th>{T.translate('table.columns.rawMaterial.unit')}</th>
                            <th>{T.translate('table.columns.rawMaterial.report')}</th>
                            <th>{T.translate('table.columns.rawMaterial.actions')}</th>
                        </tr>
                    </thead>
                    <tbody >
                        {row.original.rawMaterialCollection.map((rawMaterial) => (
                            <tr>
                                <td>{!isClosedStatusLot &&<CheckLabel id="customTemplates" onChange={e => handleCheckBoxRawMaterialClick(e, rawMaterial)} checked={rawMaterial.checkedCertificate} />}</td>
                                <td>{rawMaterial.product.articleCode}</td>
                                <td>{rawMaterial.product.name}</td>
                                <td>{rawMaterial.lotNumber}</td>
                                <td>{rawMaterial.result}</td>
                                <td>{rawMaterial.unit}</td>
                                <td>{rawMaterial.document ? rawMaterial.document.originalFileName : ""}</td>
                                <td> {row.original.lot.status !== StatusLot.TO_CLOSE && <Button size="sm" onClick={e => handleTransferResult(e, rawMaterial)}>
                                        <FontAwesomeIcon icon="upload" />
                                    </Button>}
                                    {rawMaterial.document &&
                                        <Button size="sm" onClick={() => downloadDocument(documentService.getDownloadUrl(rawMaterial.document.fileDownloadUri))} >
                                            <FontAwesomeIcon icon="file-pdf" />
                                        </Button>}
                                </td>
                                <a ref={downloadDocRef} href="#0" hidden={true} download>download</a>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </td>
    </React.Fragment>
}

const mapStateToProps = state => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm)),
        lotTransferResult: () => dispatch(lotActions.lotTransferResult()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RawMaterialTable);