import React, {useEffect, useState} from 'react';
import {Badge, Button, Card} from "react-bootstrap-v5";
import T from 'i18n-react'
import {UserRole} from "../../../utils/enums/user-role";
import {connect} from "react-redux";
import SearchInput from "../../../components/input-label/search-input";
import OverlayLoadingOrError from "../../../components/overlay-component/overlay-loading-or-error";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {lotService} from "../../../services/lot-service";
import {useHistory} from "react-router-dom";
import {SortDirection} from "../../../utils/enums/sort-direction";
import {Routes} from "../../../router/router-constants";
import Moment from "react-moment";
import TablePagination from "../../../components/table/table-pagination";
import CustomTable from "../../../components/table/custom-table";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {confirmDialogActions} from "../../../redux/confirm-dialog/actions-confirm-dialog";
import {alertActions} from "../../../redux/alert/actions-alert";
import {cancelDebounce, debounceFunc} from "../../../utils/functions/debounce-function";

const CardLotsNotCompliant = ({className, currentUser, showConfirmDialog, showToast}) => {

    const history = useHistory();
    const [state, setState] = useState({isLoading: false, error: ''});
    const [lotList, setLotList] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        size: 15,
        sortedField: T.translate('table.fields.analyse.notCompliant.lotNumber'),
        sortedOrder: SortDirection.ASC,
        searchText: '',
        totalElements: 0,
        totalPages: 1
    });

    let columns = [
        {
            dataField: T.translate('table.fields.analyse.notCompliant.categoryName').toString(),
            text: T.translate('table.columns.category').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.notCompliant.articleCode').toString(),
            text: T.translate('table.columns.product.articleCode').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.notCompliant.productName').toString(),
            text: T.translate('table.columns.lot.productName').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.notCompliant.lotNumber').toString(),
            text: T.translate('table.columns.lot.lotNumber').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.notCompliant.analyseName').toString(),
            text: T.translate('table.columns.analyseName').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.notCompliant.specs').toString(),
            text: T.translate('table.columns.specifications').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.notCompliant.result').toString(),
            text: T.translate('table.columns.result').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.notCompliant.unit').toString(),
            text: T.translate('table.columns.unit').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.analyse.notCompliant.endControlDate').toString(),
            text: T.translate('table.columns.lot.endControlDate').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.actions').toString(),
            text: T.translate('table.columns.actions').toString()
        }
    ];

    if (currentUser.role === UserRole.SUPER_ADMINISTRATOR) {
        columns = [
            {
                dataField: T.translate('table.fields.analyse.notCompliant.companyName').toString(),
                text: T.translate('table.columns.company').toString(),
                sort: true
            },
            ...columns
        ];
    }

    const sortableColumns = columns.map(col => {
        return {
            ...col,
            id: col.dataField,
            accessor: col.dataField,
            Header: ({column}) => {
                return (
                    <div className={`column-title d-flex justify-content-between align-items-center ${col.sort ? "sortable-column" : ""}`}
                         onClick={event => !col.sort ? event.stopPropagation() : handleSort(event, col.dataField)}>
                        <div>{column.text}</div>
                        {
                            col.sort &&
                            <div className={`order-icon order-${col.dataField !== pagination.sortedField || !pagination.sortedOrder ? "none" : pagination.sortedOrder}`}>
                                <FontAwesomeIcon
                                    className={`order-icon-desc ${col.dataField === pagination.sortedField && pagination.sortedOrder === SortDirection.DESC ? "d-none" : "d-block"}`}
                                    icon="chevron-up"/>
                                <FontAwesomeIcon
                                    className={`order-icon-asc ${col.dataField === pagination.sortedField && pagination.sortedOrder === SortDirection.ASC ? "d-none" : "d-block"}`}
                                    icon="chevron-down"/>
                            </div>
                        }
                    </div>
                )
            },
        };
    });

    const handleSort = (e, field) => {
        e.stopPropagation();
        let order = SortDirection.DESC;
        if (field === pagination.sortedField) {
            order = !pagination.sortedOrder || pagination.sortedOrder === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
        }
        setPagination({...pagination, page: 1, sortedField: field, sortedOrder: order});
    }

    useEffect(() => {
        const handleGoToDetailsPage = (id) => {
            history.push(`${Routes.LOTS_PAGE}/${id}`)
        }

        const hideNotCompliantAnalyse = async (lotId, lotAnalysisId) => {
            try {
                await lotService.editControl(lotId, lotAnalysisId, {hiddenIfNotCompliant: true});
                onFetchData();
                showToast(T.translate('alert.successTitle'), T.translate('dashboard.successHideAnalysis'), ToastClass.SUCCESS);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleHideNotCompliantAnalyse = (e, lotId, lotAnalysisId, analyseName) => {
            e.stopPropagation();
            showConfirmDialog(
                T.translate('dialog.title.confirmDelete'),
                T.translate('dialog.confirmHideAnalysis', {analyseName}),
                () => {
                },
                () => hideNotCompliantAnalyse(lotId, lotAnalysisId))
        }

        const completeElements = allElements => {
            return allElements.map((element, index) => {
                return {
                    ...element,
                    id: `${element.lot.lotId}-${index}`,
                    lot: {
                        ...element.lot,
                        endControlDate: !element.lot.endControlDate ? "-" : <Moment>{element.lot.endControlDate}</Moment>
                    },
                    actions: (
                        <div className="actions">
                            {currentUser.role !== UserRole.VISITOR && currentUser.role !== UserRole.CONTRIBUTOR && (
                                <Button size="sm" onClick={e => handleHideNotCompliantAnalyse(e, element.lot.lotId, element.lotAnalysisId, element.analysisName)}>
                                    <FontAwesomeIcon icon="eye-slash"/>
                                </Button>
                            )}
                            <Button size="sm" className="edit" onClick={() => handleGoToDetailsPage(element.lot.lotId)}>
                                <FontAwesomeIcon icon="edit"/>
                            </Button>
                        </div>
                    )
                }
            })
        }

        const onFetchData = async () => {
            try {
                setState({isLoading: true, error: ''});
                const data = await lotService.getAllNotCompliant(pagination.page, pagination.size, pagination.sortedOrder.toUpperCase(), pagination.sortedField, pagination.searchText);
                setLotList(completeElements(data.content));
                setPagination(prevState => ({
                    ...prevState,
                    totalElements: data.totalElements,
                    totalPages: data.totalPages
                }));
                setState({isLoading: false, error: ''});
            } catch (error) {
                setState({isLoading: false, error: error.message});
            }
        }

        const idFunc = 'card-lots-status-notCompliant';
        if (!!pagination.searchText) {
            const optimizedOnFetchData = debounceFunc(onFetchData, idFunc);
            optimizedOnFetchData();
        } else {
            cancelDebounce(idFunc);
            onFetchData();
        }
    }, [pagination.page, pagination.size, pagination.sortedOrder, pagination.sortedField, pagination.searchText, history, showConfirmDialog, showToast, currentUser.role]);

    const handleSearchChange = e => {
        const searchText = e.target.value;
        if (searchText.length === 0 || searchText.length >= 3) {
            setPagination({...pagination, page: 1, searchText})
        }
    }

    const handlePageChange = page => {
        setPagination({...pagination, page});
    }

    return (
        <Card className={`card-lots-not-compliant ${className}`}>
            <Card.Header className="d-flex justify-content-between align-items-end">
                <Card.Title>
                    <div className="icon-wrapper">
                        <FontAwesomeIcon icon="bell"/>
                        <Badge className="my-badge"><FontAwesomeIcon icon="exclamation" size="xs"/></Badge>
                    </div>
                    <div className="d-inline">{T.translate('title.lotsNotCompliant')}</div>
                </Card.Title>
                <div className="search-container">
                    <SearchInput onChange={handleSearchChange}/>
                </div>
            </Card.Header>
            <Card.Body>
                <div className="overflow-auto remote-table">
                    <CustomTable columns={sortableColumns} data={lotList} useDragRow={false}/>
                </div>
                <div className="d-block d-sm-flex justify-content-between align-item-center p-2 p-sm-4">
                    <div></div>
                    <TablePagination page={pagination.page} totalPages={pagination.totalPages} onPageChange={handlePageChange}/>
                </div>
                <OverlayLoadingOrError isLoading={state.isLoading} error={state.error} onCloseError={() => setState({isLoading: false, error: ''})}/>
            </Card.Body>
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm)),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardLotsNotCompliant);