import React from "react";
import PageWithMenuTemplate from "../../components/template-pages/page-with-menu-template";
import Header from "../../components/header/header";
import {Col, Row} from "react-bootstrap-v5";
import ProfileInfos from "./components/profile-infos";
import ResetPasswordForm from "./components/reset-password-form";
import T from "i18n-react";
import HeaderTextLabel from "../../components/header/header-text-label";
import './profile-page.scss';


const ProfilePage = () => {
    return (
        <PageWithMenuTemplate pageTitle={T.translate('pageTitle.myProfile')}>
            <Header>
                <HeaderTextLabel className="me-4" isMain={true}>
                    <h1 className="ps-1">{T.translate('menu.myProfile')}</h1>
                </HeaderTextLabel>
            </Header>
            <Row>
                <Col sm={12} md={6} className="px-4 mb-3 ps-md-4 pe-md-2">
                    <ProfileInfos/>
                </Col>
                <Col sm={12} md={6} className="px-4 mb-3 ps-md-2 pe-md-4">
                    <ResetPasswordForm/>
                </Col>
            </Row>
        </PageWithMenuTemplate>
    )
}

export default ProfilePage;
