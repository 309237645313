import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, FormControl} from "react-bootstrap-v5";
import '../editable-text/editable-text.scss'
import i18nController from "../../utils/i18n/i18n-controller";
import DatePicker from "react-date-picker";
import moment from "moment";
import T from "i18n-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DateInput = ({
                       className = '',
                       inputClassName = '',
                       id,
                       adjustWidthWithText = false,
                       adjustWidthForEmptyText = false,
                       defaultValue,
                       onChange,
                       error,
                       isTextInRed = false,
                       reload,
                   }) => {

    const spanRef = useRef(null);

    const [width, setWidth] = useState(0);
    const [editing, setEditing] = useState(false);
    const [currentValue, setCurrentValue] = useState(defaultValue);

    useEffect(() => setCurrentValue(defaultValue), [defaultValue, reload]);
    useEffect(() => {
        const widthFormatEmptyDate = 84;
        const widthTimesIcon = 15;
        const widthPadding = 12 * 2;

        let newWidth = spanRef.current?.getBoundingClientRect().width + 76;
        if (adjustWidthForEmptyText) newWidth = widthPadding + widthFormatEmptyDate + widthTimesIcon;
        setWidth(newWidth);
    }, [currentValue, editing]);

    useEffect(() => {
        setCurrentValue(defaultValue);
        setEditing(false);
    }, [defaultValue]);


    const handleClick = () => {
        setEditing(true);
    }

    const handleChange = date => {
        setCurrentValue(date);
        if (date !== defaultValue) {
            onChange(date);
        }
    }

    const handleCalendarClose = () => {
        setEditing(false);
    }

    const handleClearValue = event => {
        event?.stopPropagation();
        const clearedValue = null;
        setCurrentValue(clearedValue);
        if (clearedValue !== defaultValue) {
            onChange(clearedValue);
        }
        setEditing(false);
    }

    return (
        <div className={`date-input ${className} ${editing ? "editing" : ""}`} onClick={handleClick}
             style={{width: adjustWidthWithText ? `${width}px` : "auto"}}>
            <Form.Group controlId={id} className="position-relative">
                <div ref={spanRef} className="hidden-ref">{!currentValue ? T.translate('i18n.format.date') : currentValue}</div>
                <FormControl className={`${inputClassName} ${!currentValue ? "empty-date" : ""} ${isTextInRed ? "color-danger" : ""}`} type="text"
                             placeholder=""
                             value={!currentValue ? T.translate('i18n.datePicker.placeholder.date').toString() : moment(currentValue).format(T.translate('i18n.format.date').toString())}
                             isInvalid={!!error} readOnly={true}/>
                <div className="icons position-absolute end-0 top-50 translate-middle-y me-2">
                    <Button size="sm" onClick={handleClearValue}>
                        <FontAwesomeIcon icon="times" size="lg"/>
                    </Button>
                </div>
                <DatePicker
                    className={`date-picker position-absolute ${className} ${!currentValue ? "empty-date" : ""} ${!!error ? "is-invalid" : ""}`}
                    locale={i18nController.getInstance().getLanguageCode()}
                    value={!currentValue ? null : moment(currentValue, 'yyyy-MM-DD').toDate()}
                    onChange={date => handleChange(!date ? date : moment(date).format('yyyy-MM-DD'))}
                    onCalendarClose={handleCalendarClose}
                    isOpen={editing}
                />
            </Form.Group>
            <FormControl.Feedback type="invalid" className={!!error ? "d-block" : "d-none"}>{error}</FormControl.Feedback>
        </div>
    );
}

export default DateInput;