import React, { useState } from 'react';
import { FormControl, InputGroup, Row, Col } from "react-bootstrap-v5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import T from "i18n-react";
import { InputMaxLength } from "../../../../utils/enums/input-max-length";

const LinkAnalysisSearchInput = ({ className, onChange }) => {

    const [inputValues, setInputValues] = useState({
        category: '',
        productName: '',
        productNumber: '',
        productSupplier: ''
    });

    const handleInputChange = (field) => (e) => {
        const newValue = e.target.value;
        setInputValues(prevValues => ({
            ...prevValues,
            [field]: newValue
        }));
        if (onChange) {
            onChange({ ...inputValues, [field]: newValue });
        }
    };

    return (
        <Row>
            <Col>
                <InputGroup className={`link-analysis-search-input ${className}`}>
                    <InputGroup.Text id="link-analysis-search-input">
                        <FontAwesomeIcon icon="search" />
                    </InputGroup.Text>
                    <FormControl
                        value={inputValues.category}
                        placeholder={T.translate('table.columns.product.category')}
                        aria-label={T.translate('table.columns.product.category')}
                        aria-describedby="link-analysis-search-input"
                        onChange={handleInputChange('category')}
                        maxLength={InputMaxLength.STANDARD_TEXT}
                    />
                </InputGroup>
            </Col>
            <Col>
                <InputGroup className={`link-analysis-search-input ${className}`}>
                    <InputGroup.Text id="link-analysis-search-input">
                        <FontAwesomeIcon icon="search" />
                    </InputGroup.Text>
                    <FormControl
                        value={inputValues.productName}
                        placeholder={T.translate('table.columns.product.name')}
                        aria-label={T.translate('table.columns.product.name')}
                        aria-describedby="link-analysis-search-input"
                        onChange={handleInputChange('productName')}
                        maxLength={InputMaxLength.STANDARD_TEXT}
                    />
                </InputGroup>
            </Col>
            <Col>
                <InputGroup className={`link-analysis-search-input ${className}`}>
                    <InputGroup.Text id="link-analysis-search-input">
                        <FontAwesomeIcon icon="search" />
                    </InputGroup.Text>
                    <FormControl
                        value={inputValues.productNumber}
                        placeholder={T.translate('table.columns.product.articleCode')}
                        aria-label={T.translate('table.columns.product.articleCode')}
                        aria-describedby="link-analysis-search-input"
                        onChange={handleInputChange('productNumber')}
                        maxLength={InputMaxLength.STANDARD_TEXT}
                    />
                </InputGroup>
            </Col>
            <Col>
                <InputGroup className={`link-analysis-search-input ${className}`}>
                    <InputGroup.Text id="link-analysis-search-input">
                        <FontAwesomeIcon icon="search" />
                    </InputGroup.Text>
                    <FormControl
                        value={inputValues.productSupplier}
                        placeholder={T.translate('table.columns.product.supplier')}
                        aria-label={T.translate('table.columns.product.supplier')}
                        aria-describedby="link-analysis-search-input"
                        onChange={handleInputChange('productSupplier')}
                        maxLength={InputMaxLength.STANDARD_TEXT}
                    />
                </InputGroup>
            </Col>
        </Row>
    );
};

export default LinkAnalysisSearchInput;
