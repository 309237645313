import React from 'react';
import T from 'i18n-react'
import './status-component.scss'
import SubStatusComponent from "./sub-status-component";

const StatusComponent = ({onClick, type, subStatus}) => {
    return (
        <div className="status-container">
            <div className="status-component">
                <div className={`${type} ${!onClick ? "" : "cursor-pointer"}`} onClick={onClick}>
                    {T.translate('status.' + type)}
                </div>
            </div>
            {!!subStatus && <SubStatusComponent subStatus={subStatus} id={subStatus}/>}
        </div>
    );
}

export default StatusComponent;