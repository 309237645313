import {ADD_TOAST_ALERT} from './type';

const addToast = (title, message, className) => {
    return {
        type: ADD_TOAST_ALERT,
        title: title,
        message: message,
        className: className
    };
}

export const alertActions = {
    addToast
};