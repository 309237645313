import React, {useState} from 'react';
import T from "i18n-react";
import CustomModal from "../../../components/custom-modal/custom-modal";
import {alertActions} from "../../../redux/alert/actions-alert";
import {connect} from "react-redux";
import {Alert, Button, Form, Spinner} from "react-bootstrap-v5";
import {UserRole} from "../../../utils/enums/user-role";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import TextareaLabel from "../../../components/input-label/textarea-label";
import InputLabel from "../../../components/input-label/input-label";
import Validator from "../../../utils/validators/validator";
import {productActions} from "../../../redux/product/actions-product";
import ProductDto from "../../../models/dto/product-dto";
import {productService} from "../../../services/product-service";
import CompanyAutocompleteLabel from "../../../components/input-label/company-autocomplete-label";

const CreateProductModal = ({showCreateModal, currentUser, showToast, hideCreateModal}) => {

    const [isLoading, setLoading] = useState(false);

    const [company, setCompany] = useState({
        id: currentUser.companyId ?? '',
        name: '',
    });
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [articleCode, setArticleCode] = useState('');
    const [supplier, setSupplier] = useState('');
    const [lifeDuration, setLifeDuration] = useState('');
    const [description, setDescription] = useState('');

    const initialStateInputErrors = {
        company: '',
        name: '',
        category: '',
        articleCode: ''
    };

    const [inputErrors, setInputErrors] = useState({...initialStateInputErrors});
    const [apiError, setApiError] = useState('');

    const resetErrors = () => {
        setInputErrors({...initialStateInputErrors});
        setApiError('');
    }

    const resetForm = () => {
        setCompany({
            id: currentUser.companyId ?? '',
            name: '',
        });
        setName('');
        setCategory('');
        setArticleCode('');
        setSupplier('');
        setLifeDuration('');
        setDescription('');
    }

    const validate = () => {
        const companyError = Validator.validateRequired(company.id);
        const nameError = Validator.validateRequired(name);
        const categoryError = Validator.validateRequired(category);
        const articleCodeError = Validator.validateRequired(articleCode);
        const isValid = !companyError && !nameError && !categoryError && !articleCodeError;
        setInputErrors({
            ...inputErrors,
            company: T.translate(companyError).toString(),
            name: T.translate(nameError).toString(),
            category: T.translate(categoryError).toString(),
            articleCode: T.translate(articleCodeError).toString()
        })
        return isValid;
    }

    const hideModal = () => {
        resetForm();
        resetErrors();
        hideCreateModal();
    }

    const createProduct = async product => {
        setLoading(true);
        try {
            await productService.createProduct(product);
            hideModal();
            showToast(T.translate('alert.successTitle'), T.translate('product.successCreateProduct'), ToastClass.SUCCESS);
        } catch (error) {
            const exceptionClass = error.response?.data?.exceptionClass;
            if (exceptionClass === "ObjectAlreadyExistsException") {
                setApiError(T.translate('error.product.alreadyExists'));
            } else {
                setApiError(error.message);
            }
        } finally {
            setLoading(false);
        }
    }

    const submitCreateProductForm = e => {
        e.preventDefault();

        resetErrors();
        if (validate()) {
            const product = new ProductDto({company, name, category, articleCode, supplier, lifeDuration, description});
            createProduct(product);
        }
    }

    const handleCompanyChange = companySelected => {
        setCompany(companySelected);
    }

    const footer = (
        <React.Fragment>
            <Button variant="outline-secondary" onClick={hideModal} className="px-3 py-1">
                {T.translate('form.button.cancel')}
            </Button>
            <Button variant="primary" onClick={submitCreateProductForm} className="px-3 py-1" disabled={isLoading}>
                {isLoading ? <Spinner as="span" size="sm" animation="border"/> : ""}
                {T.translate('form.button.confirm')}
            </Button>
        </React.Fragment>
    );

    return (
        <CustomModal show={showCreateModal} onHide={hideModal} title={T.translate('product.title.createProduct')} footer={footer}>
            <Form>
                <Alert variant={"danger"} hidden={!apiError}>
                    <Alert.Heading>
                        {T.translate("alert.errorTitle")}
                    </Alert.Heading>
                    {apiError}
                </Alert>
                <fieldset>
                    {
                        currentUser.role === UserRole.SUPER_ADMINISTRATOR &&
                        <CompanyAutocompleteLabel id="companyId" className="mb-4" required={true} error={inputErrors.company}
                                                  defaultValue={company} onChange={handleCompanyChange}/>
                    }
                    <InputLabel id="name" className="mb-4" type="text" label={T.translate('form.label.name')} required={true}
                                value={name} onChange={e => setName(e.target.value)} error={inputErrors.name}/>

                    <InputLabel id="category" className="mb-4" type="text" label={T.translate('form.label.category')} required={true}
                                value={category} onChange={e => setCategory(e.target.value)} error={inputErrors.category}/>

                    <InputLabel id="articleCode" className="mb-4" type="text" label={T.translate('form.label.product.articleCode')} required={true}
                                value={articleCode} onChange={e => setArticleCode(e.target.value)} error={inputErrors.articleCode}/>

                    <InputLabel id="supplier" className="mb-4" type="text" label={T.translate('form.label.supplier')}
                                value={supplier} onChange={e => setSupplier(e.target.value)}/>

                    <InputLabel id="lifeDuration" className="mb-4" type="text" label={T.translate('form.label.lifeDuration')}
                                value={lifeDuration} onChange={e => setLifeDuration(e.target.value)}/>

                    <TextareaLabel id="description" className="mb-4" label={T.translate('form.label.description')}
                                   value={description} onChange={e => setDescription(e.target.value)}/>
                </fieldset>
            </Form>
        </CustomModal>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        showCreateModal: state.productReducer.showCreateModal
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideCreateModal: () => dispatch(productActions.hideCreateModal()),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProductModal);