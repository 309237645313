import React, {useState} from 'react';
import {Alert, Image} from "react-bootstrap-v5";
import iconWarningGreen from "../../assets/images/icons/info.svg";
import iconWarningRed from "../../assets/images/icons/info-red.svg";
import ButtonClose from "../buttons/button-close";
import './alert.scss';

const AlertWarning = ({title, onClose, className, variant = "danger"}) => {

    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        onClose();
    }

    return (
        <Alert className={`warning-alert position-relative d-flex align-items-center py-2 px-3 m-0 ${className}`} variant={variant} show={show}>
            <Image src={variant === "danger" ? iconWarningRed : iconWarningGreen} className="warning-alert-icon me-2"/>
            <div>{title}</div>
            {!!onClose && <ButtonClose onClick={handleClose}/>}
        </Alert>
    );
};

export default AlertWarning;
