import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from "react-bootstrap-v5";
import T from "i18n-react";
import IndicatorBarChart from "../../../components/card-indicator/indicator-bar-chart";
import I18NController from "../../../utils/i18n/i18n-controller";
import {connect} from "react-redux";
import OverlayLoadingOrError from "../../../components/overlay-component/overlay-loading-or-error";
import {laboratoryService} from "../../../services/laboratory-service";
import IndicatorBubbleChart from "../../../components/card-indicator/indicator-bubble-chart";

const CardLaboratoryIndicators = ({laboratoryId, className, currentUser}) => {
    const [state, setState] = useState({isLoading: false, error: ''});
    const [indicatorNbAnalyses, setIndicatorNbAnalyses] = useState({});
    const [indicatorCost, setIndicatorCost] = useState({});

    useEffect(() => {
        const getIndicators = async () => {
            try {
                setState({isLoading: true, error: ''});
                const indicatorNb = await laboratoryService.indicatorsLotsClosedOrderedAnalysesForLaboratory(laboratoryId);
                const indicatorPrice = await laboratoryService.indicatorsLotsClosedControlPriceForLaboratory(laboratoryId);
                setIndicatorNbAnalyses({currentYear: indicatorNb.currentYear.numberOfAnalyses, lastYear: indicatorNb.lastYear.numberOfAnalyses});
                setIndicatorCost({currentYear: indicatorPrice.currentYear.sumOfControlPrice, lastYear: indicatorPrice.lastYear.sumOfControlPrice});
                setState({isLoading: false, error: ''});
            } catch (error) {
                setState({isLoading: false, error: error.message})
            }
        }

        getIndicators();
    }, [laboratoryId]);


    const formatPrice = price => I18NController.getInstance().formatPrice(price, currentUser.currency, currentUser.language);

    return (
        <Card className={`card-laboratory-indicators ${className}`}>
            <Card.Header className="d-flex justify-content-between align-items-center">
                <Card.Title className="m-0">{T.translate('title.indicators')}</Card.Title>
            </Card.Header>
            <Card.Body>
                <Row className="m-0 px-2 gx-4 h-100 justify-content-center">
                    <Col className="mb-4">
                        <IndicatorBubbleChart title={T.translate('indicators.nbAnalyses')} currentYear={indicatorNbAnalyses.currentYear} lastYear={indicatorNbAnalyses.lastYear}/>
                    </Col>
                    <Col className="mb-4">
                        <IndicatorBarChart title={T.translate('indicators.cost')} currentYear={indicatorCost.currentYear} lastYear={indicatorCost.lastYear} labelFormat={formatPrice}/>
                    </Col>
                </Row>
            </Card.Body>
            <OverlayLoadingOrError isLoading={state.isLoading} error={state.error}/>
        </Card>
    );
};

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser
    }
}

export default connect(mapStateToProps)(CardLaboratoryIndicators);
