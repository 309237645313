import {backendUrl} from "./api/api";

const BASE_URL = '/products-export/';

const getControlPlansCsvUrlByCompany = (companyId) => {
    return `${backendUrl}${BASE_URL}control-plans/csv?companyId=${companyId}`;
}

const getLstingProductsCsvUrlByCompany = (companyId) => {
    return `${backendUrl}${BASE_URL}listing-products/csv?companyId=${companyId}`;
}

export const productsExportService = {
    getControlPlansCsvUrlByCompany,
    getLstingProductsCsvUrlByCompany
};
