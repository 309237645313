import React, {useEffect, useState} from 'react';
import T from "i18n-react";
import CustomModal from "../../../components/custom-modal/custom-modal";
import {alertActions} from "../../../redux/alert/actions-alert";
import {connect} from "react-redux";
import {Alert, Button, Form, Spinner, Table} from "react-bootstrap-v5";
import {analyseActions} from "../../../redux/analyse/actions-analyse";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {analyseService} from "../../../services/analyse-service";
import '../analysis-page.scss'
import AnalyseDto from "../../../models/dto/analyse-dto";
import EditableText from "../../../components/editable-text/editable-text";
import {InputMaxLength} from "../../../utils/enums/input-max-length";

const EditAnalyseReferencesModal = ({analyseReducer, currentUser, showToast, previousPopup, hideEditReferencesModal, analyseCreate}) => {
    const [laboratoryParameters, setLaboratoryParameters] = useState([]);
    const [parameterList, setParameterList] = useState([]);
    const [laboratoryList, setLaboratoryList] = useState([]);
    const [reset, setReset] = useState(false);

    const [isLoading, setLoading] = useState({value: false, next: false});

    const initialStateInputErrors = {
        analyse: '',
        reference: '',
    };
    const [inputErrors, setInputErrors] = useState({...initialStateInputErrors});
    const [apiError, setApiError] = useState('');
    const resetErrors = () => {
        setInputErrors({...initialStateInputErrors});
        setApiError('');
    }

    const [resetFormCreateOrEditModal, setResetFormCreateOrEditModal] = useState(null);

    useEffect(() => {
        const laboratoryParameters = analyseReducer.analysisBeingCreatingOrModified.laboratoryParameters;
        setLaboratoryParameters(laboratoryParameters);
        const laboratorySet = new Set(
            Array.isArray(laboratoryParameters) ? laboratoryParameters.map((item) => item.laboratoryName) : []
        );
        const parameterSet = new Set(
            Array.isArray(laboratoryParameters) ? laboratoryParameters.map((item) => item.parameterName) : []
        );
        setLaboratoryList(Array.from(laboratorySet));
        setParameterList(Array.from(parameterSet));
    }, [analyseReducer.analysisBeingCreatingOrModified.laboratoryParameters]);

    useEffect(() => {
        const laboratorySet = new Set(
            Array.isArray(laboratoryParameters) ? laboratoryParameters.map((item) => item.laboratoryName) : []
        );
        const parameterSet = new Set(
            Array.isArray(laboratoryParameters) ? laboratoryParameters.map((item) => item.parameterName) : []
        );
        setLaboratoryList(Array.from(laboratorySet));
        setParameterList(Array.from(parameterSet));
    }, [laboratoryParameters, reset]);

    useEffect(() => {
            const resetFormFromRef = analyseReducer.resetFormRef?.current;
            setResetFormCreateOrEditModal(() => resetFormFromRef);
        }, [analyseReducer.resetFormRef]
    )

    const previousModal = () => {
        resetErrors();
        previousPopup(laboratoryParameters);
    }

    const hideModal = (continueCreating) => {
        resetFormCreateOrEditModal?.({});
        resetErrors();
        if (continueCreating) analyseCreate()
        else hideEditReferencesModal()
    }

    const createOrEditAnalyse = async (analyse, continueCreating) => {
        setLoading({value: true, next: continueCreating});
        try {
            if (analyseReducer.isCreationMode) {
                await analyseService.createAnalyse(analyse);
            } else {
                await analyseService.editAnalyse(analyseReducer.analyseId, analyse);
            }
            setLoading({value: false, next: false});
            showToast(T.translate('alert.successTitle'), T.translate(`analyse.${analyseReducer.isCreationMode ? 'successCreateAnalyse' : 'successEditAnalyse'}`), ToastClass.SUCCESS);
            hideModal(continueCreating);


        } catch (error) {
            setLoading({value: false, next: false});
            const field = error.response?.data?.entityIdField;
            const exceptionClass = error.response?.data?.exceptionClass;
            if (exceptionClass === "ObjectAlreadyExistsException" && field === "nameAndMethod") {
                const [name, method] = error.response?.data?.entityIdValue.split(" / ");
                setInputErrors({
                    ...inputErrors,
                    analyse: T.translate('error.analyse.nameAndMethodAlreadyExists', {name, method}),
                });
            } else if (exceptionClass === "LaboratoryReferenceAlreadyExistsException") {
                setInputErrors({
                    ...inputErrors,
                    reference: T.translate('error.analyse.duplicateReference'),
                });
            } else {
                setApiError(error.message);
            }
        }
    }

    const submitCreateAnalyseForm = (e, continueCreating) => {
        e.preventDefault();
        resetErrors();
        const analyse = {...analyseReducer.analysisBeingCreatingOrModified, laboratoryParameters: laboratoryParameters};
        const analyseDTO = new AnalyseDto(analyse, analyseReducer.isCreationMode);
        createOrEditAnalyse(analyseDTO, continueCreating);
    }

    const handleNameParameterChange = (oldName, newName) => {
        const isNormalizedNameAlreadyExists = parameterList.some(name => name.toLowerCase() === newName.trim().toLowerCase());
        if (!isNormalizedNameAlreadyExists) {
            setLaboratoryParameters(
                laboratoryParameters.map((item) => (item.parameterName === oldName ? {
                    ...item,
                    parameterName: newName.trim()
                } : item))
            );
            setParameterList(prevParameterList =>
                prevParameterList.map(name =>
                    name === oldName ? newName : name
                )
            );
        } else {
            setReset(!reset);
        }
    };

    const footer = (
        <React.Fragment>
            <Button variant="outline-secondary" onClick={previousModal} className="px-3 py-1">
                {T.translate('form.button.previous')}
            </Button>
            {
                analyseReducer.isCreationMode &&
                <Button variant="dark" onClick={e => submitCreateAnalyseForm(e, true)} className="px-3 py-1"
                        disabled={isLoading.value}>
                    {isLoading.value && isLoading.next && <Spinner as="span" size="sm" animation="border"/>}
                    {T.translate('form.button.confirmAndNew')}
                </Button>
            }
            <Button variant="primary" onClick={e => submitCreateAnalyseForm(e, false)} className="px-3 py-1"
                    disabled={isLoading.value}>
                {isLoading.value && !isLoading.next && <Spinner as="span" size="sm" animation="border"/>}
                {T.translate('form.button.confirm')}
            </Button>
        </React.Fragment>
    );

    return (
        <CustomModal show={analyseReducer.showEditReferencesModal} onHide={() => hideModal(false)}
                     title={T.translate('analyse.title.tableOfReferences')} footer={footer}>
            <Form>
                <Alert variant={"danger"} hidden={!apiError}>
                    <Alert.Heading>
                        {T.translate("alert.errorTitle")}
                    </Alert.Heading>
                    {apiError}
                </Alert>
                <Alert variant={"danger"} hidden={!inputErrors.analyse}>
                    <Alert.Heading>
                        {T.translate("alert.errorTitle")}
                    </Alert.Heading>
                    {inputErrors.analyse}
                </Alert>
                <Alert variant={"danger"} hidden={!inputErrors.reference}>
                    <Alert.Heading>
                        {T.translate("alert.errorTitle")}
                    </Alert.Heading>
                    {inputErrors.reference}
                </Alert>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>{T.translate("analyse.parameter")}</th>
                        {
                            laboratoryList.map(
                                (lab) =>
                                    <th>{lab}</th>
                            )
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {parameterList.map((nameParameter, index) => (
                            <tr key={nameParameter}>
                                <td>
                                    <EditableText
                                        id={`nameParameter-${nameParameter}`}
                                        defaultValue={nameParameter}
                                        onSubmit={(newName) => handleNameParameterChange(nameParameter, newName)}
                                        maxLength={InputMaxLength.FIFTY_CHARACTERS_TEXT}
                                        shouldSubmitOnBlur
                                        forceUpdate={true}
                                        reload={reset}
                                    />
                                </td>
                                {laboratoryList.map((lab) => {
                                    const cellData = laboratoryParameters.find(
                                        (item) => item.laboratoryName === lab && item.parameterName === nameParameter
                                    );
                                    return (
                                        <td key={`${lab}-${nameParameter}`}>
                                            <EditableText
                                                id={`referenceParameter-${lab}-${nameParameter}`}
                                                defaultValue={cellData?.reference}
                                                onSubmit={(val) => cellData.reference = val}
                                                maxLength={InputMaxLength.FIFTY_CHARACTERS_TEXT}
                                                shouldSubmitOnBlur
                                            />
                                        </td>
                                    );
                                })}
                            </tr>
                        )
                    )}
                    </tbody>
                </Table>
            </Form>
        </CustomModal>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        analyseReducer: state.analyseReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideEditReferencesModal: () => dispatch(analyseActions.hideEditReferencesModal()),
        analyseCreate: () => dispatch(analyseActions.analyseCreate()),
        previousPopup: (referencesData) => dispatch(analyseActions.previousEditReferencesModal(referencesData)),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAnalyseReferencesModal);
