import React, {useEffect, useState} from 'react';
import {Card} from "react-bootstrap-v5";
import OverlayLoadingOrError from "../../../components/overlay-component/overlay-loading-or-error";
import LinesChart from "../../../components/charts/lines-chart";
import {lotService} from "../../../services/lot-service";
import T from "i18n-react";

const CardIndicatorsLotsClosed = ({className}) => {
    const lastYear = new Date().getFullYear() - 1;

    const [state, setState] = useState({isLoading: false, error: ''});

    const [totalCurrentYear, setTotalCurrentYear] = useState(0);
    const [totalLastYear, setTotalLastYear] = useState(0);
    const [datasets, setDatasets] = useState([]);

    useEffect(() => {
        const getIndicators = async () => {
            try {
                setState({isLoading: true, error: ''});
                const indicators = await lotService.indicatorsLotsClosed();
                setTotalCurrentYear(indicators.currentYear.totalNumberOfClosedLots);
                setTotalLastYear(indicators.lastYear.totalNumberOfClosedLots);
                const datasetCurrentYear = indicators.currentYear.numberOfClosedLotsByMonth.map((value, index) => ({x: index, y: value}));
                const datasetLastYear = indicators.lastYear.numberOfClosedLotsByMonth.map((value, index) => ({x: index, y: value}));
                setDatasets([datasetCurrentYear, datasetLastYear]);
                setState({isLoading: false, error: ''});
            } catch (error) {
                setState({isLoading: false, error: error.message})
            }
        }

        getIndicators();
    }, []);

    return (
        <Card className={`card-indicators h-100 ${className}`}>
            <Card.Header className="d-flex justify-content-between align-items-end pb-0">
                <Card.Title>{T.translate(`indicators.lotsClosed`)}</Card.Title>
            </Card.Header>
            <Card.Body>
                <div className="currentYear text-end pe-4">{totalCurrentYear}</div>
                <div className="lastYear text-end pe-4">{lastYear}: <span>{totalLastYear}</span></div>
                <LinesChart className="h-auto" datasets={datasets}/>
                <OverlayLoadingOrError isLoading={state.isLoading} error={state.error} onCloseError={() => setState({isLoading: false, error: ''})}/>
            </Card.Body>
        </Card>
    );
}

export default CardIndicatorsLotsClosed;