import React from 'react';
import {Form} from "react-bootstrap-v5";
import './input-label.scss'

const CheckLabel = ({id, label, required, checked, onChange, error, className}) => {
    return (
        <Form.Group controlId={id} className={`check-label row align-items-center ${className}`}>
            <Form.Label className="col col-4 text-end my-0">{label}{!!required ? <span className="required">*</span> : ""}</Form.Label>
            <div className="col col-8">
                <Form.Check id={`checkbox-${id}`} type="checkbox">
                    <Form.Check.Input type="checkbox" isInvalid={!!error} checked={checked} onChange={onChange}/>
                    <Form.Control.Feedback type='invalid'>
                        {error}
                    </Form.Control.Feedback>
                </Form.Check>
            </div>
        </Form.Group>
    );
}

export default CheckLabel;

