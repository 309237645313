import React, {useCallback, useEffect, useState} from 'react';
import CustomModal from "../../../components/custom-modal/custom-modal";
import {connect} from "react-redux";
import T from 'i18n-react'
import {Alert, Button, Col, Form, Row, Spinner} from "react-bootstrap-v5";
import Validator from "../../../utils/validators/validator";
import InputFileLabel from "../../../components/input-label/input-file-label";
import {alertActions} from "../../../redux/alert/actions-alert";
import {ToastClass} from "../../../components/toast-alert/toast-alert-class";
import {analyseActions} from "../../../redux/analyse/actions-analyse";
import SelectLabel from "../../../components/input-label/select-label";
import {SortDirection} from "../../../utils/enums/sort-direction";
import {laboratoryService} from "../../../services/laboratory-service";
import {UserRole} from "../../../utils/enums/user-role";
import CompanyAutocompleteLabel from "../../../components/input-label/company-autocomplete-label";
import {analyseService} from "../../../services/analyse-service";
import {config} from "@fortawesome/fontawesome-svg-core";
import {FileType} from "../../../components/input-label/file-type";

const ImportModal = ({analyseReducer, currentUser, hideImportModal, showToast}) => {
    const companyInitialState = {id: currentUser?.role === UserRole.SUPER_ADMINISTRATOR ? '' : currentUser?.companyId, name: ''};

    const [isLoading, setLoading] = useState(false);
    const [apiError, setApiError] = useState('');

    const [company, setCompany] = useState(companyInitialState);
    const [laboratory, setLaboratory] = useState({laboratoryId: '', name: ''});

    const [laboratoryList, setLaboratoryList] = useState([]);

    const [file, setFile] = useState(null);

    const [inputErrors, setInputErrors] = useState({compagny: '', laboratory: '', file: ''});

    useEffect(() => {
        resetForm();
    }, [currentUser])

    useEffect(() => {

        const getAndSetLaboratoriesVariables = async () => {
            if (company.id.length === 0) {
                setLaboratoryList([]);
                setLaboratory({laboratoryId: '', name: ''})
                return;
            }
            try {
                const response = await laboratoryService.getAll(1, 1000, SortDirection.ASC.toUpperCase(), 'name', '', company.id)
                const laboratories = response.content?.map(({laboratoryId, name}) => {
                    return {laboratoryId, name}
                }) ?? [];
                setLaboratoryList(laboratories)
                if (laboratories.length > 0) {
                    setLaboratory(laboratories[0]);
                }
            } catch (error) {
                setApiError(error.message);
            }
        };

        if (analyseReducer.showImportModal) {
            getAndSetLaboratoriesVariables();
        }
    }, [company.id, analyseReducer.showImportModal])

    const resetErrors = () => {
        setInputErrors({compagny: '', laboratory: '', file: ''});
        setApiError('');
    }

    const resetForm = useCallback(
        () => {
            setCompany(companyInitialState);
            setLaboratory({laboratoryId: '', name: ''});
            setLaboratoryList([])
            setFile(null);
        }, [],
    );

    const validate = () => {
        const companyError = Validator.validateRequired(company.id);
        const laboratoryError = Validator.validateRequired(laboratory.name);
        const fileError = Validator.validateRequiredXLSXFile(file);
        const isValid = !laboratoryError && !fileError && !companyError;
        setInputErrors({
            company: T.translate(companyError).toString(),
            laboratory: T.translate(laboratoryError).toString(),
            file: T.translate(fileError).toString()
        })
        return isValid;
    }

    const importResults = async (formData) => {
        setLoading(true);
        try {
            const data = await analyseService.importResults(laboratory.laboratoryId, formData, config);
            setLoading(false);
            hideModal();
            showToast(T.translate('alert.successTitle'), T.translate('analyse.succesImportResults', {
                errorLines: data.numberOfErrors
            }), data.numberOfErrors === 0 ? ToastClass.SUCCESS : ToastClass.ERROR)
        } catch (error) {
            setLoading(false);
            const message = error.response?.data?.message;
            const exceptionClass = error.response?.data?.exceptionClass;

            if (exceptionClass === "IllegalArgumentException") {
                if (message.includes('expected title')) {
                    setInputErrors({compagny: '', laboratory: '', file: message,});
                } else {
                    setApiError(T.translate('error.analyse.wrongFileForImport'));
                }
            } else {
                setApiError(error.message);
            }
        }
    }

    const submitForm = e => {
        e.preventDefault();

        resetErrors();
        if (validate()) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('companyId', company.id);
            importResults(formData)
        }
    }

    const hideModal = () => {
        resetErrors();
        resetForm();
        hideImportModal();
    }

    const handleCompanyChange = value => {
        setCompany(value);
    }

    const handleLaboratoryChange = e => {
        const selectedIndex = e.target.options.selectedIndex;
        setLaboratory(laboratoryList[selectedIndex]);
    }

    const laboratoryOptions = () => {
        return laboratoryList.map(laboratoryElement => (
            <option key={laboratoryElement.name} value={laboratoryElement}
                    selected={laboratoryElement.name === laboratory.name}>{laboratoryElement.name}</option>
        ))
    }

    const footer = (
        <React.Fragment>
            <Button variant="outline-secondary" onClick={hideModal} className="px-3 py-1">
                {T.translate('form.button.cancel')}
            </Button>
            <Button variant="primary" onClick={submitForm} className="px-3 py-1" disabled={isLoading}>
                {isLoading ? <Spinner as="span" size="sm" animation="border"/> : ""}
                {T.translate('form.button.confirm')}
            </Button>
        </React.Fragment>
    );

    return (

        <CustomModal show={analyseReducer.showImportModal} onHide={hideModal} footer={footer} title={T.translate('analyse.title.importResults')}>
            <Form>
                <Alert variant={"danger"} hidden={!apiError}>
                    <Alert.Heading>
                        {T.translate("alert.errorTitle")}
                    </Alert.Heading>
                    {apiError}
                </Alert>
                <Row>
                    <Col sm={12} lg={8} className="offset-lg-1">
                        {
                            currentUser?.role === UserRole.SUPER_ADMINISTRATOR &&
                            <CompanyAutocompleteLabel id="companyId" className="mb-4" required={true} error={inputErrors.company}
                                                      defaultValue={company} onChange={handleCompanyChange}/>
                        }
                        {(currentUser?.role === UserRole.SUPER_ADMINISTRATOR && laboratoryList.length > 0 || currentUser?.role !== UserRole.SUPER_ADMINISTRATOR) &&
                            <SelectLabel id="laboratory" className="mb-4" type="text" label={T.translate('form.label.analyse.laboratoryImport')} required={true}
                                         defaultValue={laboratory} onChange={handleLaboratoryChange}
                                         error={inputErrors.laboratory}
                                         options={laboratoryOptions()}/>
                        }
                        <InputFileLabel id="file" className="mb-4" label={T.translate('form.label.file')} required={true}
                                        onChange={val => setFile(val)} error={inputErrors.file} accept={FileType.OOXML}/>
                    </Col>
                </Row>
            </Form>
        </CustomModal>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        analyseReducer: state.analyseReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideImportModal: () => dispatch(analyseActions.hideImportModal()),
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportModal);