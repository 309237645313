import React from 'react';
import {connect} from "react-redux";
import ToastAlert from "./toast-alert";
import './toast-alert.scss'

const ToastAlertList = ({toasts}) => {
    return toasts.map(toast => <ToastAlert key={toast.id} title={toast.title} message={toast.message} className={toast.className}/>);
}

const mapStateToProps = state => {
    return {
        toasts: state.alertReducer.toasts
    };
}

export default connect(mapStateToProps)(ToastAlertList);
