import T from 'i18n-react';
import dataFr from "./locales/fr.json";
import dataEn from "./locales/en.json";
import Moment from "react-moment";
import 'moment/locale/fr';

const defaultLanguage = "fr";
const defaultCurrency = "EUR";

const files = {
    fr: dataFr,
    en: dataEn,
}

class I18NController {
    static _instance = null;

    static getInstance() {
        if (I18NController._instance == null) {
            I18NController._instance = new I18NController();
        }
        return this._instance;
    }

    _lang = defaultLanguage;
    _onLangChanged;

    setOnLangChanged = (callback) => this._onLangChanged = callback;

    getLanguageCode() {
        return this._getCountryCodeFromLang(this._lang);
    }

    init() {
        let lang = this._getInitialLang();
        this._updateLang(lang);
    }

    changeLang(lang) {
        this._updateLang(lang);
        if (this._onLangChanged) this._onLangChanged();
    }

    formatPrice(price, currency, language) {
        return new Intl.NumberFormat(
            !!language ? language.replace('_', '-') : defaultLanguage,
            {
                style: 'currency',
                currency: !!currency ? currency : defaultCurrency,
                maximumFractionDigits: 0,
            }
        ).format(price);
    }

    _updateLang(lang) {
        this._lang = this._isSupportedLang(lang) ? lang : defaultLanguage;
        this._initData(this._lang);
        this._initMoment(this._lang);
    }

    _isSupportedLang(lang) {
        return !!files[lang] || !!files[this._getCountryCodeFromLang(lang)];
    }

    _getInitialLang() {
        let lang = this._getUserLang();
        if (!!lang && this._isSupportedLang(lang)) {
            return lang;
        }
        lang = this._getNavigatorLang();
        if (this._isSupportedLang(lang)) {
            return lang;
        }
        return defaultLanguage;
    }

    _getUserLang() {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let lang = currentUser?.language;
        if (!!lang && this._isSupportedLang(lang)) {
            return lang;
        }
        return null;
    }

    _initData(lang) {
        let data = files[defaultLanguage];
        if (!!files[lang]) {
            data = files[lang];
        } else {
            const countryOnly = this._getCountryCodeFromLang(lang);
            if (!!files[countryOnly]) {
                data = files[countryOnly];
            }
        }
        T.setTexts(data);
    }

    _initMoment(lang) {
        Moment.globalLocale = lang;
        Moment.globalFormat = T.translate('i18n.format.date').toString();
    }

    _getNavigatorLang() {
        return window.navigator.language;
    }

    _getCountryCodeFromLang(lang) {
        return lang.replace('-', '_').split('_')[0];
    }
}

export default I18NController;
