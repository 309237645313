import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import PageWithMenuTemplate from "../../components/template-pages/page-with-menu-template";
import T from "i18n-react";
import { Button, Col, Image, OverlayTrigger, Row, Tooltip } from "react-bootstrap-v5";
import OverlayLoadingOrError from "../../components/overlay-component/overlay-loading-or-error";
import { ToastClass } from "../../components/toast-alert/toast-alert-class";
import { alertActions } from "../../redux/alert/actions-alert";
import { connect } from "react-redux";
import Header from "../../components/header/header";
import { UserRole } from "../../utils/enums/user-role";
import { lotService } from "../../services/lot-service";
import LotEntity from "../../models/entities/lot-entity";
import EditableText from "../../components/editable-text/editable-text";
import HeaderTextLabel from "../../components/header/header-text-label";
import StatusComponent from "../../components/status-component/status-component";
import { StatusLot, StatusLotClassname } from "../../utils/enums/status-lot";
import CardLotInfosAndComments from "./component/card-lot-infos-and-comments";
import CardDocuments from "../../components/card-documents/card-documents";
import ButtonWithOptions, { ButtonOption } from "../../components/buttons/button-with-options";
import CloseLotModal from "./component/close-lot-modal";
import CardLotControlPlan from "./component/card-lot-control-plan";
import { documentActions } from "../../redux/document/actions-document";
import { lotActions } from "../../redux/lot/actions-lot";
import './lot-details-page.scss';
import AnalyseHistoryModal from "../../components/analyse-history-modal/analyse-history-modal";
import CardLotIndicators from "./component/card-lot-indicators";
import moment from "moment";
import { DocumentType } from "../../utils/enums/document-type";
import ClosingDateComponent from "./component/closing-date-component";
import { ClosingReason, ClosingReasonClassname } from "../../utils/enums/closing-reason";
import { NotCompliantReason } from "../../utils/enums/not-compliant-reason";
import AppDropdown from "../../components/dropdowns/app-dropdown";
import iconTransfer from "../../assets/images/icons/transfer.svg";
import iconArrowCircled from "../../assets/images/icons/arrow-right-circle.svg";
import { Routes } from "../../router/router-constants";

const LotDetailsPage = ({ showToast, currentUserRole, generateDocument, lotControlEdited, lotCancelTransfer }) => {
    const { id } = useParams();

    const [lot, setLot] = useState({});
    const [showCloseLotModal, setShowCloseLotModal] = useState(false);
    const [generateCertificateAfterCloseLot, setGenerateCertificateAfterCloseLot] = useState(false);
    const [state, setState] = useState({ isLoading: false, error: '', progress: 0 });
    const [lotTransferedResult, setLotTransferedResult] = useState([]);

    useEffect(() => {
        const getLot = async () => {
            setState(prevState => {
                return { ...prevState, isLoading: true };
            });
            try {
                const data = await lotService.getLotById(id);
                const lotEntity = new LotEntity(data);
                setLot(lotEntity)
                setState(prevState => {
                    return { ...prevState, isLoading: false };
                });
            } catch (error) {
                setState(prevState => {
                    return { ...prevState, isLoading: false, error: error.message };
                });
            }
        }
        getLot();
    }, [id, state.progress])


    useEffect(() => {
        if (lot.destinationLots) {
            const transferedResults = lot.destinationLots
                .filter(x => x.transferedResults)
                .filter((item, index, self) =>
                    index === self.findIndex(t => t.transferedResults === item.transferedResults)
                );
            setLotTransferedResult(transferedResults);
        }
    }, [lot])



    const history = useHistory();

    const goToPreviousPage = () => {
        history.goBack();
    }

    const goToLotPage = lotId => {
        history.push(`${Routes.LOTS_PAGE}/${lotId}`);
    }

    const editLot = async (lotPatch) => {
        return await lotService.editLot(lot.id, lotPatch);
    }

    const handleLotNumberChange = async num => {
        try {
            setLot({ ...lot, lotNumber: num });
            await editLot({ lotNumber: num });
            showToast(T.translate('alert.successTitle'), T.translate('lot.successEditLotNumber'), ToastClass.SUCCESS);
        } catch (error) {
            const exceptionClass = error.response?.data?.exceptionClass;
            if (exceptionClass === "ObjectAlreadyExistsException") {
                if (error.response.data.entityIdValue === "LOT_NUMBER_PRODUCT") {
                    showToast(T.translate('alert.errorTitle'), T.translate('error.lot.lotNumberProductAlreadyExists', { lotNumber: num, product: lot.productName }), ToastClass.ERROR);
                }
                else if (error.response.data.entityIdValue === "LOT_NUMBER") {
                    showToast(T.translate('alert.errorTitle'), T.translate('error.lot.lotNumberAlreadyExists', { lotNumber: num }), ToastClass.ERROR);
                }
                else {
                    showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
                }
            } else {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
            setLot({ ...lot, lotNumber: lot.lotNumber });
        }
    }

    const handleEndControlDateChange = async date => {
        const oldEndControlDate = lot.endControlDate;
        try {
            const endControlDate = !date ? '' : moment(date).format('yyyy-MM-DD');
            setLot({ ...lot, endControlDate });
            await editLot({ endControlDate });
            showToast(T.translate('alert.successTitle'), T.translate('lot.successEditEndControlDate'), ToastClass.SUCCESS);
        } catch (error) {
            setLot({ ...lot, endControlDate: oldEndControlDate });
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleValidateLot = async () => {
        try {
            await lotService.validateLot(id);
            setState({ ...state, progress: state.progress + 1 })
            showToast(T.translate('alert.successTitle'), T.translate('lot.successValidateLot'), ToastClass.SUCCESS)
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR)
        }
    }

    const handleUnvalidateLot = async () => {
        try {
            await lotService.reopenLot(id);
            setState({ ...state, progress: state.progress + 1 })
            showToast(T.translate('alert.successTitle'), T.translate('lot.successUnvalidateLot'), ToastClass.SUCCESS);
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleOpenLot = async () => {
        try {
            await lotService.reopenLot(id);
            setState({ ...state, progress: state.progress + 1 })
            showToast(T.translate('alert.successTitle'), T.translate('lot.successOpenLot'), ToastClass.SUCCESS);
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleCloseLot = () => {
        setShowCloseLotModal(true);
    }

    const onLotClosed = () => {
        if (generateCertificateAfterCloseLot) {
            handleGenerateCertificate();
        }
        setState({ ...state, progress: state.progress + 1 });
        setGenerateCertificateAfterCloseLot(false);
    }

    const handleHideModal = (success) => {
        setShowCloseLotModal(false)
        if (success) {
            onLotClosed();
        }
    }

    const handleEditComment = async comment => {
        try {
            setLot({ ...lot, comment });
            await lotService.editLotComment(id, comment)
            showToast(T.translate('alert.successTitle'), T.translate('lot.successEditComment'), ToastClass.SUCCESS);
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleEditValue = async (name, tabNewValue, newValue) => {
        const message = name.charAt(0).toUpperCase() + name.slice(1);
        try {
            await editLot(tabNewValue)
            updateLot(name, newValue);
            showToast(T.translate('alert.successTitle'), T.translate('lot.successEdit' + message), ToastClass.SUCCESS);
        } catch (error) {
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const updateLot = (name, newValue) => {
        switch (name) {
            case 'supplier':
                const supplier = newValue;
                return setLot({ ...lot, supplier });

            case 'supplierLotNumber':
                const supplierLotNumber = newValue;
                return setLot({ ...lot, supplierLotNumber });

            case 'manufacturingDate':
                const manufacturingDate = newValue;
                return setLot({ ...lot, manufacturingDate });

            case 'dluo':
                const dluo = newValue;
                return setLot({ ...lot, dluo });

            default:
        }
    }

    const handleGenerateControlFile = async (e) => {
        e.stopPropagation();

        try {
            setState({ ...state, isLoading: true });
            await lotService.generateControlFile(id);
            generateDocument();
            setState({ ...state, isLoading: false });
            showToast(T.translate('alert.successTitle'), T.translate('lot.successGenerateControlFile'), ToastClass.SUCCESS);
        } catch (error) {
            setState({ ...state, isLoading: false });
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleGenerateConformity = async (e) => {
        e.stopPropagation();

        try {
            setState({ ...state, isLoading: true });
            await lotService.generateConformity(id);
            generateDocument();
            setState({ ...state, isLoading: false });
            showToast(T.translate('alert.successTitle'), T.translate('lot.successGenerateConformity'), ToastClass.SUCCESS);
        } catch (error) {
            setState({ ...state, isLoading: false });
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }


    const handleGenerateCertificate = async () => {
        try {
            setState({ ...state, isLoading: true });
            await lotService.generateCertificate(id);
            setState({ ...state, isLoading: false });
            generateDocument();
            showToast(T.translate('alert.successTitle'), T.translate('lot.successGenerateCertificate'), ToastClass.SUCCESS);
        } catch (error) {
            setState({ ...state, isLoading: false });
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleGenerateCertificateWithAnnexes = async (e) => {
        e.stopPropagation();

        try {
            setState({ ...state, isLoading: true });
            await lotService.generateCertificateWithAnnexes(id);
            setState({ ...state, isLoading: false });
            generateDocument();
            showToast(T.translate('alert.successTitle'), T.translate('lot.successGenerateCertificateWithAnnexes'), ToastClass.SUCCESS);
        } catch (error) {
            setState({ ...state, isLoading: false });
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleGenerateOrders = async () => {
        try {
            setState({ ...state, isLoading: true });
            await lotService.generateOrders(id);
            setState({ ...state, isLoading: false, progress: state.progress + 1 });
            generateDocument();
            lotControlEdited();
            showToast(T.translate('alert.successTitle'), T.translate('lot.successGenerateOrders'), ToastClass.SUCCESS);
        } catch (error) {
            setState({ ...state, isLoading: false });
            showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
        }
    }

    const handleCanceledTransfer = () => {
        setLot(prevState => ({
            ...prevState,
            containsTransferedLotAnalysis: false,
        }))
        setState({ ...state, progress: state.progress + 1 })
        lotCancelTransfer();
    }

    const closeOrOpenLotButton = lot.status === StatusLot.NEW || lot.status === StatusLot.IN_PROGRESS ? (
        <Button variant="accent" className="d-inline-block px-4 me-2 mb-2 mb-sm-0" onClick={handleValidateLot}>
            {T.translate('lot.button.validateLot')}
        </Button>
    ) : currentUserRole === UserRole.CONTRIBUTOR || currentUserRole === UserRole.VISITOR ? "" :
        lot.status === StatusLot.TO_CLOSE ? (
            <React.Fragment>
                <Button variant="accent" className="d-inline-block px-4 me-2 mb-2 mb-sm-0" onClick={handleUnvalidateLot}>
                    {T.translate('lot.button.openLot')}
                </Button>
                <Button variant="accent" className="d-inline-block px-4 me-2 mb-2 mb-sm-0" onClick={handleCloseLot}>
                    {T.translate('lot.button.closeLot')}
                </Button>
            </React.Fragment>
        ) : (
            <Button variant="accent" className="d-inline-block px-4 me-2 mb-2 mb-sm-0" onClick={handleOpenLot}>
                {T.translate('lot.button.openLot')}
            </Button>
        );

    const generateCertificateAndControlFileButton = (currentUserRole !== UserRole.CONTRIBUTOR) ?
        (<ButtonWithOptions variant="primary" className="me-3" onClick={handleGenerateCertificate} title={T.translate('lot.button.generateCertificate')}>
            <ButtonOption className="primary p-2" title={T.translate('lot.button.generateCertificateWithAnnexes')} onClick={handleGenerateCertificateWithAnnexes} />
            <ButtonOption className="primary p-2" title={T.translate('lot.button.generateConformityFile')} onClick={handleGenerateConformity} />
            <ButtonOption className="primary p-2" title={T.translate('lot.button.generateControlFile')} onClick={handleGenerateControlFile} />
        </ButtonWithOptions>) :
        (<Button variant="secondary" className="px-4 mb-2 mb-sm-0" onClick={handleGenerateControlFile}>
            {T.translate('lot.button.generateControlFile')}
        </Button>);

    const headerButton = currentUserRole !== UserRole.VISITOR && (
        <React.Fragment>
            {closeOrOpenLotButton}
            {generateCertificateAndControlFileButton}
        </React.Fragment>
    );

    const headerTitle = (
        <HeaderTextLabel className="me-4" label={T.translate('lot.lot')} isMain={true}>
            <h1 className="p-1">{lot.name}</h1>
        </HeaderTextLabel>
    );

    const transferTitleComponent = (
        <div className="d-flex align-items-center">
            <Image src={iconTransfer} width={18} className="me-2" />
            <div>
                {T.translate('lot.label.transfers')} <span className="text-success">({lotTransferedResult?.length})</span>
            </div>
        </div>
    );

    const destinationLotComponent = dest => {
        const component = (
            <div className="destination-lot-item d-flex align-items-center cursor-pointer px-2 py-1" onClick={() => dest.archived ? null : goToLotPage(dest.lotId)}>
                <Image src={iconTransfer} />
                <div className="text-nowrap ms-2 me-auto">{T.translate('lot.lot')} : {dest.lotNumber}</div>
                <Image src={iconArrowCircled} />
            </div>
        );

        return dest.archived ? (
            <OverlayTrigger trigger={['hover', 'focus']} key={`tooltip-trigger-dest-archived-${dest.lotId}`} placement="top"
                overlay={<Tooltip id={`tooltip-dest-archived-${dest.lotId}`}>{T.translate('lot.lotArchived')}</Tooltip>}>
                {component}
            </OverlayTrigger>
        ) : component;
    }

    return (
        <PageWithMenuTemplate pageTitle={T.translate('pageTitle.lot', { lotName: lot.lotNumber })}>
            <Header title={headerTitle} button={headerButton}>
                <HeaderTextLabel className="me-4" label={T.translate('lot.label.lotNumber')}>
                    {lot.orderFormGenerated || lot.status === StatusLot.TO_CLOSE || lot.status === StatusLot.CLOSED ?
                        <div className="header-title p-1">{lot.lotNumber}</div> :
                        <EditableText className="editable-h1" id="lotNumber" type="text" defaultValue={lot.lotNumber} onSubmit={handleLotNumberChange} adjustWidthWithText={true} shouldSubmitOnBlur />}
                </HeaderTextLabel>
                <HeaderTextLabel className="me-4" label={T.translate('table.columns.status')}>
                    <div className="px-2 pt-1">
                        <StatusComponent type={lot.status === StatusLot.CLOSED ? ClosingReasonClassname[lot.closingReason] : StatusLotClassname[lot.status]}
                            subStatus={lot.closingReason === ClosingReason.NOT_COMPLIANT ? NotCompliantReason[lot.notCompliantReason] : null} />
                    </div>
                </HeaderTextLabel>
                <HeaderTextLabel className="me-auto" label={lot.status === StatusLot.CLOSED ? T.translate('lot.label.closedOn') : T.translate('lot.label.endControlDate')}>
                    <ClosingDateComponent
                        isMainDateEditable={lot.status !== StatusLot.CLOSED && lot.status !== StatusLot.TO_CLOSE}
                        mainDate={lot.status === StatusLot.CLOSED ? lot.closingDate : lot.endControlDate}
                        secondaryDate={lot.status === StatusLot.CLOSED ? lot.endControlDate : null}
                        onMainDateChange={handleEndControlDateChange} />
                </HeaderTextLabel>
                {lotTransferedResult?.length > 0 && (
                    <AppDropdown title={transferTitleComponent} className="destination-lot ms-2">
                        {lotTransferedResult?.map(destinationLotComponent)}
                    </AppDropdown>
                )}
            </Header>
            <Row className="gx-4 mx-1">
                <Col className="mb-4">
                    <CardLotInfosAndComments
                        className="h-100"
                        lot={lot}
                        onEditValue={handleEditValue}
                        onEditComment={handleEditComment} />
                </Col>
                <Col className="indicators-container mb-4">
                    <CardLotIndicators className="h-100" lotId={id} progress={state.progress} />
                </Col>
                <Col className="mb-4">
                    <CardDocuments className="h-100" entityId={id} documentType={DocumentType.LOT} />
                </Col>
                <Col sm={12} className="mb-4">
                    <CardLotControlPlan className="h-100" lotId={id} companyId={lot.companyId}
                        onGenerateOrders={handleGenerateOrders}
                        onCanceledTransfer={handleCanceledTransfer}
                        lotEntity={lot}
                    />
                </Col>
            </Row>
            <OverlayLoadingOrError isLoading={state.isLoading} error={state.error} onCloseError={goToPreviousPage} className="position-fixed" />
            <CloseLotModal lotId={lot.id} show={showCloseLotModal} hideModal={handleHideModal} initialComment={lot.closingReasonComment} />
            <AnalyseHistoryModal />
        </PageWithMenuTemplate>
    );
}

const mapStateToProps = state => {
    return {
        currentUserRole: state.authReducer.currentUser.role
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        generateDocument: () => dispatch(documentActions.generateDocument()),
        lotControlEdited: () => dispatch(lotActions.lotControlEdited()),
        lotCancelTransfer: () => dispatch(lotActions.lotCancelTransfer()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LotDetailsPage);
