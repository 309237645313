import React, {useEffect, useState} from "react";
import Header from "../../components/header/header";
import PageWithMenuTemplate from "../../components/template-pages/page-with-menu-template";
import T from "i18n-react";
import {UserRole} from "../../utils/enums/user-role";
import {Button} from "react-bootstrap-v5";
import {connect} from "react-redux";
import TableLotList from "./components/table-lot-list";
import {lotActions} from "../../redux/lot/actions-lot";
import CreateLotModal from "./components/create-lot-modal";
import HeaderTextLabel from "../../components/header/header-text-label";
import SearchInput from "../../components/input-label/search-input";
import CardIndicator from "../../components/card-indicator/card-indicator";
import {lotService} from "../../services/lot-service";
import {StatusLot} from "../../utils/enums/status-lot";
import {ClosingReason} from "../../utils/enums/closing-reason";

const LotsPage = ({currentUserRole, searchTextFromReducer, showCreateLotModal, lotSaveSearchText}) => {
    const [state, setState] = useState({isLoading: false, error: ''});
    const [searchText, setSearchText] = useState(searchTextFromReducer);
    const [nbLotsInProgress, setNbLotsInProgress] = useState(0);
    const [nbClosedLots, setNbClosedLots] = useState(0);
    const [nbClosedLotsCompliant, setNbClosedLotsCompliant] = useState(0);
    const [nbClosedLotsNotCompliant, setNbClosedLotsNotCompliant] = useState(0);

    useEffect(() => {
        const getIndicatorNbLotsInProgress = async () => {
            const response = await lotService.indicatorsLotsStatus(StatusLot.IN_PROGRESS, '', searchText);
            setNbLotsInProgress(response.numberOfLots);
        }

        const getIndicatorNbLotsClosed = async () => {
            const response = await lotService.indicatorsLotsStatus(StatusLot.CLOSED, '', searchText);
            setNbClosedLots(response.numberOfLots);
        }

        const getIndicatorNbLotsClosedCompliant = async () => {
            const response = await lotService.indicatorsLotsStatus(StatusLot.CLOSED, ClosingReason.COMPLIANT, searchText);
            setNbClosedLotsCompliant(response.numberOfLots);
        }

        const getIndicatorNbLotsClosedNotCompliant = async () => {
            const response = await lotService.indicatorsLotsStatus(StatusLot.CLOSED, ClosingReason.NOT_COMPLIANT, searchText);
            setNbClosedLotsNotCompliant(response.numberOfLots);
        }

        const getIndicators = async () => {
            try {
                setState({isLoading: true, error: ''});
                await getIndicatorNbLotsInProgress();
                await getIndicatorNbLotsClosed();
                await getIndicatorNbLotsClosedCompliant();
                await getIndicatorNbLotsClosedNotCompliant();
                setState({isLoading: false, error: ''});
            } catch (error) {
                setState({isLoading: false, error: error.message});
            }
        }

        getIndicators();
    }, [searchText]);

    const handleSearchChange = e => {
        const text = e.target.value;
        if (text.length === 0 || text.length >= 3) {
            setSearchText(text);
            lotSaveSearchText(text);
        }
    }

    const createLotButton = currentUserRole !== UserRole.VISITOR && (
        <Button variant="success" className="px-4" onClick={() => showCreateLotModal()}>
            {T.translate('lot.button.createLot')}
        </Button>
    );

    return (
        <React.Fragment>
            <PageWithMenuTemplate pageTitle={T.translate('pageTitle.lots')}>
                <Header>
                    <HeaderTextLabel className="me-4" isMain={true}>
                        <h1 className="ps-1">{T.translate('menu.lots')}</h1>
                    </HeaderTextLabel>
                </Header>
                <Header button={createLotButton}>
                    <div className="search-container">
                        <SearchInput onChange={handleSearchChange} initialValue={searchText}/>
                    </div>
                </Header>
                <div className="d-flex flex-wrap mx-3 mb-3 gap-3">
                    <CardIndicator isLoading={state.isLoading} error={state.error} label={T.translate('indicators.lotsInProgress')} value={nbLotsInProgress}/>
                    <CardIndicator isLoading={state.isLoading} error={state.error} label={T.translate('indicators.lotsClosed')} value={nbClosedLots}/>
                    <CardIndicator isLoading={state.isLoading} error={state.error} label={T.translate('indicators.lotsClosedCompliant')}
                                   value={nbClosedLotsCompliant}/>
                    <CardIndicator isLoading={state.isLoading} error={state.error} label={T.translate('indicators.lotsClosedNotCompliant')}
                                   value={nbClosedLotsNotCompliant}/>
                </div>
                <TableLotList className="mx-3"/>
            </PageWithMenuTemplate>
            <CreateLotModal/>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        currentUserRole: state.authReducer.currentUser.role,
        searchTextFromReducer: state.lotReducer.searchText
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showCreateLotModal: () => dispatch(lotActions.lotCreate()),
        lotSaveSearchText: (text) => dispatch(lotActions.saveSearchText(text))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LotsPage);
