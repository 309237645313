import React, {useCallback, useEffect, useState} from 'react';
import {connect} from "react-redux";
import T from 'i18n-react'
import {UserRole} from "../../utils/enums/user-role";
import {alertActions} from "../../redux/alert/actions-alert";
import {confirmDialogActions} from "../../redux/confirm-dialog/actions-confirm-dialog";
import OverlayLoadingOrError from "../overlay-component/overlay-loading-or-error";
import LocalTable from "../table/local-table";
import {Button, FormCheck} from "react-bootstrap-v5";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PositionOptionsMessageKey} from "../../utils/enums/position-options";
import {productService} from "../../services/product-service";
import {useParams} from "react-router-dom";
import {ToastClass} from "../toast-alert/toast-alert-class";
import CreateOrEditAdditionalInformationsModal from "./create-or-edit-additional-information-modal";
import {lotService} from "../../services/lot-service";

const TableAdditionalInformationList = ({
                                            isLot = false,
                                            className,
                                            showToast,
                                            currentUser,
                                            showConfirmDialog,
                                            showAdditionalInformationsModal,
                                            onHideAdditionalInformationsModal,
                                            onEditAdditionalInformation,
                                            isCreationMode,
                                            additionalInformationToEdit,
                                            isModificationInProgress = true,
                                        }) => {
    const {id} = useParams();

    const [state, setState] = useState({isLoading: false, error: ''});
    const [data, setData] = useState([]);

    const canEdit = isModificationInProgress &&
        (currentUser.role === UserRole.SUPER_ADMINISTRATOR || currentUser.role === UserRole.COMPANY_ADMINISTRATOR || currentUser.role === UserRole.SUPERVISOR);


    let columns = [
        {
            dataField: T.translate('table.fields.additionalInformation').toString(),
            text: T.translate('table.columns.additionalInformation').toString(),
            sort: true
        },
        {
            dataField: T.translate('table.fields.displayInSpecsSheet').toString(),
            text: T.translate('table.columns.specsSheet').toString(),
            sort: false
        },
        {
            dataField: T.translate('table.fields.displayInCertificate').toString(),
            text: T.translate('table.columns.certificate').toString(),
            sort: false
        },
        {
            dataField: T.translate('table.fields.displayPosition').toString(),
            text: T.translate('table.columns.position').toString(),
            sort: true
        },
    ];

    if (canEdit) {
        columns = [
            ...columns,
            {
                dataField: T.translate('table.fields.actions').toString(),
                text: T.translate('table.columns.actions').toString(),
                sort: false
            },
        ];
    }

    const completeElement = useCallback((element) => {
        const handleDeletedAdditionalInformation = additionalInformationId => {
            setData(prevData => {
                const newData = [...prevData];
                const index = newData.findIndex(d => d.id === additionalInformationId);
                if (index !== -1) {
                    newData.splice(index, 1);
                }
                return newData;
            });
        }

        const deleteAdditionalInformation = async additionalInformation => {
            try {
                if (isLot) {
                    await lotService.deleteAdditionalInformation(id, additionalInformation.lotAdditionalInfoId);
                } else {
                    await productService.deleteAdditionalInformation(id, additionalInformation.productAdditionalInfoId);
                }
                showToast(T.translate('alert.successTitle'), T.translate('product.successDeleteAdditionalInformation', {additionalInformation: additionalInformation.title}), ToastClass.SUCCESS);
                handleDeletedAdditionalInformation(isLot ? additionalInformation.lotAdditionalInfoId : additionalInformation.productAdditionalInfoId);
            } catch (error) {
                showToast(T.translate('alert.errorTitle'), error.message, ToastClass.ERROR);
            }
        }

        const handleDeleteAdditionalInformation = async (e, additionalInformation) => {
            e.stopPropagation();
            showConfirmDialog(
                T.translate('dialog.title.confirmDelete'),
                T.translate('dialog.confirmDeleteAdditionalInformation', {additionalInformation: additionalInformation.title}),
                () => null,
                () => deleteAdditionalInformation(additionalInformation))
        }

        const handleEditAdditionalInformation = (e, additionalInformation) => {
            e.stopPropagation();
            onEditAdditionalInformation(additionalInformation);
        }

        return {
            ...element,
            id: isLot ? element.lotAdditionalInfoId : element.productAdditionalInfoId,
            additionalInformation: (
                <div>
                    <div><strong>{element.title}</strong></div>
                    <div>{element.content}</div>
                </div>
            ),
            displayInSpecsSheet: <FormCheck className="ms-2" disabled={true} checked={element.specsSheet}/>,
            displayInCertificate: <FormCheck className="ms-2" disabled={true} checked={element.certificate}/>,
            displayPosition: T.translate(PositionOptionsMessageKey[element.position]),
            actions: (
                <div className="actions">
                    <Button size="sm" className="edit" onClick={e => handleEditAdditionalInformation(e, element)}>
                        <FontAwesomeIcon icon="edit"/>
                    </Button>
                    <Button size="sm" className="delete ms-1" onClick={e => handleDeleteAdditionalInformation(e, element)}>
                        <FontAwesomeIcon icon="times"/>
                    </Button>
                </div>
            )
        }
    }, [showConfirmDialog, id, showToast, onEditAdditionalInformation, isLot]);

    useEffect(() => {
        const getAdditionalInformations = async () => {
            setState(prevState => {
                return {...prevState, isLoading: true};
            });
            try {
                let additionalInformations;
                if (isLot) {
                    additionalInformations = await lotService.getAdditionalInformations(id);
                } else {
                    additionalInformations = await productService.getAdditionalInformations(id);
                }
                const formattedData = additionalInformations.map(element => completeElement(element));
                setData(formattedData);
                setState(prevState => {
                    return {...prevState, isLoading: false, error: ''};
                });
            } catch (error) {
                setState(prevState => {
                    return {...prevState, isLoading: false, error: error.message};
                });
            }
        }

        getAdditionalInformations();
    }, [id, completeElement, isLot]);

    const handleHideModal = returnData => {
        onHideAdditionalInformationsModal();
        if (returnData != null) {
            const formattedAdditionalInformation = completeElement(returnData);
            setData(prevData => {
                const index = prevData.findIndex(d => d.id === formattedAdditionalInformation.id);
                if (index !== -1) {
                    const newData = [...prevData];
                    newData.splice(index, 1, formattedAdditionalInformation);
                    return newData;
                }
                return [...prevData, formattedAdditionalInformation];
            });
        }
    }

    return (
        <div>
            <LocalTable id="table-additional-informations-list" className={className} columns={columns} data={data}/>
            <OverlayLoadingOrError isLoading={state.isLoading}/>
            <CreateOrEditAdditionalInformationsModal isLot={isLot}
                                                     entityId={id}
                                                     show={showAdditionalInformationsModal}
                                                     isCreationMode={isCreationMode}
                                                     additionalInformationToEdit={additionalInformationToEdit}
                                                     onHide={handleHideModal}/>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: (title, message, className) => dispatch(alertActions.addToast(title, message, className)),
        showConfirmDialog: (title, body, onCancel, onConfirm) => dispatch(confirmDialogActions.showConfirmDialog(title, body, onCancel, onConfirm)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableAdditionalInformationList);
