export const ClosingReason = {
    COMPLIANT: "COMPLIANT",
    NOT_COMPLIANT: "NOT_COMPLIANT",
    INTERRUPTED: "INTERRUPTED"
}

export const ClosingReasonMessageKey = {
    COMPLIANT: "closingReason.compliant",
    NOT_COMPLIANT: "closingReason.notCompliant",
    INTERRUPTED: "closingReason.interrupted"
}

export const ClosingReasonShortcuts = {
    COMPLIANT: "C",
    NOT_COMPLIANT: "NC",
    INTERRUPTED: "I"
}

export const ClosingReasonClassname = {
    COMPLIANT: "compliant",
    NOT_COMPLIANT: "notCompliant",
    INTERRUPTED: "interrupted"
}