import {ApiErrorHandler} from "./api/api-error";
import {privateAPI} from "./api/api";
import axios from "axios";

const BASE_URL = '/lots/';

let sourceGetAll;
const getAllAndCancelPreviousCall = async (page = 1, size = 10, sortDirection = 'DESC', sortFieldName = 'creationDate', searchText = '') => {
    try {
        if (sourceGetAll) {
            sourceGetAll.cancel();
        }
        sourceGetAll = axios.CancelToken.source();
        const response = await privateAPI.get(`${BASE_URL}?page=${page}&size=${size}&sortDirection=${!!sortDirection ? sortDirection : 'DESC'}&sortFieldName=${!!sortFieldName ? sortFieldName : 'creationDate'}&searchText=${searchText}`, {cancelToken: sourceGetAll.token});
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getAllByLotNumber = async (lotId, searchText = '') => {
    try {
        const response = await privateAPI.get(`${BASE_URL}${lotId}/numberLot?searchtext=${searchText}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getAllByStatus = async (status, closingReason, page = 1, size = 10, sortDirection = 'DESC', sortFieldName = 'creationDate', searchText = '', data) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}status/${status}?closingReason=${closingReason}&page=${page}&size=${size}&sortDirection=${!!sortDirection ? sortDirection : 'DESC'}&sortFieldName=${!!sortFieldName ? sortFieldName : 'creationDate'}&searchText=${searchText}`, data);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getAllNotCompliant = async (page = 1, size = 10, sortDirection = 'ASC', sortFieldName = 'lot.lotNumber', searchText = '') => {
    try {
        const response = await privateAPI.get(`${BASE_URL}analyses/notCompliant?page=${page}&size=${size}&sortDirection=${!!sortDirection ? sortDirection : 'ASC'}&sortFieldName=${!!sortFieldName ? sortFieldName : 'lot.lotNumber'}&searchText=${searchText}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getLotById = async (id) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}${id}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const createLot = async (lot) => {
    try {
        const response = await privateAPI.post(BASE_URL, lot);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const deleteLot = async (id) => {
    try {
        return await privateAPI.delete(`${BASE_URL}${id}`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editLot = async (id, data) => {
    try {
        return await privateAPI.patch(`${BASE_URL}${id}`, data);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editLotComment = async (id, comment) => {
    try {
        return await privateAPI.put(`${BASE_URL}${id}/comment`, comment, {headers: {'content-type': 'text/plain'}});
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const validateLot = async (id) => {
    try {
        await privateAPI.post(`${BASE_URL}${id}/validate`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}
const closeLot = async (id, data) => {
    try {
        return await privateAPI.post(`${BASE_URL}${id}/close`, data);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const reopenLot = async (id) => {
    try {
        return await privateAPI.post(`${BASE_URL}${id}/reopen`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const addAnalysisToLot = async (lotId, analysisIdList) => {
    try {
        return await privateAPI.post(`${BASE_URL}${lotId}/analyses`, analysisIdList);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getControlsByLotId = async (lotId, sortDirection, sortFieldName) => {
    try {
        const response = await privateAPI.put(`${BASE_URL}${lotId}/analyses?sortDirection=${!!sortDirection ? sortDirection : 'ASC'}&sortFieldName=${!!sortFieldName ? sortFieldName : 'analysisName'}`);
        return response.data
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editControl = async (lotId, controlId, data) => {
    try {
        return await privateAPI.patch(`${BASE_URL}${lotId}/analyses/${controlId}`, data);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editControlComment = async (lotId, controlId, comment) => {
    try {
        return await privateAPI.put(`${BASE_URL}${lotId}/comment/${controlId}`, comment, {headers: {'content-type': 'text/plain'}});
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editControlDocument = async (lotId, controlId, documentId) => {
    try {
        return await privateAPI.put(`${BASE_URL}${lotId}/document/${controlId}`, documentId, {headers: {'content-type': 'text/plain'}});
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const deleteControl = async (lotId, controlId) => {
    try {
        return await privateAPI.delete(`${BASE_URL}${lotId}/analyses/${controlId}`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const generateControlFile = async (id) => {
    try {
        return await privateAPI.post(`${BASE_URL}${id}/controlfile`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const generateConformity = async (id) => {
    try {
        return await privateAPI.post(`${BASE_URL}${id}/conformity`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const generateCertificate = async (id) => {
    try {
        return await privateAPI.post(`${BASE_URL}${id}/certificate`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const generateCertificateWithAnnexes = async (id) => {
    try {
        return await privateAPI.post(`${BASE_URL}${id}/certificateWithAnnexes`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const generateOrders = async (id) => {
    try {
        return await privateAPI.post(`${BASE_URL}${id}/purchaseOrder`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getAnalyseHistory = async (analyseId, lotId) => {
    try {
        const response = await privateAPI.get(`/lots/results/from-lot/${lotId}/analyses/${analyseId}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getParameterHistory = async (analyseId, name, lotId) => {
    try {
        const response = await privateAPI.get(`/lots/results/from-lot/${lotId}/analyses/${analyseId}/parameter-base-64/${name}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getAdditionalInformations = async (lotId) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}${lotId}/additional-infos`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const createAdditionalInformation = async (lotId, data) => {
    try {
        return await privateAPI.post(`${BASE_URL}${lotId}/additional-infos`, data);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const deleteAdditionalInformation = async (lotId, additionalInformationId) => {
    try {
        const response = await privateAPI.delete(`${BASE_URL}${lotId}/additional-infos/${additionalInformationId}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const editAdditionalInformation = async (lotId, additionalInformationId, data) => {
    try {
        return await privateAPI.put(`${BASE_URL}${lotId}/additional-infos/${additionalInformationId}`, data);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const indicatorsLotsClosed = async () => {
    try {
        const response = await privateAPI.get(`${BASE_URL}indicators/closed-lots`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const indicatorsLotsClosedCompliance = async () => {
    try {
        const response = await privateAPI.get(`${BASE_URL}indicators/closed-lots-compliant`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const indicatorsLotsClosedNbAnalysis = async () => {
    try {
        const response = await privateAPI.get(`${BASE_URL}indicators/closed-lots-ordered-analyses`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const indicatorsLotsClosedControlPrice = async () => {
    try {
        const response = await privateAPI.get(`${BASE_URL}indicators/closed-lots-control-price`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const indicatorsLotsStatus = async (status, closingReason = '', searchText = '') => {
    try {
        const response = await privateAPI.get(`${BASE_URL}indicators/${status}?searchText=${searchText}&closingReason=${closingReason}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const indicatorsAnalysesCostForLot = async (lotId) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}${lotId}/indicators/control-price`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const getAllTransferableAnalysis = async (lotSourceId, lotDestId) => {
    try {
        const response = await privateAPI.get(`${BASE_URL}${lotSourceId}/transferable-analysis/${lotDestId}`);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const linkLots = async (data) => {
    try {
        const response = await privateAPI.post(`${BASE_URL}link`, data);
        return response.data;
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const transferResults = async (lotSourceId, lotDestId, data) => {
    try {
        return await privateAPI.patch(`${BASE_URL}${lotSourceId}/transfer-analysis-to/${lotDestId}`, data);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

const cancelTransfer = async (lotId) => {
    try {
        return await privateAPI.post(`${BASE_URL}${lotId}/cancel-transfer`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}


const cancelTransferAnalyseWithoutLink = async (destinationLotId, destinationLotAnalysisId) => {
    try {
        return await privateAPI.post(`${BASE_URL}${destinationLotId}/cancel-transfer-without-link/${destinationLotAnalysisId}`);
    } catch (error) {
        error.message = ApiErrorHandler.getErrorMessage(error);
        throw error;
    }
}

export const lotService = {
    getAllAndCancelPreviousCall,
    getAllByLotNumber,
    getAllByStatus,
    getAllNotCompliant,
    getLotById,
    createLot,
    deleteLot,
    editLot,
    editLotComment,
    validateLot,
    closeLot,
    reopenLot,
    addAnalysisToLot,
    getControlsByLotId,
    editControl,
    editControlComment,
    editControlDocument,
    deleteControl,
    generateControlFile,
    generateConformity,
    generateCertificate,
    generateCertificateWithAnnexes,
    generateOrders,
    getAnalyseHistory,
    getParameterHistory,
    getAdditionalInformations,
    createAdditionalInformation,
    deleteAdditionalInformation,
    editAdditionalInformation,
    indicatorsLotsClosed,
    indicatorsLotsClosedCompliance,
    indicatorsLotsClosedNbAnalysis,
    indicatorsLotsClosedControlPrice,
    indicatorsLotsStatus,
    indicatorsAnalysesCostForLot,
    getAllTransferableAnalysis,
    transferResults,
    linkLots,
    cancelTransfer,
    cancelTransferAnalyseWithoutLink
};
