import {backendUrl} from "./api/api";

const BASE_URL = '/statistics/';

const getExportCsvUrl = (statsData) => {
    return `${backendUrl}${BASE_URL}export/csv?companyId=${statsData.companyId}&creationDateStart=${statsData.creationDateStart}&creationDateEnd=${statsData.creationDateEnd}&closingDateStart=${statsData.closingDateStart}&closingDateEnd=${statsData.closingDateEnd}&articleCode=${statsData.articleCode}&productName=${statsData.productName}`;
}

export const statsService = {
    getExportCsvUrl
};
