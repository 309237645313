import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Table} from "react-bootstrap-v5";
import './card-documents.scss'
import T from "i18n-react";
import Moment from "react-moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OverlayLoadingOrError from "../overlay-component/overlay-loading-or-error";
import {documentService} from "../../services/document-service";
import {useHistory} from "react-router-dom";
import {Routes} from "../../router/router-constants";
import {SortDirection} from "../../utils/enums/sort-direction";
import {UserRole} from "../../utils/enums/user-role";
import {connect} from "react-redux";
import {ping} from "../../services/api/api";
import {DocumentType} from "../../utils/enums/document-type";

const CardDocuments = ({className, entityId, documentType, currentUserRole, progress}) => {

    const history = useHistory();
    const downloadDocRef = useRef();

    const [apiError, setApiError] = useState('');
    const [documentList, setDocumentList] = useState([]);

    const handleManageDocuments = () => {
        let entityUrl = '';
        if (documentType === DocumentType.LABORATORY) {
            entityUrl = Routes.LABORATORIES_PAGE;
        } else if (documentType === DocumentType.PRODUCT) {
            entityUrl = Routes.PRODUCTS_PAGE;
        } else if (documentType === DocumentType.LOT) {
            entityUrl = Routes.LOTS_PAGE;
        }
        history.push(`${entityUrl}/${entityId}/documents`)
    }

    const downloadDocument = async (fileUri) => {
        downloadDocRef.current.href = fileUri;
        // ici on ping pour éventuellement forcer axios à appeler le refresh token et éviter sinon que le download envoie un 401 car c'est un appel backend direct
        await ping();
        downloadDocRef.current.click();
    }

    useEffect(() => {
        const getDocuments = async () => {
            try {
                const data = await documentService.getAllByEntityId(entityId, 1, 5, SortDirection.DESC.toUpperCase(), 'createdDate', '');
                setDocumentList(data.content);
            } catch (error) {
                setApiError(error.message);
            }
        }
        getDocuments();
    }, [entityId, progress]);

    const headerButton = currentUserRole && currentUserRole !== UserRole.VISITOR ? (
        <Button variant="secondary" size="sm" className="small-button px-3" onClick={handleManageDocuments}>
            {T.translate('button.manageDocuments')}
        </Button>
    ) : '';

    return (
        <Card className={`card-documents ${className}`}>
            <Card.Header className="d-flex justify-content-between align-items-center">
                <Card.Title className="m-0">{T.translate('title.documents')}</Card.Title>
                {headerButton}
            </Card.Header>
            <Card.Body>
                <Table striped={true} size="sm">
                    <tbody>
                    {documentList.map(doc => (
                        <tr key={`document-${doc.documentId}`}>
                            <td className="document-item">
                                <div className="document-name">{doc.name}</div>
                                <div className="document-description">{doc.description}</div>
                            </td>
                            <td className="createdDate"><Moment format={T.translate('i18n.format.dateDayAndMonth').toString()}>{doc.createdDate}</Moment></td>
                            <td className="actions">
                                <div className="btn btn-primary btn-sm" onClick={() => downloadDocument(documentService.getDownloadUrl(doc.fileDownloadUri))}>
                                    <FontAwesomeIcon icon="download"/>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <a ref={downloadDocRef} href="#0" hidden={true} download>download</a>
            </Card.Body>
            <OverlayLoadingOrError error={apiError}/>
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        currentUserRole: state.authReducer.currentUser.role,
        progress: state.documentReducer.progress
    }
}

export default connect(mapStateToProps)(CardDocuments);