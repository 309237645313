export default class AnalyseDto {
    constructor(analyseData, isCreationMode) {
        if (isCreationMode) {
            this.companyId = analyseData?.company?.id ?? '';
        }
        this.name = analyseData?.name ?? '';
        this.method = analyseData?.method ?? '';
        this.technique = analyseData?.technique ?? '';
        this.unit = analyseData?.unit ?? '';
        this.categoryId = analyseData?.category?.id ?? '';
        this.mainLaboratoryId = analyseData?.mainLaboratoryId;
        this.laboratoryDetails = analyseData?.laboratoryList ? analyseData.laboratoryList.map(lab => {
            return {
                laboratoryId: lab.id,
                price: lab.price,
                delayInDays: lab.delay,
                quantityInGram: lab.quantity,
                reference: lab.reference
            }
        }) : [];
        this.laboratoryParameters = analyseData?.laboratoryParameters ? analyseData?.laboratoryParameters.map(laboratoryParameter => {
            return {
                laboratoryId: laboratoryParameter.laboratoryId ?? '',
                name: laboratoryParameter.parameterName ?? '',
                reference: laboratoryParameter.reference ?? ''
            }
        }) : [];
    }
}